import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

export const RestaurantMovieModel = SimpleRestModel({
  collectionName: 'restaurantsMovies',
  HttpApi,
  getApiPath: (payload, action: string) => {
    switch (action) {
      case 'loadCollection':
      case 'createItem':
      default:
        return `restaurants/${payload.restaurantId}/movies/`;
      case 'loadItem':
      case 'updateItem':
      case 'deleteItem':
        return `restaurants/${payload.restaurantId}/movies/${payload.id}/`;
      case 'customCollectionAction':
        return `restaurants/${payload.restaurantId}/movies/${payload.action}/`;
      case 'customItemAction':
        return `restaurants/${payload.restaurantId}/movies/${payload.id}/${payload.action}/`;
    }
  },
});
