import { styled } from '../../styled-components';

// {
//   align?: string;
// }
export const DrawerContent = styled.div.attrs({
  className: 'DrawerContent',
})`
  text-align: ${p => (p.align ? p.align : 'left')};
  overflow: auto;
  padding-right: ${p => p.theme.space};
  position: relative;
`;
