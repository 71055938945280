/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FunctionComponent } from 'react';
import { FormikProps, FormikValues } from 'formik';
import { Group } from '../ui';
import { styled } from '../ui/styled-components';
import { FormField } from './FormField';

export interface AddressFieldProps extends FormikProps<FormikValues> {
  streetFieldName: string;
  postalCodeFieldName: string;
  cityFieldName: string;
  houseNumberFieldName: string;
  detailsFieldName: string;
  label?: string;
  type?: string;
  vertical?: boolean;
  onEnter?: Function;
}

const FullWidthContainer = styled.div`
  width: 100%;
`;

const AddressField: FunctionComponent<AddressFieldProps> = props => {
  return (
    <Group vertical={props.vertical}>
      <FullWidthContainer>
        <FormField {...props} name={props.streetFieldName} label="Ulica" width={'100%'} />
        <FormField {...props} name={props.houseNumberFieldName} label="nr budynku" width={'50%'} />
        <FormField {...props} name={props.detailsFieldName} label="nr lokalu, piętro etc." width={'100%'} />
        <FormField {...props} name={props.postalCodeFieldName} label="Kod pocztowy" width={'100%'} />
        <FormField {...props} name={props.cityFieldName} label="Miejscowość" width={'100%'} />
      </FullWidthContainer>
    </Group>
  );
};

export { AddressField };
