import { Dropdown } from 'antd';
import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent, ReactElement } from 'react';

import { Icon, IconColor, IconFa, IconSize } from '../..';
import { ButtonVariant } from './ButtonVariant';
import { ButtonSize } from './ButtonSize';

import styles from './Button.module.scss';

export type ButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  iconColor?: IconColor;
  iconName?: string;
  iconFa?: IconFa;
  label?: string;
  onClick?: Function;
  disabled?: boolean;
  loading?: boolean;
  dropdown?: ReactElement;
  style?: CSSProperties;
};

const ButtonDefaultProps = {
  variant: ButtonVariant.Default,
  iconFa: IconFa.Fal,
  iconColor: IconColor.Default,
};

export const Button: FunctionComponent<ButtonProps> = ({
  variant,
  size,
  children,
  label,
  onClick,
  disabled,
  loading,
  iconName,
  iconFa,
  iconColor,
  dropdown,
  style,
}) => (
  <Dropdown overlay={dropdown} trigger={['click']} disabled={!dropdown}>
    <div
      style={style}
      className={classNames({
        [styles.Button]: true,
        [styles.vPrimary]: variant === ButtonVariant.Primary,
        [styles.vPlain]: variant === ButtonVariant.Plain,
        [styles.vAction]: variant === ButtonVariant.Action,
        [styles.disabled]: disabled,
        [styles.loading]: loading,
        [styles.small]: size === ButtonSize.Small,
        [styles.large]: size === ButtonSize.Large,
      })}
      onClick={e => {
        if (onClick && !disabled && !loading) {
          onClick(e);
        }
      }}
    >
      {(loading || iconName) && (
        <div
          className={classNames(styles.icon, {
            [styles.iconWithMargin]: label || children,
          })}
        >
          <Icon
            name={loading ? 'fa-spinner-third' : iconName || ''}
            fa={iconFa}
            color={iconColor}
            size={
              size === ButtonSize.Small
                ? IconSize.SmallX
                : size === ButtonSize.Large
                ? IconSize.LargeX
                : IconSize.Default
            }
            spin={loading}
          />
        </div>
      )}
      {(label || children) && (
        <div className={styles.label}>
          {label} {children}
        </div>
      )}
    </div>
  </Dropdown>
);

Button.defaultProps = ButtonDefaultProps;
