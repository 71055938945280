export const printPrintables: (selector: string) => void = selector => {
  /*
    it's a little hacky way to do it, but it works :)
    cloned printable elements to document.body
    display print window (with those elements and hidden all others)
    immediately delete cloned elements

    it requires some CSS code to hide all other elements for printing, f.e.:
    @media print {
      body > *:not([data-printable]) {
      {
        display: none;
      }
    }
   */
  const printables = document.querySelectorAll(selector);
  const clones: Node[] = [];
  printables.forEach(e => {
    if (e.parentElement !== document.body) {
      const cloned = e.cloneNode(true);
      clones.push(cloned);
      document.body.appendChild(cloned);
    }
  });
  window.print();
  clones.forEach(cloned => document.body.removeChild(cloned));
};
