import { format } from 'date-fns';

import { Meal } from 'modules/Restaurant';
import { flatten, pick, uniq } from 'rambdax';
import { BehaviorSubject } from 'rxjs';

import { BasketMealExtras, OrderFormBasketSetMeal, OrderFormBasketSetModel, OrderFormState } from './OrderFormState';

const defaultState = {
  client: null,
  clientName: null,
  address: null,
  phone: null,
  email: null,
  company: null,
  basket: {
    selectedeSet: 0,
    sets: [
      {
        name: 'Zestaw 1',
        meals: [],
      },
    ],
    extraExtras: {},
  },
  newClientData: null,
  restaurant: null,
  restaurantMealSelectedOnStep3: null,
  deliveryDate: null,
  deliveryCost: null,
  deliveryTime: null,
  paymentType: null,
  promotion: null,
  withDirectPickUp: false,
  allDeliveryCosts: [],
  note: '',
  bonus: '',
  driverBonusAmount: 0,
  driverBonusDescription: '',
  privateNote: '',
  clientNote: '',
};

const state$ = new BehaviorSubject<OrderFormState>(defaultState);

// for debug only
state$.asObservable().subscribe(state => {
  // console.log('%c OrderFormService.state ->', 'background:#ddd; color: black; padding:2px 20px;', state);
});

export const OrderFormService = {
  setState: (state: Partial<OrderFormState>) => {
    state$.next({
      ...state$.getValue(),
      ...state,
    });
  },
  resetState: () => {
    state$.next(defaultState);
  },
  getState: () => state$,
  getStateValue: () => state$.getValue(),
  getStateAsObservable: () => state$.asObservable(),
  // save helpers
  saveRestaurantWithDeliveryCost: restaurantWithDeliveryCosts => {
    const currentAllDeliveryCosts = state$
      .getValue()
      .allDeliveryCosts.filter(
        deliveryCostForRestaurant =>
          deliveryCostForRestaurant.restaurantId !== restaurantWithDeliveryCosts.restaurant.id,
      );

    OrderFormService.setState({
      restaurant: restaurantWithDeliveryCosts.restaurant,
      deliveryCost: restaurantWithDeliveryCosts.deliveryInfo.cost,
      deliveryTime: restaurantWithDeliveryCosts.deliveryInfo.time,
      allDeliveryCosts: [
        ...currentAllDeliveryCosts,
        {
          restaurantId: restaurantWithDeliveryCosts.restaurant.id,
          cost: restaurantWithDeliveryCosts.deliveryInfo.cost,
        },
      ],
    });
  },
  // helpers
  getBasketSummary: () => {
    const state = state$.getValue();

    // calculate the sum for extra-extras
    const extraExtrasSum = OrderFormService.getBasketExtraExtrasSum();
    const deliveryCost = state.withDirectPickUp ? 0 : OrderFormService.getDeliveryCost();
    return {
      sum:
        deliveryCost +
        state.basket.sets.reduce((sum, set) => sum + OrderFormService.getBasketSetSummary(set), 0) +
        extraExtrasSum,
      sets: state.basket.sets.map(set => ({
        name: set.name,
        sum: OrderFormService.getBasketSetSummary(set),
      })),
      extraExtrasSum,
    };
  },
  getBasketSetSummary: (set: OrderFormBasketSetModel): number => {
    return set.meals.reduce((sum, basketMeal) => {
      return sum + basketMeal.meal.price * basketMeal.counter + OrderFormService.getBasketSetMealSummary(basketMeal);
    }, 0);
  },
  getBasketSetMealSummary: basketMeal => {
    return basketMeal.extras.reduce(
      (sum, basketMealExtras) =>
        sum +
        basketMealExtras.reduce((extrasSum, extrasItem) => extrasSum + extrasItem.priceChange * extrasItem.counter, 0),
      0,
    );
  },
  getBasketExtraExtrasSum: () => {
    return OrderFormService.getBasketExtraExtrasAsArray().reduce((sum, extra) => sum + extra.price * extra.counter, 0);
  },
  getBasketExtraExtrasAsArray: () => {
    const state = state$.getValue();

    // filter out the extras that has counter set to 0 - it means they are not selected
    return Object.keys(state.basket.extraExtras)
      .filter(extraId => state.basket.extraExtras[extraId].counter > 0)
      .map(extraId => {
        const extra = state.basket.extraExtras[extraId];
        extra.sum = extra.price * extra.counter;
        return extra;
      });
  },
  getBasketMealFromCurrentSet: (meal: Meal): OrderFormBasketSetMeal | undefined => {
    const orderState = state$.getValue();
    const currentSet = orderState.basket.sets[orderState.basket.selectedeSet];
    return currentSet.meals.find(setMeal => meal.id === setMeal.meal.id);
  },
  addOrUpdateBasketMealInCurrentSet: (meal: Meal, counter: number, extras: Array<BasketMealExtras[]>, note): void => {
    const orderState = state$.getValue();
    const sets = [...orderState.basket.sets];
    let currentSet = sets[orderState.basket.selectedeSet];
    const basketMealIndex = currentSet.meals.findIndex(setMeal => meal.id === setMeal.meal.id);

    const basketMeal = {
      meal,
      restaurant: orderState.restaurant,
      counter,
      note,
      extras,
    };

    if (basketMealIndex > -1) {
      currentSet.meals[basketMealIndex] = basketMeal;
    } else {
      currentSet = {
        ...currentSet,
        meals: [...currentSet.meals, basketMeal],
      };
    }

    sets[orderState.basket.selectedeSet] = currentSet;
    OrderFormService.setState({
      basket: {
        ...orderState.basket,
        sets,
      },
    });
  },
  getDeliveryCost: () => {
    const state = state$.getValue();
    if (state.withDirectPickUp) {
      return 0;
    }
    const restaurantIdsArray = state.basket.sets.map(basketSet =>
      basketSet.meals.map(basketMeal => basketMeal.restaurant && basketMeal.restaurant.id),
    );
    const restaurantIds = uniq(flatten(restaurantIdsArray));
    const allCosts = restaurantIds.map(restaurantId => {
      const restaurantCost = state.allDeliveryCosts.find(c => c.restaurantId === restaurantId);
      return restaurantCost ? restaurantCost.cost : 0;
    });

    if (restaurantIds.length === 1) {
      if (allCosts[0]) {
        return allCosts[0];
      }
      throw new Error(
        `OrderFormService.getDeliveryCost :: restaurantDeliveryCost not found for resturant: ${restaurantIds[0]}`,
      );
    } else if (restaurantIds.length > 1) {
      const maxCost = Math.max(...allCosts);
      return maxCost + 990 * (allCosts.length - 1);
    }

    return 0;
  },
  getDiscountSum: () => {
    const state = state$.getValue();
    let discountSum = 0;
    if (state.client && state.client.discount) {
      discountSum = (OrderFormService.getBasketSummary().sum * state.client.discount) / 100;
    }
    return discountSum;
  },
  getClientBalance: () => {
    const state = state$.getValue();
    if (state.client && state.client.discount) {
      return state.client.discount;
    }
    return 0;
  },
  getFinalSum: () => {
    return (
      OrderFormService.getBasketSummary().sum - OrderFormService.getClientBalance() - OrderFormService.getDiscountSum()
    );
  },
  //  create & update requests body
  getBaseModelForApi: () => {
    const state = state$.getValue();

    return {
      customerNote: state.clientNote || (state.client && state.client.notes),
      notes: state.note,
      privateNotes: state.privateNote,
      gross: OrderFormService.getFinalSum(),
      // discount: null,
      bonus: state.bonus,
      withDirectPickUp: !!state.withDirectPickUp,
      driverBonusAmount: !state.withDirectPickUp ? state.driverBonusAmount : undefined,
      driverBonusDescription: !state.withDirectPickUp ? state.driverBonusDescription : undefined,
      orderType: 0,
      deliveryDate: state.deliveryDate ? format(state.deliveryDate, 'YYYY-MM-DD HH:mm') : undefined,
      deliveryAddress: {
        id: state.address && state.address.id ? state.address.id : null,
        // addressCity: state.address && state.address.addressCity,
        // addressData: state.address && state.address.addressData,
        // addressPostalCode: state.address && state.address.addressPostalCode,
        // notes: state.address && state.address.notes,
        email: state.email,
        phone: state.phone,
        // lon: state.address && state.address.lon,
        // lat: state.address && state.address.lat,
        deliveryCost: OrderFormService.getDeliveryCost(),
        deliveryTime: state.deliveryTime,
      },
      invoiceData: state.company ? state.company.id : null,
      paymentData: state.paymentType,
      basket: {
        sets: state.basket.sets.map(basketSet => ({
          name: basketSet.name,
          meals: basketSet.meals.map(basketSetMeal => ({
            meal: pick(['id'], basketSetMeal.meal),
            counter: basketSetMeal.counter,
            note: basketSetMeal.note,
            extras: basketSetMeal.extras.map(arrayOfExtras => [
              ...arrayOfExtras.map(extras => ({
                id: extras.id,
                counter: extras.counter,
              })),
            ]),
          })),
        })),
        extraExtras: Object.keys(state.basket.extraExtras)
          .filter(extraExtraId => state.basket.extraExtras[extraExtraId].counter > 0)
          .map(extraExtraId => {
            const basketExtraExtra = state.basket.extraExtras[extraExtraId];
            return {
              id: extraExtraId,
              counter: basketExtraExtra.counter,
            };
          }),
      },
    };
  },
  getModelForCreate: () => {
    const state = state$.getValue();

    return {
      ...OrderFormService.getBaseModelForApi(),
      customer: state.client && state.client.id,
    };
  },
  getModelForUpdate: () => {
    return {
      ...OrderFormService.getBaseModelForApi(),
    };
  },
};
// @ts-ignore
// window.o = OrderFormService;
