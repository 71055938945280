import React, { FunctionComponent, ReactNode } from 'react';

import { formattedDate, styled, ensureDateObject, formattedTime } from '../..';

export interface DateTimeRangeProps {
  children?: ReactNode;
  start: string | number;
  end: string | number;
  short?: boolean;
}

const DateTimeRangeDefaultProps = {};

const DateTimeRangeContainer = styled.span``;

const DateTimeRange: FunctionComponent<DateTimeRangeProps> = props => {
  const start = ensureDateObject(props.start);
  const end = ensureDateObject(props.end);

  return (
    <DateTimeRangeContainer>
      od {formattedDate(start)} {formattedTime(start)} do {formattedDate(end)} {formattedTime(end)}
    </DateTimeRangeContainer>
  );
};

DateTimeRange.defaultProps = DateTimeRangeDefaultProps;

export { DateTimeRange };
