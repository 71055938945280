export const getLabelForStatus = (status: number) => {
  switch (status) {
    default:
    case 0:
      return 'Nowe';
    case 10:
      return 'Przyjęte przez kierowcę';
    case 20:
      return 'Odebrane z restauracji';
    case 30:
      return 'Klient odebrał zamówienie';
    case 35:
      return 'Klient oczekuje faktury';
    case 36:
      return 'Klient odbiera zamówienie';
    case 40:
      return 'Sfinalizowane';
  }
};
