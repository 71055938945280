import { OrderFormService } from 'modules/Orders/OrderFormService';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';

import { SelectableList, styled } from 'lib/ui';
import connect from 'react-redux/es/connect/connect';

export interface OrderFormPromotionPickerProps {
  selected: number | null;
}

const OrderFormPromotionPickerContainer = styled.div.attrs({ className: 'OrderFormPromotionPickerContainer' })``;

const enhance = connect(
  ({ promotions, loading }) => ({
    promotionsStore: promotions,
    loading,
  }),
  ({ promotions }) => ({
    promotions,
  }),
);

export const OrderFormPromotionPicker: FunctionComponent<OrderFormPromotionPickerProps> = enhance(props => {
  const orderState = OrderFormService.getStateValue();

  useEffect(() => {
    props.promotions.loadCollection();
  }, []);

  return (
    <OrderFormPromotionPickerContainer>
      <SelectableList
        onSelectItem={item => {
          OrderFormService.setState({
            promotion: +item.id,
          });
        }}
        options={props.promotionsStore.data.map(item => ({
          name: item.namePl,
          id: item.id,
        }))}
        selectedItemId={orderState.promotion}
      />
    </OrderFormPromotionPickerContainer>
  );
});
