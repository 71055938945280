import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  FormField,
  ButtonSubmit,
  FormTextareaField,
  FormFieldInputNumber,
} from 'lib/forms';
import { Group, Modal, ModalProps, styled } from 'lib/ui';
import { stringRequired } from 'lib/forms/YupValidation';

export interface RestaurantMenuGroupModalProps extends ModalProps {
  children?: ReactNode;
  onSubmit: Function;
  item?: any;
  withoutDescription?: boolean;
  withNumber?: boolean;
  numberLabel?: string;
  saveButtonText?: string;
}

const RestaurantMenuGroupDefaultProps = {
  item: {},
  saveButtonText: 'Zapisz',
};

const RestaurantBasicInputModal: FunctionComponent<
  RestaurantMenuGroupModalProps
> = ({
  item,
  saveButtonText,
  onSubmit,
  withoutDescription,
  withNumber,
  numberLabel,
  ...props
}) => {
  return (
    <Modal {...props}>
      <Formik
        initialValues={{
          namePl: item.namePl || '',
          nameEn: item.nameEn || '',
          descriptionPl: item.descriptionPl || '',
          descriptionEn: item.descriptionEn || '',
          number: item.number || '',
        }}
        validationSchema={
          withoutDescription
            ? Yup.object().shape({
                namePl: stringRequired(),
                nameEn: stringRequired(),
              })
            : Yup.object().shape({
                namePl: stringRequired(),
                nameEn: stringRequired(),
                descriptionPl: stringRequired(),
                descriptionEn: stringRequired(),
              })
        }
        onSubmit={(values, bag) => {
          onSubmit(values, bag);
        }}
      >
        {formikProps => (
          <>
            {/*{props.model.error && <FormError errors={props.model.error} />}*/}
            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <FormField name="namePl" label="Nazwa PL" {...formikProps} />
                <FormField name="nameEn" label="Nazwa EN" {...formikProps} />
                {!withoutDescription && (
                  <>
                    <FormTextareaField
                      name="descriptionPl"
                      label="Opis PL"
                      {...formikProps}
                    />
                    <FormTextareaField
                      name="descriptionEn"
                      label="Opis EN"
                      {...formikProps}
                    />
                  </>
                )}
                {withNumber && (
                  <FormFieldInputNumber
                    name="number"
                    label={numberLabel || ''}
                    {...formikProps}
                  />
                )}
              </Group>
              <div className={'mt-4'}>
                <ButtonSubmit
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                  loading={formikProps.isSubmitting}
                  label={saveButtonText}
                  onClick={formikProps.handleSubmit}
                  iconName="save"
                />
              </div>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
};

RestaurantBasicInputModal.defaultProps = RestaurantMenuGroupDefaultProps;

export { RestaurantBasicInputModal };
