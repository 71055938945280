import { OrderFormService } from 'modules/Orders/OrderFormService';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';

import { SelectableList, styled } from 'lib/ui';
import connect from 'react-redux/es/connect/connect';

export interface OrderFormPaymentTypePickerProps {
  selected: number | null;
}

const OrderFormPaymentTypePickerContainer = styled.div.attrs({ className: 'OrderFormPaymentTypePickerContainer' })``;

const enhance = connect(
  ({ paymentTypes, loading }) => ({
    paymentTypesStore: paymentTypes,
    loading,
  }),
  ({ paymentTypes }) => ({
    paymentTypes,
  }),
);

export const OrderFormPaymentTypePicker: FunctionComponent<OrderFormPaymentTypePickerProps> = enhance(props => {
  const orderState = OrderFormService.getStateValue();

  useEffect(() => {
    props.paymentTypes.loadCollection();
  }, []);

  return (
    <OrderFormPaymentTypePickerContainer>
      <SelectableList
        onSelectItem={item => {
          OrderFormService.setState({
            paymentType: +item.id,
          });
        }}
        options={props.paymentTypesStore.data.map(item => ({
          name: item.namePl,
          id: item.id,
        }))}
        selectedItemId={orderState.paymentType}
      />
    </OrderFormPaymentTypePickerContainer>
  );
});
