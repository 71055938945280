import React from 'react';

import { DividerLine } from './DividerLine';
import { DividerText } from './DividerText';
import { DividerWithText } from './DividerWithText';
import { DividerStyled } from './DividerStyled';

export enum DividerType {
  Horizontal = 'horizontal', // default
  Vertical = 'vertical',
}

export enum DividerTextOrientation {
  Left = 'left',
  Center = 'center', //  default
  Right = 'right',
}

export interface DividerProps {
  type?: DividerType;
  text?: string;
  textOrientation?: DividerTextOrientation;
  textStrong?: boolean;
  hidden?: boolean;
  noMargin?: boolean;
  color?: string;
}

const Divider = (props: DividerProps) => {
  if (props.text) {
    return (
      <DividerWithText noMargin={props.noMargin} hidden={props.hidden}>
        <DividerLine short={props.textOrientation === DividerTextOrientation.Left} color={props.color} />
        <DividerText strong={props.textStrong}>{props.text}</DividerText>
        <DividerLine short={props.textOrientation === DividerTextOrientation.Right} color={props.color} />
      </DividerWithText>
    );
  }

  return (
    <DividerStyled
      vertical={props.type === DividerType.Vertical}
      hidden={props.hidden}
      noMargin={props.noMargin}
      color={props.color}
    />
  );
};

export { Divider };
