import { message } from 'antd';
import { AppDateTimePickerDefaults } from 'components/AppUtils';
import { format } from 'date-fns';
import { Box, Button, ButtonSize, ButtonVariant, Group, Icon, Price, Spacer, styled, Table, Tooltip } from 'lib/ui';
import { DriversReckoningDay } from 'modules/Drivers/DriversReckoning';
import { OrderMark } from 'modules/Orders/components/OrderMark';
import { DriverOrderBonus } from 'pages/DriversReckoningPage/DriverOrderBonus';
import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useToggle } from 'react-use';

export interface DriverReckoningDayProps {
  data: DriversReckoningDay;
  driverId: number;
  onUpdate: () => void;
}

const DriverReckoningDayContainer = styled.div.attrs({ className: 'DriverReckoningDayContainer' })`
  margin: ${p => p.theme.space};
`;

const BoxesContainer = styled.div.attrs({ className: 'BoxesContainer' })`
  display: flex;

  > * {
    width: 25%;
    margin: 1rem 1rem !important;

    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
`;

export const DayHeader = styled.div.attrs({ className: 'dayHeader' })<{ color: string; clickable: boolean }>`
  background: ${p => (p.color ? `#${p.color}` : p.theme.colorLightGrey)};
  padding: ${p => p.theme.spaceSmall};
  cursor: ${p => (p.clickable ? 'pointer' : 'default')};
`;

export const DayContent = styled.div.attrs({ className: 'DayContent' })<{ collapsed: boolean }>`
  height: ${p => (p.collapsed ? 0 : 'auto')};
  overflow: hidden;
  transition: height 500ms;
`;

const RedText = styled.div.attrs({ className: 'redText' })`
  color: ${p => p.theme.colorRed};
`;

export const DriverReckoningDay: FunctionComponent<DriverReckoningDayProps> = withRouter(props => {
  const [collapsed, toggleCollapsed] = useToggle(props.data.isPaid);
  const [isPaid, setIsPaid] = useState(props.data.isPaid);
  const dispatch = useDispatch();
  let orderIndex = 0;

  const { paymentDetails } = props.data;

  let headerColor: string = '';
  if (isPaid === null && props.data.ordersCount > 0) {
    headerColor = 'cf1e2f30';
  }
  if (isPaid) {
    headerColor = 'b0d5f3';
  }
  if (isPaid === false) {
    headerColor = 'eee';
  }

  //  orders table definition

  const columns = [
    {
      title: 'L.p.',
      dataIndex: 'index',
      render: (text, record, index) => {
        orderIndex += 1;
        if (record.isSummary) {
          return null;
        }
        return <span>{orderIndex}</span>;
      },
    },
    {
      title: 'Kwota zamówienia',
      dataIndex: 'gross',
      render: (text, record, index) => {
        if (record.isSummary) {
          return (
            <strong>
              <Price amount={record.gross} />
            </strong>
          );
        }

        return <Price amount={record.gross} />;
      },
    },
    {
      title: 'Kwota dostawy',
      dataIndex: 'deliveryAddress.deliveryCost',
      render: (text, record, index) => {
        if (record.isSummary) {
          return (
            <strong>
              <Price amount={record.deliveryCost} />
            </strong>
          );
        }
        return <Price amount={record.deliveryAddress.deliveryCost} />;
      },
    },
    {
      title: 'Płatność klienta',
      dataIndex: 'paymentAmount',
      render: (text, record, index) => {
        if (record.isSummary) {
          return (
            <strong>
              <Price amount={record.paymentAmount} />
            </strong>
          );
        }
        return <Price amount={record.paymentAmount} />;
      },
    },
    {
      title: 'Typ płatności',
      dataIndex: 'paymentData.displayedName',
      render: (text, record, index) => {
        if (!record.paymentData) {
          return null;
        }
        return (
          <Tooltip text={record.paymentData.namePl}>
            <OrderMark>{record.paymentData.displayedName || '?'}</OrderMark>
          </Tooltip>
        );
      },
    },
    {
      title: 'Extra dodatki',
      dataIndex: 'extraExtrasPrice',
      render: (text, record, index) => {
        if (record.isSummary) {
          return (
            <strong>
              <Price amount={record.extraExtrasPrice} />
            </strong>
          );
        }
        return <Price amount={record.extraExtrasPrice} />;
      },
    },
    {
      title: 'Bonus / kara',
      dataIndex: 'discount',
      render: (text, record, index) => {
        if (record.isSummary) {
          return (
            <strong>
              <Price amount={record.driverBonusAmount} />
            </strong>
          );
        }
        return <DriverOrderBonus record={record} onUpdate={() => props.onUpdate()} isPaid={!!isPaid} />;
      },
    },
    {
      title: 'Uwagi',
      dataIndex: 'driverBonusDescription',
    },
  ];

  let rows = props.data.ordersDetails;
  if (rows && rows.length) {
    //  hacky way of adding summary to antd table
    //  add row with summaries
    rows = [
      ...rows,
      {
        gross: rows.map(r => r.gross).reduce((sum, v) => sum + v, 0),
        deliveryCost: rows.map(r => r.deliveryAddress.deliveryCost).reduce((sum, v) => sum + v, 0),
        paymentAmount: rows.map(r => r.paymentAmount).reduce((sum, v) => sum + v, 0),
        extraExtrasPrice: rows.map(r => r.extraExtrasPrice).reduce((sum, v) => sum + v, 0),
        driverBonusAmount: rows.map(r => r.driverBonusAmount).reduce((sum, v) => sum + v, 0),
        paymentData: null,
        isSummary: true,
      },
    ];
  }

  return (
    <DriverReckoningDayContainer>
      <DayHeader color={headerColor}>
        <Group align="space-between">
          <Group>
            <strong>{format(props.data.dayStart, AppDateTimePickerDefaults.DateFormat)}</strong>
            {props.data.ordersCount === 0 && <span> - brak zamówień</span>}
            {!isPaid && props.data.ordersCount > 0 && (
              <>
                <span>(nierozliczony)</span>
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Primary}
                  onClick={() => {
                    dispatch({
                      type: 'drivers/customCollectionAction',
                      payload: {
                        action: 'pay-for-day',
                        data: {
                          driverId: props.driverId,
                          date: format(props.data.dayStart, 'YYYY-MM-DD'),
                        },
                      },
                    })
                      .then(data => {
                        const messageText = data && data.detail;
                        message.success(messageText || 'Dzień został oznaczony jako rozliczony');
                        setIsPaid(true);
                        toggleCollapsed(true);
                      })
                      .catch(err => {
                        const messageText = err.data && err.data.detail;
                        message.error(messageText || 'Wystąpił nieoczekiwany błąd');
                      });
                  }}
                >
                  oznacz jako rozliczony
                </Button>
              </>
            )}
            {isPaid && (
              <>
                <span>Rozliczony. Kwota </span>
                <Price amount={props.data.driverPaymentSum} />
              </>
            )}
          </Group>

          <Group align="right">
            {isPaid === null && props.data.ordersCount > 0 && (
              <>
                <RedText>Brak grafiku</RedText>
                <Button
                  size={ButtonSize.Small}
                  onClick={() => {
                    props.history.push(`/schedules/${format(props.data.dayStart, 'YYYY.MM.DD')}`);
                  }}
                >
                  Ustaw grafik
                </Button>
              </>
            )}
            {collapsed && (
              <Button
                size={ButtonSize.Small}
                onClick={() => {
                  toggleCollapsed(false);
                }}
              >
                <Icon name="chevron-down" />
              </Button>
            )}
            {!collapsed && props.data.ordersCount > 0 && (
              <Button
                size={ButtonSize.Small}
                onClick={() => {
                  toggleCollapsed(true);
                }}
              >
                <Icon name="chevron-up" />
              </Button>
            )}
          </Group>
        </Group>
      </DayHeader>

      {props.data.ordersCount > 0 && (
        <DayContent collapsed={collapsed}>
          <Spacer />
          <h3>Zamówienia</h3>
          <Table columns={columns} rows={rows} rowKey={record => record.id} />
          <Spacer />

          {paymentDetails && (
            <BoxesContainer>
              {paymentDetails.forRbh && (
                <Box subtitle={'godziny'} noMargin noShadow headerWithoutBackground>
                  <Group align="center" vertical>
                    <span>
                      {paymentDetails.forRbh.countRbh} x <Price amount={paymentDetails.forRbh.amountPerHour} />
                    </span>
                    <strong>
                      <Price amount={paymentDetails.forRbh.total} />
                    </strong>
                  </Group>
                </Box>
              )}
              {paymentDetails.forDistance && (
                <Box subtitle={'km'} noMargin noShadow headerWithoutBackground>
                  <Group align="center" vertical>
                    <span>
                      <Price amount={paymentDetails.forDistance.amountPerKm} /> x{' '}
                      {paymentDetails.forDistance.distance / 1000} km
                    </span>
                    <strong>
                      <Price amount={paymentDetails.forDistance.total} />
                    </strong>
                  </Group>
                </Box>
              )}
              {paymentDetails.forDay && (
                <Box subtitle={'dniówki'} noMargin noShadow headerWithoutBackground>
                  <Group align="center" vertical>
                    <span>
                      1 x <Price amount={paymentDetails.forDay.rate} />
                    </span>
                    <strong>
                      <Price amount={paymentDetails.forDay.total} />
                    </strong>
                  </Group>
                </Box>
              )}
              {paymentDetails.forDelivery && (
                <Box subtitle={'dostawy'} noMargin noShadow headerWithoutBackground>
                  <Group align="center" vertical>
                    <span>
                      {props.data.ordersCount} x <Price amount={paymentDetails.forDelivery.amountPerOrder[0]} />
                    </span>
                    <strong>
                      <Price amount={paymentDetails.forDelivery.total} />
                    </strong>
                  </Group>
                </Box>
              )}
              {paymentDetails.forDeliveryAll && (
                <Box subtitle={'dostawy'} noMargin noShadow headerWithoutBackground>
                  <Group align="center" vertical>
                    <span>licza zamówień: {props.data.ordersCount}</span>
                    <strong>
                      <Price amount={paymentDetails.forDeliveryAll.total} />
                    </strong>
                  </Group>
                </Box>
              )}
              <Box subtitle={'Bonus / kara'} noMargin noShadow headerWithoutBackground>
                <Group align="center" vertical>
                  <span>{paymentDetails.discount.count} x</span>
                  <strong>
                    <Price amount={paymentDetails.discount.total} />
                  </strong>
                </Group>
              </Box>
            </BoxesContainer>
          )}

          <Spacer />

          <h1 className="text-center">
            Do zapłaty: <Price amount={props.data.driverPaymentSum} />
          </h1>

          <Spacer />
        </DayContent>
      )}
    </DriverReckoningDayContainer>
  );
});
