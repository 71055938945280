import { HttpApi } from 'services/HttpApi';

export type EventType = 'core' | 'app_settings' | 'restaurants' | 'employees' | 'customers' | 'companies' | 'orders';

export const CoreService = {
  sendAbuseEvent: (payload: { eventUrl?: string }) => {
    return HttpApi.post('/core/abuseEvents/', {
      data: {
        ...payload,
      },
    });
  },
};
