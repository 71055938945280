import { styled } from '../../styled-components';

type Props = {
  simple?: boolean;
  inline?: boolean;
};

export const EmptyStateContainerStyled = styled.div.attrs({ className: '' })<Props>`
  display: flex;
  flex-direction: ${p => (p.inline ? 'row' : 'column')};
  justify-content: ${p => (p.inline ? 'flex-start' : 'center')};
  align-items: ${p => (p.inline ? 'center' : 'flex-start')};
  text-align: center;
  padding: ${p => p.theme.space};
  background: ${p => (p.simple ? 'transparent' : p.theme.colorWhite)};
  border-radius: ${p => p.theme.borderRadius};
  border: 1px solid ${p => (p.simple ? 'transparent' : p.theme.colorLightGrey)};
`;
