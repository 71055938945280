import { init, RematchDispatch, RematchRootState } from '@rematch/core';
// import createRematchPersist from '@rematch/persist';
import createLoadingPlugin from '@rematch/loading';
import selectPlugin from '@rematch/select';
import { ClientAddressModel } from 'modules/Clients';
import { EmployeesReckoningModel } from 'modules/Employees/EmployeesReckoningModel';
import {
  RestaurantCuisineTypeModel,
  RestaurantExtrasModel,
  RestaurantImageModel,
  RestaurantMealGroupModel,
  RestaurantMealModel,
  RestaurantModel,
  RestaurantMovieModel,
  RestaurantsSettlementModel,
} from 'modules/Restaurant';
import { AuthModel } from './modules/Auth/authModel';
import { ClientPhoneModel } from './modules/Clients/ClientPhoneModel';
import { ClientsModel } from './modules/Clients/ClientsModel';
import { CompaniesModel } from './modules/Companies/CompaniesModel';
import { DriversMessagesModel } from './modules/Drivers/DriversMessagesModel';
import { DriversModel } from './modules/Drivers/DriversModel';
import { DriversReckoningModel } from './modules/Drivers/DriversReckoning';
import { EmployeesModel } from './modules/Employees/EmployeesModel';
import { ExtraExtrasModel } from './modules/ExtraExtras/ExtraExtrasModel';
import { CurrentOrderModel } from './modules/Orders/CurrentOrdersModel';
import { OrderDriversModel } from './modules/Orders/OrderDriversModel';
import { OrderModel } from './modules/Orders/OrdersModel';
import { PromotionModel } from './modules/Promotions/PromotionModel';
import { ScheduleModel } from './modules/Schedule/ScheduleModel';
import { DeliveryZonesModel } from './modules/Settings/DeliveryZonesModel';
import { GeneralSettingsModel } from './modules/Settings/GeneralSettingsModel';
import { PaymentTypesModel } from './modules/Settings/PaymentTypesModel';
import { UiModel } from './modules/UiModel';

// const persistPlugin = createRematchPersist({
//   whitelist: ['AuthModel'],
//   throttle: 5000,
//   version: 1,
// });

const loadingPlugin = createLoadingPlugin({});

export interface RootModel {
  UiModel: typeof UiModel;
  AuthModel: typeof AuthModel;
  restaurants: typeof RestaurantModel;
  restaurantsCuisineType: typeof RestaurantCuisineTypeModel;
  restaurantsMealGroup: typeof RestaurantMealGroupModel;
  restaurantsMovies: typeof RestaurantMovieModel;
  restaurantsMeals: typeof RestaurantMealModel;
  restaurantsExtras: typeof RestaurantExtrasModel;
  restaurantsImages: typeof RestaurantImageModel;
  restaurantsSettlement: typeof RestaurantsSettlementModel;
  promotions: typeof PromotionModel;
  employees: typeof EmployeesModel;
  employeesReckoning: typeof EmployeesReckoningModel;
  drivers: typeof DriversModel;
  driversMessages: typeof DriversMessagesModel;
  schedules: typeof ScheduleModel;
  clients: typeof ClientsModel;
  clientPhones: typeof ClientPhoneModel;
  clientAddress: typeof ClientAddressModel;
  orders: typeof OrderModel;
  currentOrders: typeof CurrentOrderModel;
  companies: typeof CompaniesModel;
  orderDrivers: typeof OrderDriversModel;
  extraExtras: typeof ExtraExtrasModel;
  paymentTypes: typeof PaymentTypesModel;
  deliveryZones: typeof DeliveryZonesModel;
  generalSettings: typeof GeneralSettingsModel;
  driversReckoning: typeof DriversReckoningModel;
}

export const models: RootModel = {
  // ...models,
  UiModel,
  AuthModel,
  restaurants: RestaurantModel,
  restaurantsCuisineType: RestaurantCuisineTypeModel,
  restaurantsMealGroup: RestaurantMealGroupModel,
  restaurantsMovies: RestaurantMovieModel,
  restaurantsMeals: RestaurantMealModel,
  restaurantsExtras: RestaurantExtrasModel,
  restaurantsImages: RestaurantImageModel,
  restaurantsSettlement: RestaurantsSettlementModel,
  promotions: PromotionModel,
  employees: EmployeesModel,
  employeesReckoning: EmployeesReckoningModel,
  drivers: DriversModel,
  driversMessages: DriversMessagesModel,
  schedules: ScheduleModel,
  clients: ClientsModel,
  clientPhones: ClientPhoneModel,
  clientAddress: ClientAddressModel,
  orders: OrderModel,
  currentOrders: CurrentOrderModel,
  companies: CompaniesModel,
  orderDrivers: OrderDriversModel,
  extraExtras: ExtraExtrasModel,
  paymentTypes: PaymentTypesModel,
  deliveryZones: DeliveryZonesModel,
  generalSettings: GeneralSettingsModel,
  driversReckoning: DriversReckoningModel,
};

export const store = init({
  models,
  plugins: [loadingPlugin, selectPlugin()],
});

export const { dispatch } = store;

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type StoreRootState = RematchRootState<RootModel>;
