import React from 'react';

import { Box } from 'lib/ui';

const Page404 = () => (
  <Box noShadow>
    <h2>Strona, której szukasz nie została znaleziona.</h2>
    <p>
      <a href="/">Powrót do strony głównej</a>
    </p>
  </Box>
);

export { Page404 };
