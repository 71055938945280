/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Empty, message } from 'antd';
import { SearchInput } from 'lib/forms';
import { AddressDialog, Button, ButtonVariant, DimmerLoading, Group, Loading } from 'lib/ui';
import { Client } from 'modules/Clients';
import { OrderClientsTable } from 'modules/Orders/components/OrderClientsTable/OrderClientsTable';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { compose } from 'rambdax';
import React, { useCallback, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import useToggle from 'react-use/lib/useToggle';
import { PageLayout } from 'components';
import { OrderFormSteps } from '../OrderFormSteps';

const enhance = compose(
  connect(
    ({ AuthModel: { isLoggedIn }, orders, loading, clients, clientPhones, clientAddress }) => ({
      isLoggedIn,
      loading,
      ordersStore: orders,
      clientsStore: clients,
      clientPhonesStore: clientPhones,
      clientAddressStore: clientAddress,
    }),
    ({ AuthModel: { logout }, orders, clients, clientPhones, clientAddress }) => ({
      logout,
      orders,
      clients,
      clientPhones,
      clientAddress,
    }),
  ),
);

export const OrderFormPageStep1 = enhance(props => {
  // reset order form on enter
  useEffect(() => {
    OrderFormService.resetState();
  }, []);

  //  currently selected item (for edit or to see details)
  const [client, setClient] = useState<Client | null>(null);
  const [clients, setClients] = useState<Client[]>([]);

  //  address form
  const [addressDialogVisible, toggleAddressDialog] = useToggle(false);
  // search query
  const [query, setQuery] = useState('');
  const [loading, toggleLoading] = useToggle(false);

  const loadClients = () => {
    if (query && !loading) {
      toggleLoading(true);
      props.orders
        .customCollectionAction({
          action: 'searchCustomers',
          data: {
            limit: 100,
            search: query,
          },
        })
        .then(data => {
          setClients(data);
          toggleLoading(false);
        });
    }
  };

  //  load items on enter, on query change
  useEffect(() => {
    loadClients();
  }, []);

  //  set focus in input on start
  const [inputRef, setInputRef] = useState<any>();
  const inputCallbackRef = useCallback(node => {
    if (node !== null) {
      setInputRef(node);
    }
  }, []);
  useEffect(() => {
    if (inputRef) {
      inputRef.focus();
    }
  }, [inputRef]);

  // const items = props.clientsStore.data.filter(item => item.id !== 1);

  return (
    <PageLayout title="Tworzenie nowego zamówienia" header={<OrderFormSteps activePage={1} />}>
      <Group align="space-between">
        <Group>
          <SearchInput
            ref={inputCallbackRef}
            value={query}
            onChange={setQuery}
            onEnter={loadClients}
            debounceTime={0}
            placeholder="Wyszukaj klienta"
          />
          <Button
            label="Szukaj"
            variant={ButtonVariant.Primary}
            disabled={loading}
            onClick={() => {
              loadClients();
            }}
          />
        </Group>
        <Group align="right" width="auto">
          <Button
            label="Nowy klient"
            variant={ButtonVariant.Primary}
            onClick={() => {
              OrderFormService.setState({
                client: null,
              });
              props.history.push('/orders/new/2/new');
            }}
          />
        </Group>
      </Group>
      <div data-bp="4--max padding-top--sm">
        <p>
          {`Podaj numer telefonu, adres lub imię i nazwisko klienta. Po opuszczeniu pola System przeszuka bazę danych. Możesz też podać część numeru telefonu, adresu czy nazwiska.`}
        </p>
      </div>
      {clients.length > 0 && (
        <div style={{ position: 'relative' }}>
          <OrderClientsTable
            onRefresh={loadClients}
            items={clients}
            onAddressNew={record => {
              setClient(record);
              toggleAddressDialog(true);
            }}
            onDirectPickUp={client => {
              let phone = '';
              if (query.match(/^\d+$/)) {
                phone = query;
              }
              OrderFormService.setState({
                client,
                withDirectPickUp: true,
                phone,
              });
              props.history.push(`/orders/new/2/${client.id}`);
            }}
            onAddressPick={(client, address) => {
              let phone = '';
              if (query.match(/^\d+$/)) {
                phone = query;
              }
              OrderFormService.setState({
                client,
                address: { ...address },
                phone,
              });
              props.history.push(`/orders/new/2/${client.id}`);
            }}
          />
          <DimmerLoading visible={props.loading.effects.orders.customCollectionAction} />
        </div>
      )}

      {clients.length === 0 && props.loading.effects.orders.customCollectionAction && <Loading />}
      {clients.length === 0 && !props.loading.effects.orders.customCollectionAction && (
        <Empty description={'Wpisz nazwisko lub telefon celem znalezienia klienta w bazie.'} />
      )}

      {addressDialogVisible && (
        <AddressDialog
          open
          loading={props.loading.effects.clientAddress.createItem || props.loading.effects.clientAddress.updateItem}
          errors={props.clientAddressStore.errors}
          title={`Dodawanie adresu`}
          okText={'Dodaj'}
          withNotes
          onSave={data => {
            props.clientAddress
              .createItem({
                data: {
                  street: data.values.street,
                  postalCode: data.values.postalCode,
                  city: data.values.city,
                  houseNumber: data.values.houseNumber,
                  details: data.values.details,
                },
                customerId: client ? client.id : null,
              })
              .then(() => {
                message.success('Adres został dodany');
                toggleAddressDialog(false);
                loadClients();
              });
          }}
          onCancel={() => {
            toggleAddressDialog(false);
          }}
        />
      )}
    </PageLayout>
  );
});
