import { Steps } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { styled } from 'lib/ui';

export interface OrderEditStepsProps {
  activePage: number;
}

const OrderEditStepsContainer = styled.div.attrs({
  className: 'OrderEditStepsContainer',
})``;

const stepStyles = {
  cursor: 'pointer',
};

export const OrderEditSteps: FunctionComponent<OrderEditStepsProps> = withRouter(props => {
  const getStepStatus: (step: number) => 'process' | 'wait' | 'finish' = step => {
    if (step === props.activePage) {
      return 'process';
    }
    if (props.activePage > step) {
      return 'finish';
    }
    return 'wait';
  };
  const finishedSteps = {
    1: getStepStatus(1) === 'finish',
    2: getStepStatus(2) === 'finish',
    3: getStepStatus(3) === 'finish',
  };

  const { orderId } = props.match.params;

  return (
    <OrderEditStepsContainer>
      <Steps>
        <Steps.Step
          status={getStepStatus(1)}
          onClick={() => {
            if (finishedSteps[1]) {
              props.history.push(`/orders/edit/${orderId}/1`);
            }
          }}
          title="Dane kontaktowe"
          style={finishedSteps[2] ? stepStyles : {}}
        />
        <Steps.Step
          status={getStepStatus(2)}
          onClick={() => {
            if (finishedSteps[2]) {
              props.history.push(`/orders/edit/${orderId}/2`);
            }
          }}
          title="Koszyk"
          style={finishedSteps[4] ? stepStyles : {}}
        />
        <Steps.Step
          status={getStepStatus(3)}
          onClick={() => {
            if (finishedSteps[3]) {
              props.history.push(`/orders/edit/${orderId}/3`);
            }
          }}
          title="Podsumowanie i płatność"
        />
      </Steps>
    </OrderEditStepsContainer>
  );
});
