import { Checkbox } from 'antd';
import React, { FunctionComponent } from 'react';

import { Spacer } from '../Spacer/Spacer';
import { styled } from '../../styled-components';

export interface SelectableListItem {
  name: string;
  id: string | number;
}

export interface SelectableListProps {
  options: SelectableListItem[];
  selectedItemId: string | number | null;
  onSelectItem: (item: SelectableListItem) => void;
}

const SelectableListDefaultProps = {};

const SelectableListContainer = styled.div.attrs({
  className: 'SelectableListContainer',
})``;

const SelectableListItemContainer = styled.div.attrs({
  className: 'SelectableListItem',
})`
  padding: ${p => p.theme.spaceSmall} 0;
  cursor: pointer;
`;

export const SelectableList: FunctionComponent<SelectableListProps> = props => {
  return (
    <SelectableListContainer>
      {props.options.map(item => (
        <SelectableListItemContainer
          key={item.id}
          onClick={() => {
            props.onSelectItem(item);
          }}>
          <Checkbox checked={item.id === props.selectedItemId} />
          <Spacer size="small" horizontal />
          <span>{item.name}</span>
        </SelectableListItemContainer>
      ))}
    </SelectableListContainer>
  );
};

SelectableList.defaultProps = SelectableListDefaultProps;
