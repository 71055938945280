/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FunctionComponent } from 'react';
import { ClientAddress } from 'modules/Clients';
import { Button, Group, Modal, Spacer } from 'lib/ui';
import { Address } from './Address';

export interface AddressComponentDialogProps {
  addressData: Array<ClientAddress>;
  clientId: number;
  onEdit?: (addressObject: ClientAddress) => void;
  onDelete?: (addressObject: ClientAddress) => void;
  onShowMap?: (addressObject: ClientAddress) => void;
  open?: boolean;
  title?: string;
  onCancel: () => void;
  cancelText?: string;
}

export const AddressComponentDialog: FunctionComponent<AddressComponentDialogProps> = props => {
  return (
    <Modal
      zIndex={1000}
      verticalAlign={'center'}
      closeOnEsc
      full
      position={'center'}
      visible={props.open}
      header={<h2>{props.title || '...'}</h2>}
      onClose={props.onCancel}
      footer={
        <Group align="right">
          <Button onClick={props.onCancel} label={props.cancelText || 'Wyjdź'} />
        </Group>
      }
    >
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gridGap: '10px' }}>
        {props.addressData.length > 0 &&
          props.addressData.map(addressObject => (
            <React.Fragment key={addressObject.id}>
              <Address
                key={addressObject.id}
                street={addressObject.street}
                houseNumber={addressObject.houseNumber}
                postalCode={addressObject.postalCode}
                city={addressObject.city}
                lon={addressObject.lon}
                lat={addressObject.lat}
                details={addressObject.details}
                editable
                onDelete={() => {
                  if (props.onDelete) {
                    props.onDelete(addressObject);
                  }
                }}
                onEdit={() => {
                  if (props.onEdit) {
                    props.onEdit(addressObject);
                  }
                }}
                onShowOnMap={() => {
                  if (props.onShowMap) {
                    props.onShowMap(addressObject);
                  }
                }}
              />
              <Spacer horizontal />
            </React.Fragment>
          ))}
      </div>
    </Modal>
  );
};
