import { PageLayout } from 'components';
import { Group, Spacer } from 'lib/ui';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormState } from 'modules/Orders/OrderFormState';
import { CuisineType } from 'modules/Restaurant';
import { compose } from 'rambdax';
import React, { useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { useObservable } from 'react-use';
import { MealInputSearch } from '../components/MealInputSearch/MealInputSearch';
import { OrderFormSteps } from '../OrderFormSteps';
import { OrderFormCuisineTypePicker } from './OrderFormCuisineTypePicker';
import { OrderFormRestaurantPicker } from './OrderFormRestaurantPicker/OrderFormRestaurantPicker';

const enhance = compose(
  connect(
    ({ companies, loading }) => ({
      companiesStore: companies,
      loading,
    }),
    ({ companies }) => ({
      companies,
    }),
  ),
);

export const OrderFormPageStep3 = enhance(props => {
  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());
  const [selectedCuisineType, setSelectedCuisineType] = useState<CuisineType | null>(null);

  const [mealQuery, setMealQuery] = useState('');

  if (!orderState) {
    return null;
  }

  return (
    <PageLayout title="Tworzenie nowego zamówienia" header={<OrderFormSteps activePage={3} />}>
      <Group align="space-between" verticalAlign="top">
        <Group vertical width="50%">
          <MealInputSearch
            onSearch={setMealQuery}
            onReset={() => {
              setMealQuery('');
            }}
          />
          <Spacer />
          <OrderFormRestaurantPicker
            cuisineType={selectedCuisineType}
            query={mealQuery}
            onSelectRestaurant={searchRestaurantItem => {
              OrderFormService.saveRestaurantWithDeliveryCost(searchRestaurantItem);
              props.history.push('/orders/new/4');
            }}
            onSelectRestaurantAndMeal={(searchRestaurantItem, meal) => {
              OrderFormService.saveRestaurantWithDeliveryCost(searchRestaurantItem);
              OrderFormService.setState({
                restaurantMealSelectedOnStep3: meal,
              });
              props.history.push('/orders/new/4');
            }}
          />
        </Group>

        <Spacer size="large" horizontal />

        <OrderFormCuisineTypePicker
          onSelect={cuisineType => {
            setSelectedCuisineType(cuisineType);
          }}
        />
      </Group>
    </PageLayout>
  );
});
