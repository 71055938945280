import { Box, Button, ButtonVariant, Group, Price, SimpleInputDialog, Spacer, styled } from 'lib/ui';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormBasketSetMeal, OrderFormState } from 'modules/Orders/OrderFormState';
import { OrderFormExtraExtrasDialog } from 'pages/OrderFormPage/OrderFormPageStep4/OrderFormExtraExtrasDialog';
import React, { FunctionComponent, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { useObservable, useToggle } from 'react-use';
import { compose } from 'recompose';
import { OrderFormBasketDeliveryDate } from './OrderFormBasketDeliveryDate';
import { OrderFormBasketSet } from './OrderFormBasketSet';
import { OrderFormBasketSummary } from './OrderFormBasketSummary';

const OrderFormBasketContainer = styled.div.attrs({
  className: 'OrderBasketContainer',
})`
  width: 100%;
`;

export interface OrderBasketProps {
  editable: boolean;
  onMealClick?: (basketMeal: OrderFormBasketSetMeal) => void;
  onMealExtrasClick?: (meal: OrderFormBasketSetMeal, extrasIndex: number) => void;
  onSubmit?: () => void;
}

const enhance = compose(
  connect(
    ({ extraExtras, loading }) => ({
      extraExtrasData: extraExtras.data,
      loading,
    }),
    ({ extraExtras }) => ({
      extraExtrasStore: extraExtras,
    }),
  ),
);

export const OrderFormBasket: FunctionComponent<OrderBasketProps> = enhance(props => {
  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());
  const [simpleInputDialogVisible, toggleSimpleInputDialog] = useToggle(false);
  const [orderExtraExtrasDialogVisible, toggleOrderExtraExtrasDialog] = useToggle(false);
  const [selectedName, setSelectedName] = useState('');
  const [selectedSetIndex, setSelectedSetIndex] = useState<any>();

  useEffect(() => {
    props.extraExtrasStore.loadCollection();
  }, [props.extraExtrasStore]);

  if (!orderState) {
    return null;
  }

  const basketExtraExtras = OrderFormService.getBasketExtraExtrasAsArray();

  const basketExtraExtrasSum = OrderFormService.getBasketExtraExtrasSum();

  const extraExtrasAvailable = props.extraExtrasData.filter(extra => extra.isActive);

  return (
    <OrderFormBasketContainer>
      <Box dirty title="Koszyk">
        {orderState.basket.sets.map((basketSet, setIndex) => (
          <OrderFormBasketSet
            key={`basket-${basketSet.name}${basketSet.meals.length}`}
            editable={props.editable}
            basketSet={basketSet}
            selected={orderState.basket.selectedeSet === setIndex}
            onToggleSelect={selected => {
              OrderFormService.setState({
                basket: {
                  ...orderState.basket,
                  selectedeSet: setIndex,
                },
              });
            }}
            onNameChangeClick={() => {
              setSelectedName(basketSet.name);
              setSelectedSetIndex(setIndex);
              toggleSimpleInputDialog();
            }}
            onMealClick={BasketMeal => {
              if (props.onMealClick) {
                props.onMealClick(BasketMeal);
              }
            }}
            onMealExtrasClick={(meal: OrderFormBasketSetMeal, extrasIndex: number) => {
              if (props.onMealExtrasClick) {
                props.onMealExtrasClick(meal, extrasIndex);
              }
            }}
          />
        ))}

        <Spacer />
        {props.editable && (
          <Button
            label="Dodaj zestaw"
            iconName="plus"
            onClick={() => {
              OrderFormService.setState({
                basket: {
                  ...orderState.basket,
                  sets: [
                    ...orderState.basket.sets,
                    {
                      name: `Zestaw ${orderState.basket.sets.length + 1}`,
                      meals: [],
                    },
                  ],
                },
              });
            }}
          />
        )}

        {/* 'Ekstra dodatki' */}
        {basketExtraExtrasSum > 0 && (
          <>
            {props.editable && <Spacer />}

            {!props.editable && (
              <>
                <Group>
                  <Spacer horizontal />
                  <h3>Ekstra dodatki</h3>
                </Group>
                <Spacer size="small" />
              </>
            )}

            {basketExtraExtras.map(basketExtraSummary => {
              return (
                <React.Fragment key={`${basketExtraSummary.sum}${basketExtraSummary.name}`}>
                  <Group align="space-between">
                    <Group>
                      <span className="u-flex-no-shrink">{basketExtraSummary.counter} x</span>
                      <Spacer size="small" />
                      <span>{basketExtraSummary.name}</span>
                    </Group>
                    <Group width="20%" align="right">
                      <Price amount={basketExtraSummary.sum} />
                    </Group>
                  </Group>
                  <Spacer size="small" />
                </React.Fragment>
              );
            })}
          </>
        )}

        {props.editable && extraExtrasAvailable.length && (
          <>
            {basketExtraExtrasSum < 1 && <Spacer />}
            <Button
              label="Ekstra dodatki"
              onClick={() => {
                toggleOrderExtraExtrasDialog(true);
              }}
            />
          </>
        )}

        <OrderFormBasketSummary orderState={orderState} />

        {props.editable && (
          <OrderFormBasketDeliveryDate
            date={orderState.deliveryDate}
            onChange={date => {
              OrderFormService.setState({
                deliveryDate: date,
              });
            }}
          />
        )}
      </Box>

      <Spacer />

      {props.editable && (
        <Button
          label="Podsumowanie i płatność"
          variant={ButtonVariant.Primary}
          onClick={() => {
            if (props.onSubmit) {
              props.onSubmit();
            }
          }}
        />
      )}

      {simpleInputDialogVisible && (
        <SimpleInputDialog
          open
          inputValue={selectedName}
          title="Edycja nazwy zestawu"
          inputLabel="Nazwa zestawu"
          description={null}
          okText="Zapisz"
          onSave={data => {
            // todo - refactor, simplify it
            OrderFormService.setState({
              basket: {
                ...orderState.basket,
                sets: [
                  ...orderState.basket.sets.slice(0, selectedSetIndex),
                  {
                    meals: orderState.basket.sets[selectedSetIndex].meals,
                    name: data.values.input,
                  },
                  ...orderState.basket.sets.slice(selectedSetIndex + 1),
                ],
              },
            });
            toggleSimpleInputDialog(false);
          }}
          onCancel={() => {
            toggleSimpleInputDialog(false);
          }}
        />
      )}
      {orderExtraExtrasDialogVisible && (
        <OrderFormExtraExtrasDialog
          extraExtras={extraExtrasAvailable}
          onClose={() => {
            toggleOrderExtraExtrasDialog(false);
          }}
        />
      )}
    </OrderFormBasketContainer>
  );
});
