import { ComponentClass, FunctionComponent, createElement } from 'react';
import { unmountComponentAtNode, render } from 'react-dom';

export const DIALOG_CONTAINER_ID = 'dialog-container';

export const DialogService = {
  open<T>(component: FunctionComponent<T> | ComponentClass<T>, props: T) {
    const dialogContainer = this.getDialogContainer();
    const componentElement = createElement(component, {
      ...props,
      onClose: (...args) => {
        unmountComponentAtNode(dialogContainer);
        // todo - fix and remove ts-ignore ;)
        // @ts-ignore
        if (props.onClose) {
          // @ts-ignore
          props.onClose(...args);
        }
      },
    });
    render(componentElement, dialogContainer);
  },
  getDialogContainer(): HTMLElement {
    let dialogContainer = document.getElementById(DIALOG_CONTAINER_ID);
    if (!dialogContainer) {
      dialogContainer = this.createDialogContainer();
    }
    return dialogContainer;
  },
  createDialogContainer(): HTMLElement {
    const div = document.createElement('div');
    div.setAttribute('id', DIALOG_CONTAINER_ID);
    document.body.appendChild(div);
    return div;
  },
  closeDialog(): void {
    unmountComponentAtNode(this.getDialogContainer());
  },
};
