import { Switch } from 'antd';
import React, { FunctionComponent } from 'react';

import { styled, AgGridTable, Button, ButtonSize } from 'lib/ui';
import { EmployeeRoles } from '../';

export interface EmployeesTableProps {
  items: any[];
  onItemClick?: Function;
  onStatusChangeClick?: Function;
  onEmployeeReckoning: (emplyeeId: number) => void;
}

const EmployeesTableDefaultProps = {};

const EmployeesTableContainer = styled.div``;

const EmployeesTable: FunctionComponent<EmployeesTableProps> = props => {
  const columns = [
    {
      headerName: 'Imię i nazwisko',
      field: 'name',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: cellProps => (
        <a
          onClick={() => {
            if (props.onItemClick) {
              props.onItemClick(cellProps.data);
            }
          }}
        >
          {cellProps.data.user.firstName} {cellProps.data.user.lastName}
        </a>
      ),
    },
    {
      headerName: 'Login',
      field: 'login',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: cellProps => <span>{cellProps.data.user.email}</span>,
    },
    {
      headerName: 'Rola',
      field: 'role',
      cellRendererFramework: cellProps => {
        const role = EmployeeRoles.find(role => role.key === cellProps.data.role);
        if (!role) {
          return <span>-</span>;
        }
        return <span>{role.label}</span>;
      },
    },
    {
      headerName: 'Status',
      cellRendererFramework: cellProps => (
        <Switch
          checked={cellProps.data.isActive}
          size="small"
          onClick={() => {
            if (props.onStatusChangeClick) {
              props.onStatusChangeClick(cellProps.data);
            }
          }}
        />
      ),
    },
    {
      headerName: ' ',
      cellRendererFramework: cellProps => (
        <Button
          size={ButtonSize.Small}
          onClick={() => {
            props.onEmployeeReckoning(cellProps.data.id);
          }}
        >
          Rozliczenia
        </Button>
      ),
    },
  ];

  return (
    <EmployeesTableContainer>
      <AgGridTable columnDefs={columns} rowData={props.items} />
    </EmployeesTableContainer>
  );
};

EmployeesTable.defaultProps = EmployeesTableDefaultProps;

export { EmployeesTable };
