import { styled } from '../../styled-components';

// {
//   vertical: boolean;
//   hidden?: boolean;
//   noMargin?: boolean;
//   color?: string;
// }
export const DividerStyled = styled.div.attrs({ className: 'DividerStyled' })`
  display: block;
  width: ${p => (p.vertical ? '1px' : '100%')};
  height: ${p => (p.vertical ? '100%' : '1px')};
  background-color: ${p => (p.color ? p.color : p.hidden ? 'transparent' : p.theme.colorLightGrey)};
  margin: ${p => (p.noMargin ? '0' : p.vertical ? `0 ${p.theme.space}` : `${p.theme.space} 0`)};
`;
