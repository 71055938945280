import { Button, ButtonSize, Group, Modal, ModalProps, Price, styled } from 'lib/ui';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { BasketExtraExtras, OrderFormState } from 'modules/Orders/OrderFormState';
import React, { FunctionComponent, useState } from 'react';
import { Empty } from 'antd';
import { useObservable } from 'react-use';
import { ExtraExtra } from 'modules/ExtraExtras/ExtraExtrasModel';

const OrderExtraExtrasDialogContainer = styled.div.attrs({ className: 'OrderExtraExtrasDialogContainer' })``;

export interface OrderExtraExtrasDialogProps extends ModalProps {
  extraExtras: ExtraExtra[];
}

export const OrderFormExtraExtrasDialog: FunctionComponent<OrderExtraExtrasDialogProps> = props => {
  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());
  const [basketExtraExtras, setBasketExtraExtras] = useState<BasketExtraExtras | {}>(
    OrderFormService.getStateValue().basket.extraExtras,
  );

  if (!orderState) {
    return null;
  }

  const basketExtraExtrasClone: BasketExtraExtras = JSON.parse(JSON.stringify(basketExtraExtras));

  props.extraExtras.forEach(extraExtra => {
    if (!basketExtraExtrasClone[extraExtra.id] || !basketExtraExtrasClone[extraExtra.id].name) {
      basketExtraExtrasClone[extraExtra.id] = {
        counter: 0,
        name: extraExtra.namePl,
        price: extraExtra.price,
      };
    }
  });

  if (JSON.stringify(basketExtraExtras) !== JSON.stringify(basketExtraExtrasClone)) {
    setBasketExtraExtras(basketExtraExtrasClone);
  }

  return (
    <Modal {...props} visible>
      <OrderExtraExtrasDialogContainer>
        <div data-bp="grid">
          <div data-bp="12">
            <h2>Ekstra dodatki</h2>
          </div>

          {!props.extraExtras.length && <Empty description="Brak ekstra dodatków" />}

          {props.extraExtras.length && (
            <div data-bp="12">
              {props.extraExtras.map(extraExtra => {
                return (
                  <React.Fragment key={`extraExtra${extraExtra.id}`}>
                    {basketExtraExtras[extraExtra.id] && (
                      <Group align="space-between">
                        <Group width="50%">
                          <h3>{extraExtra.namePl}</h3>
                        </Group>
                        <Group width="25%" noShrink>
                          <span>{basketExtraExtras[extraExtra.id].counter} x</span>
                          <Price amount={extraExtra.price} />
                        </Group>
                        <Group width="25%" noShrink>
                          <Button
                            disabled={basketExtraExtras[extraExtra.id].counter < 1}
                            size={ButtonSize.Small}
                            iconName={'minus'}
                            onClick={() => {
                              if (basketExtraExtras[extraExtra.id].counter > 0) {
                                basketExtraExtras[extraExtra.id].counter -= 1;

                                OrderFormService.setState({
                                  basket: {
                                    ...orderState.basket,
                                    extraExtras: basketExtraExtras,
                                  },
                                });

                                setBasketExtraExtras(basketExtraExtras);
                              }
                            }}
                          />
                          <Button
                            size={ButtonSize.Small}
                            iconName={'plus'}
                            onClick={() => {
                              basketExtraExtras[extraExtra.id].counter += 1;

                              OrderFormService.setState({
                                basket: {
                                  ...orderState.basket,
                                  extraExtras: basketExtraExtras,
                                },
                              });

                              setBasketExtraExtras(basketExtraExtras);
                            }}
                          />
                        </Group>
                      </Group>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </OrderExtraExtrasDialogContainer>
    </Modal>
  );
};
