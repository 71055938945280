import { styled } from '../../styled-components';

// {
//   short: boolean;
//   color?: string;
// }
export const DividerLine = styled.div.attrs({ className: 'DividerLine' })`
  height: 1px;
  background-color: ${p => (p.color ? p.color : p.hidden ? 'transparent' : p.theme.colorLightGrey)};
  flex-grow: ${p => (p.short ? 0 : 1)};
  width: ${p => (p.short ? p.theme.space : 'auto')};
`;
