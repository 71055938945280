import { message } from 'antd';
import { Input, Textarea } from 'lib/forms';
import { OrderMessageModel } from 'modules/Orders/models/order-message.model';
import { OrderModel } from 'modules/Orders/models/order.model';
import { compose } from 'recompose';
import React, { FunctionComponent, useState } from 'react';
import { Button, ButtonVariant, Group, Spacer, styled } from 'lib/ui';
import connect from 'react-redux/es/connect/connect';
import { useToggle } from 'react-use';
import { printPrintables } from 'services/printPrintables';

export interface OrderMessageFormProps {
  message: OrderMessageModel;
  order: OrderModel;
  messageIndex: number;
  onClose: () => void;
  onMessageSend: () => void;
  onPrint: () => void;
}

const enhance = compose(
  connect(
    ({ orders, orderDrivers, drivers, loading }) => ({
      loading,
      driversStore: drivers,
      ordersStore: orders,
      orderDriversStore: orderDrivers,
    }),
    ({ orders, orderDrivers, drivers }) => ({
      orders,
      orderDrivers,
      drivers,
    }),
  ),
);

const OrderMessageFormContainer = styled.div.attrs({ className: 'OrderMessageFormContainer' })``;

export const OrderMessageForm: FunctionComponent<OrderMessageFormProps> = enhance(props => {
  const [submiting, toggleSubmiting] = useToggle(false);
  const [state, setState] = useState({
    email: props.message.email || '',
    subject: props.message.subject || '',
    message: '',
  });

  return (
    <OrderMessageFormContainer>
      <div data-bp="grid">
        <div data-bp="3">Wysyłam do:</div>
        <div data-bp="9">
          <Input
            value={state.email}
            width={'100%'}
            onChange={ev => {
              setState({
                ...state,
                email: ev.currentTarget.value,
              });
            }}
            readonly="readonly"
          />
        </div>
        <div data-bp="3">Temat:</div>
        <div data-bp="9">
          <Input
            value={state.subject}
            width={'100%'}
            onChange={ev => {
              setState({
                ...state,
                subject: ev.currentTarget.value,
              });
            }}
          />
        </div>
        <div data-bp="12">Dodatkowa treść:</div>
        <div data-bp="12">
          <Textarea
            value={state.message}
            onChange={ev => {
              setState({
                ...state,
                message: ev.currentTarget.value,
              });
            }}
            width="100%"
          />
        </div>

        <div
          data-bp="12"
          key={`${props.message.message}`}
          dangerouslySetInnerHTML={{ __html: props.message.message }}
          data-printable={props.messageIndex}
        />

        <div data-bp="12">
          <Spacer />
          <Group align="space-between">
            <Button
              label="Anuluj"
              onClick={() => {
                props.onClose();
              }}
              disabled={submiting}
            />

            <Button iconName="print" label="Drukuj" onClick={() => props.onPrint()} />

            <div data-bp="flex">
              <Button
                variant={ButtonVariant.Primary}
                label="Wyślij"
                disabled={submiting}
                onClick={() => {
                  toggleSubmiting(true);
                  props.orders
                    .customItemAction({
                      id: props.order.id,
                      action: 'message',
                      data: {
                        restaurants: [
                          {
                            id: props.message.id,
                            emails: state.email,
                            subject: state.subject,
                            additionalNote: state.message,
                          },
                        ],
                      },
                    })
                    .then(data => {
                      message.success('Wiadomość została wysłana');
                      props.onMessageSend();
                    })
                    .catch(err => {
                      const errorMessage =
                        err.data && err.data.detail ? err.data.detail : 'Wystąpił błąd podczas wysyłki';
                      message.error(errorMessage);
                    })
                    .finally(() => {
                      toggleSubmiting(false);
                    });
                }}
              />
            </div>
          </Group>
        </div>
      </div>
    </OrderMessageFormContainer>
  );
});
