import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';
import { User } from 'modules/User';

export const ClientsModel = SimpleRestModel({
  collectionName: 'clients',
  apiPath: 'customers',
  HttpApi,
});

// TODO - add missing properties to type

export interface Client {
  id: number;
  user: User;
  balance: number;
  name: string;
  points: number;
  moneyTransfer: boolean;
  discount: number;
  notes: string;
  balanceNotes: string;
}
