import { AppToolbar } from 'components';
import { DIALOG_CONTAINER_ID } from 'lib/services/DialogService';

import { LoginPage, RegisterPage, ResetPasswordConfirmPage, ResetPasswordPage } from 'modules/Auth';
import { AuthRoute } from 'modules/Auth/helpers/AuthRoute';
import { RolesEnum } from 'modules/Auth/rolesEnum';
import { ClientsOrderPage } from 'pages/ClientsOrderPage/ClientsOrderPage';
import { ClientsPage } from 'pages/ClientsPage/ClientsPage';
import { DriversPage } from 'pages/DriversPage/DriversPage';
import { DriversReckoningPage } from 'pages/DriversReckoningPage/DriversReckoningPage';
import { EmployeeReckoningPage } from 'pages/EmployeeReckoningPage/EmployeeReckoningPage';
import { EmployeesPage } from 'pages/EmployeesPage/EmployeesPage';
import { HomePage } from 'pages/HomePage/HomePage';
import { LoadingPage } from 'pages/LoadingPage/LoadingPage';
import { MapPage } from 'pages/MapPage/MapPage';
import { OrderEditPage1 } from 'pages/OrderEdit/OrderEditPage1';
import { OrderEditPage2 } from 'pages/OrderEdit/OrderEditPage2';
import { OrderEditPage3 } from 'pages/OrderEdit/OrderEditPage3';
import { OrderFormPageStep1 } from 'pages/OrderFormPage/OrderFormPageStep1/OrderFormPageStep1';
import { OrderFormPageStep2 } from 'pages/OrderFormPage/OrderFormPageStep2/OrderFormPageStep2';
import { OrderFormPageStep3 } from 'pages/OrderFormPage/OrderFormPageStep3/OrderFormPageStep3';
import { OrderFormPageStep4 } from 'pages/OrderFormPage/OrderFormPageStep4/OrderFormPageStep4';
import { OrderFormPageStep5 } from 'pages/OrderFormPage/OrderFormPageStep5/OrderFormPageStep5';
import { OrdersArchivePage } from 'pages/OrdersPage/OrdersArchivePage';
import { OrdersPage } from 'pages/OrdersPage/OrdersPage';
import { OrdersWithDirectPickupPage } from 'pages/OrdersWithDirectPickupPage/OrdersWithDirectPickupPage';
import { OrdersWithInvoicePage } from 'pages/OrdersWithInvoicePage/OrdersWithInvoicePage';
import { Page404 } from 'pages/Page404/Page404';
import { PromotionsPage } from 'pages/PromotionsPage/PromotionsPage';
import { RestaurantsPage } from 'pages/RestaurantsPage/RestaurantsPage';
import { SchedulePage } from 'pages/SchedulePage/SchedulePage';
import { SettingsPage } from 'pages/SettingsPage/SettingsPage';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import connect from 'react-redux/es/connect/connect';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { RestaurantsSettlementPage } from 'pages/RestaurantsPage/RestaurantsSettlementPage';
import styles from './App.module.scss';
import { ExtraExtrasPage } from './pages/ExtraExtrasPage/ExtraExtrasPage';

const enhance = connect(
  state => ({
    initialized: state.AuthModel.initialized,
    isLoggedIn: state.AuthModel.isLoggedIn,
    loading: state.loading.effects.AuthModel.getUserInfo,
  }),
  ({ AuthModel: { getUserInfo }, restaurantsCuisineType, restaurants }) => ({
    getUserInfo,
    restaurantsCuisineType,
    restaurants,
  }),
);

const App = ({ initialized, loading, getUserInfo, isLoggedIn, restaurantsCuisineType, restaurants }) => {
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialized && token) {
      getUserInfo();
      restaurantsCuisineType.loadCollection();
      restaurants.loadCollection();
      dispatch({ type: 'paymentTypes/loadCollection' });
      dispatch({ type: 'employees/loadCollection' });
      dispatch({ type: 'drivers/loadCollection' });
    }
  }, [dispatch, getUserInfo, initialized, restaurants, restaurantsCuisineType, token]);

  if (!initialized && token) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.App}>
      {!isLoggedIn && (
        <div className={styles.Logo}>
          <img src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="" />
        </div>
      )}
      <Router>
        <>
          {isLoggedIn && <AppToolbar />}
          <Switch>
            <AuthRoute path="/" exact component={HomePage} />
            <Route path="/login" exact component={LoginPage} />
            <Route path="/register" exact component={RegisterPage} />
            <Route path="/reset-password" exact component={ResetPasswordPage} />
            <Route path="/reset-password/:uid/:token" component={ResetPasswordConfirmPage} />

            <AuthRoute
              path="/orders"
              exact
              component={OrdersPage}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator, RolesEnum.dispatcher]}
            />
            <AuthRoute
              path="/orders/archive"
              exact
              component={OrdersArchivePage}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/orders/withDirectPickup"
              exact
              component={OrdersWithDirectPickupPage}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/orders/withInvoice"
              exact
              component={OrdersWithInvoicePage}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/orders/new/1"
              exact
              component={OrderFormPageStep1}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/orders/new/2/new"
              exact
              component={OrderFormPageStep2}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/orders/new/2/:clientId"
              exact
              component={OrderFormPageStep2}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/orders/new/3"
              exact
              component={OrderFormPageStep3}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/orders/new/4"
              exact
              component={OrderFormPageStep4}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/orders/new/5"
              exact
              component={OrderFormPageStep5}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/orders/edit/:orderId/1"
              exact
              component={OrderEditPage1}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/orders/edit/:orderId/2"
              exact
              component={OrderEditPage2}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/orders/edit/:orderId/3"
              exact
              component={OrderEditPage3}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/restaurants"
              exact
              component={RestaurantsPage}
              onlyForRoles={[RolesEnum.admin, RolesEnum.operator]}
            />
            <AuthRoute
              path="/restaurants/settlement"
              exact
              component={RestaurantsSettlementPage}
              onlyForRoles={[RolesEnum.admin]}
            />
            <AuthRoute path="/employees" exact component={EmployeesPage} onlyForRoles={[RolesEnum.admin]} />
            <AuthRoute
              path="/employees/:employeeId/reckoning"
              exact
              component={EmployeeReckoningPage}
              onlyForRoles={[RolesEnum.admin]}
            />
            <AuthRoute path="/map" exact component={MapPage} onlyForRoles={[RolesEnum.admin, RolesEnum.operator]} />
            <AuthRoute path="/drivers" exact component={DriversPage} onlyForRoles={[RolesEnum.admin]} />
            <AuthRoute
              path="/drivers/:driverId/reckoning"
              exact
              component={DriversReckoningPage}
              onlyForRoles={[RolesEnum.admin]}
            />
            <AuthRoute path="/clients" exact component={ClientsPage} onlyForRoles={[RolesEnum.admin]} />
            <AuthRoute
              path="/clients/:clientId/orders"
              exact
              component={ClientsOrderPage}
              onlyForRoles={[RolesEnum.admin]}
            />
            <AuthRoute path="/promotions" exact component={PromotionsPage} onlyForRoles={[RolesEnum.admin]} />
            <AuthRoute path="/schedules" exact component={SchedulePage} onlyForRoles={[RolesEnum.admin]} />
            <AuthRoute path="/schedules/:startDate" exact component={SchedulePage} onlyForRoles={[RolesEnum.admin]} />
            <AuthRoute path="/settings" exact component={SettingsPage} onlyForRoles={[RolesEnum.admin]} />
            <AuthRoute path="/extra-extras" exact component={ExtraExtrasPage} onlyForRoles={[RolesEnum.admin]} />
            <Route path="*" component={Page404} />
          </Switch>
        </>
      </Router>
      <div id={DIALOG_CONTAINER_ID}></div>
    </div>
  );
};

export default enhance(App);
