import { PaymentType, PaymentTypeTypes, PaymentTypeVisibilities } from 'modules/Settings/PaymentTypesModel';

export const mapPaymentTypeDataToFormValues = (paymentTypeData: PaymentType) => {
  let paymentType;
  if (typeof paymentTypeData.paymentType !== 'undefined') {
    paymentType = PaymentTypeTypes.find(_paymentType => _paymentType.value === paymentTypeData.paymentType);
  }
  let paymentVisibility;
  if (typeof paymentTypeData.paymentVisibility !== 'undefined') {
    paymentVisibility = PaymentTypeVisibilities.find(
      _paymentVisibility => _paymentVisibility.value === paymentTypeData.paymentVisibility,
    );
  }

  return {
    icon: paymentTypeData.icon || '',
    namePl: paymentTypeData.namePl || '',
    nameEn: paymentTypeData.nameEn || '',
    displayedName: paymentTypeData.displayedName || '',
    isActive: typeof paymentTypeData.isActive !== 'undefined' ? paymentTypeData.isActive : false,
    paymentType: paymentType
      ? {
          key: paymentType.value,
          label: paymentType.name,
        }
      : 0,
    paymentVisibility: paymentVisibility
      ? {
          key: paymentVisibility.value,
          label: paymentVisibility.name,
        }
      : 0,
  };
};

export const mapFormValuesToPaymentData = values => {
  return {
    ...values,
    paymentType: values.paymentType.key,
    paymentVisibility: values.paymentVisibility.key,
  };
};
