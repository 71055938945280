import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

const mainApiCollection = 'customers';
const subCollection = 'phones';

export const ClientPhoneModel = SimpleRestModel({
  collectionName: 'clientPhones',
  HttpApi,
  getApiPath: (payload, action: string) => {
    switch (action) {
      case 'loadCollection':
      case 'createItem':
      default:
        return `${mainApiCollection}/${payload.customerId}/${subCollection}/`;
      case 'loadItem':
      case 'updateItem':
      case 'deleteItem':
        return `${mainApiCollection}/${payload.customerId}/${subCollection}/${payload.id}/`;
      case 'customCollectionAction':
        return `${mainApiCollection}/${payload.customerId}/${subCollection}/${payload.action}/`;
      case 'customItemAction':
        return `${mainApiCollection}/${payload.customerId}/${subCollection}/${payload.id}/${payload.action}/`;
    }
  },
});

export interface ClientPhone {
  id: number;
  customer: number;
  isMain: boolean;
  phone: string;
}
