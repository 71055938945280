import { parse } from 'date-fns';
import { OrderModel } from 'modules/Orders/models/order.model';
import { OrderFormState } from 'modules/Orders/OrderFormState';

export const mapOrderToFormOrderState: (order: OrderModel) => OrderFormState = order => {
  const orderClientUserAccount = order.customer.user;
  const orderClientUserName = orderClientUserAccount
    ? `${orderClientUserAccount.firstName} ${orderClientUserAccount.lastName}`
    : '';

  // todo - would be nice to fix all types for OrderFormState - should be independent from other models & types
  return <OrderFormState>{
    client: <any>order.customer, // todo: can we fix types here?
    clientName: orderClientUserName,
    address: {
      ...order.deliveryAddress,
    },
    phone: order.deliveryAddress.phone || null,
    email: order.deliveryAddress.email || null,
    company: order.invoiceData
      ? {
          id: order.invoiceData.id || undefined,
          companyName: order.invoiceData.companyName || '',
          vatNumber: order.invoiceData.vatNumber || '',
          addressCity: order.invoiceData.addressCity || '',
          addressPostalCode: order.invoiceData.addressPostalCode || '',
          addressData: order.invoiceData.addressData || '',
          isActive: false,
        }
      : null,
    basket: {
      selectedeSet: 0,
      extraExtras: {},
      sets: order.basket.sets.map(set => ({
        name: set.name,
        meals: set.meals.map(setMeal => ({
          meal: {
            id: setMeal.meal.id,
            index: setMeal.meal.index,
            price: setMeal.meal.price,
            nameEn: setMeal.meal.nameEn,
            namePl: setMeal.meal.namePl,
            descriptionEn: setMeal.meal.descriptionEn,
            descriptionPl: setMeal.meal.descriptionPl,
          },
          note: setMeal.note,
          extras: [], // todo!
          counter: setMeal.counter,
          restaurant: {
            id: setMeal.meal.restaurant.id,
          },
        })),
      })),
    },
    newClientData: null,
    restaurant: null,
    restaurantMealSelectedOnStep3: null,
    deliveryDate: order.customerTimeSet && order.deliveryDate ? parse(order.deliveryDate) : null,
    deliveryCost: null,
    deliveryTime: null,
    paymentType: order.paymentData ? order.paymentData.id : null,
    promotion: null,
    allDeliveryCosts: [],
    withDirectPickUp: order.withDirectPickUp,
    note: order.notes,
    bonus: order.bonus,
    driverBonusAmount: order.driverBonusAmount,
    driverBonusDescription: order.driverBonusDescription,
    privateNote: order.privateNotes,
    clientNote: '',
    orderId: order.id,
  };
};
