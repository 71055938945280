import { ColumnProps } from 'antd/es/table';
import { formattedDate, formattedTime, Header, Spacer, Table } from 'lib/ui';
import { OrderHistoryModel } from 'modules/Orders/models/order-history.model';
import { OrderModel } from 'modules/Orders/models/order.model';
import React from 'react';

export interface OrderHistoryProps {
  order: OrderModel;
}

export const OrderHistory: React.FC<OrderHistoryProps> = props => {
  const columns: ColumnProps<OrderHistoryModel>[] = [
    {
      title: 'Użytkownik',
      dataIndex: 'userName',
    },
    {
      title: 'Data',
      dataIndex: 'issueTime',
      render: (text, record, index) => {
        return `${formattedDate(record.issueTime)}, ${formattedTime(record.issueTime)}`;
      },
    },
    {
      title: 'Czynność',
      dataIndex: 'issueDescription',
    },
  ];

  return (
    <div>
      <Header>Historia zamówienia</Header>
      <Spacer />
      <Table columns={columns} rows={props.order.orderHistory} />
    </div>
  );
};
