import { Badge, message, Popconfirm, Radio, Tabs } from 'antd';
import { Textarea } from 'lib/forms';

import { Button, ButtonVariant, EmptyState, Group, Loading, Modal, SimpleInputDialog, Spacer, styled } from 'lib/ui';
import { OrderSentMessages } from 'pages/OrdersPage/components/OrderMessageDialog/OrderSentMessages';
import React, { useCallback, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { useToggle } from 'react-use';
import { compose } from 'recompose';
import { printPrintables } from 'services/printPrintables';
import { OrderMessageForm } from './OrderMessageForm';

// export interface OrderMessageDialogProps extends ModalProps {
//   order: any;
// }

const OrderMessageDialogContainer = styled.div.attrs({ className: 'OrderMessageDialogContainer' })``;

const enhance = compose(
  connect(
    ({ orders, orderDrivers, drivers, loading }) => ({
      ordersStore: orders,
      orderDriversStore: orderDrivers,
      driversStore: drivers,
      loading,
    }),
    ({ orders, orderDrivers, drivers, restaurants }) => ({
      orders,
      orderDrivers,
      drivers,
      restaurants,
    }),
  ),
);

export const OrderMessageDialog = enhance(props => {
  //  get always fresh order object from store
  const order = props.order;

  const [loading, toggleLoading] = useToggle(false);
  const [orderMessages, setOrderMessages] = useState<any>();
  const [additionalMessage, setAdditionalMessage] = useState('');
  const [simpleInputDialogVisible, toggleSimpleInputDialog] = useToggle(false);
  const [inputType, setInputType] = useState<'contactFax' | 'contactEmail'>('contactFax');
  const [restaurantID, setRestaurantID] = useState(null);

  const refreshOrder = useCallback(() => {
    props.orders.loadItem({
      id: props.order.id,
    });
  }, [order]);

  useEffect(() => {
    if (order && order.id) {
      props.orders
        .customItemAction({
          id: order.id,
          action: 'message',
          actionMethod: 'get',
        })
        .then(data => {
          setOrderMessages(data && data.restaurants);
        })
        .finally(() => {
          toggleLoading(false);
        });
    }
  }, [order]);

  const printMessage = index => {
    let messageForPrint;
    if (additionalMessage) {
      messageForPrint = document.createElement('p');
      messageForPrint.innerText = additionalMessage;
      messageForPrint.setAttribute('data-printable', index);
      document.body.appendChild(messageForPrint);
    }

    printPrintables(`[data-printable="${index}"]`);

    if (messageForPrint) {
      document.body.removeChild(messageForPrint);
    }
  };

  return (
    <Modal {...props} visible width="60%">
      <OrderMessageDialogContainer>
        <div data-bp="grid">
          <div data-bp="12">
            {orderMessages && orderMessages.length > 0 && (
              <Tabs>
                {orderMessages.map((orderMessage, index) => {
                  const sentNotificationsForOrder = order.sentNotifications
                    ? order.sentNotifications.filter(notification => notification.initialData.id === orderMessage.id)
                    : [];

                  return (
                    <Tabs.TabPane
                      forceRender
                      key={orderMessage.id}
                      tab={
                        <Badge count={sentNotificationsForOrder.length}>
                          <span>{orderMessage.restaurantName ? orderMessage.restaurantName : index + 1} </span>
                        </Badge>
                      }
                    >
                      <h1 data-printable={index} className="print-only">
                        {orderMessage.restaurantName}
                      </h1>

                      <div data-bp="flex margin-bottom">
                        <Radio.Group value={orderMessage.prefferedOrdersContact} buttonStyle="solid">
                          <Popconfirm
                            title="Czy na pewno zmienić domyślną formę kontaktu dla tej restauracji na Email?"
                            okText="Tak, zmień"
                            cancelText="Anuluj"
                            onConfirm={() => {
                              if (orderMessage.email.length) {
                                props.restaurants
                                  .updateItem({
                                    data: {
                                      prefferedOrdersContact: 10,
                                    },
                                    id: orderMessage.id,
                                  })
                                  .then(() => {
                                    message.success('Forma kontaktu została zmieniona');
                                    refreshOrder();
                                  })
                                  .catch(() => {
                                    message.error('Wystąpił błąd');
                                  });
                              } else {
                                setInputType('contactEmail');
                                setRestaurantID(orderMessage.id);
                                toggleSimpleInputDialog(true);
                              }
                            }}
                          >
                            <Radio.Button value={10}>Email</Radio.Button>
                          </Popconfirm>
                        </Radio.Group>
                      </div>

                      <div data-bp="12">
                        <h3
                          data-printable={index}
                          className={orderMessage.prefferedOrdersContact === 0 ? '' : 'print-only'}
                        >
                          FAX: {orderMessage.contactFax}
                        </h3>
                      </div>

                      {orderMessage.prefferedOrdersContact === 10 && (
                        <>
                          <OrderMessageForm
                            message={orderMessage}
                            messageIndex={index}
                            order={order}
                            onClose={() => {
                              props.onClose();
                            }}
                            onPrint={() => printMessage(index)}
                            onMessageSend={() => {
                              refreshOrder();
                              if (props.onMessageSend) {
                                props.onMessageSend();
                              }
                              props.onClose();
                            }}
                          />
                        </>
                      )}

                      {orderMessage.prefferedOrdersContact === 0 && (
                        <div data-bp="12">
                          <div data-bp="grid">
                            <div data-bp="12">Dodatkowa treść:</div>
                            <div data-bp="12">
                              <Textarea
                                value={additionalMessage}
                                onChange={ev => {
                                  setAdditionalMessage(ev.currentTarget.value);
                                }}
                                width="100%"
                              />
                            </div>
                            <Spacer />
                            <div
                              data-bp="12"
                              key={`${orderMessage.message}`}
                              dangerouslySetInnerHTML={{ __html: orderMessage.message }}
                              data-printable={index}
                            />
                          </div>
                          <Spacer />
                          <Group align="space-between">
                            <Button
                              label="Anuluj"
                              onClick={() => {
                                props.onClose();
                              }}
                            />

                            <div data-bp="flex">
                              <Button
                                iconName="print"
                                label="Drukuj"
                                onClick={() => {
                                  printMessage(index);
                                }}
                              />
                              <Spacer horizontal />
                              <Button
                                iconName="fax"
                                variant={ButtonVariant.Primary}
                                label="Oznacz jako wysłane"
                                onClick={() => {
                                  console.warn('ok');
                                }}
                              />
                            </div>
                          </Group>
                        </div>
                      )}

                      {sentNotificationsForOrder.length > 0 && (
                        <OrderSentMessages sentNotificationsForOrder={sentNotificationsForOrder} />
                      )}
                    </Tabs.TabPane>
                  );
                })}
              </Tabs>
            )}
            {!orderMessages ||
              (orderMessages.length === 0 && <EmptyState message="Brak wiadomości do wysłania." withIcon={false} />)}
          </div>

          {simpleInputDialogVisible && (
            <SimpleInputDialog
              open
              inputValue={''}
              title={`Zmiana preferowanego kontaktu dla restauracji`}
              inputLabel={inputType === 'contactFax' ? 'Numer FAX' : 'Adres email'}
              okText="Zapisz"
              onSave={data => {
                props.restaurants
                  .updateItem({
                    data: {
                      [inputType]: inputType === 'contactFax' ? data.values.input : [data.values.input],
                      prefferedOrdersContact: inputType === 'contactFax' ? 0 : 10,
                    },
                    id: restaurantID,
                  })
                  .then(() => {
                    message.success('Forma kontaktu została zmieniona');
                    refreshOrder();
                    toggleSimpleInputDialog(false);
                  })
                  .catch(error => {
                    let errorMessage = 'Wystąpił błąd';
                    if (error.data && error.data.contactEmail) {
                      // error.data.contactEmail is not an Array - it's returned from API as object
                      // eslint-disable-next-line prefer-destructuring
                      errorMessage = error.data.contactEmail[0];
                    }
                    if (error.data && error.data.contactFax) {
                      // error.data.contactFax is not an Array - it's returned from API as object
                      // eslint-disable-next-line prefer-destructuring
                      errorMessage = error.data.contactFax[0];
                    }
                    message.error(errorMessage);
                  });
              }}
              onCancel={() => {
                toggleSimpleInputDialog(false);
              }}
            />
          )}

          {loading && <Loading />}
        </div>
      </OrderMessageDialogContainer>
    </Modal>
  );
});
