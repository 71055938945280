import { styled } from '../ui/styled-components';

export interface FormFieldContainerProps {
  width?: number | string;
}

export const FormFieldContainer = styled.div.attrs({
  className: 'FormFieldContainerContainer',
})<FormFieldContainerProps>`
  margin: ${p => p.theme.spaceSmall} 0 0 0;
  text-align: left;
  max-width: 100%;
  width: ${p => (p.width ? p.width : 'auto')};
`;
