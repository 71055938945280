import { formattedPrice } from 'lib/ui';
import { OrderModel } from 'modules/Orders/models/order.model';

export const getRestaurantAddressFromOrder: (order: OrderModel) => string = order => {
  const restaurant = order.restaurants[0];
  if (!restaurant) {
    return '-';
  }
  return `${restaurant.displayedName} - ${restaurant.address.addressData} ${restaurant.address.addressPostalCode ||
    ''} ${restaurant.address.addressCity}`;
};

export const getRestaurantNameFromOrder: (order: OrderModel) => string = order => {
  const restaurant = order.restaurants[0];
  return `${restaurant.displayedName}`;
};

export const getFormattedPriceFromOrder: (order: OrderModel) => string = order =>
  formattedPrice({ amount: order.gross });

export const getDeliveryAddressFromOrder: (order: OrderModel) => string = order =>
  `${order.deliveryAddress.street || ''} ${order.deliveryAddress.houseNumber || ''} ${order.deliveryAddress
    .details || ''}  ${order.deliveryAddress.city || ''}`;

export const getClientNameFromOrder: (order: OrderModel) => string = order => `${order.customer.name}`;
