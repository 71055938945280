import { message } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AgGridColumnProps } from 'ag-grid-react';

import { OrderInvoiceDataDialog } from 'modules/Orders/components/OrderInvoiceDataDialog/OrderInvoiceDataDialog';
import {
  AgGridTable,
  Button,
  ButtonSize,
  formattedShortDateWithYear,
  formattedTime,
  Group,
  modalConfirm,
} from 'lib/ui';
import { OrderModel } from 'modules/Orders/models/order.model';
import {
  getFormattedPriceFromOrder,
  getRestaurantAddressFromOrder,
  getRestaurantNameFromOrder,
} from 'modules/Orders/utils/order.getters';
import { useToggle } from 'react-use';

export interface OrdersWithInvoiceTableProps {
  orders: OrderModel[];
  onRefresh: () => void;
}

export const OrdersWithInvoiceTable: React.FC<OrdersWithInvoiceTableProps> = props => {
  const dispatch = useDispatch();
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [invoiceDataDialogOpened, toggleInvoiceDataDialog] = useToggle(false);

  const columns: AgGridColumnProps[] = [
    {
      headerName: 'Restauracja',
      tooltipValueGetter: params => params.value,
      valueGetter: params => getRestaurantNameFromOrder(params.data),
    },
    {
      headerName: 'Adres dostawy',
      tooltipValueGetter: params => params.value,
      valueGetter: params => getRestaurantAddressFromOrder(params.data),
    },
    {
      headerName: 'Kwota',
      tooltipValueGetter: params => params.value,
      valueGetter: params => getFormattedPriceFromOrder(params.data),
    },
    {
      headerName: 'Czas przyg.',
      tooltipValueGetter: params => params.value,
      valueGetter: params =>
        `${formattedShortDateWithYear(params.data.deliveryDate)} ${formattedTime(params.data.deliveryDate)}`,
    },
    {
      headerName: ' ',
      // tooltipValueGetter: params => params.value,
      cellRendererFramework: rowProps => {
        const order = rowProps.data;

        return (
          <Group>
            <Button
              size={ButtonSize.Small}
              label={'Dane do FV'}
              onClick={() => {
                setSelectedOrder(order);
                toggleInvoiceDataDialog();
              }}
            />
            <Button
              size={ButtonSize.Small}
              label={'FV wystawiona'}
              onClick={() => {
                modalConfirm({
                  title: `Czy na pewno oznaczyć FV jako wysłaną?`,
                  content: ' ',
                  yesCallback: () => {
                    dispatch({
                      type: 'currentOrders/updateItem',
                      payload: {
                        id: order.id,
                        data: {
                          invoiceSent: true,
                        },
                      },
                    })
                      .then(() => {
                        message.success('Zamówienie zostało zaktualizowane');
                        props.onRefresh();
                      })
                      .catch(() => {
                        message.error('Wystąpił nieoczekiwany błąd');
                      });
                  },
                });
              }}
            />
          </Group>
        );
      },
    },
  ];

  const rows = props.orders;

  return (
    <div>
      <AgGridTable columnDefs={columns} rowData={rows} />

      {invoiceDataDialogOpened && <OrderInvoiceDataDialog order={selectedOrder} onClose={toggleInvoiceDataDialog} />}
    </div>
  );
};
