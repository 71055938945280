import { DatePicker } from 'antd';
import { AppDateTimePickerDefaults } from 'components/AppUtils';
import { Formik } from 'formik';

import { Button, ButtonVariant, Group, Modal, ModalProps } from 'lib/ui';
import { TimePickerDefaults } from 'lib/ui/utils';
import moment from 'moment';
import React, { FunctionComponent, ReactNode } from 'react';

export interface DateRangePickerModalProps extends ModalProps {
  children?: ReactNode;
  onSubmit: Function;
  onReset?: Function;
  dateStart?: Date;
  dateEnd?: Date;
  dateStartLabel?: string;
  dateEndLabel?: string;
  saveButtonText?: string;
}

const DateRangePickerModalProps = {
  dateStart: new Date(),
  dateEnd: new Date(),
  dateStartLabel: 'Data początkowa',
  dateEndLabel: 'Data końcowa',
  saveButtonText: 'Zapisz',
};

const DateRangePickerModal: FunctionComponent<DateRangePickerModalProps> = ({
  dateStart,
  dateEnd,
  onSubmit,
  dateStartLabel,
  dateEndLabel,
  saveButtonText,
  ...props
}) => {
  return (
    <Formik
      initialValues={{
        start: dateStart || '',
        end: dateEnd || '',
      }}
      validate={values => {
        const errors = {};
        return errors;
      }}
      onSubmit={(values, bag) => {
        onSubmit(
          {
            ...values,
            start: moment(values.start).toISOString(),
            end: moment(values.end).toISOString(),
          },
          bag,
        );
      }}
    >
      {formikProps => (
        <Modal
          {...props}
          footer={
            <Group align="space-between">
              {props.onReset && (
                <Button
                  label="Resetuj"
                  disabled={formikProps.isSubmitting}
                  onClick={() => {
                    if (props.onReset) {
                      props.onReset();
                    }
                  }}
                />
              )}
              <Group align="right">
                <Button disabled={formikProps.isSubmitting} onClick={props.onClose} label={'Anuluj'} />
                <Button
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                  variant={ButtonVariant.Primary}
                  loading={formikProps.isSubmitting}
                  onClick={formikProps.handleSubmit}
                  label={saveButtonText || 'Zapisz'}
                />
              </Group>
            </Group>
          }
        >
          <>
            <form onSubmit={formikProps.handleSubmit}>
              <DatePicker.RangePicker
                showTime={{
                  format: TimePickerDefaults.TimeFormat,
                  minuteStep: TimePickerDefaults.MinuteStep,
                }}
                format={AppDateTimePickerDefaults.DateTimeFormat}
                value={[moment(formikProps.values['start']), moment(formikProps.values['end'])]}
                onChange={(dates, dateStrings) => {
                  formikProps.setFieldValue('start', dates[0]);
                  formikProps.setFieldValue('end', dates[1]);
                }}
              />
            </form>
          </>
        </Modal>
      )}
    </Formik>
  );
};

DateRangePickerModal.defaultProps = DateRangePickerModalProps;

export { DateRangePickerModal };
