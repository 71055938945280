import { FormikProps, FormikValues } from 'formik';
import { path } from 'rambdax';
import React, { FunctionComponent } from 'react';

import { FormFieldLabel, FieldError, Textarea, FormFieldContainer } from '.';

export interface FormTextareaFieldProps extends FormikProps<FormikValues> {
  name: string;
  label?: string;
  hint?: string;
  placeholder?: string;
  width?: number | string;
}

const FormTextareaField: FunctionComponent<FormTextareaFieldProps> = props => {
  const isTouched = path(props.name, props.touched);
  const error = isTouched && path(props.name, props.errors);

  return (
    <FormFieldContainer width={props.width}>
      {props.label && <FormFieldLabel hint={props.hint}>{props.label}</FormFieldLabel>}
      <div>
        <Textarea
          placeholder={props.placeholder}
          name={props.name}
          onChange={ev => {
            props.setFieldValue(props.name, ev.currentTarget.value);
          }}
          onBlur={_ => {
            props.setFieldTouched(props.name, true);
          }}
          value={props.values[props.name]}
          width={props.width}
        />
      </div>
      {error && <FieldError error={error} />}
    </FormFieldContainer>
  );
};

export { FormTextareaField };
