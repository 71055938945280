import { addMonths, subMonths } from 'date-fns';
import React from 'react';
import { Button, ButtonVariant, ensureDateObject, Group } from 'lib/ui';

export interface MonthSwitcherProps {
  date: Date | string;
  onDateChange: (date: Date) => void;
}

export const MonthSwitcher: React.FC<MonthSwitcherProps> = props => {
  return (
    <div>
      <Group>
        <Button
          variant={ButtonVariant.Plain}
          label="<<"
          onClick={() => {
            props.onDateChange(subMonths(props.date, 1));
          }}
        />
        <span>
          {new Intl.DateTimeFormat('pl-PL', {
            formatMatcher: 'basic',
            month: 'long',
            year: 'numeric',
          }).format(ensureDateObject(props.date))}
        </span>
        <Button
          variant={ButtonVariant.Plain}
          label=">>"
          onClick={() => {
            props.onDateChange(addMonths(props.date, 1));
          }}
        />
      </Group>
    </div>
  );
};
