import { OrderSet } from 'modules/Orders/models/order-basket.model';
import { OrderSetMealModel } from 'modules/Orders/models/order-set-meal.model';

const getOrderSetExtrasAmount: (orderSetMeal: OrderSetMealModel) => number = orderSetMeal => {
  return orderSetMeal.meal.extras.reduce(
    (sum, basketMealExtras) => sum + basketMealExtras.priceChange * basketMealExtras.counter,
    0,
  );
};

export const getOrderSetAmount: (set: OrderSet) => number = set => {
  return set.meals.reduce((sum, orderSetMeal) => {
    return sum + orderSetMeal.meal.price * orderSetMeal.counter + getOrderSetExtrasAmount(orderSetMeal);
  }, 0);
};
