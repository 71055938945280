import React, { FunctionComponent } from 'react';

import { styled, Button, Group, ButtonSize } from '../..';

export interface CounterProps {
  counter: number;
  minValue?: number;
  maxValue?: number;
  hideIncreaceButton?: boolean;
  hideDecreseButton?: boolean;
  canIncreace?: boolean;
  canDecrese?: boolean;
  onChange: (counter: number) => void;
}
const CounterValue = styled.div.attrs({ className: 'CounterValue' })`
  font-weight: ${p => p.theme.fontWeightBold};
`;

const CounterContainer = styled.div.attrs({ className: 'CounterContainer' })``;

export const Counter: FunctionComponent<CounterProps> = props => {
  return (
    <CounterContainer>
      <Group>
        {!props.hideDecreseButton && (
          <Button
            size={ButtonSize.Small}
            iconName={'minus'}
            disabled={(typeof props.minValue !== 'undefined' && props.counter === props.minValue) || !props.canDecrese}
            onClick={() => {
              props.onChange(props.counter - 1);
            }}
          />
        )}
        <CounterValue>{props.counter}</CounterValue>
        {!props.hideIncreaceButton && (
          <Button
            size={ButtonSize.Small}
            iconName={'plus'}
            disabled={(typeof props.maxValue !== 'undefined' && props.counter === props.maxValue) || !props.canIncreace}
            onClick={() => {
              props.onChange(props.counter + 1);
            }}
          />
        )}
      </Group>
    </CounterContainer>
  );
};
