import { AgGridTable, Button, ButtonSize, ButtonVariant, styled } from 'lib/ui';
import React, { FunctionComponent } from 'react';

export interface DriversTableProps {
  items: any[];
  onItemClick?: Function;
  onDriverReckoning: (driverId: number) => void;
  onStatusChangeClick?: Function;
}

const DriversTableDefaultProps = {};

const DriversTableContainer = styled.div``;

const DriversTable: FunctionComponent<DriversTableProps> = props => {
  const columns = [
    {
      headerName: 'Imię i nazwisko',
      field: 'name',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: cellProps => (
        <a
          onClick={() => {
            if (props.onItemClick) {
              props.onItemClick(cellProps.data);
            }
          }}
        >
          {cellProps.data.user.firstName} {cellProps.data.user.lastName}
        </a>
      ),
    },
    {
      headerName: 'Login',
      field: 'login',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: cellProps => <span>{cellProps.data.user.email}</span>,
    },
    {
      headerName: 'Telefon',
      field: 'phone',
      cellRendererFramework: cellProps => {
        const item = cellProps.data;
        if (item.isMainPhone1) {
          return item.phone1;
        }
        if (item.isMainPhone2) {
          return item.phone2;
        }
        if (item.isMainPhone3) {
          return item.phone3;
        }
        return null;
      },
    },
    {
      headerName: 'Terminal',
      cellRendererFramework: cellProps => (cellProps.data.isActive ? 'TAK' : 'NIE'),
    },
    {
      headerName: 'Uwagi',
      field: 'notes',
    },
    {
      headerName: '',
      field: 'action',
      width: 80,
      cellRendererFramework: cellProps => (
        <Button
          size={ButtonSize.Small}
          onClick={() => {
            props.onDriverReckoning(cellProps.data.id);
          }}
        >
          Rozliczenia
        </Button>
      ),
    },
  ];

  return (
    <DriversTableContainer>
      <AgGridTable columnDefs={columns} rowData={props.items} />
    </DriversTableContainer>
  );
};

DriversTable.defaultProps = DriversTableDefaultProps;

export { DriversTable };
