import React, { FunctionComponent, ReactNode } from 'react';
import { Formik } from 'formik';

import { Modal, Button, ButtonVariant, Group, ModalProps } from '..';

import { FieldError, FormError, Textarea, FormFieldContainer, Input, FormFieldLabel } from '../../forms';

export interface SimpleInputDialogProps extends ModalProps {
  onCancel: () => void;
  onSave: (data: any) => void;
  open?: boolean;
  loading?: boolean;
  title?: string;
  description?: ReactNode;
  errors?: any;
  inputValue?: string;
  inputLabel?: string;
  textValue?: string;
  textLabel?: string;
  requireText?: boolean;
  onlyText?: boolean;
  cancelText?: string;
  okText?: string;
}

export const SimpleInputDialog: FunctionComponent<SimpleInputDialogProps> = props => {
  const {
    open,
    title,
    description,
    errors,
    inputValue,
    inputLabel,
    textValue,
    textLabel,
    requireText,
    onlyText,
    loading,
    onCancel,
    onSave,
    cancelText,
    okText,
  } = props;

  if (!open) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        input: inputValue || '',
        text: textValue || '',
      }}
      validate={values => {
        const errors: any = {};
        if (!values.input && !onlyText) {
          errors.input = 'Pole jest wymagane';
        }
        if (requireText && !values.text) {
          errors.text = 'Pole jest wymagane';
        }
        return errors;
      }}
      onSubmit={(values, bag) => {
        onSave({
          values,
          ...bag,
        });
      }}
      render={formikProps => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <Modal
              closable={!loading}
              visible={open}
              header={<h2>{title || '...'}</h2>}
              onClose={onCancel}
              footer={
                <Group align="right">
                  <Button disabled={loading} onClick={onCancel} label={cancelText || 'Anuluj'} />
                  <Button
                    variant={ButtonVariant.Primary}
                    loading={loading}
                    onClick={formikProps.handleSubmit}
                    label={okText || 'Zapisz'}
                  />
                </Group>
              }>
              {description && <div>{description}</div>}

              {errors && <FormError errors={errors} />}

              {!onlyText && (
                <FormFieldContainer>
                  <FormFieldLabel>{inputLabel}</FormFieldLabel>
                  <Input
                    name="input"
                    type="text"
                    onChange={ev => {
                      formikProps.setFieldValue('input', ev.currentTarget.value);
                    }}
                    onBlur={_ => {
                      formikProps.setFieldTouched('input', true);
                    }}
                    value={formikProps.values.input}
                  />
                  {formikProps.errors.input && formikProps.touched.input && (
                    <FieldError error={formikProps.errors.input} />
                  )}
                </FormFieldContainer>
              )}

              {(textLabel || textValue) && (
                <React.Fragment>
                  <label>{textLabel}</label>
                  <Textarea
                    name="text"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.text}
                  />
                  {formikProps.errors.text && formikProps.touched.text && (
                    <FieldError error={formikProps.errors.text} />
                  )}
                </React.Fragment>
              )}
            </Modal>
          </form>
        );
      }}
    />
  );
};
