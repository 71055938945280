import { DatePicker } from 'antd';
import { AppDateTimePickerDefaults } from 'components/AppUtils';
import { Formik, FormikActions } from 'formik';

import { ButtonSubmit, FormError, FormField, FormFieldContainer, FormFieldLabel, FormSwitchField } from 'lib/forms';
import { stringRequired } from 'lib/forms/YupValidation';
import { ButtonUpload, Group, Modal, ModalProps, Spacer } from 'lib/ui';
import { TimePickerDefaults } from 'lib/ui/utils';
import { Promotion } from 'modules/Promotions';
import moment from 'moment';
import { path } from 'rambdax';
import React, { FunctionComponent, ReactNode } from 'react';
import { getUrlForMediaImage } from 'services/utils/getUrlForMediaImage';
import * as Yup from 'yup';

export interface PromotionModalProps extends ModalProps {
  children?: ReactNode;
  onSubmit: (values: any, bag: FormikActions<any>) => void;
  errors: any;
  item?: Promotion;
  withoutDescription?: boolean;
  saveButtonText?: string;
}

export const PromotionModal: FunctionComponent<PromotionModalProps> = props => {
  const item: Partial<Promotion> = props.item || {};

  return (
    <Formik
      initialValues={{
        dateStart: item.dateStart || new Date(),
        dateEnd: item.dateEnd || new Date(),
        namePl: item.namePl || '',
        nameEn: item.nameEn || '',
        urlPl: item.urlPl || '',
        urlEn: item.urlEn || '',
        descriptionPl: item.descriptionPl || '',
        descriptionEn: item.descriptionEn || '',
        isActive: item.isActive || false,
        image: undefined,
      }}
      validationSchema={Yup.object().shape({
        namePl: stringRequired(),
        nameEn: stringRequired(),
        urlPl: stringRequired(),
        urlEn: stringRequired(),
        descriptionPl: stringRequired(),
        descriptionEn: stringRequired(),
      })}
      onSubmit={(values, bag) => {
        props.onSubmit(
          {
            ...values,
            dateStart: moment(values.dateStart).toISOString(),
            dateEnd: moment(values.dateEnd).toISOString(),
          },
          bag,
        );
      }}
    >
      {formikProps => {
        const selectedFileName = path('values.image.name', formikProps);

        return (
          <Modal {...props}>
            <>
              {props.errors && <FormError errors={props.errors} />}
              <form onSubmit={formikProps.handleSubmit}>
                <Group vertical>
                  <FormField name="namePl" label="Nazwa PL" {...formikProps} />
                  <FormField name="nameEn" label="Nazwa EN" {...formikProps} />
                  <FormField name="descriptionPl" label="Opis PL" {...formikProps} />
                  <FormField name="descriptionEn" label="Opis EN" {...formikProps} />
                  <FormField name="urlPl" label="Adres PL" {...formikProps} />
                  <FormField name="urlEn" label="Adres EN" {...formikProps} />
                  <FormFieldContainer>
                    <FormFieldLabel>Okres obowiązywania</FormFieldLabel>
                    <DatePicker.RangePicker
                      showTime={{
                        format: TimePickerDefaults.TimeFormat,
                        minuteStep: TimePickerDefaults.MinuteStep,
                      }}
                      format={AppDateTimePickerDefaults.DateTimeFormat}
                      value={[moment(formikProps.values.dateStart), moment(formikProps.values.dateEnd)]}
                      onChange={(dates, dateStrings) => {
                        formikProps.setFieldValue('dateStart', dates[0]);
                        formikProps.setFieldValue('dateEnd', dates[1]);
                      }}
                    />
                  </FormFieldContainer>

                  <Group>
                    <Spacer />
                    <ButtonUpload
                      label={`${item.image ? 'Zmień obrazek' : 'Dodaj obrazek'}: ${selectedFileName || ''}`}
                      onChange={file => formikProps.setFieldValue('image', file)}
                    />
                    {item.image && (
                      <img
                        src={getUrlForMediaImage(item.image)}
                        style={{
                          maxWidth: '100%',
                          maxHeight: '80px',
                        }}
                        alt="Obrazek promocji"
                      />
                    )}
                  </Group>

                  <FormFieldContainer>
                    <FormSwitchField name="isActive" label="Status" {...formikProps} />
                  </FormFieldContainer>
                </Group>
                <div className={'mt-4'}>
                  <ButtonSubmit
                    disabled={formikProps.isSubmitting}
                    loading={formikProps.isSubmitting}
                    label={'Zapisz'}
                    onClick={formikProps.handleSubmit}
                    iconName="save"
                  />
                </div>
              </form>
            </>
          </Modal>
        );
      }}
    </Formik>
  );
};
