import config from 'config';
import { httpClient } from 'lib/services';

export const HttpApi = httpClient({
  baseURL: config.apiUrl,
  localStorageKeyWithToken: 'token',
  debug: false,
  onUnauthorized: res => {
    const loginPath = '/login';
    if (window.location.pathname !== loginPath) {
      window.location.href = loginPath;
    }
  },
});
