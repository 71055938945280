import { PageLayout } from 'components';
import { useCurrentOrderForEdit } from 'hooks/useCurrentOrderForEdit';

import { Button, ButtonVariant, Divider, DividerTextOrientation, Group, Loading, Spacer } from 'lib/ui';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormState } from 'modules/Orders/OrderFormState';
import { OrderEditSteps } from 'pages/OrderEdit/OrderEditSteps';
import { OrderFormCompany } from 'pages/OrderFormPage/components/OrderFormCompany';
import { OrderFormClient } from 'pages/OrderFormPage/OrderFormPageStep2/OrderFormClient';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useObservable } from 'react-use';

export const OrderEditPage1 = props => {
  const dispatch = useDispatch();
  const currentAddress = useRef<number | null>(null);
  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());

  const { orderId } = props.match.params;

  const { order, isLoading } = useCurrentOrderForEdit(orderId, true);

  // fetch delivery cost for current address and the restaurant
  useEffect(() => {
    let addressHasChanged = false;

    if (orderState && orderState.address) {
      if (orderState.address.id !== currentAddress.current) {
        currentAddress.current = orderState.address.id;
        addressHasChanged = true;
      }
    }

    if (order && orderState && addressHasChanged) {
      dispatch({
        type: 'orders/customCollectionAction',
        payload: {
          actionMethod: 'post',
          action: 'searchRestaurants',
          data: {
            deliveryAddress: { id: currentAddress.current },
            restaurantId:
              orderState.basket.sets[0].meals[0] &&
              orderState.basket.sets[0].meals[0].restaurant &&
              orderState.basket.sets[0].meals[0].restaurant.id,
          },
        },
      }).then(data => {
        OrderFormService.saveRestaurantWithDeliveryCost(data[0]);
      });
    }
  }, [dispatch, order, orderState]);

  if (!orderState) {
    return null;
  }

  return (
    <PageLayout title="Edycja zamówienia" header={<OrderEditSteps activePage={1} />}>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <OrderFormClient
            addressEditable={!orderState.withDirectPickUp}
            directPickUpEditable={false}
            clientId={orderState.client ? orderState.client.id : undefined}
          />

          <Spacer />

          <Divider text="Dane do FV" textOrientation={DividerTextOrientation.Left} />

          <Spacer />

          <OrderFormCompany clientId={orderState.client ? orderState.client.id : undefined} />

          <Spacer />
          <Group>
            <Button
              label="Dalej"
              disabled={!orderState || (!orderState.deliveryCost && !orderState.withDirectPickUp)}
              loading={!orderState || (!orderState.deliveryCost && !orderState.withDirectPickUp)}
              variant={ButtonVariant.Primary}
              onClick={() => {
                props.history.push(`/orders/edit/${orderId}/2`);
              }}
            />
          </Group>
        </>
      )}
    </PageLayout>
  );
};
