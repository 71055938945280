import { message } from 'antd';
import { Formik } from 'formik';

import { Button, ButtonVariant, Divider, Group, Hint, Modal, ModalProps } from 'lib/ui';
import React, { FunctionComponent, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  ButtonSubmit,
  FormError,
  FormField,
  FormFieldInputNumber,
  FormSwitchField,
  FormTextareaField,
} from 'lib/forms';

const enhance = connect(
  ({ clients, loading }) => ({
    clientsStore: clients,
    loading,
  }),
  ({ clients }) => ({
    clients,
  }),
);

export interface ClientModalProps extends ModalProps {
  item?: any;
  onSave?: (values: any) => void;
}

export const ClientModal: FunctionComponent<ClientModalProps> = enhance(({ item, ...props }) => {
  const getInitialValues = () => ({
    firstName: item && item.user ? item.user.firstName : '',
    lastName: item && item.user ? item.user.lastName : '',
    email: item && item.user ? item.user.email : '',
    notes: item ? item.notes : '',
    balance: item ? item.balance / 100 : 0,
    discount: item ? item.discount : 0,
    createUser: false,
    isActive: item ? item.isActive : false,
  });

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={(values: any, bag) => {
        const payload = {
          ...values,
          balance: values.balance * 100,
        };

        if (item && item.id) {
          props.clients
            .updateItem({
              data: payload,
              id: item.id,
            })
            .then(() => {
              bag.setSubmitting(false);
              message.success('Zmiany zostały zapisane');
            })
            .catch(() => {
              bag.setSubmitting(false);
            });
        } else {
          props.clients
            .createItem({ data: payload })
            .then(() => {
              bag.setSubmitting(false);
              message.success('Klient został dodany');
              props.clients.clearError();
              props.onSave();
              return props.clients.loadCollection();
            })
            .catch(() => {
              bag.setSubmitting(false);
            });
        }
      }}
      enableReinitialize
    >
      {formikProps => {
        return (
          <Modal
            {...props}
            visible
            header={item ? <h2>Edycja klienta</h2> : <h2>Tworzenie nowego klienta</h2>}
            footer={
              <Group align="right">
                <Button disabled={formikProps.isSubmitting} onClick={() => {
                  props.clients.clearError();
                  props.onClose();
                }} label={'Anuluj'} />
                <Button
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                  variant={ButtonVariant.Primary}
                  loading={formikProps.isSubmitting}
                  onClick={formikProps.handleSubmit}
                  iconName="save"
                  label="Zapisz"
                />
              </Group>
            }
          >
            {props.clientsStore.errors && <FormError errors={props.clientsStore.errors} />}
            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <Group align="space-between">
                  <FormField name="firstName" label="Imię" {...formikProps} />
                </Group>

                <Group align="space-between">
                  <FormField name="lastName" label="Nazwisko" {...formikProps} />
                </Group>

                <FormField name="email" label="Email" {...formikProps} />
                <FormField name="discount" label="Rabat" {...formikProps} />
                <FormFieldInputNumber name="balance" label="Saldo klienta" {...formikProps} />

                <FormTextareaField name="notes" label="Informacje o kliencie" {...formikProps} />

                <FormSwitchField
                  name="isActive"
                  label="Aktywny"
                  hint="Klient aktywny może składać zamówienia telefonicznie i online"
                  {...formikProps}
                />

                {(!item || !item.hasUserAccount) && (
                  <FormSwitchField
                    name="createUser"
                    label="Utwórz konto"
                    hint="Tylko klient z utworzonym Kontem może składać zamówienia online"
                    {...formikProps}
                  />
                )}
              </Group>

              <Divider />
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
});
