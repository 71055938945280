import { Typography } from 'antd';
import { isBefore } from 'date-fns';
import React, { FunctionComponent, ReactNode } from 'react';

import {
  Button,
  Group,
  ButtonSize,
  Spacer,
  Divider,
  DateTime,
  DateTimeRange,
} from 'lib/ui';
import {
  AvailabilityHours,
  WithMarginSmall,
  ColorGreen,
  ColorRed,
} from 'components';

export interface MealGroupsProps {
  children?: ReactNode;
  groups: any[];
  renderMeals: (itemId) => ReactNode;
  onEdit?: Function;
  onDelete?: Function;
  onActionClock?: Function;
  onActionPowerOff?: Function;
  onActionSort?: Function;
  onAddMeal?: Function;
}

const MealGroupsDefaultProps = {
  groups: [],
};

const MealGroups: FunctionComponent<MealGroupsProps> = ({
  groups,
  onEdit,
  onActionClock,
  onActionPowerOff,
  onActionSort,
  onDelete,
  onAddMeal,
  renderMeals,
}) => {
  return (
    <WithMarginSmall>
      {groups.map(item => {
        return (
          <WithMarginSmall key={item.id}>
            <Divider text={`${item.namePl} / ${item.nameEn}`} textStrong />
            <Group align="space-between" verticalAlign="top">
              <Group vertical>
                <Group>
                  <span className="text-pre-line">
                    <Typography.Text>{item.descriptionPl}</Typography.Text>
                  </span>

                  <span className="text-pre-line">
                    <Typography.Text type="secondary">
                      {item.descriptionEn}
                    </Typography.Text>
                  </span>
                </Group>
              </Group>
              <Group align="right">
                <Button
                  label="Edytuj"
                  size={ButtonSize.Small}
                  onClick={() => {
                    if (onEdit) {
                      onEdit(item);
                    }
                  }}
                />
                <Button
                  iconName="clock"
                  size={ButtonSize.Small}
                  onClick={() => {
                    if (onActionClock) {
                      onActionClock(item);
                    }
                  }}
                />
                <Button
                  iconName="power-off"
                  size={ButtonSize.Small}
                  onClick={() => {
                    if (onActionPowerOff) {
                      onActionPowerOff(item);
                    }
                  }}
                />
                <Button
                  iconName="sort"
                  size={ButtonSize.Small}
                  onClick={() => {
                    if (onActionSort) {
                      onActionSort(item);
                    }
                  }}
                />
                <Button
                  iconName="trash-alt"
                  size={ButtonSize.Small}
                  onClick={() => {
                    if (onDelete) {
                      onDelete(item);
                    }
                  }}
                />
              </Group>
            </Group>

            <ColorGreen>
              <AvailabilityHours
                {...item}
                emptyMessage="-"
                prefix="Dostępne w godzinach:"
              />
            </ColorGreen>

            {item.disableFrom && !isBefore(item.disableFrom, new Date()) && (
              <ColorRed>
                Nieaktywna:{' '}
                <DateTimeRange start={item.disableFrom} end={item.disableTo} />
              </ColorRed>
            )}

            {renderMeals(item.id)}

            <Spacer />
            <Button
              label={`Dodaj potrawę do grupy: ${item.namePl}`}
              onClick={() => {
                if (onAddMeal) {
                  onAddMeal(item);
                }
              }}
            />
          </WithMarginSmall>
        );
      })}
    </WithMarginSmall>
  );
};

MealGroups.defaultProps = MealGroupsDefaultProps;

export { MealGroups };
