import React, { FunctionComponent } from 'react';
import { Alert } from 'antd';

import { Group } from '../../ui/components/Group/Group';
import { styled } from '../../ui/styled-components';

const ErrorContainer = styled.div`
  margin: ${p => p.theme.spaceSmallX} ${p => p.theme.spaceSmall} ${p => p.theme.spaceSmallX};
  > strong {
    margin-right: ${p => p.theme.space};
  }
`;
const AlertContainer = styled.div`
  width: 100%;
`;

export interface FormErrorProps {
  errors: any;
}

const FormError: FunctionComponent<FormErrorProps> = ({ errors }) => {
  const { nonFieldErrors, ...fieldErrors } = errors;

  return (
    <Group vertical>
      {nonFieldErrors && <Alert message={nonFieldErrors} type="error" />}

      {fieldErrors && Object.keys(fieldErrors).length > 0 && (
        <AlertContainer>
          <Alert
            message={'Wystąpił błąd'}
            description={
              <>
                {Object.keys(fieldErrors).map(fieldErrorIndex => (
                  <ErrorContainer key={fieldErrorIndex}>
                    <strong>{fieldErrorIndex}</strong>
                    <span>{fieldErrors[fieldErrorIndex]}</span>
                  </ErrorContainer>
                ))}
              </>
            }
            type="error"
          />
        </AlertContainer>
      )}
    </Group>
  );
};

export { FormError };
