/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Address, Communication } from 'components';
import { Button, ButtonSize, Group, SimpleInputDialog, Spacer, styled, ValueOrDash } from 'lib/ui';
import { Balance } from 'modules/Clients';
import { AddressPickerModal } from 'modules/Clients/components/AddressPickerModal';
import { PhonePickerModal } from 'modules/Clients/components/PhonePickerModal';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { path, pathOr } from 'rambdax';
import React, { FunctionComponent, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { useToggle } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

export interface OrderFormClientProps {
  clientId?: number | string;
  addressEditable?: boolean;
  directPickUpEditable?: boolean;
  shortForm?: boolean;
}

const OrderFormClientDefaultProps = {
  addressEditable: true,
  directPickUpEditable: true,
};

const OrderFormClientContainer = styled.div.attrs({
  className: 'OrderFormClientContainer',
})``;

const enhance = connect(
  ({ loading, clientPhones, clientAddress }) => ({
    loading,
    clientPhonesStore: clientPhones,
    clientAddressStore: clientAddress,
  }),
  ({ clients, clientPhones, clientAddress }) => ({
    clients,
    clientPhones,
    clientAddress,
  }),
);

const ClientValue = styled.div.attrs({ className: 'clientValue' })`
  padding: ${p => p.theme.spaceSmall};
  background: ${p => p.theme.colorDirtyWhite};
  border-radius: ${p => p.theme.borderRadius};
`;

const ClientValueLabel = styled.div.attrs({ className: 'ClientValueLabel' })`
  width: 150px;
`;

export const OrderFormClient: FunctionComponent<OrderFormClientProps> = enhance(props => {
  const orderState = OrderFormService.getStateValue();
  const [phoneDialogVisible, togglePhoneDialog] = useToggle(false);
  const [addressDialogVisible, toggleAddressDialog] = useToggle(false);
  const [directPickUp, toggleDirectPickUp] = useToggle(path('withDirectPickUp', orderState));
  const clientPhones = useSelector(state => state.clientPhones.data);

  const [simpleInput, setSimpleInput] = useState('');
  const [editedField, setEditedField] = useState('');
  const [simpleInputDialogVisible, toggleSimpleInputDialog] = useToggle(false);

  // set clients main phone if orders phone is empty
  useEffect(() => {
    // select main phone number or any phone number if there is only one
    const mainPhone = clientPhones.length === 1 ? clientPhones[0] : clientPhones.find(phone => phone.isMain);
    if (!orderState.phone && mainPhone) {
      OrderFormService.setState({
        phone: mainPhone.phone,
      });
    }
  }, [clientPhones, orderState]);

  let clientNameFromUserAccount = `${pathOr('', 'client.user.firstName', orderState)} ${pathOr(
    '',
    'client.user.lastName',
    orderState,
  )}`;
  let clientName = orderState.clientName ? orderState.clientName : clientNameFromUserAccount;
  if (!clientName || clientName === ' ') {
    clientName = 'Nie podano';
  }
  const email = orderState.email ? orderState.email : `${pathOr('', 'client.user.email', orderState)}`;
  const clientId = props.clientId || path('client.id', orderState);

  if (orderState.client) {
    if (!clientNameFromUserAccount || clientNameFromUserAccount.length < 2) {
      clientNameFromUserAccount = orderState.client.name;
    }
  }
  return (
    <OrderFormClientContainer>
      <h2>{clientNameFromUserAccount}</h2>
      {!props.shortForm && (
        <>
          <Group vertical>
            <Group verticalAlign="top">
              <ClientValueLabel>Osoba do kontaktu</ClientValueLabel>
              <Spacer />
              <div>
                <ClientValue>{clientName}</ClientValue>
                <Spacer />
                <Group>
                  <Button
                    label="Zmień"
                    size={ButtonSize.Small}
                    onClick={() => {
                      setEditedField('clientName');
                      setSimpleInput(clientName);
                      toggleSimpleInputDialog(true);
                    }}
                  />
                </Group>
              </div>
            </Group>
          </Group>

          <Spacer />

          <Group vertical>
            <Group verticalAlign="top">
              <ClientValueLabel>Adres e-mail</ClientValueLabel>
              <Spacer />
              <div>
                <ClientValue>
                  <ValueOrDash value={email} />
                </ClientValue>
                <Spacer />
                <Group>
                  <Button
                    label="Zmień"
                    size={ButtonSize.Small}
                    onClick={() => {
                      setEditedField('email');
                      setSimpleInput(email);
                      toggleSimpleInputDialog(true);
                    }}
                  />
                </Group>
              </div>
            </Group>
          </Group>

          <Spacer />

          <Group vertical>
            <Group verticalAlign="top">
              <ClientValueLabel>Telefon *</ClientValueLabel>
              <Spacer />
              <div>
                <ClientValue>
                  <ValueOrDash value={orderState.phone} />
                </ClientValue>

                {!orderState.phone && <Communication text={'Wybierz telefon.'} color="red" />}

                <Spacer />
                <Group>
                  <Button
                    label="Telefony klienta"
                    size={ButtonSize.Small}
                    onClick={() => {
                      togglePhoneDialog(true);
                    }}
                  />
                </Group>
              </div>
            </Group>
          </Group>
        </>
      )}

      <Spacer />

      <Group vertical>
        <Group verticalAlign="top">
          <ClientValueLabel>Adres dostawy</ClientValueLabel>
          <Spacer />
          <div>
            <ClientValue>
              {directPickUp && (
                <p>
                  <strong>Odbiór osobisty</strong>
                  <br />w wybranej restauracji
                </p>
              )}
              {!directPickUp && (
                <p>
                  <strong>Dostawa na adres</strong>
                </p>
              )}

              <Address
                street={path('address.street', orderState)}
                houseNumber={path('address.houseNumber', orderState)}
                postalCode={path('address.postalCode', orderState)}
                city={path('address.city', orderState)}
                details={path('address.details', orderState)}
              />
            </ClientValue>

            {!directPickUp && (!path('address.lat', orderState) || !path('address.lon', orderState)) && (
              <Communication text={'Wybierz adres z lokalizacją.'} color="red" />
            )}

            <Spacer />
            {props.addressEditable && (
              <Group>
                <Button
                  label="Adresy klienta"
                  size={ButtonSize.Small}
                  onClick={() => {
                    toggleAddressDialog(true);
                  }}
                />
                {props.directPickUpEditable && !directPickUp && (
                  <Button
                    label="Odbiór osobisty"
                    size={ButtonSize.Small}
                    onClick={() => {
                      OrderFormService.setState({
                        withDirectPickUp: true,
                      });
                      toggleDirectPickUp();
                    }}
                  />
                )}
                {props.directPickUpEditable && directPickUp && (
                  <Button
                    label="Dostawa na adres"
                    size={ButtonSize.Small}
                    onClick={() => {
                      OrderFormService.setState({
                        withDirectPickUp: false,
                      });
                      toggleDirectPickUp();
                    }}
                  />
                )}
              </Group>
            )}
          </div>
        </Group>
      </Group>

      <Spacer size="small" />

      <Spacer size="small" />

      <Group>
        <ClientValueLabel>Nadpłata/Niedopłata</ClientValueLabel>
        <ClientValue>
          <span>
            <strong>
              <Balance balance={pathOr(0, 'client.balance', orderState)} />
            </strong>
          </span>
        </ClientValue>
      </Group>
      <Spacer size="small" />
      <Group>
        <ClientValueLabel>Rabat</ClientValueLabel>
        <ClientValue>
          <strong>{pathOr(0, 'client.discount', orderState)} %</strong>
        </ClientValue>
      </Group>

      {phoneDialogVisible && (
        <PhonePickerModal
          clientId={clientId}
          description={<Communication text={'Zmieniasz dane na stałe.'} color="red" />}
          onClose={() => {
            togglePhoneDialog(false);
          }}
          onSelect={phone => {
            OrderFormService.setState({
              phone,
            });
            togglePhoneDialog(false);
          }}
        />
      )}

      {addressDialogVisible && (
        <AddressPickerModal
          clientId={clientId}
          description={<Communication text={'Zmieniasz dane na stałe.'} color="red" />}
          onClose={() => {
            toggleAddressDialog(false);
          }}
          onSelect={address => {
            OrderFormService.setState({
              address,
              withDirectPickUp: false,
            });
            if (directPickUp) {
              toggleDirectPickUp();
            }
            toggleAddressDialog(false);
          }}
          onAddressGeoSave={address => {
            if (orderState.address && address.id === orderState.address.id) {
              OrderFormService.setState({
                address,
                withDirectPickUp: false,
              });
            }
          }}
        />
      )}

      {simpleInputDialogVisible && (
        <SimpleInputDialog
          open
          inputValue={simpleInput}
          title={editedField === 'clientName' ? `Edycja osoby kontaktowej` : `Edycja adresu email`}
          inputLabel={editedField === 'clientName' ? 'Osoba do kontaktu' : `Adresu email`}
          description={<Communication text={'Zmieniasz dane tylko na zamówieniu.'} color="blue" />}
          okText="Zapisz"
          onSave={data => {
            OrderFormService.setState({
              [editedField]: data.values.input,
            });
            toggleSimpleInputDialog(false);
          }}
          onCancel={() => {
            toggleSimpleInputDialog(false);
          }}
        />
      )}
    </OrderFormClientContainer>
  );
});

OrderFormClient.defaultProps = OrderFormClientDefaultProps;
