import { Empty } from 'antd';
import { PageLayout } from 'components';
import { useCurrentOrderForEdit } from 'hooks/useCurrentOrderForEdit';
import { Group, Loading, Spacer } from 'lib/ui';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormBasketMeal, OrderFormState } from 'modules/Orders/OrderFormState';

import { MealGroup } from 'modules/Restaurant';
import { OrderEditSteps } from 'pages/OrderEdit/OrderEditSteps';
import { MealInputSearch } from 'pages/OrderFormPage/components/MealInputSearch/MealInputSearch';
import { OrderFormBasket } from 'pages/OrderFormPage/components/OrderFormBasket';
import { OrderFormMealGroupPicker } from 'pages/OrderFormPage/OrderFormPageStep4/OrderFormMealGroupPicker';
import { OrderFormMealPicker } from 'pages/OrderFormPage/OrderFormPageStep4/OrderFormMealPicker';
import { OrderFormMealWithExtrasPicker } from 'pages/OrderFormPage/OrderFormPageStep4/OrderFormMealWithExtrasPicker';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useObservable, useToggle } from 'react-use';

export const OrderEditPage2 = props => {
  const dispatch = useDispatch();
  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());
  const [mealQuery, setMealQuery] = useState('');
  const [restaurantsExtras, setRestaurantsExtras] = useState([]);

  const [selectedMealGroup, setSelectedMealGroup] = useState<MealGroup | null>(null);
  const [mealPickerVisible, toggleMealPicker] = useToggle(false);
  const [selectedMeal, setSelectedMeal] = useState<OrderFormBasketMeal | null>(null);
  const [selectedMealExtrasIndex, setSelectedMealExtrasIndex] = useState<number | null>(null);

  const { orderId } = props.match.params;

  useEffect(() => {
    if (orderState && orderState.restaurant) {
      dispatch({
        type: 'restaurantsExtras/loadCollection',
        payload: {
          restaurantId: orderState.restaurant.id,
        },
      }).then(data => setRestaurantsExtras(data));
    }
  }, [orderState]);

  const { order, isLoading } = useCurrentOrderForEdit(orderId);

  const hasData = orderState && orderState.restaurant && order;

  return (
    <PageLayout title="Edycja zamówienia" header={<OrderEditSteps activePage={2} />}>
      {isLoading && <Loading />}
      {!isLoading && !hasData && <Empty description="Brak danych." />}
      {!isLoading && orderState && orderState.restaurant && order && (
        <>
          <Group align="space-between" verticalAlign="top">
            <Group vertical width="60%">
              <OrderFormMealGroupPicker
                restaurantId={orderState.restaurant.id}
                onSelect={mealGroup => {
                  setSelectedMealGroup(mealGroup);
                }}
              />

              <OrderFormMealPicker
                basketSets={orderState.basket.sets}
                restaurantId={orderState.restaurant.id}
                mealGroup={selectedMealGroup}
                query={mealQuery}
                onSelect={meal => {
                  setSelectedMeal(meal);
                  toggleMealPicker();
                }}
              />
            </Group>
            <Group vertical width="35%">
              <MealInputSearch
                onQueryChange={setMealQuery}
                onSearch={setMealQuery}
                onReset={() => {
                  setMealQuery('');
                }}
                hideSearchButton
              />
              <Spacer />
              <OrderFormBasket
                editable
                key={'orderBasket'}
                onMealClick={basketMeal => {
                  setSelectedMeal(basketMeal.meal);
                  setSelectedMealExtrasIndex(null);
                  toggleMealPicker();
                }}
                onMealExtrasClick={(basketMeal, extrasIndex) => {
                  setSelectedMeal(basketMeal.meal);
                  setSelectedMealExtrasIndex(extrasIndex);
                  toggleMealPicker();
                }}
                onSubmit={() => {
                  props.history.push(`/orders/edit/${orderId}/3`);
                }}
              />

              <Spacer />
            </Group>
          </Group>

          <Spacer />
        </>
      )}

      {mealPickerVisible && selectedMeal && (
        <OrderFormMealWithExtrasPicker
          extras={[]}
          meal={selectedMeal}
          extrasIndex={selectedMealExtrasIndex}
          restaurantMealExtras={restaurantsExtras}
          onClose={toggleMealPicker}
        />
      )}
    </PageLayout>
  );
};
