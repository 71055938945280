import { Loading } from 'lib/ui';
import { OrdersPageNavigation } from 'modules/Orders/components/OrdersPageNavigation/OrdersPageNavigation';
import { OrdersWithInvoiceTable } from 'pages/OrdersWithInvoicePage/components/OrdersWithInvoiceTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PageLayout } from 'components';

export interface OrdersWithInvoicePageProps {}

export const OrdersWithInvoicePage: React.FC<OrdersWithInvoicePageProps> = props => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const orders = useSelector(state => state.currentOrders.data);

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch({
      type: 'currentOrders/loadCollection',
      payload: {
        params: {
          limit: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          with_invoice: true,
        },
      },
    }).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    dispatch({
      type: 'currentOrders/reset',
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <PageLayout title="Bieżące zamówienia - faktury">
      <OrdersPageNavigation withRefreshButton onRefresh={loadData} refreshing={isLoading} />
      {!orders.length && isLoading && <Loading text="Ładowanie danych" />}

      <OrdersWithInvoiceTable orders={orders} onRefresh={loadData} />
    </PageLayout>
  );
};
