/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Alert } from 'antd';
import { DriverMapData } from 'pages/MapPage/MapDataModel';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import { WARSAW_CENTER_LAT_LNG } from 'components/modals/MapLabModal/MapLabService';
import { DialogService } from 'lib/services/DialogService';
import { Button, ButtonVariant, Group, Modal, Spacer, styled } from 'lib/ui';
import { SimplePickerTitle } from 'lib/ui/dialogs/SimplePickerDialog.styled';
import { displayTasksPointsAndLines } from 'pages/MapPage/mapHelpers';
import { HttpApi } from 'services/HttpApi';
import { DriverTasksList } from './DriverTaskItem';

export interface DriverTaskRestaurant {
  id: number;
  name: string;
  displayedName: string;
  isNew: boolean;
  descriptionPl: string;
  descriptionEn: string;
  packagePrice: number;
  address: {
    lon: number;
    lat: number;
    city: string;
    street: string;
    postalCode: string;
    houseNumber: string;
  };
  contactPhone: string;
  contactName: string;
}

export interface DriverTaskCustomer {
  id: number;
  name: string;
  address: {
    id: number;
    lat: string;
    lon: string;
    entranceLon?: string;
    entranceLat?: string;
    city: string;
    email?: string;
    phone: string;
    street: string;
    details: string;
    postalCode: string;
    houseNumber: string;
  };
}

export interface DriverTaskOrder {
  id: string;
  // more data.. add if you need ;)
}

export interface DriverTask {
  id: number;
  taskType: 'restaurant' | 'customer';
  lon: string;
  lat: string;
  position: number;
  isFinished: boolean;
  finishedDate: null | string;
  restaurant: DriverTaskRestaurant;
  customer: DriverTaskCustomer;
  currentOrder: DriverTaskOrder;
  order: DriverTaskOrder;
}

export interface DriverTaskWithOrderLetters extends DriverTask {
  orderLetter: string;
}

export interface DriverTasksDialogProps {
  tasks: DriverTask[];
  closable: boolean;
  driverId: number;
  driverMapData?: DriverMapData;
  onClose: (success?: boolean) => void;
}

const MapContainer = styled.div`
  height: ${window.innerHeight - 400}px;
`;

export const DriverTasksDialog: FunctionComponent<DriverTasksDialogProps> = props => {
  const [data, setData] = useState<DriverTaskWithOrderLetters[]>([]);
  const [modified, setModified] = useState(false);
  //  loading state for save button
  const [loading, setLoading] = useState(false);
  // the map itself
  const mapRef = useRef(null);
  const [mapInitialized, setMapInitialized] = useState<Boolean>(false);

  if (!props.driverMapData) {
    throw new Error('[DriverTasksDialog] No driverMapData found');
  }

  // set tasks on start
  useEffect(() => {
    const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    const orderIds: string[] = [];
    setData(
      props.tasks.map(task => {
        const currentOrderId = task.currentOrder.id;
        if (!orderIds.includes(currentOrderId)) {
          orderIds.push(currentOrderId);
        }
        return {
          ...task,
          orderLetter: letters[orderIds.indexOf(currentOrderId)],
        };
      }),
    );
  }, []);

  // create map
  const mapInitiate = useCallback(node => {
    if (node !== null && !mapRef.current) {
      mapRef.current = window.L.map('maplab-dialog').setView(WARSAW_CENTER_LAT_LNG, 13);
      window.L.tileLayer.provider('MaplabPL').addTo(mapRef.current);
      setMapInitialized(true);
    }
  }, []);

  //  display points
  useEffect(() => {
    if (mapRef.current && data && props.driverMapData) {
      displayTasksPointsAndLines(props.driverMapData, data, mapRef.current);
    }
  }, [data, mapInitialized, props.driverMapData]);

  const moveTask = useCallback(
    ({ oldIndex, newIndex }) => {
      const dragTask = data[oldIndex];
      const newData = [...data];
      newData.splice(oldIndex, 1);
      newData.splice(newIndex, 0, dragTask);
      setData([...newData]);
      setModified(true);
    },
    [data],
  );

  return (
    <Modal
      visible
      width="90%"
      header={
        <SimplePickerTitle>
          <span>Marszruta</span>
        </SimplePickerTitle>
      }
      onClose={() => {
        DialogService.closeDialog();
      }}
      closable={props.closable}
      position="top"
      footer={
        <Group align="right">
          {props.closable && (
            <Button
              disabled={loading}
              loading={loading}
              onClick={() => {
                props.onClose(false);
                DialogService.closeDialog();
              }}
              label={'Anuluj'}
            />
          )}
          <Button
            disabled={loading}
            loading={loading}
            variant={ButtonVariant.Primary}
            onClick={() => {
              setLoading(true);
              HttpApi.patch(`/drivers/${props.driverId}/tasks/set-order/`, {
                data: {
                  tasks: data.map(task => task.id),
                },
              })
                .then(() => {
                  props.onClose(true);
                  DialogService.closeDialog();
                })
                .catch(errorResponse => {
                  console.warn('error', errorResponse);
                });
            }}
            label={'Zapisz'}
          />
        </Group>
      }
    >
      <div data-bp="grid">
        <div data-bp="9">
          <MapContainer ref={mapInitiate} id="maplab-dialog" />
        </div>
        <div data-bp="3">
          <DriverTasksList items={data} onSortEnd={moveTask} />

          <Spacer />
          {modified && <Alert message="Zapisz zmiany po ustawieniu optymalnej marszruty dla kierowcy" type="warning" />}
        </div>
      </div>
    </Modal>
  );
};
