import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Loading } from 'lib/ui';
import { OrdersPageNavigation } from 'modules/Orders/components/OrdersPageNavigation/OrdersPageNavigation';
import { PageLayout } from 'components';
import { useHistory } from 'react-router';
import { useToggle } from 'react-use';
import { OrderDetailsDialog } from 'modules/Orders/components/OrderDetailsDialog/OrderDetailsDialog';
import { OrdersWithDirectPickupTable } from './components/OrdersWithDirectPickupTable';

export interface OrdersWithDirectPickupProps {}

export const OrdersWithDirectPickupPage: React.FC<OrdersWithDirectPickupProps> = props => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [order, setOrder] = useState<any>({});
  const [orderDetailsOpened, toggleOrderDetails] = useToggle(false);

  const orders = useSelector(state => state.currentOrders.data);
  const history = useHistory();

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch({
      type: 'currentOrders/loadCollection',
      payload: {
        params: {
          limit: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          with_direct_pick_up: true,
        },
      },
    }).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: 'currentOrders/reset',
    });
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <PageLayout title="Bieżące zamówienia - z odbiorem osobistym">
        <OrdersPageNavigation
          withRefreshButton
          onAdd={() => history.push('/orders/new/1')}
          onRefresh={loadData}
          refreshing={isLoading}
        />
        {!orders.length && isLoading && <Loading text="Ładowanie danych" />}

        <OrdersWithDirectPickupTable
          onOrderDetails={order => {
            setOrder(order);
            toggleOrderDetails();
          }}
          orders={orders}
          onRefresh={loadData}
        />
      </PageLayout>

      {orderDetailsOpened && (
        <OrderDetailsDialog
          isEditable
          withDirectPickUp
          orderId={order.id}
          visible={orderDetailsOpened}
          onClose={hasChanged => {
            toggleOrderDetails();
            if (hasChanged) {
              loadData();
            }
          }}
        />
      )}
    </>
  );
};
