import { format, isWeekend } from 'date-fns';
import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';
import { Driver } from 'modules/Drivers';

export const ScheduleModel = SimpleRestModel({
  collectionName: 'schedules',
  apiPath: 'schedule',
  HttpApi,
});

export interface Schedule {
  dateEnd: string;
  dateStart: string;
  driver: Driver | null;
  employee: object | null;
  id: number;
  isHoliday: boolean;
  isPaid: boolean;
  note: string | null;
}

export const isOnHoliday: (schedule: Schedule) => boolean = schedule => {
  return schedule.isHoliday;
};
export const getHourStart: (schedule: Schedule) => number = schedule => +format(schedule.dateStart, 'HH');
export const getHourEnd: (schedule: Schedule) => number = schedule => +format(schedule.dateEnd, 'HH');
export const startsAtMorning: (schedule: Schedule) => boolean = schedule => {
  if (!schedule || isOnHoliday(schedule)) {
    return false;
  }
  const hour = getHourStart(schedule);
  const isWeekendDay = isWeekend(schedule.dateStart);
  return isWeekendDay ? hour === 11 : hour === 10;
};
export const startsAt17: (schedule: Schedule) => boolean = schedule => {
  if (!schedule || isOnHoliday(schedule)) {
    return false;
  }
  return getHourStart(schedule) === 17;
};
export const endsAt17: (schedule: Schedule) => boolean = schedule => {
  if (!schedule || isOnHoliday(schedule)) {
    return false;
  }
  return getHourEnd(schedule) === 17;
};
export const endsAt22: (schedule: Schedule) => boolean = schedule => {
  if (!schedule || isOnHoliday(schedule)) {
    return false;
  }
  return getHourEnd(schedule) === 22;
};
export const isAllDayShift: (schedule: Schedule) => boolean = schedule => {
  if (!schedule || isOnHoliday(schedule)) {
    return false;
  }
  return startsAtMorning(schedule) && endsAt22(schedule);
};
export const isDefaultMorningShift: (schedule: Schedule) => boolean = schedule => {
  if (!schedule || isOnHoliday(schedule)) {
    return false;
  }
  return startsAtMorning(schedule) && endsAt17(schedule);
};
export const isDefaultEveningShift: (schedule: Schedule) => boolean = schedule => {
  if (!schedule || isOnHoliday(schedule)) {
    return false;
  }
  return startsAt17(schedule) && endsAt22(schedule);
};
export const isMorningShift: (schedule: Schedule) => boolean = schedule => {
  if (!schedule || isOnHoliday(schedule)) {
    return false;
  }
  return getHourStart(schedule) < 17;
};
export const isEveningShift: (schedule: Schedule) => boolean = schedule => {
  if (!schedule || isOnHoliday(schedule)) {
    return false;
  }
  const hour = getHourEnd(schedule);
  const minutes = +format(schedule.dateEnd, 'mm');
  return getHourEnd(schedule) > 17 || (hour === 17 && minutes > 0);
};
export const isCustomSchedule: (schedule: Schedule) => boolean = schedule => {
  if (!schedule || isOnHoliday(schedule)) {
    return false;
  }
  return (!startsAtMorning(schedule) && !startsAt17(schedule)) || (!endsAt17(schedule) && !endsAt22(schedule));
};
