import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { path } from 'rambdax';

import { store } from 'store';
import { RolesEnum } from 'modules/Auth/rolesEnum';
import { Auth403 } from 'modules/Auth/components/Auth403';

/**

 We are using render prop instead of component in Route to prevent from
 remounting Component on location change
 - for example when navigating from /somePage/A/beer to /somePage/A/whisky
 we don't want to remount SomePage, only subPages

 But... we pass key param to Component to force remounting on location change
 from /somePage/A to /somePage/B

 */

export interface AuthRouteProps {
  onlyForRoles?: RolesEnum[];
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const AuthRoute: React.FC<AuthRouteProps & RouteProps> = ({ component, onlyForRoles, ...rest }) => {
  const Component: any = component;
  return (
    <Route
      {...rest}
      render={props => {
        const paramId = path('match.params.id', props);
        const auth = store.getState().AuthModel;

        if (!auth.isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        }

        const hasRole = store.select.AuthModel.hasRole(onlyForRoles)(store.getState());
        const hasAccess = !onlyForRoles || hasRole;
        if (!hasAccess) {
          return <Auth403 path={props.location.pathname} />;
        }

        return <Component {...props} key={paramId} />;
      }}
    />
  );
};
