import { styled } from 'lib/ui';

export const RestaurantItem = styled.div.attrs({ className: 'RestaurantItem' })<{ disabled?: boolean }>`
  padding: ${p => p.theme.spaceSmall};
  opacity: ${p => (p.disabled ? '0.5' : '1')};
  background-color: ${p => p.theme.colorDirtyWhite};
  margin-bottom: ${p => p.theme.spaceSmall};
  border-radius: 4px;
  &:hover {
    background-color: ${p => p.theme.colorLightGrey};
    cursor: pointer;
  }
`;
