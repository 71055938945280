import { message, Switch } from 'antd';
import React, { FunctionComponent } from 'react';
import connect from 'react-redux/es/connect/connect';

import { Button, ButtonVariant, Drawer, DrawerProps, Group, modalConfirm, Spacer } from 'lib/ui';
import { FormFieldLabel } from 'lib/forms';
import { weekdays } from 'lib/ui/utils';

const enhance = connect(
  ({ drivers, loading }) => ({
    driversStore: drivers,
    loading,
  }),
  ({ drivers }) => ({
    drivers,
  }),
);

export interface EmployeeDrawerProps extends DrawerProps {
  item: any;
  onEditClick: Function;
}

const DriverDrawer: FunctionComponent<EmployeeDrawerProps> = enhance(({ item, ...props }) => {
  const closeDrawer = () => {
    props.onClose();
  };

  return (
    <Drawer
      displayCloseButton
      closeOnEsc
      onClose={closeDrawer}
      visible={props.visible}
      title={`${'Szczegóły kierowcy'}`}
      destroyOnClose
    >
      <Group vertical>
        <Group>
          <span>Imię i nazwisko:</span>
          <span>
            <strong>
              {item.user.firstName} {item.user.lastName}
            </strong>
          </span>
        </Group>
        <Group>
          <span>Login:</span>
          <span>
            <strong>{item.user.email}</strong>
          </span>
        </Group>
        <Group>
          <span>Telefon:</span>
          <span>
            <strong>
              {item.phone1} {item.phone2} {item.phone3}{' '}
            </strong>
          </span>
        </Group>
        <Group verticalAlign="top">
          <span className="u-flex-no-shrink">Uwagi:</span>
          <span className="text-pre-line">
            <strong>{item.notes}</strong>
          </span>
        </Group>

        <Spacer />

        <Group verticalAlign="top">
          <Group vertical>
            <FormFieldLabel>
              <Switch checked={item.isPaidRbh} disabled size="small" />
              <span className="ml-2">Godzina</span>
            </FormFieldLabel>
            {item.isPaidRbh && <strong>{(item.hourRate / 10).toFixed(2)}</strong>}
          </Group>
          <Group vertical>
            <FormFieldLabel>
              <Switch checked={item.isPaidDistance} disabled size="small" />
              <span className="ml-2">Kilometr</span>
            </FormFieldLabel>
            {item.isPaidDistance && <strong>{(item.distanceRate / 10).toFixed(2)}</strong>}
          </Group>
          <Group vertical>
            <FormFieldLabel>
              <Switch checked={item.isPaidDay} disabled size="small" />
              <span className="ml-2">Dniówka</span>
            </FormFieldLabel>
          </Group>
          <Group vertical>
            <FormFieldLabel>
              <Switch checked={item.isPaidDelivery} disabled size="small" />
              <span className="ml-2">Dostawa</span>
            </FormFieldLabel>
            {item.isPaidDelivery && <strong>{(item.deliveryRate / 10).toFixed(2)}</strong>}
          </Group>
          <Group vertical>
            <FormFieldLabel>
              <Switch checked={item.isPaidDeliveryAll} disabled size="small" />
              <span className="ml-2">Dostawa całość</span>
            </FormFieldLabel>
          </Group>
        </Group>

        {item.isPaidDay && (
          <>
            <Spacer />
            <Group align="space-between">
              {weekdays.map(day => (
                <Group vertical width="90px" key={day.shortnameEn}>
                  <FormFieldLabel>{day.shortnamePl}</FormFieldLabel>
                  <strong>{(item[`dayRate${day.shortnameEn}`] / 100).toFixed(2)}</strong>
                </Group>
              ))}
            </Group>
          </>
        )}

        <Spacer />

        <Group align="space-between">
          <Button variant={ButtonVariant.Primary} label="Zmień" onClick={props.onEditClick} />
          <Button
            label="Usuń kierowcę"
            iconName="trash-alt"
            onClick={() => {
              modalConfirm({
                title: 'Czy na pewno usunąć kierowcę?',
                content: ' ',
                okText: 'Usuń',
                yesCallback: () => {
                  const hide = message.loading('Usuwam kierowcę...', 0);
                  props.employees
                    .deleteItem({
                      id: item.id,
                    })
                    .then(() => {
                      message.success('Kierowca usunięty');
                      closeDrawer();
                    })
                    .catch(() => {
                      message.error('Wystąpił nieoczekiwany bład');
                    })
                    .finally(hide);
                },
              });
            }}
          />
        </Group>
      </Group>
    </Drawer>
  );
});

export { DriverDrawer };
