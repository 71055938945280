import React, { FunctionComponent, useEffect, useState } from 'react';
import { isWeekend, setHours, setMinutes, parse } from 'date-fns';
import { TimePicker } from 'antd';
import moment, { Moment } from 'moment';
import useToggle from 'react-use/lib/useToggle';
import { path } from 'rambdax';

import { FormFieldLabel, Input } from 'lib/forms';
import { Button, ButtonVariant, formattedDate, Group, Icon, Modal, ModalProps, Spacer } from 'lib/ui';
import { TimePickerDefaultsProps } from 'lib/ui/utils';
import { UserSchedule } from 'pages/SchedulePage/SchedulePage';

export interface ScheduleDialogProps extends ModalProps {
  item?: UserSchedule;
  onSave: (item: any, setLoading: (boolean) => void) => void;
  onDelete: (id: string | number, setLoading: (boolean) => void) => void;
  onClose: () => void;
}

const ensureProperHourValue = (hour: string | number) => {
  const value = +hour;
  if (value < 0) {
    return 0;
  }
  if (value > 23) {
    return 23;
  }
  return value;
};

const ScheduleDialog: FunctionComponent<ScheduleDialogProps> = props => {
  // console.log('ScheduleDialog props', props);
  const [isHoliday, toggleHoliday] = useToggle(false);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState<Moment | undefined>();
  const [end, setEnd] = useState<Moment | undefined>();
  const [selectedDate, setSelectedDate] = useState<any>();

  useEffect(() => {
    const selectedDateString = path<string>('item.selectedDate', props);
    setSelectedDate(selectedDateString);
    const dateStart = path<string>('item.schedule.dateStart', props);
    const dateEnd = path<string>('item.schedule.dateEnd', props);
    if (dateStart) {
      setStart(moment(dateStart));
    } else {
      setStart(
        moment(selectedDateString)
          .minute(0)
          .hour(10),
      );
    }
    if (dateEnd) {
      setEnd(moment(dateEnd));
    } else {
      setEnd(
        moment(selectedDateString)
          .minute(0)
          .hour(21),
      );
    }
  }, [props.item]);

  if (!props.item) {
    return null;
  }

  const onSave = (startDate: Date | null, endDate: Date | null, isHolidayDate: boolean) => {
    setLoading(true);
    props.onSave(
      {
        ...props.item,
        start: !isHolidayDate && startDate ? startDate : selectedDate,
        end: !isHolidayDate && endDate ? endDate : selectedDate,
        isHoliday: isHolidayDate,
      },
      setLoading,
    );
  };

  // console.log('start', start);

  return (
    <Modal
      {...props}
      visible
      header={<span>Szczegóły grafiku na wybrany dzień</span>}
      footer={
        <Group align="right">
          <Button disabled={loading} onClick={props.onClose} label={'Anuluj'} />
          <Button
            loading={loading}
            variant={ButtonVariant.Primary}
            onClick={() => {
              if (props.item && props.item.schedule && !start && !end) {
                setLoading(true);
                props.onDelete(props.item.schedule.id, setLoading);
              } else {
                onSave(start ? start.toDate() : null, end ? end.toDate() : null, false);
              }
            }}
            label={'Zapisz'}
          />
        </Group>
      }
    >
      <Group>
        <Icon name="user" />
        <span>{props.item.userName}</span>
      </Group>
      <Spacer />
      <Group>
        <Icon name="calendar" />
        <span>{formattedDate(selectedDate)}</span>
      </Group>
      <Spacer />
      <Group>
        <Icon name="clock" />
        <Button
          label="C"
          onClick={() => {
            const isWeekendDate = isWeekend(selectedDate);
            const start = setMinutes(setHours(selectedDate, isWeekendDate ? 11 : 10), 0);
            const end = setMinutes(setHours(selectedDate, 22), 0);
            onSave(start, end, false);
          }}
          variant={ButtonVariant.Primary}
        />
        <Button
          label="R"
          onClick={() => {
            const isWeekendDate = isWeekend(selectedDate);
            const start = setMinutes(setHours(selectedDate, isWeekendDate ? 11 : 10), 0);
            const end = setMinutes(setHours(selectedDate, 17), 0);
            onSave(start, end, false);
          }}
          variant={ButtonVariant.Primary}
        />
        <Button
          label="W"
          onClick={() => {
            const start = setMinutes(setHours(selectedDate, 17), 0);
            const end = setMinutes(setHours(selectedDate, 22), 0);
            onSave(start, end, false);
          }}
          variant={ButtonVariant.Primary}
        />
        <Button
          label="U"
          onClick={() => {
            onSave(parse(selectedDate), parse(selectedDate), true);
          }}
        />
        <Button
          iconName={'trash'}
          onClick={() => {
            setStart(undefined);
            setEnd(undefined);
            toggleHoliday(false);
          }}
        />
      </Group>

      <Spacer />
      {!isHoliday && (
        <Group>
          <Group vertical width="120px">
            <FormFieldLabel>Od</FormFieldLabel>
            <Group>
              <Input
                name="input"
                type="text"
                onChange={ev => {
                  const hour = ensureProperHourValue(+ev.currentTarget.value);
                  setStart(moment(start).hour(hour));
                }}
                value={start ? start.format('HH') : ''}
                disabled={loading}
                style={{
                  width: '60px',
                }}
              />
              <span>:</span>
              <TimePicker
                {...TimePickerDefaultsProps}
                value={start}
                onChange={value => setStart(value)}
                disabled={loading}
                style={{
                  width: '60px',
                }}
                format={'mm'}
                minuteStep={10}
              />
            </Group>
          </Group>
          <Group vertical width="130px">
            <FormFieldLabel>Do</FormFieldLabel>
            <Group>
              <Input
                name="input"
                type="text"
                onChange={ev => {
                  const hour = ensureProperHourValue(+ev.currentTarget.value);
                  setEnd(moment(end).hour(hour));
                }}
                value={end ? end.format('HH') : ''}
                disabled={loading}
                style={{
                  width: '60px',
                }}
              />
              <span>:</span>
              <TimePicker
                {...TimePickerDefaultsProps}
                value={end}
                onChange={value => setEnd(value)}
                disabled={loading}
                style={{
                  width: '60px',
                }}
                format={'mm'}
                minuteStep={10}
              />
            </Group>
          </Group>
        </Group>
      )}
      {isHoliday && <span>Urlop</span>}
    </Modal>
  );
};

export { ScheduleDialog };
