import * as Yup from 'yup';

import { stringRequired_2_100, stringRequired } from 'lib/forms/YupValidation';

export default Yup.object().shape({
  name: stringRequired_2_100(),
  displayedName: stringRequired(),
  //  custom validation example
  // openHours: Yup.mixed().test('openHours', 'Błędna wartość', value => {
  //   console.log('openHours test, value', value);
  //   console.log('value.monday[0].hour()', value.monday[0].hour());
  //   if (value.monday[0].hour() < 12) {
  //     return false;
  //   }
  //   return true;
  // }),
  contactEmail: Yup.string().when('prefferedOrdersContact', {
    is: 10,
    then: Yup.string().required('Podaj adres email'),
    otherwise: Yup.string(),
  }),
  contactFax: Yup.string().when('prefferedOrdersContact', {
    is: 0,
    then: Yup.string().required('Podaj fax'),
    otherwise: Yup.string(),
  }),
});
