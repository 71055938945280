import { styled } from '../../../styled-components';

const AgGridTableContainer = styled.div.attrs({
  className: 'ag-theme-balham',
})`
  width: 100%;
  margin: ${p => p.theme.spaceSmall} 0 ${p => p.theme.spaceLarge};
  overflow: hidden;
  flex-grow: 1;
`;

export { AgGridTableContainer };
