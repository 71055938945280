import { message } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { BalanceWithDescriptionModal } from 'components/modals/BalanceWithDescriptionModal';
import { DialogService } from 'lib/services/DialogService';
import { Button, ButtonSize, ButtonVariant, Group, Price } from 'lib/ui';
import { DriversReckoningDayOrderDetails } from 'modules/Drivers/DriversReckoning';

export interface DriverOrderBonusProps {
  record: DriversReckoningDayOrderDetails;
  onUpdate: () => void;
  isPaid: boolean;
}

export const DriverOrderBonus: React.FC<DriverOrderBonusProps> = props => {
  const dispatch = useDispatch();

  const hasBonus = Boolean(props.record.driverBonusAmount && props.record.driverBonusAmount !== 0);

  return (
    <>
      <Group>
        {hasBonus && <Price amount={props.record.driverBonusAmount} />}
        {!props.isPaid && (
          <Button
            variant={ButtonVariant.Default}
            size={ButtonSize.Small}
            onClick={() => {
              DialogService.open(BalanceWithDescriptionModal, {
                amount: props.record.driverBonusAmount,
                description: props.record.driverBonusDescription,
                onClose: values => {
                  if (!values) {
                    return;
                  }

                  //  update order with bonus value and description
                  dispatch({
                    type: 'orders/updateItem',
                    payload: {
                      id: props.record.id,
                      data: {
                        driverBonusAmount: values.bonus,
                        driverBonusDescription: values.description,
                      },
                    },
                  })
                    .then(data => {
                      const messageText = data && data.detail;
                      message.success(messageText || 'Bonus / kara została zaktualizowana');
                      props.onUpdate();
                    })
                    .catch(err => {
                      const messageText = err.data && err.data.detail;
                      message.error(messageText || 'Wystąpił nieoczekiwany błąd');
                    });
                },
              });
            }}
          >
            {hasBonus ? 'zmień' : 'ustaw'}
          </Button>
        )}
      </Group>
    </>
  );
};
