import { Switch, Typography } from 'antd';

import { Button, ButtonSize, Group, Price, Spacer, styled, ValueOrDash } from 'lib/ui';
import { replaceValueAtIndex } from 'lib/utils';
import { withoutValueAtIndex } from 'lib/utils/arrays/withoutValueAtIndex';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormBasketSetMeal, OrderFormBasketSetModel } from 'modules/Orders/OrderFormState';
import React, { FunctionComponent } from 'react';
import { ColorRed } from 'components';
import { MealIndexBadge } from 'components/MealIndexBadge/MealIndexBadge';

export interface OrderBasketSetProps {
  basketSet: OrderFormBasketSetModel;
  selected: boolean;
  editable: boolean;
  onToggleSelect: (selected: boolean) => void;
  onNameChangeClick: () => void;
  onMealClick: (meal: OrderFormBasketSetMeal) => void;
  onMealExtrasClick: (meal: OrderFormBasketSetMeal, extrasIndex: number) => void;
}

const OrderBasketSetContainer = styled.div.attrs({
  className: 'OrderBasketSetContainer',
})`
  display: flex;
  flex-direction: column;
  margin-top: ${p => p.theme.space};
`;

const BasketMealName = styled.h4.attrs({ className: '' })`
  cursor: pointer;
  color: ${p => p.theme.colorPrimaryLight};
  &:hover {
    text-decoration: underline;
  }
`;

export const OrderFormBasketSet: FunctionComponent<OrderBasketSetProps> = props => {
  return (
    <OrderBasketSetContainer>
      <Group align="space-between" verticalAlign="top">
        <Group verticalAlign="top">
          {props.editable && <Switch checked={props.selected} onChange={selected => props.onToggleSelect(selected)} />}
          <Spacer horizontal />
          <h3>{props.basketSet.name}</h3>
        </Group>
        {props.editable && (
          <Button
            size={ButtonSize.Small}
            label="zmień"
            onClick={() => {
              props.onNameChangeClick();
            }}
          />
        )}
      </Group>

      {props.basketSet.meals.map((basketMeal, index) => (
        <React.Fragment key={`${basketMeal.meal.id}`}>
          <Spacer size="large" />
          <Group align="space-between" verticalAlign="top">
            <Group>
              <span className="u-flex-no-shrink">{basketMeal.counter}x</span>
              <Spacer size="smallX" horizontal />
              <BasketMealName
                onClick={() => {
                  props.onMealClick(basketMeal);
                }}
              >
                {basketMeal.meal.namePl}
              </BasketMealName>
              <Spacer size="smallX" horizontal />
              <MealIndexBadge>
                <ValueOrDash value={basketMeal.meal.index} />
              </MealIndexBadge>
              <Spacer size="smallX" horizontal />
            </Group>
            <Group align="right" width="auto" noShrink>
              <Price amount={basketMeal.meal.price} />
              <Spacer size="smallX" horizontal />
              {props.editable && (
                <Button
                  size={ButtonSize.Small}
                  iconName="trash"
                  onClick={() => {
                    const orderState = OrderFormService.getStateValue();
                    const sets = [...orderState.basket.sets];
                    let set = sets[orderState.basket.selectedeSet];
                    set = {
                      ...set,
                      meals: withoutValueAtIndex(set.meals, index),
                    };
                    sets[orderState.basket.selectedeSet] = set;
                    OrderFormService.setState({
                      basket: {
                        ...orderState.basket,
                        sets,
                      },
                    });
                  }}
                />
              )}
            </Group>
          </Group>
          {basketMeal.note && (
            <div className="text-pre-line">
              <ColorRed>{basketMeal.note}</ColorRed>
            </div>
          )}

          <Group vertical>
            {basketMeal.extras.length > 0 &&
              basketMeal.extras.map((basketMealExtras, basketMealExtrasIndex) => {
                if (!basketMealExtras.length || !basketMealExtras.some(extrasItem => extrasItem.counter > 0)) {
                  return null;
                }

                return (
                  <React.Fragment key={`${basketMeal.meal.id}${basketMealExtrasIndex}`}>
                    <Group align="space-between" verticalAlign="top">
                      <Group vertical>
                        {basketMealExtras.map((extrasItem, extrasItemIndex) => (
                          <div key={`${basketMeal.meal.id}-${extrasItem.id}`} style={{ marginTop: '2px' }}>
                            <Spacer size="small" horizontal />
                            <Typography.Text strong>{extrasItem.counter}x</Typography.Text>
                            <Spacer horizontal size="smallX" />
                            <a
                              href="#"
                              onClick={ev => {
                                ev.preventDefault();
                                props.onMealExtrasClick(basketMeal, basketMealExtrasIndex);
                              }}
                            >
                              {extrasItem.name}
                            </a>
                          </div>
                        ))}
                      </Group>

                      <Group align="right">
                        <Price
                          amount={basketMealExtras.reduce((sum, extrasItem) => {
                            return sum + extrasItem.priceChange * extrasItem.counter;
                          }, 0)}
                        />
                        <Spacer size="smallX" horizontal />
                        {props.editable && (
                          <Button
                            size={ButtonSize.Small}
                            label="x"
                            onClick={() => {
                              const orderState = OrderFormService.getStateValue();
                              const sets = [...orderState.basket.sets];
                              let set = sets[orderState.basket.selectedeSet];
                              set = {
                                ...set,
                                meals: replaceValueAtIndex(set.meals, index, basketMeal => ({
                                  ...basketMeal,
                                  extras: withoutValueAtIndex(basketMeal.extras, basketMealExtrasIndex),
                                  counter: basketMeal.counter - 1,
                                })),
                              };
                              sets[orderState.basket.selectedeSet] = set;
                              OrderFormService.setState({
                                basket: {
                                  ...orderState.basket,
                                  sets,
                                },
                              });
                            }}
                          />
                        )}
                      </Group>
                    </Group>
                    <Spacer size="small" />
                  </React.Fragment>
                );
              })}
          </Group>
        </React.Fragment>
      ))}
    </OrderBasketSetContainer>
  );
};
