import { message, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { format, addDays, subDays, startOfWeek } from 'date-fns';

import { PageLayout } from 'components';
import { Button, ButtonVariant, DimmerLoading, Divider, formattedDate, Group } from 'lib/ui';
import { ScheduleDriversTable, ScheduleDialog, Schedule } from 'modules/Schedule';
import useToggle from 'react-use/lib/useToggle';

const enhance = connect(
  ({ drivers, loading, employees, schedules }) => ({
    driversStore: drivers,
    employeesStore: employees,
    schedulesStore: schedules,
    loading,
  }),
  ({ drivers, employees, schedules }) => ({
    drivers,
    employees,
    schedules,
  }),
);

export interface UserSchedule {
  userName: string;
  userId: string | number;
  selectedDate: Date;
  start?: Date;
  end?: Date;
  id?: string | number;
  isHoliday?: boolean;
  type?: 'driver' | 'employee';
  schedule: Schedule;
}

const SchedulePage = enhance(props => {
  //  schedule dialog
  const [isScheduleDialogVisible, toggleScheduleDialog] = useToggle(false);
  const [editedSchedule, setEditedSchedule] = useState<UserSchedule>();
  //  first visible date
  const [date, setDate] = useState(startOfWeek(props.match.params.startDate || new Date(), { weekStartsOn: 1 }));

  //  load collection at page start
  useEffect(() => {
    props.drivers.loadCollection();
    props.employees.loadCollection();
  }, []);

  //  refresh schedules on date change
  useEffect(() => {
    props.schedules.loadItem({
      id: format(date, 'YYYY-MM-DD'),
    });
  }, [date]);

  const schedules: Schedule[] = props.schedulesStore.dataById[format(date, 'YYYY-MM-DD')] || [];
  const isLoading =
    props.loading.effects.drivers.loadCollection ||
    props.loading.effects.employees.loadCollection ||
    (props.loading.effects.schedules.loadItem && !schedules.length);

  return (
    <>
      <PageLayout title="Grafik">
        <Group align="center">
          <Button
            variant={ButtonVariant.Plain}
            label="<<"
            onClick={() => {
              setDate(subDays(date, 7));
            }}
          />
          <DatePicker.WeekPicker
            value={moment(date)}
            allowClear={false}
            onChange={d => {
              if (d) {
                setDate(startOfWeek(d.toDate(), { weekStartsOn: 1 }));
              }
            }}
          />
          <Button
            variant={ButtonVariant.Plain}
            label=">>"
            onClick={() => {
              setDate(addDays(date, 7));
            }}
          />
        </Group>

        <Divider text={`${formattedDate(date)} - ${formattedDate(addDays(date, 6))}`} />

        <h2>Kierowcy</h2>
        <div style={{ position: 'relative' }}>
          <ScheduleDriversTable
            drivers={props.driversStore.data}
            schedules={schedules.filter(schedule => schedule.driver)}
            startDate={date}
            userType="driver"
            onScheduleDialog={data => {
              setEditedSchedule({
                userName: data.userName,
                userId: data.userId,
                type: 'driver',
                schedule: data.schedule,
                selectedDate: new Date(data.day),
              });
              toggleScheduleDialog(true);
            }}
          />
          <DimmerLoading visible={isLoading} />
        </div>

        <h2>Pracownicy</h2>
        <div style={{ position: 'relative' }}>
          <ScheduleDriversTable
            employees={props.employeesStore.data}
            schedules={schedules.filter(schedule => schedule.employee)}
            startDate={date}
            userType="employee"
            onScheduleDialog={data => {
              setEditedSchedule({
                userName: data.userName,
                userId: data.userId,
                type: 'employee',
                schedule: data.schedule,
                selectedDate: new Date(data.day),
              });
              toggleScheduleDialog(true);
            }}
          />
          <DimmerLoading visible={isLoading} />
        </div>
      </PageLayout>

      {isScheduleDialogVisible && (
        <ScheduleDialog
          item={editedSchedule}
          onSave={(data, setLoading) => {
            props.schedules
              .createItem({
                data: {
                  driverId: editedSchedule && editedSchedule.type === 'driver' ? data.userId : undefined,
                  employeeId: editedSchedule && editedSchedule.type === 'employee' ? data.userId : undefined,
                  dateStart: format(data.start, 'YYYY-MM-DD HH:mm'),
                  dateEnd: format(data.end, 'YYYY-MM-DD HH:mm'),
                  isHoliday: data.isHoliday,
                },
              })
              .then(() => {
                props.schedules
                  .loadItem({
                    id: format(date, 'YYYY-MM-DD'),
                  })
                  .then(() => {
                    message.success('Grafik zaktualizowany');
                    toggleScheduleDialog(false);
                  });
              })
              .catch(() => {
                message.error('Wystąpił błąd');
                setLoading(false);
              });
          }}
          onDelete={(id, setLoading) => {
            props.schedules
              .deleteItem({ id })
              .then(() => {
                props.schedules
                  .loadItem({
                    id: format(date, 'YYYY-MM-DD'),
                  })
                  .then(() => {
                    message.success('Pozycja z grafika usunięta');
                    toggleScheduleDialog(false);
                  });
              })
              .catch(() => {
                message.error('Wystąpił błąd');
                setLoading(false);
              });
          }}
          onClose={() => {
            toggleScheduleDialog(false);
          }}
        />
      )}
    </>
  );
});

export { SchedulePage };
