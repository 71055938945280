/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { defaultEndTime, defaultStartTime } from 'lib/forms';
import moment from 'moment';

export const mapRestaurantDataToFormValues = restaurantData => {
  return {
    name: restaurantData.name || '',
    displayedName: restaurantData.displayedName || '',
    commision: restaurantData.commision || 0,
    descriptionPl: restaurantData.descriptionPl || '',
    descriptionEn: restaurantData.descriptionEn || '',
    contactName: restaurantData.contactName || '',
    contactPhone: restaurantData.contactPhone || '',
    contactEmail: restaurantData.contactEmail ? restaurantData.contactEmail.join(', ') : '',
    contactFax: restaurantData.contactFax || '',
    prefferedOrdersContact: restaurantData.prefferedOrdersContact,
    notes: restaurantData.notes || '',
    pageUrl: restaurantData.pageUrl || '',
    addressData: restaurantData.addressData || '',
    addressCity: restaurantData.addressCity || '',
    addressPostalCode: restaurantData.addressPostalCode || '',
    street: restaurantData.street || '',
    houseNumber: restaurantData.houseNumber || '',
    postalCode: restaurantData.postalCode || '',
    city: restaurantData.city || '',
    details: restaurantData.details || '',
    lat: restaurantData.lat || '',
    lon: restaurantData.lon || '',
    cuisineTypes: (restaurantData.cuisineTypes || []).map(item => ({
      key: `${item.id}`,
      label: `${item.namePl} / ${item.nameEn}`,
    })),
    isActive: typeof restaurantData.isActive !== 'undefined' ? restaurantData.isActive : false,
    isNew: typeof restaurantData.isNew !== 'undefined' ? restaurantData.isNew : true,
    hasLunch: typeof restaurantData.hasLunch !== 'undefined' ? restaurantData.hasLunch : false,
    openHours: {
      monday: [
        restaurantData.openHoursMonOpen ? moment(restaurantData.openHoursMonOpen) : defaultStartTime,
        restaurantData.openHoursMonClose ? moment(restaurantData.openHoursMonClose) : defaultEndTime,
      ],
      tuesday: [
        restaurantData.openHoursTueOpen ? moment(restaurantData.openHoursTueOpen) : defaultStartTime,
        restaurantData.openHoursTueClose ? moment(restaurantData.openHoursTueClose) : defaultEndTime,
      ],
      wednesday: [
        restaurantData.openHoursWedOpen ? moment(restaurantData.openHoursWedOpen) : defaultStartTime,
        restaurantData.openHoursWedClose ? moment(restaurantData.openHoursWedClose) : defaultEndTime,
      ],
      thursday: [
        restaurantData.openHoursThuOpen ? moment(restaurantData.openHoursThuOpen) : defaultStartTime,
        restaurantData.openHoursThuClose ? moment(restaurantData.openHoursThuClose) : defaultEndTime,
      ],
      friday: [
        restaurantData.openHoursFriOpen ? moment(restaurantData.openHoursFriOpen) : defaultStartTime,
        restaurantData.openHoursFriClose ? moment(restaurantData.openHoursFriClose) : defaultEndTime,
      ],
      saturday: [
        restaurantData.openHoursSatOpen ? moment(restaurantData.openHoursSatOpen) : defaultStartTime,
        restaurantData.openHoursSatClose ? moment(restaurantData.openHoursSatClose) : defaultEndTime,
      ],
      sunday: [
        restaurantData.openHoursSunOpen ? moment(restaurantData.openHoursSunOpen) : defaultStartTime,
        restaurantData.openHoursSunClose ? moment(restaurantData.openHoursSunClose) : defaultEndTime,
      ],
    },
    averageMealPrepareTime: restaurantData.averageMealPrepareTime || 0,
  };
};

export const mapFormValuesToRestaurantData = formValue => {
  return {
    ...formValue,
    cuisineTypes: formValue.cuisineTypes.map(item => item.key),
    contactEmail: formValue.contactEmail.length > 0 ? formValue.contactEmail.split(',') : [],
    openHoursMonOpen: formValue.openHours.monday[0],
    openHoursMonClose: formValue.openHours.monday[1],
    openHoursTueOpen: formValue.openHours.tuesday[0],
    openHoursTueClose: formValue.openHours.tuesday[1],
    openHoursWedOpen: formValue.openHours.wednesday[0],
    openHoursWedClose: formValue.openHours.wednesday[1],
    openHoursThuOpen: formValue.openHours.thursday[0],
    openHoursThuClose: formValue.openHours.thursday[1],
    openHoursFriOpen: formValue.openHours.friday[0],
    openHoursFriClose: formValue.openHours.friday[1],
    openHoursSatOpen: formValue.openHours.saturday[0],
    openHoursSatClose: formValue.openHours.saturday[1],
    openHoursSunOpen: formValue.openHours.sunday[0],
    openHoursSunClose: formValue.openHours.sunday[1],
  };
};
