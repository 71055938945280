import { WARSAW_CENTER_LAT_LNG } from 'components/modals/MapLabModal/MapLabService';
import { Events } from 'lib/services';
import { MapDataModel } from 'pages/MapPage/MapDataModel';
import {
  displayLineForOrder,
  displayLinesForDriver,
  displayMapMarkers,
  initEventsForMap,
  removeAllLinesFromMap,
} from 'pages/MapPage/mapHelpers';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import { styled } from 'lib/ui';

export interface MapViewProps {
  data?: MapDataModel;
}

const MapContainer = styled.div`
  width: 100%;
  height: ${window.innerHeight - 400}px;
`;

const MapViewContainer = styled.div.attrs({ className: 'MapViewContainer' })``;

export const MapView: FunctionComponent<MapViewProps> = props => {
  // the map itself
  const mapRef = useRef(null);
  const [mapInitialized, setMapInitialized] = useState<Boolean>(false);

  useEffect(() => {
    if (mapRef.current && props.data) {
      displayMapMarkers(props.data, mapRef.current);
    }
  }, [props.data, mapInitialized]);

  // create map
  const mapInitiate = useCallback(node => {
    if (node !== null && !mapRef.current) {
      mapRef.current = window.L.map('maplab').setView(WARSAW_CENTER_LAT_LNG, 13);
      window.L.tileLayer.provider('MaplabPL').addTo(mapRef.current);
      setMapInitialized(true);
      initEventsForMap(mapRef.current);
    }
  }, []);

  //  listen to events from orders table
  useEffect(() => {
    Events.on('map.displayOrderLines', orderWithRestaurants => {
      if (orderWithRestaurants) {
        displayLineForOrder(orderWithRestaurants.id, orderWithRestaurants.restaurantIds);
      } else {
        removeAllLinesFromMap();
      }
    });

    Events.on('map.displayDriverTasks', ({ id, driverId }) => {
      displayLinesForDriver(driverId);
    });
  }, []);

  return (
    <MapViewContainer>
      <MapContainer ref={mapInitiate} id="maplab" />
    </MapViewContainer>
  );
};
