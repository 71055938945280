import {Box, Button, ButtonSize, ButtonVariant, Group, Price, styled} from 'lib/ui';
import React, { FunctionComponent } from 'react';
import { RestaurantSettlement } from 'modules/Restaurant/RestaurantsSettlementModel';

export interface RestaurantSettlementBoxProps {
  restaurantsSettlement: RestaurantSettlement[];
  onSelect: Function;
}

const RestaurantSettlementBoxGroupContainer = styled.div.attrs({
  className: 'RestaurantSettlementBoxGroupContainer',
})`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
`;

const RestaurantSettlementBoxContainer = styled.div.attrs({
  className: 'RestaurantSettlementBoxContainer',
})<{
  selected: boolean;
}>`
  padding: ${p => p.theme.spaceSmallX};
  background: ${p => (p.selected ? p.theme.colorPrimaryLightest : 'transparent')};
  border-radius: ${p => p.theme.borderRadius};
  min-width: 350px;
`;

export const RestaurantSettlementBoxGroup: FunctionComponent<RestaurantSettlementBoxProps> = props => {
  return (
    <RestaurantSettlementBoxGroupContainer>
      {props.restaurantsSettlement.map((restaurantSettlement: RestaurantSettlement) => {
        return (
          <RestaurantSettlementBoxContainer key={restaurantSettlement.restaurant.id}>
            <Box
              headerOneLine
              title={restaurantSettlement.restaurant.name}
              subtitle={`Prowizja: ${restaurantSettlement.restaurant.commision}%`}
              headerWithoutBackground
            >
              <div>
                <Group vertical wrap>
                  <Group>
                    <span>
                      <span>Zamówienia:</span>
                    </span>
                    <span>{restaurantSettlement.settlementData.ordersCount}</span>
                  </Group>
                  <Group>
                    <span>
                      <span>Kwota:</span>
                    </span>
                    <Price amount={restaurantSettlement.settlementData.restaurantPayment}/>
                  </Group>
                  <Group>
                    <span>
                      <span>Potrącenia przed prowizją:</span>
                    </span>
                    <Price amount={restaurantSettlement.settlementData.beforeCommisionDockSum}/>
                  </Group>
                  <Group>
                    <span>
                      <span>Kwota:</span>
                    </span>
                    <Price amount={restaurantSettlement.settlementData.ordersGrossSum}/>
                  </Group>
                  <Group>
                    <span>
                      <span>Potrącenia po prowizji:</span>
                    </span>
                    <Price amount={restaurantSettlement.settlementData.afterCommisionDockSum}/>
                  </Group>
                  <Group>
                    <span>
                      <strong>Do zapłaty:</strong>
                    </span>
                    <Price amount={restaurantSettlement.settlementData.restaurantPayment}/>
                  </Group>
                  <div className={'mt-1'}>
                    <Button
                      size={ButtonSize.Small}
                      onClick={() => props.onSelect(restaurantSettlement)}
                      label="Szczegóły"
                      variant={ButtonVariant.Primary}
                    />
                  </div>
                  {restaurantSettlement.restaurant.notes && (
                    <div className={'mt-1'}>
                      <span>Uwagi: {restaurantSettlement.restaurant.notes}</span>
                    </div>
                  )}
                </Group>
              </div>
            </Box>
          </RestaurantSettlementBoxContainer>
        );
      })}
    </RestaurantSettlementBoxGroupContainer>
  );
};
