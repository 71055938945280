import { Switch } from 'antd';
import { AgGridTable, Button, ButtonSize, formattedDate, formattedTime, Group, Spacer, styled } from 'lib/ui';
import { Promotion } from 'modules/Promotions';
import React, { FunctionComponent } from 'react';
import { getUrlForMediaImage } from 'services/utils/getUrlForMediaImage';

export interface PromotionsTableProps {
  items: Promotion[];
  onDetailClick?: Function;
  onEditClick?: Function;
  onSelectionChanged?: Function;
  onStatusChangeClick: (promotion: Promotion) => void;
  onOrderChange: (rowIds: number[]) => void;
}

const PromotionsTableContainer = styled.div``;

export const PromotionsTable: FunctionComponent<PromotionsTableProps> = props => {
  const columns = [
    {
      headerName: 'Nazwa',
      field: 'namePl',
      sortable: true,
      rowDrag: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Obrazek',
      field: 'image',
      cellRendererFramework: params => {
        return <img src={getUrlForMediaImage(params.data.image)} style={{ maxHeight: 70, marginTop: 5 }} alt={''} />;
      },
    },
    {
      headerName: 'Data od',
      field: 'dateStart',
      valueFormatter: params => `${formattedDate(params.value)} ${formattedTime(params.value)}`,
    },
    {
      headerName: 'Data do',
      field: 'dateEnd',
      valueFormatter: params => `${formattedDate(params.value)} ${formattedTime(params.value)}`,
    },
    {
      headerName: 'Status',
      minWidth: 80,
      maxWidth: 80,
      cellRendererFramework: params => (
        <Switch checked={params.data.isActive} size="small" onClick={() => props.onStatusChangeClick(params.data)} />
      ),
    },
    {
      headerName: 'Akcje',
      cellRendererFramework: cellProps => {
        const row = cellProps.data;
        return (
          <Group>
            <Spacer size="small" />
            <Button
              size={ButtonSize.Small}
              label={'edytuj'}
              iconName="edit"
              onClick={event => {
                if (props.onEditClick) {
                  props.onEditClick(row);
                }
              }}
            />
          </Group>
        );
      },
    },
  ];
  return (
    <PromotionsTableContainer>
      <AgGridTable
        columnDefs={columns}
        rowData={props.items}
        rowHeight={80}
        rowSelection="multiple"
        getRowNodeId={row => row.id}
        onSelectionChanged={event => {
          const selectedRowNodes = event.api.getSelectedNodes();
          if (props.onSelectionChanged) {
            props.onSelectionChanged(selectedRowNodes);
          }
        }}
        rowDragManaged
        animateRows
        onDragStopped={event => {
          const rows = event.api.getRenderedNodes();
          const rowIds = rows.map(rowNode => rowNode.data.id);
          props.onOrderChange(rowIds);
        }}
      />
    </PromotionsTableContainer>
  );
};
