/* eslint-disable import/no-unresolved */
import { differenceInSeconds, distanceInWordsStrict } from 'date-fns';
import plLocale from 'date-fns/locale/pl';
import { useInterval } from 'lib/hooks/useInterval';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { styled } from 'lib/ui';

export interface TimeLeftProps {
  targetDatetime: string;
  relativeDatetime?: string;
  colorGreen?: string;
  colorRed?: string;
  prefixForDelay?: string;
  refreshInterval?: number; // ms
  withMinusPrefix?: boolean; // whether display minus indicator
  displayOnlyIfDelayed?: boolean; // when true - will be displayed only when delayated so timeLeft < 0
}

const SpanColored = styled.span.attrs({ className: 'SpanColored' })`
  color: ${p => (p.color ? p.color : 'inherit')};
`;

export const TimeLeft: FunctionComponent<TimeLeftProps> = props => {
  const [timeLeft, setTimeLeft] = useState<number>();
  const [timeLeftString, setTimeLeftString] = useState<string>();

  const refreshData = useCallback(() => {
    const newTimeLeftString = distanceInWordsStrict(props.targetDatetime, new Date(), { locale: plLocale });
    if (newTimeLeftString !== timeLeftString) {
      setTimeLeft(differenceInSeconds(props.targetDatetime, props.relativeDatetime || new Date()));
      setTimeLeftString(newTimeLeftString);
    }
  }, [props.targetDatetime, timeLeftString]);

  useInterval(
    () => {
      if (props.relativeDatetime) {
        refreshData();
      }
    },
    props.refreshInterval ? props.refreshInterval : 10 * 1000,
  );

  useEffect(() => {
    refreshData();
  }, []);

  if (timeLeft && timeLeft > 0) {
    return <SpanColored color={props.colorGreen}>Pozostało: {timeLeftString}</SpanColored>;
  }

  return props.displayOnlyIfDelayed ? (
    <SpanColored color={props.colorRed}>
      {props.prefixForDelay}
      {props.withMinusPrefix && '-'}
      {timeLeftString}
    </SpanColored>
  ) : null;
};
