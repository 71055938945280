import { MonthSwitcher } from 'components/MonthSwitcher/MonthSwitcher';
import React, { useCallback, useEffect, useState } from 'react';
import { Empty, message, PageHeader } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addMonths, endOfMonth, format, startOfMonth, subMonths } from 'date-fns';

import { PageLayout } from 'components';
import { Box, Button, ButtonVariant, Divider, ensureDateObject, Group, Icon, IconFa, Loading, Price } from 'lib/ui';
import { DriversReckoning } from 'modules/Drivers/DriversReckoning';
import { DriverReckoningDay } from 'pages/DriversReckoningPage/DriverReckoningDay';

const formatDateParam = date => format(date, 'YYYY-MM-DD');

export const DriversReckoningPage = props => {
  const dispatch = useDispatch();
  const { driverId } = props.match.params;
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [loading, setLoading] = useState<Boolean>(false);

  // reckoning data
  const [data, setData] = useState<DriversReckoning | null>();

  const loadData = useCallback(() => {
    setLoading(true);
    setData(null);
    dispatch({
      type: 'driversReckoning/loadCollection',
      payload: {
        driverId,
        dateStart: formatDateParam(startDate),
        dateEnd: formatDateParam(endOfMonth(startDate)),
      },
    })
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(err => {
        message.error('Wystąpił nieoczekiwany błąd');
        setLoading(false);
        setData(null);
      });
  }, [driverId, startDate]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  //  get drivers from store
  const drivers = useSelector(state => state.drivers.dataById);

  if (loading) {
    return <Loading text="Ładowanie danych" />;
  }

  if (!data || data.details.length === 0) {
    return <Empty description="Brak danych" />;
  }

  // DialogService.open(DriverReckoningBonusModal, {});

  const driver = drivers[props.match.params.driverId];
  const driverName = driver ? `${driver.user.firstName} ${driver.user.lastName}` : '';

  return (
    <PageLayout>
      <PageHeader title={`${driverName}`} subTitle={'Rozliczenia'} />
      <MonthSwitcher date={startDate} onDateChange={setStartDate} />

      <div data-bp="grid">
        <div data-bp="9">
          {data &&
            data.details.map(dayData => {
              return (
                <DriverReckoningDay
                  key={dayData.dayStart}
                  driverId={driverId}
                  data={dayData}
                  onUpdate={() => {
                    loadData();
                  }}
                />
              );
            })}
        </div>
        <div data-bp="3">
          <Box title="Podsumowanie miesiąca">
            <div data-bp="grid 6">
              <strong>Nierozliczone</strong>
              <div>
                {data.globalData.orders.unpaid.days} dni (<Price amount={data.globalData.orders.unpaid.amount} />)
              </div>
            </div>
            <div data-bp="grid 6">
              <strong>Rozliczone</strong>
              <div>
                {data.globalData.orders.paid.days} dni (<Price amount={data.globalData.orders.paid.amount} />)
              </div>
            </div>

            <Divider />

            <div data-bp="grid margin-top--sm">
              <strong data-bp="8">Zrealizowane zamówienia</strong>
              <div data-bp="4">{data.globalData.orders.count}</div>
            </div>
            <div data-bp="grid margin-top--sm">
              <strong data-bp="8">Przepracowany czas</strong>
              <div data-bp="4">{data.globalData.countRbh}</div>
            </div>
            <div data-bp="grid margin-top--sm">
              <strong data-bp="8">Przejechane kilometry</strong>
              <div data-bp="4">{(data.globalData.countDistance / 1000).toFixed(2)} km</div>
            </div>
            <div data-bp="grid margin-top--sm">
              <div data-bp="8">
                <Group>
                  <Icon name="smile" fa={IconFa.Far} />
                  <span>Bonusy</span>
                </Group>
              </div>
              <div data-bp="4">
                {' '}
                {data.globalData.bonuses.ok.count} (<Price amount={data.globalData.bonuses.ok.amount} />)
              </div>
            </div>

            <div data-bp="grid margin-top--sm">
              <div data-bp="8">
                <Group>
                  <Icon name="frown" fa={IconFa.Far} />
                  <span>Kary</span>
                </Group>
              </div>
              <div data-bp="4">
                {' '}
                {data.globalData.bonuses.nook.count} (<Price amount={data.globalData.bonuses.nook.amount} />)
              </div>
            </div>
          </Box>
        </div>
      </div>

      {!data && <Empty description="Brak danych" />}
    </PageLayout>
  );
};
