import { SimpleRestModel } from 'lib/services';
import { User } from 'modules/User';
import { HttpApi } from 'services/HttpApi';

export const DriversModel = SimpleRestModel({
  collectionName: 'drivers',
  HttpApi,
});

export interface Driver {
  id: number;
  dayRateFri: number;
  dayRateMon: number;
  dayRateSat: number;
  dayRateSun: number;
  dayRateThu: number;
  dayRateTue: number;
  dayRateWed: number;
  deliveryRate: number;
  distanceRate: number;
  hourRate: number;
  hasTerminal: boolean;
  isActive: boolean;
  isDeleted: boolean;
  isMainPhone1: boolean;
  isMainPhone2: boolean;
  isMainPhone3: boolean;
  isPaidDay: boolean;
  isPaidDelivery: boolean;
  isPaidDeliveryAll: boolean;
  isPaidDistance: boolean;
  isPaidRbh: boolean;
  notes: string;
  phone1: string;
  phone2: string;
  phone3: string;
  user: User;
}
