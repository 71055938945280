import { Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import useToggle from 'react-use/lib/useToggle';
import connect from 'react-redux/es/connect/connect';
import { PageLayout } from 'components';
import { Button, ButtonVariant, DimmerLoading, Group, styled } from 'lib/ui';
import { ExtraExtra } from 'modules/ExtraExtras/ExtraExtrasModel';
import { ExtraExtrasTable } from 'modules/ExtraExtras/components/ExtraExtrasTable';
import { ExtraExtraDrawer } from 'modules/ExtraExtras/components/ExtraExtraDrawer';

const TableContainer = styled.div`
  position: relative;
`;

const enhance = connect(
  ({ extraExtras, loading }) => ({
    extraExtrasData: extraExtras.data,
    loading,
  }),
  ({ extraExtras }) => ({
    extraExtrasStore: extraExtras,
  }),
);

const ExtraExtrasPage = enhance(props => {
  useEffect(() => {
    props.extraExtrasStore.loadCollection();
  }, []);

  const [selectedExtraExtra, setSelectedExtraExtra] = useState<any>(null);
  const [extraExtraFormVisible, toggleExtraExtraForm] = useToggle(false);

  const extraExtras: ExtraExtra[] = props.extraExtrasData;

  return (
    <>
      <PageLayout title="Ekstra dodatki">
        <Group align="right" width="auto">
          <Button label="Dodaj" variant={ButtonVariant.Primary} onClick={toggleExtraExtraForm} />
        </Group>
        <TableContainer>
          {extraExtras.length > 0 && (
            <ExtraExtrasTable
              extraExtras={extraExtras}
              onEditClick={extraExtra => {
                setSelectedExtraExtra(extraExtra);
                toggleExtraExtraForm();
              }}
              onStatusChangeClick={paymentType => {
                props.extraExtrasStore.updateItem({
                  id: paymentType.id,
                  data: {
                    isActive: !paymentType.isActive,
                  },
                });
              }}
            />
          )}
          <DimmerLoading visible={props.loading.models.extraExtras} />
          {extraExtras.length === 0 && <Empty description="Brak danych" />}
        </TableContainer>
      </PageLayout>

      <ExtraExtraDrawer
        visible={extraExtraFormVisible}
        onClose={() => {
          props.extraExtrasStore.clearError();
          setSelectedExtraExtra(null);
          toggleExtraExtraForm(false);
        }}
        extraExtra={selectedExtraExtra}
      />
    </>
  );
});

export { ExtraExtrasPage };
