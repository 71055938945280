import { Drawer, DrawerProps } from 'lib/ui';
import { PaymentType, PaymentTypeForm, PaymentTypeType } from 'modules/Settings';
import React, { FunctionComponent, useState } from 'react';

export interface PaymentTypeDrawerProps extends DrawerProps {
  paymentType?: PaymentType;
}

const defaultPaymentType = {
  id: 0,
  position: 0,
  namePl: '',
  nameEn: '',
  displayedName: '',
  isActive: false,
  paymentType: PaymentTypeType.Gotowka,
};

export const PaymentTypeDrawer: FunctionComponent<PaymentTypeDrawerProps> = props => {
  // from store
  const [closable, setClosable] = useState(true);
  const paymentType = props.paymentType || defaultPaymentType;
  const paymentTypeName = paymentType && (paymentType.namePl || paymentType.nameEn);

  return (
    <Drawer
      displayCloseButton
      closeOnEsc={closable}
      onClose={props.onClose}
      visible={props.visible}
      title={`${paymentTypeName || 'Tworzenie nowej formy płatności'}`}
      destroyOnClose
    >
      <PaymentTypeForm
        paymentType={paymentType}
        onClose={props.onClose}
        onModalToggle={visible => {
          setClosable(!visible);
        }}
      />
    </Drawer>
  );
};
