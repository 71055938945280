import React, { FunctionComponent } from 'react';

import { Icon, IconSize, Spacer, styled } from '../..';

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export interface TextWithSortableProps {
  text: string;
  sortOrder: SortOrder | null;
  isActive: boolean;
  onSortChange: (sortOrder: SortOrder) => void;
}

const TextWithSortableDefaultProps = {};

const TextWithSortableContainer = styled.div.attrs({
  className: 'TextWithSortableContainer',
})`
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1.4rem;
`;

export const TextWithSortable: FunctionComponent<TextWithSortableProps> = props => {
  return (
    <TextWithSortableContainer
      onClick={() => {
        props.onSortChange(props.sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc);
      }}>
      {props.text}
      <Spacer size="small" horizontal />
      {props.isActive && (
        <Icon name={props.sortOrder === SortOrder.Asc ? 'sort-amount-down' : 'sort-amount-up'} size={IconSize.Small} />
      )}
    </TextWithSortableContainer>
  );
};

TextWithSortable.defaultProps = TextWithSortableDefaultProps;
