import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { styled } from '../../styled-components';

const OverlayStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1005; // for use with antd dropdowns in modals etc
`;

export interface OverlayProps {
  color?: string;
  ms?: number;
  children?: ReactNode;
  zIndex?: number;
  verticalAlign?: 'top' | 'center' | 'bottom';
}

const Overlay: FunctionComponent<OverlayProps> = props => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      requestAnimationFrame(() => {
        if (ref.current) {
          // @ts-ignore-next-line
          ref.current.style.opacity = 1;
        }
      });
    }
  }, []);

  const style = {
    background: props.color,
    transition: `${props.ms}ms opacity`,
    alignItems: props.verticalAlign === 'top' ? 'flex-start' : props.verticalAlign === 'bottom' ? 'flex-end' : 'center',
  };

  if (props.zIndex) {
    style['zIndex'] = props.zIndex;
  }
  return createPortal(
    <OverlayStyled ref={ref} style={style}>
      {props.children}
    </OverlayStyled>,
    document.body,
  );
};

Overlay.defaultProps = {
  color: 'rgba(0,0,0,0.4)',
  ms: 250,
};

export { Overlay };
