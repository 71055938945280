import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

export interface ExtraExtra {
  id: number;
  price: number;
  namePl: string;
  nameEn: string;
  isActive: boolean;
  isDeleted: boolean;
}

export const ExtraExtrasModel = SimpleRestModel({
  collectionName: 'extraExtras',
  apiPath: 'restaurants/extra-extras',
  HttpApi,
});
