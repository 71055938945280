export const getOrderTypeName = orderType => {
  switch (orderType) {
    case 0:
      return 'Telefoniczne';
    case 10:
      return 'Internetowe';
    default:
      return `orderType: ${orderType}`;
  }
};
