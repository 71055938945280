import { Select, Switch } from 'antd';
import { Formik } from 'formik';
import { FormFieldInputNumber, FormSwitchField } from 'lib/forms';

import { Button, ButtonVariant, Divider, Group, Modal, ModalProps } from 'lib/ui';
import React, { FunctionComponent, ReactNode } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'recompose';

export interface RestaurantMealExtrasModalProps extends ModalProps {
  children?: ReactNode;
  meal: any;
  restaurant: any;
  extras: any[];
}

const RestaurantMealExtrasModalDefaultProps = {
  meal: {},
  extras: [],
  saveButtonText: 'Zapisz',
};

const enhance = compose(
  connect(
    ({ restaurantsMealGroup, restaurantsMeals, restaurantsExtras }) => ({
      restaurantsMealGroupStore: restaurantsMealGroup,
      restaurantsMealsStore: restaurantsMeals,
      restaurantsExtrasStore: restaurantsExtras,
    }),
    ({ restaurantsMealGroup, restaurantsMeals, restaurantsExtras }) => ({
      restaurantsMealGroup,
      restaurantsMeals,
      restaurantsExtras,
    }),
  ),
);

const RestaurantMealExtrasModal: FunctionComponent<RestaurantMealExtrasModalProps> = enhance(
  ({ meal, extras, onSubmit, ...props }) => {
    const getExtraFromMeal = extra => {
      return meal.extras.find(mealExtra => mealExtra.extras.id === extra.id);
    };

    const initialValues = {
      requiredExtras: meal.requiredExtras || 1,
    };
    extras.forEach(extra => {
      const mealExtra = getExtraFromMeal(extra);
      initialValues[`e_${extra.id}_price`] = mealExtra ? mealExtra.priceChange / 100 : extra.defaultPriceChange / 100;
    });
    extras.forEach(extra => {
      const mealExtra = getExtraFromMeal(extra);
      initialValues[`e_${extra.id}_selected`] = !!mealExtra;
    });

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, bag) => {
          const selectedExtras: any[] = [];
          extras.forEach(extra => {
            if (values[`e_${extra.id}_selected`]) {
              selectedExtras.push({
                id: extra.id,
                priceChange: values[`e_${extra.id}_price`] * 100,
              });
            }
          });

          props.restaurantsMeals
            .customItemAction({
              restaurantId: props.restaurant.id,
              id: meal.id,
              action: 'extras',
              data: {
                extras: selectedExtras,
              },
            })
            .then(() => {
              return props.restaurantsMeals.loadItem({
                restaurantId: props.restaurant.id,
                id: meal.id,
              });
            })
            .then(() => {
              return props.restaurantsMeals.updateItem({
                restaurantId: props.restaurant.id,
                id: meal.id,
                data: {
                  requiredExtras: values.requiredExtras,
                },
              });
            })
            .then(() => {
              bag.setSubmitting(false);
              props.onClose();
            });
        }}
      >
        {formikProps => {
          return (
            <Modal
              {...props}
              visible
              footer={
                <Group align="right">
                  <Button disabled={formikProps.isSubmitting} onClick={props.onClose} label={'Anuluj'} />
                  <Button
                    disabled={formikProps.isSubmitting || !formikProps.isValid}
                    variant={ButtonVariant.Primary}
                    loading={formikProps.isSubmitting}
                    onClick={formikProps.handleSubmit}
                    label="Zapisz"
                  />
                </Group>
              }
            >
              <>
                <Group align="space-between">
                  <Group>Nazwa</Group>
                  <Group align="right">
                    <span>Maks. do wyboru</span>

                    <Select
                      value={formikProps.values['requiredExtras']}
                      onChange={value => {
                        formikProps.setFieldValue('requiredExtras', value);
                      }}
                    >
                      {new Array(4).fill(null).map((item, index) => (
                        <Select.Option key={index + 1}>{index + 1}</Select.Option>
                      ))}
                    </Select>
                  </Group>
                </Group>

                <Divider />

                {extras.map(extra => {
                  return (
                    <Group align="space-between" key={extra.id}>
                      <Group width="auto">
                        <Switch checked={extra.isActive} disabled size="small" />
                        <span className="ml-2">{`${extra.namePl} / ${extra.nameEn}`}</span>
                      </Group>
                      <Group align="right" width="auto">
                        <FormFieldInputNumber name={`e_${extra.id}_price`} {...formikProps} />
                        <FormSwitchField name={`e_${extra.id}_selected`} {...formikProps} />
                      </Group>
                    </Group>
                  );
                })}
              </>
            </Modal>
          );
        }}
      </Formik>
    );
  },
);

RestaurantMealExtrasModal.defaultProps = RestaurantMealExtrasModalDefaultProps;

export { RestaurantMealExtrasModal };
