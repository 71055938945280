/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Switch } from 'antd';

import { AgGridTable, Button, ButtonSize, Group, styled, Tooltip } from 'lib/ui';
import { pathOr, path } from 'rambdax';
import React, { FunctionComponent } from 'react';

import { Balance } from './Balance';

export interface ClientsTableProps {
  items: any[];
  onItemClick?: Function;
  onItemEditClick?: Function;
  onFlagChange?: Function;
}

const ClientsTableContainer = styled.div``;

const ClientsTable: FunctionComponent<ClientsTableProps> = props => {
  const columns = [
    {
      headerName: 'Imię i nazwisko',
      field: 'name',
      sortable: true,
      filter: 'agTextColumnFilter',
      minWidth: 170,
      cellRendererFramework: cellProps => (
        <a
          onClick={() => {
            if (props.onItemClick) {
              props.onItemClick(cellProps.data);
            }
          }}
        >
          {pathOr('-', 'user.firstName', cellProps.data)} {pathOr('-', 'user.lastName', cellProps.data)}
        </a>
      ),
    },
    {
      headerName: 'Email',
      field: 'email',
      cellRendererFramework: cellProps => {
        return pathOr('-', 'user.email', cellProps.data);
      },
    },
    {
      headerName: 'Rabat %',
      field: 'discount',
      valueGetter: cellProps => {
        return `${cellProps.data.discount} %`;
      },
    },
    {
      headerName: 'Nadpłata/Niedopłata',
      headerTooltip: 'Nadpłata/Niedopłata',
      field: 'balance',
      cellRendererFramework: cellProps => {
        const { balance } = cellProps.data;
        return <Balance balance={balance} />;
      },
    },
    {
      headerName: 'Punkty',
      field: 'points',
      cellRendererFramework: cellProps => {
        return pathOr('-', 'points', cellProps.data);
      },
    },
    {
      headerName: 'Informacja o kliencie',
      field: 'notes',
      headerTooltip: 'Informacja o kliencie',
      cellRendererFramework: cellProps => {
        const notes = pathOr('-', 'notes', cellProps.data);
        return (
          <Tooltip text={<p className="text-pre-line">{notes}</p>}>
            <p className="text-truncate">{notes}</p>
          </Tooltip>
        );
      },
    },
    {
      headerName: 'Status',
      minWidth: 80,
      maxWidth: 80,
      cellRendererFramework: cellProps => {
        return (
          <Switch
            checked={cellProps.data.isActive}
            size="small"
            onClick={() => {
              if (props.onFlagChange) {
                props.onFlagChange(cellProps.data, 'isActive');
              }
            }}
          />
        );
      },
    },
    {
      headerName: 'Mrktg.',
      minWidth: 80,
      maxWidth: 80,
      headerTooltip: 'Zgoda marketingowa',
      cellRendererFramework: cellProps => {
        return (
          <Switch
            checked={cellProps.data.marketingPermission}
            size="small"
            onClick={() => {
              if (props.onFlagChange) {
                props.onFlagChange(cellProps.data, 'marketingPermission');
              }
            }}
          />
        );
      },
    },
    {
      headerName: 'SMS',
      minWidth: 80,
      maxWidth: 80,
      cellRendererFramework: cellProps => {
        return (
          <Switch
            checked={cellProps.data.smsContact}
            size="small"
            onClick={() => {
              if (props.onFlagChange) {
                props.onFlagChange(cellProps.data, 'smsContact');
              }
            }}
          />
        );
      },
    },
    {
      headerName: 'Email',
      minWidth: 80,
      maxWidth: 80,
      cellRendererFramework: cellProps => {
        return (
          <Switch
            checked={cellProps.data.emailContact}
            size="small"
            onClick={() => {
              if (props.onFlagChange) {
                props.onFlagChange(cellProps.data, 'emailContact');
              }
            }}
          />
        );
      },
    },
    {
      headerName: 'Tel.',
      minWidth: 80,
      maxWidth: 80,
      cellRendererFramework: cellProps => {
        return (
          <Switch
            checked={cellProps.data.phoneContact}
            size="small"
            onClick={() => {
              if (props.onFlagChange) {
                props.onFlagChange(cellProps.data, 'phoneContact');
              }
            }}
          />
        );
      },
    },
    {
      headerName: 'Akcje',
      minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: cellProps => {
        return (
          <Group>
            <Button
              label={'Szczegóły'}
              size={ButtonSize.Small}
              onClick={() => {
                if (props.onItemClick) {
                  props.onItemClick(cellProps.data);
                }
              }}
            />
            <Button
              label={'Edytuj'}
              size={ButtonSize.Small}
              onClick={() => {
                if (props.onItemEditClick) {
                  props.onItemEditClick(cellProps.data);
                }
              }}
            />
          </Group>
        );
      },
    },
  ];

  return (
    <ClientsTableContainer>
      <AgGridTable
        columnDefs={columns}
        rowData={props.items}
        frameworkComponents={{
          agColumnHeader: props => {
            const tooltipText = path<string>('column.userProvidedColDef.headerTooltip', props);
            return (
              <Tooltip text={tooltipText}>
                <div className="text-truncate">{props.displayName}</div>
              </Tooltip>
            );
          },
        }}
      />
    </ClientsTableContainer>
  );
};

export { ClientsTable };
