/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Empty, message } from 'antd';
import { PageLayout } from 'components';
import { useCurrentOrderForEdit } from 'hooks/useCurrentOrderForEdit';

import { Textarea } from 'lib/forms';
import { Box, Button, ButtonSize, ButtonVariant, Group, Loading, Spacer } from 'lib/ui';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormState } from 'modules/Orders/OrderFormState';
import { OrderEditSteps } from 'pages/OrderEdit/OrderEditSteps';
import { OrderFormBasket } from 'pages/OrderFormPage/components/OrderFormBasket';
import { OrderFormBasketDeliveryDate } from 'pages/OrderFormPage/components/OrderFormBasketDeliveryDate';
import { OrderFormCompany } from 'pages/OrderFormPage/components/OrderFormCompany';
import { OrderFormDriverBonus } from 'pages/OrderFormPage/OrderFormPageStep5/components/OrderFormDriverBonus';
import { OrderFormPaymentTypePicker } from 'pages/OrderFormPage/OrderFormPageStep5/components/OrderFormPaymentTypePicker';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useObservable, useToggle } from 'react-use';

export const OrderEditPage3 = props => {
  const dispatch = useDispatch();
  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());
  const { orderId } = props.match.params;
  const { order, isLoading } = useCurrentOrderForEdit(orderId);
  const hasData = orderState && orderState.restaurant && order;
  const [isSaving, toggleSaving] = useToggle(false);

  const saveOrder = () => {
    const data = OrderFormService.getModelForUpdate();

    const hide = message.loading('Zapisuję zamówienie...', 0);
    toggleSaving();

    dispatch({
      type: 'currentOrders/updateItem',
      payload: {
        id: orderId,
        data,
      },
    })
      .then(res => {
        message.success('Zamówienie zapisane');
        props.history.push('/orders');
        OrderFormService.resetState();
      })
      .catch(err => {
        if (err && err.detail) {
          message.error(err.detail);
        } else if (err && err.data) {
          message.error(err.data);
        } else {
          message.error('Wystąpił błąd podczas zapisywania zamówienia');
        }
      })
      .finally(() => {
        toggleSaving();
        hide();
      });
  };

  return (
    <PageLayout title="Edycja zamówienia" header={<OrderEditSteps activePage={3} />}>
      {isLoading && <Loading />}
      {!isLoading && !hasData && <Empty description="Brak danych." />}
      {!isLoading && orderState && orderState.restaurant && order && (
        <>
          <div data-bp="grid 4">
            <div>
              <div data-bp="grid 12">
                <OrderFormBasket editable={false} key={'orderBasket'} />

                <Group>
                  <Button
                    label="Zapisz zamówienie"
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.Large}
                    loading={isSaving}
                    onClick={saveOrder}
                  />
                </Group>
              </div>
            </div>

            <div>
              <div data-bp="12">
                <Box noMargin title={orderState.withDirectPickUp ? 'Termin odbioru osobistego' : 'Termin dostawy'}>
                  <OrderFormBasketDeliveryDate
                    date={orderState.deliveryDate}
                    onChange={date => {
                      OrderFormService.setState({
                        deliveryDate: date,
                      });
                    }}
                  />
                </Box>
              </div>

              <div data-bp="12">
                <Spacer />
                <Box noMargin title="Bonus / kara">
                  <OrderFormDriverBonus orderState={orderState} />
                </Box>
              </div>

              <div data-bp="12">
                <Spacer />
                <Box noMargin title="Płatność">
                  <OrderFormPaymentTypePicker selected={orderState.paymentType} />
                </Box>
              </div>
            </div>

            <div>
              <div data-bp="12">
                <Box noMargin title="Dane do FV">
                  <OrderFormCompany clientId={orderState.client ? orderState.client.id : ''} />
                </Box>
              </div>

              <div data-bp="12">
                <Spacer />
                <Box noMargin title="Ogólne uwagi do zamówienia">
                  <Textarea
                    value={orderState.note}
                    onChange={ev =>
                      OrderFormService.setState({
                        note: ev.currentTarget.value,
                      })
                    }
                    height="200px"
                  />
                </Box>
              </div>
              <div data-bp="12">
                <Spacer />
                <Box noMargin title="Informacja poufna">
                  <Textarea
                    value={orderState.privateNote}
                    onChange={ev =>
                      OrderFormService.setState({
                        privateNote: ev.currentTarget.value,
                      })
                    }
                    height="200px"
                  />
                </Box>
              </div>
            </div>
          </div>
        </>
      )}
    </PageLayout>
  );
};
