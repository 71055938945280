import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

// todo - add type
export interface MealGroup {
  id: number;
  namePl: string;
}

export const RestaurantMealGroupModel = SimpleRestModel({
  collectionName: 'restaurantsMealGroup',
  HttpApi,
  getApiPath: (payload, action) => {
    switch (action) {
      case 'loadCollection':
      case 'createItem':
      default:
        return `restaurants/${payload.restaurantId}/menu-groups/`;
      case 'loadItem':
      case 'updateItem':
      case 'deleteItem':
        return `restaurants/${payload.restaurantId}/menu-groups/${payload.id}/`;
      case 'customCollectionAction':
        return `restaurants/${payload.restaurantId}/menu-groups/${payload.action}/`;
      case 'customItemAction':
        return `restaurants/${payload.restaurantId}/menu-groups/${payload.id}/${payload.action}/`;
    }
  },
});
