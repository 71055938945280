import React, {FunctionComponent, useEffect, useRef, useState} from 'react';

import {Button, ButtonVariant, DimmerLoading, Group, Modal, ModalProps, Spacer, styled} from 'lib/ui';

import {
    CUSTOM_MARKER_SET_LABEL, ENTRANCE_MARKER_DELETE_LABEL,
    ENTRANCE_MARKER_SET_LABEL,
    LatLng,
    MapLabService,
    WARSAW_CENTER_LAT_LNG,
} from 'components/modals/MapLabModal/MapLabService';
import {ButtonSubmit} from 'lib/forms';
import {Events} from 'lib/services';
import {message} from 'antd';
import {statusMessage} from 'modules/MessageHelper';

export interface MapLabModalProps extends ModalProps {
    city: string;
    postalCode: string;
    street: string;
    details: string;
    houseNumber: string;
    addressLat: LatLng;
    addressLon: LatLng;
    storeName: string;
    onSaveGeoCoordinates: (lat, lon, entranceLat, entranceLon) => Promise<any>;
}

const MapContainer = styled.div`
  height: 400px;
  width: 800px;
`;

const MapControlsContainer = styled.div<{ visible: boolean }>`
  visibility: ${p => (p.visible ? 'visible' : 'hidden')};
`;

const ButtonContainer = styled.div<{ width: string }>`
  display: block;
  width: ${p => p.width};
`;

const Location = styled.span.attrs({
    className: 'locationItem',
})`
  cursor: pointer;
  &:hover {
    background: ${p => p.theme.colorDirtyWhite};
  }
  visibility: ${p => (p.enabled ? 'visible' : 'hidden')};
  height: ${p => (p.enabled ? 'auto' : '0')};
`;

/**
 * Create service as a local global
 */
const mapLabService = new MapLabService();

export const MapLabModal: FunctionComponent<MapLabModalProps> = props => {
    // container for the map
    const mapContainerRef = useRef(null);

    // the map itself
    const mapRef = useRef(null);

    // fields/buttons under the map for coordinates found with geocoder
    const mapControlsRef = useRef(null);

    // marker for custom location
    const customMarkerRef = useRef(null);
    const isCustomLocationSelectedRef = useRef(false);

    // buffer for custom location label
    const customLatLonLabelRef = useRef('Ustaw własną lokalizację');

    // lat/lon coordinates
    const currentLatRef = useRef<LatLng>(props.addressLat as LatLng);
    const currentLonRef = useRef<LatLng>(props.addressLon as LatLng);

    // marker for entrance location
    const entranceMarkerRef = useRef(null);
    const isEntranceSelectedRef = useRef(false);

    //buffer for entrance location label
    const [entranceLatLonLabelRef, setEntranceLatLonLabelRef] = useState('');

    const saveButtonLabelRef = useRef('');

    const givenAddressHasLatLonRef = useRef<boolean>(!!(props.addressLat && props.addressLon));

    // control the states...
    const [loading, setLoading] = useState<boolean>(true);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
    const [entranceButtonDisabled, setEntranceButtonDisabled] = useState<boolean>(true);
    const [results, setResults] = useState<any[]>([]);
    const [selectedLatLon, setSelectedLatLon] = useState<any>({});
    const [entranceLatLon, setEntranceLatLon] = useState<any>({});

    if (!loading) {
        saveButtonLabelRef.current = 'Zapisz lokalizację';
    }

    const addressAsString = [props.city, props.postalCode, mapLabService.getActualStreetName(props.street)]
        .filter(item => item)
        .join(' ');

    let selectedLatLonIndex = -1;

    // handle messages after updates
    useEffect(() => {
        message.destroy();

        Events.on(`Model.${props.storeName}.updateItem.success`, () => {
            statusMessage(<p>Wybrana lokalizacja
                została {givenAddressHasLatLonRef.current ? `zmieniona` : 'zapisana'}</p>);
        });

        Events.on([`Model.${props.storeName}.updateItem.error`], () => {
            statusMessage(<p>Wystąpił nieoczekiwany błąd</p>, 'error');
        });

        return () => {
            Events.off([`Model.${props.storeName}.updateItem.success`, `Model.${props.storeName}.updateItem.error`], true);
        };
    }, []);

    // create map
    useEffect(() => {
        if (mapContainerRef.current && !mapRef.current) {
            mapRef.current = window.L.map('maplab').setView(WARSAW_CENTER_LAT_LNG, 13);

            window.L.tileLayer.provider('MaplabPL').addTo(mapRef.current);

            /**
             * 'dependency injection' - do not do that this way at home kids! ;>
             */
            mapLabService.setDependencies(
                {
                    map: mapRef,
                    mapControls: mapControlsRef,
                    isCustomLocationSelected: isCustomLocationSelectedRef,
                    customMarker: customMarkerRef,
                    entranceMarker: entranceMarkerRef
                },
                {
                    geoResultsMarkers: window.L.layerGroup(),
                    customMarker: window.L.layerGroup(),
                    entranceMarker: window.L.layerGroup()
                },
                {
                    setSelectedLatLon,
                    setEntranceLatLon,
                    setSaveButtonDisabled,
                    setEntranceButtonDisabled,
                },
            );
        }
    }, [mapContainerRef.current]);

    const processGeocodeResults = geocodeResults => {
        setLoading(false);

        if (!mapRef.current) {
            // no map YET...
            return;
        }

        // if we are here, then we have a map
        const geoResultsFound = geocodeResults.length > 0;

        if (givenAddressHasLatLonRef.current) {
            if (geoResultsFound) {
                /**
                 * If we are here, then we have some lat/lon results from geocoder
                 * let's try to find the selected one...
                 */
                selectedLatLonIndex = mapLabService.findIndexOfSelectedGeocoderResult(
                    geocodeResults,
                    givenAddressHasLatLonRef.current,
                    props.addressLat,
                    props.addressLon,
                );
            }

            /**
             * If we are here, then the address is already selected...
             */
            if (selectedLatLonIndex === -1) {
                /**
                 * If we are here, then given lat/lon is NOT found among the found geocodeResults
                 * BUT custom location is selected
                 *
                 * In this case:
                 * do not show any geo results!
                 * create custom marker
                 */
                customLatLonLabelRef.current = CUSTOM_MARKER_SET_LABEL;
                customMarkerRef.current = mapLabService.createCustomMarker({
                    lat: props.addressLat as number,
                    lng: props.addressLon as number,
                });

            } else {
                /**
                 * If we are here, then given lat/lon is found among the geocodeResults
                 *
                 * In this case:
                 * display only found georesult - do not display the rest...
                 */
                mapLabService.createMarkersForGeocoderResults(geocodeResults, selectedLatLonIndex);
                geocodeResults[selectedLatLonIndex].enabled = true;
            }
        } else {
            /**
             * If we are here, then the address is not selected YET...
             */
            if (geoResultsFound) {
                /**
                 * If we are here, then there are some results found with geocoder
                 * let's prepare the markers
                 */
                mapLabService.createMarkersForGeocoderResults(geocodeResults);

                // set ell georesults as enabled
                for (let i = 0; i < geocodeResults.length; i++) {
                    geocodeResults[i].enabled = true;
                }
            } else {
                /**
                 * If we are here, then there are no results found with geocoder
                 *
                 * In this case:
                 * create custom marker
                 */
                customLatLonLabelRef.current = CUSTOM_MARKER_SET_LABEL;
                customMarkerRef.current = mapLabService.createCustomMarker({
                    lat: props.addressLat as number,
                    lng: props.addressLon as number,
                });

            }
        }

        /**
         * Finally, let's select one of the the locations...
         */
        setResults(geocodeResults);
        if (selectedLatLonIndex === -1) {
            mapLabService.selectLocationForCustomMarker();
        } else {
            mapLabService.selectLocationForGeoCoderResult(geocodeResults[selectedLatLonIndex], selectedLatLonIndex);
        }
    };

    // search for locations by given address
    useEffect(() => {
        const geocoder = window.L.Control.Geocoder.createGeocoder();

        if (addressAsString) {
            //  search with full address (parsed stret name, city, postalCode)
            geocoder.geocode(addressAsString, (geocodeResults: any[]) => {
                if (geocodeResults.length) {
                    // at least one location has been found...
                    processGeocodeResults(geocodeResults);
                } else {
                    // no location has been found - search again for the street only...
                    geocoder.geocode(mapLabService.getActualStreetName(props.street), (geocodeResults: any[]) => {
                        if (geocodeResults.length) {
                            processGeocodeResults(geocodeResults);
                        } else {
                            // search again with raw street name from DB
                            geocoder.geocode(props.street, (geocodeResults: any[]) => {
                                processGeocodeResults(geocodeResults);
                            });
                        }
                    });
                }
            });
        }
    }, []);

    return (
        <Modal {...props}>
            <p>
                <strong>Obecna lokalizacja: </strong>
                {currentLatRef.current && currentLonRef.current
                    ? `${currentLatRef.current}, ${currentLonRef.current}`
                    : 'brak - wskaż na mapie i zapisz'}
            </p>

            <MapContainer ref={mapContainerRef} id="maplab"/>

            <MapControlsContainer ref={mapControlsRef} visible={mapLabService.getHasAllDependencies()}>
                {/* message under the map */}
                {loading && <h3>Wyszukuję lokalizacje dla podanego adresu...</h3>}
                {!loading && !givenAddressHasLatLonRef.current && results.length === 0 && (
                    <h3>Nie znaleziono lokalizacji dla podanego adresu.</h3>
                )}
                {!loading && !givenAddressHasLatLonRef.current && results.length > 1 && (
                    <h3>Znalezione lokalizacje: {results.length}.</h3>
                )}
                {/* /message under the map */}

                <Spacer horizontal size="small"/>

                <Group vertical>
                    {results.map((geoResult, geoResultIndex) => {
                        return (
                            <Location
                                key={`mapLabLocationLatLng${geoResultIndex}`}
                                onClick={() => {
                                    mapLabService.selectLocationForGeoCoderResult(geoResult, geoResultIndex);
                                }}
                                enabled={geoResult.enabled}
                            >
                                <span>{mapLabService.getNameToDisplayFromGeoresult(geoResult)}</span>
                            </Location>
                        );
                    })}
                    {results.length < 1 && (
                        <Button
                            variant={ButtonVariant.Default}
                            label="Pokaż na mapach googla"
                            onClick={() => {
                                const win = window.open(`https://maps.google.com/?q=${encodeURIComponent(addressAsString)}`, '_blank');
                                if (win) {
                                    win.focus();
                                }
                            }}
                        />
                    )}
                </Group>

                <Spacer horizontal size="small"/>

                <Group align="space-between">
                    <Group>
                        <ButtonContainer width="25%">
                            <ButtonSubmit
                                disabled={saveButtonDisabled}
                                loading={loading}
                                label={saveButtonLabelRef.current}
                                onClick={() => {
                                    const {lat, lon} = mapLabService.getLatLonFromGeoData(selectedLatLon);
                                    const {entranceLat, entranceLon} = mapLabService.getEntranceLatLonFromGeoData(entranceLatLon);

                                    setLoading(false);

                                    const oldLat = currentLatRef.current;
                                    const oldLon = currentLonRef.current;

                                    const oldCustomLocationLabel = `${customLatLonLabelRef.current}`;

                                    // set new lat/lon before save - optimistic update
                                    currentLatRef.current = lat;
                                    currentLonRef.current = lon;

                                    // set new label for custom location - optimistic update
                                    if (isCustomLocationSelectedRef.current) {
                                        customLatLonLabelRef.current = CUSTOM_MARKER_SET_LABEL;
                                    }

                                    props
                                        .onSaveGeoCoordinates(lat, lon, entranceLat, entranceLon)
                                        .then(() => {
                                            givenAddressHasLatLonRef.current = true;
                                        })
                                        .catch(() => {
                                            // restore old values and re-render
                                            currentLatRef.current = oldLat;
                                            currentLonRef.current = oldLon;

                                            customLatLonLabelRef.current = oldCustomLocationLabel;

                                            setSelectedLatLon({
                                                center: {
                                                    lat: oldLat,
                                                    lng: oldLon,
                                                },
                                            });
                                        })
                                        .finally(() => setLoading(false));
                                }}
                            />
                        </ButtonContainer>

                        <ButtonContainer width="35%">
                            <ButtonSubmit
                                disabled={entranceButtonDisabled}
                                label={entranceLatLonLabelRef}
                                variant={ButtonVariant.Primary}
                                onClick={() => {
                                    const {entranceLat, entranceLon} = mapLabService.getEntranceLatLonFromGeoData(entranceLatLon);
                                    if (entranceLat && entranceLon) {
                                        setEntranceLatLonLabelRef(ENTRANCE_MARKER_SET_LABEL);
                                        mapLabService.removeEntranceMarker();
                                    } else {
                                        setEntranceLatLonLabelRef(ENTRANCE_MARKER_DELETE_LABEL);
                                        entranceMarkerRef.current = mapLabService.createEntranceMarker({
                                            lat: mapLabService.getRefs().map.current.getCenter().lat + 0.0002,
                                            lng: mapLabService.getRefs().map.current.getCenter().lng + 0.0002
                                        });
                                    }
                                }}
                            />
                        </ButtonContainer>
                    </Group>

                    <ButtonContainer width="25%">
                        <Button
                            variant={ButtonVariant.Default}
                            label={customLatLonLabelRef.current}
                            onClick={() => {
                                customMarkerRef.current = mapLabService.createCustomMarker({
                                    lat: mapLabService.getRefs().map.current.getCenter().lat + 0.0002,
                                    lng: mapLabService.getRefs().map.current.getCenter().lng + 0.0002
                                });
                                mapLabService.selectLocationForCustomMarker();
                            }}
                        />
                    </ButtonContainer>
                </Group>
            </MapControlsContainer>

            <DimmerLoading visible={loading}/>
        </Modal>
    );
};
