import config from 'config';

export interface FileUploadOptions {
  path: string;
  fieldName?: string;
  methodName?: 'POST' | 'PATCH';
}

const defaultFieldName = 'image';

export const fileUpload = (file: File, options: FileUploadOptions) => {
  const formData = new FormData();
  formData.append(options.fieldName ? options.fieldName : defaultFieldName, file);
  return fetch(`${config.apiUrl}${options.path}`, {
    method: options.methodName ? options.methodName : 'POST',
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
    },
    body: formData,
  });
};
