import { DatePicker } from 'antd';

import { styled } from 'lib/ui';
import { TimePickerDefaults } from 'lib/ui/utils';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { AppDateTimePickerDefaults } from 'components/AppUtils';

export interface OrderBasketDeliveryDateProps {
  date?: Date | null;
  onChange: (date: Date | null) => void;
}

const OrderBasketDeliveryDateContainer = styled.div.attrs({
  className: 'OrderBasketDeliveryDateContainer',
})`
  padding: ${p => p.theme.spaceSmall};
`;

export const OrderFormBasketDeliveryDate: FunctionComponent<OrderBasketDeliveryDateProps> = props => {
  return (
    <OrderBasketDeliveryDateContainer>
      <DatePicker
        showTime={{
          format: TimePickerDefaults.TimeFormat,
          minuteStep: TimePickerDefaults.MinuteStep,
        }}
        format={AppDateTimePickerDefaults.DateTimeFormat}
        value={props.date ? moment(props.date) : undefined}
        placeholder="Jak najszybciej"
        onChange={value => props.onChange(value ? value.toDate() : null)}
        suffixIcon={null}
      />
    </OrderBasketDeliveryDateContainer>
  );
};
