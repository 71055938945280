import { DriverTaskWithOrderLetters } from 'pages/OrdersPage/DriverTasksDialog/DriverTasksDialog';
import React from 'react';

import { styled } from 'lib/ui';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

export interface DriverTaskItemProps {
  task: DriverTaskWithOrderLetters;
}

const TaskContainer = styled.div.attrs({ className: 'TaskContainer' })<{
  background: string;
}>`
  padding: 4px 8px;
  margin: 4px 0;
  background: ${p => p.background};
  color: #000;
  z-index: 999999;
  cursor: pointer;
`;

export const DriverTaskItem = SortableElement((props: DriverTaskItemProps) => {
  const restaurant = props.task.restaurant;
  return (
    <TaskContainer background={props.task.taskType === 'customer' ? '#ABA39D' : '#DE5F1F'}>
      <div data-bp="grid gap-none">
        <div data-bp="1 text-center">
          <strong>{props.task.orderLetter}</strong>
        </div>
        <div data-bp="11">
          {props.task.taskType === 'customer' && <span>{props.task.customer.address.street} {props.task.customer.address.houseNumber} {props.task.customer.address.details} {props.task.customer.address.city}</span>}
          {props.task.taskType === 'restaurant' && restaurant && (
            <span>{restaurant.displayedName || restaurant.name} - {restaurant.address.street} {restaurant.address.houseNumber}</span> 
          )}
        </div>
      </div>
    </TaskContainer>
  );
});

export const DriverTasksList = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((task, index) => (
        <DriverTaskItem key={`item-${task.id}`} index={index} task={task} />
      ))}
    </div>
  );
});
