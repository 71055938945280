import { TimePicker } from 'antd';
import { Formik } from 'formik';

import { ButtonSubmit, FormSwitchField } from 'lib/forms';
import { Button, Group, Modal, ModalProps, Spacer } from 'lib/ui';
import { TimePickerDefaultsProps, weekdays } from 'lib/ui/utils';
import moment from 'moment';
import React, { FunctionComponent, ReactNode } from 'react';

export interface RestaurantGroupAvailabilityModalProps extends ModalProps {
  children?: ReactNode;
  onSubmit: Function;
  item?: any;
  withoutDescription?: boolean;
  saveButtonText?: string;
}

const RestaurantMenuGroupDefaultProps = {
  item: {},
  saveButtonText: 'Zapisz',
};

const RestaurantGroupAvailabilityModal: FunctionComponent<RestaurantGroupAvailabilityModalProps> = ({
  item,
  saveButtonText,
  onSubmit,
  withoutDescription,
  ...props
}) => {
  const dateStartTime =
    item.availiableHoursMonOpen ||
    item.availiableHoursTueOpen ||
    item.availiableHoursWedOpen ||
    item.availiableHoursThuOpen ||
    item.availiableHoursFriOpen ||
    item.availiableHoursSatOpen ||
    item.availiableHoursSunOpen;
  const dateEndTime =
    item.availiableHoursMonClose ||
    item.availiableHoursTueClose ||
    item.availiableHoursWedClose ||
    item.availiableHoursThuClose ||
    item.availiableHoursFriClose ||
    item.availiableHoursSatClose ||
    item.availiableHoursSunClose;

  return (
    <Modal {...props}>
      <Formik
        initialValues={{
          start: dateStartTime
            ? moment(dateStartTime)
            : moment()
                .hour(11)
                .minute(0),
          end: dateEndTime
            ? moment(dateEndTime)
            : moment()
                .hour(21)
                .minute(0),
          monday: !!item.availiableHoursMonOpen || false,
          tuesday: !!item.availiableHoursTueOpen || false,
          wednesday: !!item.availiableHoursWedOpen || false,
          thursday: !!item.availiableHoursThuOpen || false,
          friday: !!item.availiableHoursFriOpen || false,
          saturday: !!item.availiableHoursSatOpen || false,
          sunday: !!item.availiableHoursSunOpen || false,
        }}
        validate={values => {
          const errors = {};
          return errors;
        }}
        onSubmit={(values, bag) => {
          const modelValue = {};
          weekdays.forEach(dayData => {
            modelValue[`availiableHours${dayData.shortnameEn}Open`] = values[dayData.nameEn.toLowerCase()]
              ? values.start.toISOString()
              : null;
            modelValue[`availiableHours${dayData.shortnameEn}Close`] = values[dayData.nameEn.toLowerCase()]
              ? values.end.toISOString()
              : null;
          });
          onSubmit(modelValue, bag);
        }}
      >
        {formikProps => (
          <>
            {/*{props.model.error && <FormError errors={props.model.error} />}*/}
            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <Group>
                  <TimePicker
                    {...TimePickerDefaultsProps}
                    value={formikProps.values['start']}
                    onChange={value => {
                      formikProps.setFieldValue('start', value);
                    }}
                  />
                  <TimePicker
                    {...TimePickerDefaultsProps}
                    value={formikProps.values['end']}
                    onChange={value => {
                      formikProps.setFieldValue('end', value);
                    }}
                  />
                </Group>
                <Group>
                  {weekdays.map(dayData => (
                    <FormSwitchField
                      key={dayData.nameEn}
                      name={dayData.nameEn.toLowerCase()}
                      label={dayData.shortnamePl}
                      {...formikProps}
                    />
                  ))}
                </Group>
              </Group>
              <Spacer />
              <Group align={'right'}>
                <Button
                  label={'wyczyść'}
                  onClick={() => {
                    const modelValue = {};
                    weekdays.forEach(dayData => {
                      modelValue[`availiableHours${dayData.shortnameEn}Open`] = null;
                      modelValue[`availiableHours${dayData.shortnameEn}Close`] = null;
                    });
                    onSubmit(modelValue);
                  }}
                />
                <ButtonSubmit
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                  loading={formikProps.isSubmitting}
                  label={saveButtonText}
                  onClick={formikProps.handleSubmit}
                  iconName="save"
                />
              </Group>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
};

RestaurantGroupAvailabilityModal.defaultProps = RestaurantMenuGroupDefaultProps;

export { RestaurantGroupAvailabilityModal };
