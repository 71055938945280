import React, { FunctionComponent } from 'react';

import { Drawer, DrawerProps, formattedDate, formattedTime, Group, Spacer } from 'lib/ui';
import { DriverMessage, DriversMessageForm } from 'modules/Drivers';

export interface DriversMessageDrawerProps extends DrawerProps {
  driversMessage?: DriverMessage;
}

const DriversMessageDrawer: FunctionComponent<DriversMessageDrawerProps> = ({ onClose, visible, driversMessage }) => {
  return (
    <Drawer
      displayCloseButton
      closeOnEsc
      onClose={onClose}
      visible={visible}
      title={
        driversMessage
          ? `Wiadomość z dnia: ${formattedDate(driversMessage.sendDate)}, ${formattedTime(driversMessage.sendDate)}`
          : 'Nowa wiadomość'
      }
      destroyOnClose
    >
      {/* show create new message form */}
      {!driversMessage && <DriversMessageForm driversMessage={driversMessage} onClose={onClose} />}

      {/* show chosen message details */}
      {driversMessage && driversMessage.sendTo && driversMessage.sendTo.length > 0 && (
        <Group vertical>
          <Group vertical verticalAlign="top">
            <span className="text-pre-line">
              <strong>Treść:</strong>
            </span>
            <span className="u-flex-no-shrink">{driversMessage.content}</span>
          </Group>
          <Spacer size="large" />
          <Group style={{ flexDirection: 'row' }} vertical wrap>
            <Group vertical width={'50%'} wrap>
              <span className="text-pre-line">
                <strong>Przeczytana:</strong>
              </span>
              <Group style={{ padding: 0, margin: 0, flexDirection: 'column' }}>
                {driversMessage.driversRead.map(driver => (
                  <React.Fragment key={driver.id}>
                    <Group style={{ marginTop: '5px' }}>
                      <span>&bull; {driver.name}</span>
                    </Group>
                  </React.Fragment>
                ))}
              </Group>
            </Group>
            <Group width={'50%'} wrap>
              <span className="u-flex-no-shrink">
                <strong>Nieprzeczytana:</strong>
              </span>
              <Group style={{ padding: 0, margin: 0, flexDirection: 'column' }}>
                {driversMessage.sendTo
                  .filter(d => !driversMessage.driversRead.some(dm => d.id === dm.id))
                  .map(driver => (
                    <React.Fragment key={driver.id}>
                      <Group style={{ marginTop: '5px' }}>
                        <span>&bull; {driver.name}</span>
                      </Group>
                    </React.Fragment>
                  ))}
              </Group>
            </Group>
          </Group>
        </Group>
      )}
    </Drawer>
  );
};

export { DriversMessageDrawer };
