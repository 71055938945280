import { groupBy } from 'rambdax';
import React, { FunctionComponent } from 'react';

import { formattedTime, Group, styled } from 'lib/ui';
import { weekdays } from 'lib/ui/utils';

export interface AvailabilityHoursProps {
  availiableHoursMonOpen: string | null;
  availiableHoursTueOpen: string | null;
  availiableHoursWedOpen: string | null;
  availiableHoursThuOpen: string | null;
  availiableHoursFriOpen: string | null;
  availiableHoursSatOpen: string | null;
  availiableHoursSunOpen: string | null;
  availiableHoursMonClose: string | null;
  availiableHoursTueClose: string | null;
  availiableHoursWedClose: string | null;
  availiableHoursThuClose: string | null;
  availiableHoursFriClose: string | null;
  availiableHoursSatClose: string | null;
  availiableHoursSunClose: string | null;
  emptyMessage?: string;
  prefix?: string;
}

const AvailabilityHoursContainer = styled.div`
  margin-top: ${p => p.theme.space};
`;

const AvailabilityHours: FunctionComponent<AvailabilityHoursProps> = props => {
  const daysWithHours = weekdays
    .map((dayData, dayIndex) => {
      const from = props[`availiableHours${dayData.shortnameEn}Open`];
      const to = props[`availiableHours${dayData.shortnameEn}Close`];
      return {
        dayIndex,
        dayName: dayData.shortnamePl,
        from: from ? formattedTime(from) : null,
        to: to ? formattedTime(to) : null,
      };
    })
    .filter(item => item.from);

  const grouppedDaysByHours = groupBy(item => `${item.from} - ${item.to}`, daysWithHours);
  // const d3 = sortBy(group => group.map(day => day.dayIndex), d2);

  return (
    <AvailabilityHoursContainer>
      <Group>
        {props.prefix && <span>{props.prefix}</span>}
        <span>{!daysWithHours.length && `${props.emptyMessage ? props.emptyMessage : '-'}`}</span>
        {Object.keys(grouppedDaysByHours).map(hours => (
          <span key={hours}>
            <span>{grouppedDaysByHours[hours].map(day => day.dayName).join(', ')}</span> <span>{hours}</span>
          </span>
        ))}
      </Group>
    </AvailabilityHoursContainer>
  );
};

export { AvailabilityHours };
