import React, { FunctionComponent } from 'react';

import { styled } from 'lib/ui';

export interface CommunicationProps {
  text: string;
  color: 'red' | 'blue';
}

const CommunicationDefaultProps = {};

const CommunicationContainer = styled.div.attrs({
  className: 'CommunicationContainer',
})<{
  color: 'red' | 'blue';
}>`
  color: ${p =>
    p.color === 'red' ? p.theme.colorRed : p.color === 'blue' ? p.theme.colorPrimaryLighter : p.theme.colorGrey};
`;

export const Communication: FunctionComponent<CommunicationProps> = props => {
  return <CommunicationContainer color={props.color}>{props.text}</CommunicationContainer>;
};

Communication.defaultProps = CommunicationDefaultProps;
