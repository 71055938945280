import { Group, Header, styled } from 'lib/ui';
import { Restaurant } from 'modules/Restaurant';
import React, { FunctionComponent } from 'react';

export interface RestaurantsSettlementSelectedItemGroupProps {
  restaurants: Restaurant[];
  onSelect: Function;
}

const RestaurantsSettlementSelectedItemsContainer = styled.div.attrs({
  className: 'RestaurantsSettlementSelectedItemsContainer',
})`
  width: 50%;
  padding: 1rem;
`;

const RestaurantsSettlementSelectedItemContainer = styled.div.attrs({
  className: 'RestaurantsSettlementSelectedItemContainer',
})<{
  selected: boolean;
}>`
  padding: ${p => p.theme.spaceSmallX};
  font-weight: ${p => (p.selected ? p.theme.fontWeightBold : p.theme.fontWeightDefault)};
  background: ${p => (p.selected ? p.theme.colorPrimaryLightest : 'transparent')};
  cursor: pointer;
  border-radius: ${p => p.theme.borderRadius};

  :hover {
    background: ${p => p.theme.backgroundHover};
  }
`;

export const RestaurantsSettlementSelectedItems: FunctionComponent<
  RestaurantsSettlementSelectedItemGroupProps
> = props => {
  return (
    <RestaurantsSettlementSelectedItemsContainer>
      <Header>Wybrane restauracje</Header>
      <Group wrap>
        {props.restaurants.map((restaurant: Restaurant) => {
          return (
            <RestaurantsSettlementSelectedItemContainer onClick={() => props.onSelect(restaurant.id)} key={restaurant.id}>
              {restaurant.name}
            </RestaurantsSettlementSelectedItemContainer>
          );
        })}
      </Group>
    </RestaurantsSettlementSelectedItemsContainer>
  );
};
