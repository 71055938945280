import { message } from 'antd';
import { ColorRed } from 'components';
import { Formik } from 'formik';
import { FormField, FormTextareaField } from 'lib/forms';
import { Button, ButtonVariant, Group, Loading, Price, Spacer } from 'lib/ui';
import { OrderModel } from 'modules/Orders/models/order.model';
import React, { useEffect, useState } from 'react';
import { HttpApi } from 'services/HttpApi';

export interface OrderPayuProps {
  order: OrderModel;
}

export const OrderPayu: React.FC<OrderPayuProps> = props => {
  const [data, setData] = useState<any | null>(null);

  useEffect(() => {
    HttpApi.get(`/orders/sendPayULink/${props.order.id}/`).then(data => {
      setData(data);
    });
  }, [props.order]);

  const sendMessage = ({ email, content }, bag) => {
    HttpApi.post(`/orders/sendPayULink/${props.order.id}/`, {
      data: {
        recipientEmail: email,
        emailBody: content,
      },
    })
      .then(res => {
        setData(res);
        if (res.sentEmail) {
          message.success('Wiadomość została wysłana');
        } else {
          message.error('Wystąpił błąd podczas wysyłki wiadomości');
        }
      })
      .catch(err => {
        message.error('Wystąpił błąd podczas wysyłki wiadomości');
      })
      .finally(() => {
        bag.setSubmitting(false);
      });
  };

  const paymentStatus = props.order.paymentData && props.order.paymentData.paymentStatus;

  if (!data) {
    return <Loading text="Ładowanie danych" />;
  }

  if (paymentStatus === 'paid') {
    return (
      <div>
        <p>Zamówienie zostało już opłacone w payU. Nie ma potrzeby wysyłać klientowi linka do płatności.</p>
        <h3>E-mail klienta</h3>
        <p>{data.customerEmail || ''}</p>
        <h3>Podsumowanie</h3>
        <p>
          Aktualne zamówienie: <Price amount={Number(props.order.gross)} /> (opłacone)
        </p>
      </div>
    );
  }

  if (paymentStatus === 'unpaid') {
    return (
      <div>
        <p>Zamówienie nie zostało jeszcze opłacone. Wyślij klinetowi ponownie link do płatności.</p>

        <Formik
          initialValues={{
            email: data.customerEmail || '',
            content: data.emailBody || '',
          }}
          validate={values => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values, bag) => {
            sendMessage(values, bag);
          }}
        >
          {formikProps => {
            return (
              <>
                <h3>E-mail klienta</h3>
                <FormField name="email" label="" {...formikProps} />
                <Spacer size="large" />

                <h3>Podsumowanie</h3>
                <Group>
                  <span>Aktualne zamówienie:</span> <Price amount={Number(data.gross)} /> (
                  <ColorRed>nieopłacone</ColorRed>)
                </Group>
                <Spacer size="large" />

                <h3>Email</h3>
                <FormTextareaField name="content" label="" {...formikProps} />

                <Spacer size="large" />
                <Group>
                  <Button
                    disabled={formikProps.isSubmitting}
                    variant={ButtonVariant.Primary}
                    loading={formikProps.isSubmitting}
                    onClick={formikProps.handleSubmit}
                    label="Wyślij"
                  />
                </Group>
              </>
            );
          }}
        </Formik>
      </div>
    );
  }

  if (paymentStatus === 'partialPaid') {
    return (
      <div>
        <p>Zamówienie zostało opłacone częściowo. Wyślij klinetowi link do płatności celem dopłacenia reszty kwoty.</p>

        <Formik
          initialValues={{
            email: data.customerEmail || '',
            content: data.emailBody || '',
          }}
          validate={values => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values, bag) => {
            sendMessage(values, bag);
          }}
        >
          {formikProps => {
            return (
              <>
                <h3>E-mail klienta</h3>
                <FormField name="email" label="" {...formikProps} />
                <Spacer size="large" />

                <h3>Podsumowanie</h3>
                <Group>
                  <span>Aktualne zamówienie:</span> <Price amount={Number(data.gross)} /> (
                  <ColorRed>opłacone częściowo</ColorRed>)
                </Group>
                <Group>
                  <span>Opłacone:</span> <Price amount={Number(data.paid)} /> <span>(opłacone)</span>
                </Group>
                <Group>
                  <span>Dopłata:</span> <Price amount={Number(data.unpaid)} /> (<ColorRed>nieopłacone</ColorRed>)
                </Group>
                <Spacer size="large" />

                <h3>Email</h3>
                <FormTextareaField name="content" label="" {...formikProps} />

                <Spacer size="large" />
                <Group>
                  <Button
                    disabled={formikProps.isSubmitting}
                    variant={ButtonVariant.Primary}
                    loading={formikProps.isSubmitting}
                    onClick={formikProps.handleSubmit}
                    label="Wyślij"
                  />
                </Group>
              </>
            );
          }}
        </Formik>
      </div>
    );
  }

  return <div></div>;
};
