import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services/HttpApi';

export const OrderDriversModel = SimpleRestModel({
  collectionName: 'orderDrivers',
  apiPath: 'orders/availiableDriversList',
  HttpApi,
});

export interface OrderDrivers {
  id: number;
  name: string;
  orders: Array<{
    id: string;
    arrivalDestination: string;
    arrivalAt: string;
  }>;
  isBusy: boolean;
}
