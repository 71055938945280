import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

export interface CuisineType {
  id: number;
  namePl: string;
  nameEn: string;
}

export const RestaurantCuisineTypeModel = SimpleRestModel({
  collectionName: 'restaurantsCuisineType',
  apiPath: 'restaurants/cuisine-types',
  HttpApi,
});
