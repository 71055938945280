import { Button, ButtonSize, DimmerLoading, EmptyState, styled } from 'lib/ui';
import React, { FunctionComponent } from 'react';
import ReactSortable from 'react-sortablejs';
import { getUrlForMediaImage } from 'services/utils/getUrlForMediaImage';

type Id = number | string;

interface Image {
  id: Id;
  url: string;
}

export interface ImagesListProps {
  images: Image[];
  onOrderChange?: (order: Id[]) => void;
  onDelete?: (image: Image) => void;
  loading?: boolean;
}

const ImagesListContainer = styled.div.attrs({
  className: 'ImagesListContainer',
})`
  position: relative;
  margin-top: ${p => p.theme.space};
`;

const ImgContainer = styled.div.attrs({ className: 'ImgContainer' })`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const ImgStyled = styled.img.attrs({ className: 'ImgStyled' })`
  max-width: 300px;
  max-height: 70px;
  padding: ${p => p.theme.spaceSmallX};
  margin: ${p => p.theme.spaceSmallX};
`;

export const ImagesList: FunctionComponent<ImagesListProps> = props => {
  return (
    <ImagesListContainer>
      <ReactSortable
        onChange={(order, sortable, evt) => {
          if (props.onOrderChange) {
            props.onOrderChange(order);
          }
        }}
      >
        {props.images.map(image => (
          <div data-id={image.id} key={image.id}>
            <ImgContainer>
              <ImgStyled src={getUrlForMediaImage(image.url)} alt="" />
              <Button
                size={ButtonSize.Small}
                iconName={'trash'}
                label={'Usuń'}
                onClick={() => {
                  if (props.onDelete) {
                    props.onDelete(image);
                  }
                }}
              />
            </ImgContainer>
          </div>
        ))}
      </ReactSortable>
      <DimmerLoading visible={!!props.loading} />

      {!props.images.length && <EmptyState simple message={'Brak zdjęć'} />}
    </ImagesListContainer>
  );
};
