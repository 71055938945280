import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { Formik } from 'formik';

import { Button, ButtonVariant, Divider, DividerTextOrientation, Group } from 'lib/ui';
import { path } from 'rambdax';
import { ButtonSubmit, FormField, FormError } from 'lib/forms';
import { Events } from 'lib/services';

import { AuthBasePage } from '../AuthBasePage';

const enhance = connect(
  state => ({
    auth: state.AuthModel,
    loading: state.loading.effects.AuthModel.register,
  }),
  state => ({ AuthModel: { register, getUserInfo, clearError } }) => ({
    register,
    getUserInfo,
    clearError,
  }),
);

const RegisterPage = enhance(props => {
  const redirectToLogin = useRef();

  useLifecycles(
    () => {
      Events.on('AuthModel.register.success', () => {
        redirectToLogin.current = true;
        console.warn('AuthModel.register.success');
      });
    },
    () => {
      Events.off('AuthModel.register.success', true);
      props.clearError();
    },
  );

  useEffect(() => {
    if (props.auth.token) {
      props.getUserInfo();
    }
  }, [props.auth.token]);

  const { loading } = props;

  if (redirectToLogin.current) {
    // @todo - display some message
    return <Redirect to={'/login'} />;
  }

  const isLoggedIn = path('auth.isLoggedIn', props);
  if (isLoggedIn) {
    return <Redirect to={props.auth.nextUrl ? props.auth.nextUrl : '/'} />;
  }

  return (
    <AuthBasePage title="Rejestracja">
      <Formik
        initialValues={{
          email: '',
          username: '',
          password: '',
        }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Enter your e-mail';
          }
          if (!values.username) {
            errors.username = 'Enter your username';
          }
          if (!values.password) {
            errors.password = 'Enter password';
          }
          return errors;
        }}
        onSubmit={(values, bag) => {
          props.register(values).then(() => {
            bag.setSubmitting(false);
          });
        }}
      >
        {formikProps => (
          <div>
            {props.auth.error && <FormError errors={props.auth.error} />}
            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <Group>
                  <FormField name="email" placeholder="" label="E-mail address" {...formikProps} />
                </Group>
                <Group>
                  <FormField name="username" placeholder="" label="Username" {...formikProps} />
                </Group>
                <Group>
                  <FormField name="password" placeholder="" label="Password" type="password" {...formikProps} />
                </Group>
              </Group>
              <div className={'mt-4'}>
                <ButtonSubmit
                  disabled={formikProps.isSubmitting || !formikProps.isValid || loading}
                  loading={loading || formikProps.isSubmitting}
                  label="Register"
                  onClick={formikProps.handleSubmit}
                  iconName="sign-in"
                />
              </div>
            </form>
          </div>
        )}
      </Formik>
      <Divider text="already have an account?" textOrientation={DividerTextOrientation.Center} />
      <Group align="center">
        <Button variant={ButtonVariant.Plain}>
          <Link to="/login">Login</Link>
        </Button>
      </Group>
    </AuthBasePage>
  );
});

export { RegisterPage };
