import { message } from 'antd';
import { ImagesList } from 'components';
import { Events } from 'lib/services';

import {
  Button,
  ButtonSize,
  ButtonUpload,
  DimmerLoading,
  Divider,
  EmptyState,
  Group,
  IconFa,
  Loading,
  modalConfirm,
  SimpleInputDialog,
} from 'lib/ui';
import React, { FunctionComponent, useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import ReactSortable from 'react-sortablejs';
import useToggle from 'react-use/lib/useToggle';
import { compose } from 'recompose';
import { fileUpload } from 'services';
import { getUrlForMediaImage } from 'services/utils/getUrlForMediaImage';

export interface RestaurantFormAssetsProps {
  restaurant?: any;
  onModalToggle: Function;
}

const RestaurantFormAssetsDefaultProps = {};

const enhance = compose(
  connect(
    ({ restaurantsMovies, restaurantsImages, loading }) => ({
      restaurantsMoviesStore: restaurantsMovies,
      restaurantsImagesStore: restaurantsImages,
      loading,
    }),
    ({ restaurantsMovies, restaurantsImages }) => ({
      restaurantsMovies,
      restaurantsImages,
    }),
  ),
);
const RestaurantFormAssets: FunctionComponent<RestaurantFormAssetsProps> = enhance(props => {
  const { restaurant } = props;

  useEffect(() => {
    if (restaurant) {
      props.restaurantsMovies.loadCollection({
        restaurantId: restaurant.id,
      });
      props.restaurantsImages.loadCollection({
        restaurantId: restaurant.id,
      });
    }
    return () => {
      props.restaurantsMovies.reset();
      props.restaurantsImages.reset();
    };
  }, [restaurant]);

  //  movies
  const [movieModalOpened, toggleMovieModal] = useToggle(false);
  const [movieSaving, toggleMovieSaving] = useToggle(false);
  useEffect(() => {
    Events.on('Model.restaurantsMovies.createItem.success', () => {
      message.success('Film został dodany');
      toggleMovieModal(false);
      toggleMovieSaving(false);
    });
    return () => {
      Events.off('Model.restaurantsMovies.createItem.success', true);
    };
  }, []);
  useEffect(() => {
    if (props.restaurantsMoviesStore.errors) {
      toggleMovieSaving(false);
    }
  }, [props.restaurantsMoviesStore.errors]);
  useEffect(() => {
    if (!movieModalOpened) {
      props.restaurantsMovies.clearError();
    }
  }, [movieModalOpened]);
  const movies = props.restaurantsMoviesStore.data;

  //  images
  const [imagesSaving, toggleImagesSaving] = useToggle(false);
  const [uploadingImage, toggleUploadingImage] = useToggle(false);
  const images = props.restaurantsImagesStore.data;

  return (
    <>
      <Divider text="Zdjęcia" />

      <Group>
        <ButtonUpload
          label="Dodaj zdjęcie"
          iconName="camera-alt"
          loading={uploadingImage}
          onChange={file => {
            if (file) {
              toggleUploadingImage();
              fileUpload(file, {
                path: `/restaurants/${restaurant.id}/images/`,
              })
                .then(() => {
                  message.success('Zdjęcie zostało dodane');
                })
                .catch(() => {
                  message.error('Wystąpił błąd');
                })
                .finally(() => {
                  toggleUploadingImage();
                  props.restaurantsImages.loadCollection({
                    restaurantId: restaurant.id,
                  });
                });
            }
          }}
        />
      </Group>

      <ImagesList
        images={images.map(image => ({ ...image, url: image.image }))}
        loading={imagesSaving || props.loading.effects.restaurantsImages.loadCollection}
        onDelete={image => {
          modalConfirm({
            title: 'Czy na pewno chcesz usunąć wybrane zdjęcie?',
            content: 'Operacja jest nieodwracalna',
            okText: 'Tak, usuń',
            yesCallback: () => {
              toggleImagesSaving(true);
              props.restaurantsImages
                .deleteItem({
                  restaurantId: props.restaurant.id,
                  id: image.id,
                })
                .then(() => {
                  message.success('Zdjęcie zostało usunięte');
                })
                .catch(() => {
                  message.success('Wystąpił nieoczekiwany błąd');
                })
                .finally(() => {
                  toggleImagesSaving(false);
                });
            },
          });
        }}
        onOrderChange={order => {
          toggleImagesSaving(true);
          props.restaurantsImages
            .customCollectionAction({
              action: 'update_position',
              restaurantId: props.restaurant.id,
              data: {
                imagesList: order.map((id, index) => ({
                  id: id,
                  position: index,
                })),
              },
            })
            .then(() => {
              props.restaurantsImages
                .loadCollection({
                  restaurantId: restaurant.id,
                })
                .finally(() => {
                  toggleImagesSaving(false);
                });
            });
        }}
      />

      <Divider text="Filmy" />

      <Group>
        <Button
          label="Dodaj film"
          iconName="youtube"
          iconFa={IconFa.Fab}
          onClick={() => {
            toggleMovieModal(true);
          }}
        />
      </Group>

      {!props.restaurantsMoviesStore.data.length && !props.loading.effects.restaurantsMovies.loadCollection && (
        <EmptyState message={'Brak filmów'} simple />
      )}

      {!props.restaurantsMoviesStore.data.length && props.loading.effects.restaurantsMovies.loadCollection && (
        <Loading text="Ładowanie filmów" />
      )}

      <div style={{ position: 'relative' }}>
        <DimmerLoading visible={movieSaving} />
        <ReactSortable
          tag="div"
          onChange={(order, sortable, evt) => {
            toggleMovieSaving(true);
            props.restaurantsMovies
              .customCollectionAction({
                restaurantId: props.restaurant.id,
                action: 'update_position',
                data: {
                  moviesList: order.map((id, index) => ({
                    id: id,
                    position: index,
                  })),
                },
              })
              .then(() => {
                props.restaurantsMovies
                  .loadCollection({
                    restaurantId: restaurant.id,
                  })
                  .then(() => {
                    toggleMovieSaving(false);
                  });
              });
          }}
        >
          {movies.map(movie => (
            <div data-id={movie.id} key={movie.id} style={{ padding: '6px 0' }}>
              <Group align="space-between">
                <a href={getUrlForMediaImage(movie.movieUrl)} target="_blank">
                  {movie.movieUrl}
                </a>
                <Button
                  size={ButtonSize.Small}
                  iconName={'trash'}
                  label={'Usuń'}
                  onClick={() => {
                    modalConfirm({
                      yesCallback: () => {
                        props.restaurantsMovies.deleteItem({
                          id: movie.id,
                          restaurantId: restaurant.id,
                        });
                      },
                    });
                  }}
                />
              </Group>
            </div>
          ))}
        </ReactSortable>
      </div>

      <SimpleInputDialog
        open={movieModalOpened}
        loading={movieSaving}
        errors={props.restaurantsMoviesStore.errors}
        title="Dodawanie nowego filmu"
        inputLabel="Adres URL"
        okText="Dodaj"
        onSave={data => {
          const url = data.values.input;
          toggleMovieSaving(true);
          props.restaurantsMovies.createItem({
            data: {
              movieUrl: url,
            },
            restaurantId: props.restaurant.id,
          });
        }}
        onCancel={() => {
          toggleMovieModal(false);
        }}
      />
    </>
  );
});

RestaurantFormAssets.defaultProps = RestaurantFormAssetsDefaultProps;

export { RestaurantFormAssets };
