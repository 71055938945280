import { Select } from 'antd';
import { Formik, FormikActions } from 'formik';
import {
  ButtonSubmit,
  FormError,
  FormField,
  FormFieldContainer,
  FormFieldInputNumber,
  FormFieldLabel,
  FormSwitchField,
  FormTextareaField,
} from 'lib/forms';
import { ButtonUpload, Group, Modal, ModalProps, Spacer, styled } from 'lib/ui';

import { MEAL_SPICY_LEVELS } from 'modules/Restaurant';
import { path } from 'rambdax';
import React, { FunctionComponent, ReactNode } from 'react';
import { getUrlForMediaImage } from 'services/utils/getUrlForMediaImage';

const { Option } = Select;

export interface RestaurantMenuMealModalProps extends ModalProps {
  children?: ReactNode;
  item?: any;
  errors?: any;
  mealGroups: any[];
  defaultGroupMeal?: number | string;
  onSubmit: (values: any, bag: FormikActions<any>) => void;
}

const ShortFormFieldContainer = styled.div`
  width: 100px;
`;
const ShortXFormFieldContainer = styled.div`
  width: 50px;
`;
const SwitchGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const RestaurantMenuMealModalDefaultProps = {};

const RestaurantMenuMealModal: FunctionComponent<RestaurantMenuMealModalProps> = ({
  item,
  mealGroups,
  onSubmit,
  errors,
  defaultGroupMeal,
  ...rest
}) => {
  const selectedSpicyLevel = MEAL_SPICY_LEVELS.find(spicyLevel => spicyLevel.value === item.spicyLevel);
  let selectedGroup;
  if (item.group) {
    selectedGroup = {
      key: `${item.group.id}`,
      label: `${item.group.namePl}`,
    };
  } else {
    if (defaultGroupMeal) {
      const group = mealGroups.find(group => group.id === defaultGroupMeal);
      selectedGroup = {
        key: `${group.id}`,
        label: `${group.namePl}`,
      };
    } else {
      selectedGroup = {
        key: `${mealGroups[0].id}`,
        label: `${mealGroups[0].namePl}`,
      };
    }
  }

  return (
    <Modal {...rest} visible>
      <Formik
        initialValues={{
          group: selectedGroup,
          namePl: item.namePl || '',
          nameEn: item.nameEn || '',
          descriptionPl: item.descriptionPl || '',
          descriptionEn: item.descriptionEn || '',
          price: item.price / 100 || 0,
          index: item.index || '',
          oldId: item.oldId || '',
          note: item.note || '',
          spicyLevel: selectedSpicyLevel
            ? {
                key: `${selectedSpicyLevel.value}`,
                label: selectedSpicyLevel.label,
              }
            : {
                key: `${MEAL_SPICY_LEVELS[0].value}`,
                label: MEAL_SPICY_LEVELS[0].label,
              },
          isActive: item.isActive || false,
          isVegetarian: item.isVegetarian || false,
          isVegan: item.isVegan || false,
          isBeverage: item.isBeverage || false,
          isAlcohol: item.isAlcohol || false,
          isGlutenFree: item.isGlutenFree || false,
          isNew: item.isNew || false,
          isLunch: item.isLunch || false,
          image: undefined,
        }}
        // validationSchema={Yup.object().shape({
        //   index: stringRequired(),
        //   namePl: stringRequired(),
        //   nameEn: stringRequired(),
        //   descriptionPl: stringRequired(),
        //   descriptionEn: stringRequired(),
        // })}
        onSubmit={(values, bag) => {
          onSubmit(
            {
              ...values,
              price: values.price * 100,
              // group: parseInt(values.group.key, 10),
              group: values.group.key,
              spicyLevel: values.spicyLevel.key,
            },
            bag,
          );
        }}
      >
        {formikProps => {
          const selectedFileName = path('values.image.name', formikProps);
          return (
            <>
              {errors && <FormError errors={errors} />}
              <form onSubmit={formikProps.handleSubmit}>
                <Group vertical>
                  <Group>
                    <FormFieldContainer width="100%">
                      <FormFieldLabel>Dział:</FormFieldLabel>
                      <Select
                        labelInValue
                        value={formikProps.values.group}
                        onChange={value => {
                          formikProps.setFieldValue('group', value);
                        }}
                      >
                        {mealGroups.map(item => {
                          return (
                            <Option key={item.id} title={item.namePl}>
                              {item.namePl} / {item.nameEn}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormFieldContainer>
                  </Group>
                  <Group>
                    <FormField name="namePl" label="Nazwa PL" {...formikProps} />
                    <FormField name="nameEn" label="Nazwa EN" {...formikProps} />
                  </Group>

                  <Group>
                    <FormTextareaField name="descriptionPl" label="Opis PL" {...formikProps} />
                    <FormTextareaField name="descriptionEn" label="Opis EN" {...formikProps} />
                  </Group>
                  <Group>
                    <ShortFormFieldContainer>
                      <FormFieldInputNumber name="price" label="Cena" {...formikProps} />
                    </ShortFormFieldContainer>
                    <ShortXFormFieldContainer>
                      <FormField name="index" label="Index" {...formikProps} />
                    </ShortXFormFieldContainer>
                    <ShortXFormFieldContainer>
                      <FormField name="oldId" label="St. ID" {...formikProps} />
                    </ShortXFormFieldContainer>
                  </Group>
                  <Group>
                    <FormFieldContainer width="100%">
                      <FormFieldLabel>Poziom ostrości:</FormFieldLabel>
                      <Select
                        labelInValue
                        value={formikProps.values.spicyLevel}
                        onChange={value => {
                          formikProps.setFieldValue('spicyLevel', value);
                        }}
                      >
                        {MEAL_SPICY_LEVELS.map(spicyLevel => (
                          <Option key={spicyLevel.value} title={spicyLevel.label}>
                            {spicyLevel.label}
                          </Option>
                        ))}
                      </Select>
                    </FormFieldContainer>
                  </Group>
                  <Group>
                    <SwitchGridContainer>
                      <FormSwitchField name="isActive" label="Aktywna" {...formikProps} />
                      <FormSwitchField name="isNew" label="Nowość" {...formikProps} />
                      <FormSwitchField name="isLunch" label="Lunch" {...formikProps} />
                      <FormSwitchField name="isVegetarian" label="Wegetariańska" {...formikProps} />
                      <FormSwitchField name="isVegan" label="Wegańska" {...formikProps} />
                      <FormSwitchField name="isGlutenFree" label="Bez glutenu" {...formikProps} />
                      <FormSwitchField name="isBeverage" label="Napój" {...formikProps} />
                      <FormSwitchField name="isAlcohol" label="Alkohol (18+)" {...formikProps} />
                    </SwitchGridContainer>
                    <FormTextareaField name="note" label="Uwagi" {...formikProps} />
                  </Group>

                  <Spacer />

                  <Group>
                    <ButtonUpload
                      label={`${item.image ? 'Zmień zdjęcie' : 'Dodaj zdjęcie'}: ${selectedFileName || ''}`}
                      onChange={file => {
                        formikProps.setFieldValue('image', file);
                      }}
                    />
                    {item.image && (
                      <img
                        src={getUrlForMediaImage(item.image)}
                        style={{
                          maxWidth: '100%',
                          maxHeight: '80px',
                        }}
                        alt="Zdjęcie posiłku"
                      />
                    )}
                  </Group>
                </Group>
                <div className={'mt-4'}>
                  <ButtonSubmit
                    disabled={formikProps.isSubmitting}
                    loading={formikProps.isSubmitting}
                    label="Zapisz"
                    onClick={() => {
                      formikProps.handleSubmit();
                    }}
                    iconName="save"
                  />
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

RestaurantMenuMealModal.defaultProps = RestaurantMenuMealModalDefaultProps;

export { RestaurantMenuMealModal };
