import { Checkbox, Switch, Typography } from 'antd';
import { ColorRed, MealIcons, WhiteSpaceNoWrap } from 'components';
import { isBefore } from 'date-fns';

import {
  Button,
  ButtonSize,
  DateTimeRange,
  Divider,
  EmptyState,
  Group,
  Price,
  Spacer,
  styled,
} from 'lib/ui';
import React, { FunctionComponent, ReactNode } from 'react';

export interface MealGroupsProps {
  children?: ReactNode;
  items: any[];
  onEdit?: Function;
  onDelete?: Function;
  onExtrasClick?: Function;
  onStatusChangeClick?: Function;
  onToggleSelect?: Function;
  onActionSort?: Function;
  selected?: any[];
}

const MealGroupsDefaultProps = {
  items: [],
};

const MealsContainer = styled.div.attrs({ className: 'MealsContainer' })`
  margin: ${p => p.theme.spaceSmall} 0;
`;
const MealContainer = styled.div.attrs({ className: 'MealContainer' })`
  margin: ${p => p.theme.spaceSmall} 0 ${p => p.theme.spaceSmall}
    ${p => p.theme.space};
`;

const MealMetaContainer = styled.div.attrs({ className: 'MealMetaContainer' })`
  display: flex;
  align-items: flex-start;
`;

const MealMeta = styled.div.attrs({ className: 'MealMeta' })`
  flex-grow: 1;
  display: flex;
  align-items: center;
  > * {
    margin-right: ${p => p.theme.spaceSmallX};
  }
`;

const GroupShort = styled.div.attrs({ className: 'Group150' })`
  width: 300px;
`;

const Meals: FunctionComponent<MealGroupsProps> = ({
  items,
  onEdit,
  onDelete,
  onExtrasClick,
  onStatusChangeClick,
  onToggleSelect,
  onActionSort,
  selected,
}) => {
  return (
    <MealsContainer>
      {!items.length && (
        <EmptyState message={'Brak posiłków w grupie'} simple inline />
      )}
      {items.map((item, index) => {
        return (
          <MealContainer key={item.id}>
            {index < items.length && <Divider />}
            <MealMetaContainer>
              <MealMeta>
                <span>
                  <Checkbox
                    checked={
                      !!(selected && selected.find(meal => item.id === meal.id))
                    }
                    onChange={e => {
                      if (onToggleSelect) {
                        onToggleSelect(item);
                      }
                    }}
                  />
                </span>

                <WhiteSpaceNoWrap>
                  <Typography.Text code>{item.index}</Typography.Text>
                </WhiteSpaceNoWrap>
                <Typography.Text strong>{item.namePl}</Typography.Text>
              </MealMeta>

              <GroupShort>
                <Group vertical>
                  <Group align="right">
                    <MealIcons meal={item} />
                    <Typography.Text strong>
                      <Price amount={item.price} />
                    </Typography.Text>
                    <Button
                      label={`Dodatki ${
                        item.extras.length > 0
                          ? `(${item.requiredExtras}|${item.extras.length})`
                          : ''
                      }`}
                      size={ButtonSize.Small}
                      onClick={() => {
                        if (onExtrasClick) {
                          onExtrasClick(item);
                        }
                      }}
                    />
                  </Group>
                  <Group align="right">
                    <Switch
                      checked={item.isActive}
                      size="small"
                      onClick={() => {
                        if (onStatusChangeClick) {
                          onStatusChangeClick(item);
                        }
                      }}
                    />
                    <Button
                      label="Edytuj"
                      size={ButtonSize.Small}
                      onClick={() => {
                        if (onEdit) {
                          onEdit(item);
                        }
                      }}
                    />
                    <Button
                      iconName="sort"
                      size={ButtonSize.Small}
                      onClick={() => {
                        if (onActionSort) {
                          onActionSort(item);
                        }
                      }}
                    />
                    <Button
                      iconName="trash-alt"
                      size={ButtonSize.Small}
                      onClick={() => {
                        if (onDelete) {
                          onDelete(item);
                        }
                      }}
                    />
                  </Group>
                </Group>
              </GroupShort>
            </MealMetaContainer>

            <Spacer />
            <Group verticalAlign="top">
              <span className="text-pre-line">
                <Typography.Text>{item.descriptionPl}</Typography.Text>
              </span>
              <span className="text-pre-line">
                <Typography.Text type="secondary">
                  {item.descriptionEn}
                </Typography.Text>
              </span>
            </Group>
            {item.note && item.note !== "None" && (
              <>
              <Spacer size="smallX" horizontal />
              <p style={{color: 'green'}}>{item.note}</p>
              </>
            )}

            {item.disableFrom && !isBefore(item.disableTo, new Date()) && (
              <>
                <Spacer />
                <ColorRed>
                  Nieaktywny:{' '}
                  <DateTimeRange
                    start={item.disableFrom}
                    end={item.disableTo}
                  />
                </ColorRed>
              </>
            )}
          </MealContainer>
        );
      })}
    </MealsContainer>
  );
};

Meals.defaultProps = MealGroupsDefaultProps;

export { Meals };
