import { message, Select } from 'antd';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { Divider, Drawer, DrawerProps, Group, Spacer } from 'lib/ui';
import {
  ButtonSubmit,
  FormError,
  FormField,
  FormFieldContainer,
  FormFieldInputNumber,
  FormFieldLabel,
  FormSwitchField,
  FormTextareaField,
} from 'lib/forms';
import { weekdays } from 'lib/ui/utils';

const enhance = connect(
  ({ drivers, loading }) => ({
    driversStore: drivers,
    loading,
  }),
  ({ drivers }) => ({
    drivers,
  }),
);

export interface EmployeeFormDrawerProps extends DrawerProps {
  item?: any;
}

const DriverFormDrawer: FunctionComponent<EmployeeFormDrawerProps> = enhance(
  ({ item, ...props }) => {
    const itemName = item && (item.displayedName || item.name);
    const closeDrawer = () => {
      props.drivers.clearError();
      props.onClose();
    };

    const getInitialValues = () => {
      const v = {
        // name: item ? `${item.user.firstName} ${item.user.lastName}` : '',
        firstName: item ? item.user.firstName : '',
        lastName: item ? item.user.lastName : '',
        login: item ? item.user.email : '',
        password: '',
        notes: item ? item.notes : '',
        phone1: item ? item.phone1 : '',
        phone2: item ? item.phone2 : '',
        phone3: item ? item.phone3 : '',
        hasTerminal: item ? item.hasTerminal : false,
        isActive: item ? item.isActive : false,
        isDeleted: item ? item.isDeleted : false,
        isMainPhone1: item ? item.isMainPhone1 : true,
        isMainPhone2: item ? item.isMainPhone2 : false,
        isMainPhone3: item ? item.isMainPhone3 : false,
        isPaidDay: item ? item.isPaidDay : false,
        isPaidDelivery: item ? item.isPaidDelivery : false,
        deliveryRate: item ? item.deliveryRate / 100 : 0,
        isPaidDeliveryAll: item ? item.isPaidDeliveryAll : false,
        isPaidDistance: item ? item.isPaidDistance : false,
        distanceRate: item ? item.distanceRate / 100 : 0,
        isPaidRbh: item ? item.isPaidRbh : false,
        hourRate: item ? item.hourRate / 100 : 0,
      };
      weekdays.forEach(day => {
        const rateIndex = `dayRate${day.shortnameEn}`;
        v[rateIndex] = item && item[rateIndex] ? item[rateIndex] / 100 : 12;
      });
      return v;
    };

    return (
      <Drawer
        displayCloseButton
        closeOnEsc
        onClose={closeDrawer}
        visible={props.visible}
        title={`${itemName || 'Tworzenie nowego kierowcy'}`}
        destroyOnClose
      >
        <Formik
          initialValues={getInitialValues()}
          onSubmit={(values: any, bag) => {
            const payload = {
              ...values,
              dayRateFri: values.dayRateFri * 100,
              dayRateMon: values.dayRateMon * 100,
              dayRateSat: values.dayRateSat * 100,
              dayRateSun: values.dayRateSun * 100,
              dayRateThu: values.dayRateThu * 100,
              dayRateTue: values.dayRateTue * 100,
              dayRateWed: values.dayRateWed * 100,
              hourRate: values.hourRate * 100,
              deliveryRate: values.deliveryRate * 100,
              distanceRate: values.distanceRate * 100,
              username: values.login,
            };

            if (item && item.id) {
              props.drivers
                .updateItem({
                  data: payload,
                  id: item.id,
                })
                .then(() => {
                  bag.setSubmitting(false);
                  message.success('Zmiany zostały zapisane');
                  // props.drivers.loadItem({ id: item.id });
                })
                .catch(() => {
                  bag.setSubmitting(false);
                });
            } else {
              props.drivers
                .createItem({ data: payload })
                .then(() => {
                  bag.setSubmitting(false);
                  message.success('Kierowca został dodany');
                  closeDrawer();
                  return props.drivers.loadCollection();
                })
                .catch(() => {
                  bag.setSubmitting(false);
                });
            }
          }}
          enableReinitialize
        >
          {formikProps => {
            const FormSubmitButton = (
              <ButtonSubmit
                disabled={!formikProps.dirty || formikProps.isSubmitting}
                loading={formikProps.isSubmitting}
                label="Zapisz"
                onClick={formikProps.handleSubmit}
                iconName="save"
              />
            );
            return (
              <>
                {props.driversStore.errors && (
                  <FormError errors={props.driversStore.errors} />
                )}
                <form onSubmit={formikProps.handleSubmit}>
                  <Group vertical>
                    <Group align="space-between">
                      <FormField
                        name="firstName"
                        label="Imię"
                        {...formikProps}
                      />
                      {FormSubmitButton}
                    </Group>

                    <Group align="space-between">
                      <FormField
                        name="lastName"
                        label="Nazwisko"
                        {...formikProps}
                      />
                      <FormSwitchField
                        name="hasTerminal"
                        label="Ma terminal"
                        {...formikProps}
                      />
                    </Group>

                    <FormField name="login" label="Login" {...formikProps} />
                    <FormField
                      name="password"
                      label="Hasło"
                      {...formikProps}
                      type="password"
                    />

                    <Group align="space-between">
                      <Group vertical>
                        <FormField
                          name="phone1"
                          label="Telefon 1"
                          width="120px"
                          {...formikProps}
                        />
                        <FormSwitchField
                          name="isMainPhone1"
                          label="Główny"
                          {...formikProps}
                          onChange={checked => {
                            if (checked) {
                              formikProps.setFieldValue('isMainPhone2', false);
                              formikProps.setFieldValue('isMainPhone3', false);
                            }
                          }}
                        />
                      </Group>
                      <Group vertical>
                        <FormField
                          name="phone2"
                          label="Telefon 2"
                          width="120px"
                          {...formikProps}
                        />
                        <FormSwitchField
                          name="isMainPhone2"
                          label="Główny"
                          {...formikProps}
                          onChange={checked => {
                            if (checked) {
                              formikProps.setFieldValue('isMainPhone1', false);
                              formikProps.setFieldValue('isMainPhone3', false);
                            }
                          }}
                        />
                      </Group>
                      <Group vertical>
                        <FormField
                          name="phone3"
                          label="Telefon 3"
                          {...formikProps}
                          width="120px"
                        />
                        <FormSwitchField
                          name="isMainPhone3"
                          label="Główny"
                          {...formikProps}
                          onChange={checked => {
                            if (checked) {
                              formikProps.setFieldValue('isMainPhone1', false);
                              formikProps.setFieldValue('isMainPhone2', false);
                            }
                          }}
                        />
                      </Group>
                    </Group>

                    <FormTextareaField
                      name="notes"
                      label="Uwagi"
                      {...formikProps}
                    />

                    <Divider text="Typ rozliczeń" />

                    <Group align="space-between" verticalAlign="top">
                      <Group vertical>
                        <FormSwitchField
                          name="isPaidRbh"
                          label="Godzina"
                          {...formikProps}
                        />
                        {formikProps.values.isPaidRbh && (
                          <FormFieldInputNumber
                            name="hourRate"
                            {...formikProps}
                          />
                        )}
                      </Group>
                      <Group vertical>
                        <FormSwitchField
                          name="isPaidDistance"
                          label="Kilometr"
                          {...formikProps}
                        />
                        {formikProps.values.isPaidDistance && (
                          <FormFieldInputNumber
                            name="distanceRate"
                            {...formikProps}
                          />
                        )}
                      </Group>

                      <Group vertical>
                        <FormSwitchField
                          name="isPaidDay"
                          label="Dniówki"
                          {...formikProps}
                        />
                      </Group>

                      <Group vertical>
                        <FormSwitchField
                          name="isPaidDelivery"
                          label="Dostawa"
                          {...formikProps}
                          onChange={checked => {
                            if (checked) {
                              formikProps.setFieldValue(
                                'isPaidDeliveryAll',
                                false,
                              );
                            }
                          }}
                        />
                        {formikProps.values.isPaidDelivery && (
                          <FormFieldInputNumber
                            name="deliveryRate"
                            {...formikProps}
                          />
                        )}
                      </Group>

                      <Group vertical>
                        <FormSwitchField
                          name="isPaidDeliveryAll"
                          label="Dostawa całość"
                          {...formikProps}
                          onChange={checked => {
                            if (checked) {
                              formikProps.setFieldValue(
                                'isPaidDelivery',
                                false,
                              );
                            }
                          }}
                        />
                      </Group>
                    </Group>

                    {formikProps.values.isPaidDay && (
                      <>
                        <Divider text="Dniówki" />

                        <Group align="space-between">
                          {weekdays.map(day => (
                            <Group vertical width="90px" key={day.shortnameEn}>
                              <FormFieldLabel>{day.shortnamePl}</FormFieldLabel>
                              <FormFieldInputNumber
                                name={`dayRate${day.shortnameEn}`}
                                {...formikProps}
                              />
                            </Group>
                          ))}
                        </Group>
                      </>
                    )}

                    <FormSwitchField
                      name="isActive"
                      label="Aktywny"
                      {...formikProps}
                    />
                  </Group>

                  <Spacer />
                  {FormSubmitButton}
                </form>
              </>
            );
          }}
        </Formik>
      </Drawer>
    );
  },
);

export { DriverFormDrawer };
