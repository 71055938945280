import React, { FunctionComponent } from 'react';

import { Icon, styled, Tooltip } from 'lib/ui';

import alcoholIcon from 'assets/icons/alcohol.svg';
import beverageIcon from 'assets/icons/beverage.svg';
import glutenFreeIcon from 'assets/icons/glutenFree.svg';
import lunchIcon from 'assets/icons/lunch.svg';
import newIcon from 'assets/icons/new.svg';
import spicyLevel0Icon from 'assets/icons/spicyLevel0.svg';
import spicyLevel10Icon from 'assets/icons/spicyLevel10.svg';
import spicyLevel20Icon from 'assets/icons/spicyLevel20.svg';
import veganIcon from 'assets/icons/vegan.svg';
import vegetarianIcon from 'assets/icons/vegetarian.svg';

export interface MealIconsProps {
  meal: any; // TODO - add type
}

const MealIconsContainer = styled.div.attrs({
  className: 'MealIconsContainer',
})`
  display: flex;
  align-items: center;
  height: 1rem;

  img {
    width: auto;
    height: 100%;
    margin-right: 0.25rem;
  }
`;

export const MealIcons: FunctionComponent<MealIconsProps> = props => {
  // console.log('MealIcons props', props);
  return (
    <MealIconsContainer>
      {props.meal.isAlcohol && (
        <Tooltip text="Alkohol">
          <img alt="" src={alcoholIcon} />
        </Tooltip>
      )}
      {props.meal.isBeverage && (
        <Tooltip text="Napój">
          <img alt="" src={beverageIcon} />
        </Tooltip>
      )}
      {props.meal.isGlutenFree && (
        <Tooltip text="Bezglutenowe">
          <img alt="" src={glutenFreeIcon} />
        </Tooltip>
      )}
      {props.meal.isLunch && (
        <Tooltip text="Lunch">
          <img alt="" src={lunchIcon} />
        </Tooltip>
      )}
      {props.meal.isNew && (
        <Tooltip text="Nowość">
          <img alt="" src={newIcon} />
        </Tooltip>
      )}
      {props.meal.isVegan && (
        <Tooltip text="Wegańskie">
          <img alt="" src={veganIcon} />
        </Tooltip>
      )}
      {props.meal.isVegetarian && (
        <Tooltip text="Wegetariańskie">
          <img alt="" src={vegetarianIcon} />
        </Tooltip>
      )}
      {/*{props.meal.spicyLevel === 0 && (*/}
      {/*  <Tooltip text="Łagodne">*/}
      {/*    <img alt="" src={spicyLevel0Icon} />*/}
      {/*  </Tooltip>*/}
      {/*)}*/}
      {props.meal.spicyLevel === 10 && (
        <Tooltip text="Średnie">
          <img alt="" src={spicyLevel0Icon} />
        </Tooltip>
      )}
      {props.meal.spicyLevel === 20 && (
        <Tooltip text="Ostre">
          <img alt="" src={spicyLevel10Icon} style={{ width: '1.5rem' }} />
        </Tooltip>
      )}
      {props.meal.spicyLevel === 30 && (
        <Tooltip text="Ostre">
          <img alt="" src={spicyLevel20Icon} style={{ width: '2rem' }} />
        </Tooltip>
      )}
      {props.meal.image && <Icon name="image" />}
    </MealIconsContainer>
  );
};
