import { styled } from 'lib/ui';

export const ColorGreen = styled.div.attrs({ className: 'ColorGreen' })`
  color: #72ac03;
`;
export const ColorRed = styled.div.attrs({ className: 'ColorRed' })`
  color: #cf1e2f;
`;

export const WithMarginSmall = styled.div.attrs({
  className: 'WithMarginSmall',
})`
  margin: ${p => p.theme.spaceSmall} 0;
`;

export const WhiteSpaceNoWrap = styled.div.attrs({
  className: 'WhiteSpaceNoWrap',
})`
  white-space: nowrap;
`;

export const AffixContent = styled.div.attrs({ className: 'AffixContent' })`
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  padding: ${p => p.theme.space};
`;

export const loadingIconString = `<i class="fal fa-spin fa-spinner-third"></i>`;
export const editIconString = `<i class="fal fa-edit"></i>`;

export const AbsoluteTopContainer = styled.div.attrs({ className: 'LoadingSpinnerAboveView' })`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
`;
