import React, { FunctionComponent, ReactNode } from 'react';
import { Hint, Spacer } from '../ui';
import { styled } from '../ui/styled-components';

export interface FormFieldLabelProps {
  children?: ReactNode;
  hint?: ReactNode;
  small?: boolean;
}

const FormFieldLabelContainer = styled.div.attrs({
  className: 'FormFieldLabelContainer',
})<{ small: boolean }>`
  font-size: ${p => (p.small ? p.theme.fontSizeSmallX : p.theme.fontSizeSmall)};
  margin-bottom: ${p => p.theme.spaceSmallX};
  color: ${p => p.theme.colorGrey};
  width: 100%;
  display: flex;
  align-items: center;
`;

const FormFieldLabel: FunctionComponent<FormFieldLabelProps> = (props: FormFieldLabelProps) => {
  return (
    <FormFieldLabelContainer small={!!props.small}>
      {props.children}
      {props.hint && (
        <>
          <Spacer size="small" horizontal />
          <Hint>{props.hint}</Hint>
        </>
      )}
    </FormFieldLabelContainer>
  );
};

export { FormFieldLabel };
