import React, { FunctionComponent } from 'react';
import { FormikProps, FormikValues } from 'formik';
import { InputNumber } from 'antd';
import { path } from 'rambdax';

import { FieldError } from '../FieldError';
import { FormFieldLabel } from '../FormFieldLabel';
import { FormFieldContainer } from '../FormFieldContainer';

export interface FormFieldInputNumberProps extends FormikProps<FormikValues> {
  name: string;
  label?: string;
  placeholder?: string;
  onEnter?: Function;
  precision?: number;
  decimalSeparator?: string;
}

const FormFieldInputNumber: FunctionComponent<FormFieldInputNumberProps> = props => {
  const isTouched = path(props.name, props.touched);
  const error = isTouched && path(props.name, props.errors);

  return (
    <FormFieldContainer>
      {props.label && <FormFieldLabel>{props.label}</FormFieldLabel>}
      <div>
        <InputNumber
          name={props.name}
          onChange={value => {
            props.setFieldValue(props.name, value);
          }}
          onBlur={_ => {
            props.setFieldTouched(props.name, true);
          }}
          onKeyDown={ev => {
            if (ev.key === 'Enter' && props.onEnter) {
              ev.preventDefault();
              props.onEnter(ev);
            }
          }}
          value={props.values[props.name]}
          precision={props.precision}
        />
      </div>
      {error && <FieldError error={error} />}
    </FormFieldContainer>
  );
};

FormFieldInputNumber.defaultProps = {
  precision: 2,
  decimalSeparator: ',',
};

export { FormFieldInputNumber };
