import { message, Popconfirm } from 'antd';
import { Input } from 'lib/forms';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Divider,
  Group,
  Modal,
  ModalProps,
  SimpleInputDialog,
  Spacer,
  styled,
} from 'lib/ui';
import { ClientPhone } from 'modules/Clients';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { useToggle } from 'react-use';
import { useDispatch } from 'react-redux';
import { getErrorMessageFromErrorResponse } from 'services/utils/getErrorMessageFromErrorResponse';

export interface PhonePickerModalProps extends ModalProps {
  clientId?: number | string;
  onSelect?: (phone: string) => void;
  description?: ReactNode;
}

const PhonePickerModalContainer = styled.div.attrs({
  className: 'PhonePickerModalContainer',
})``;

const enhance = connect(
  ({ loading, clientPhones }) => ({
    loading,
    clientPhonesStore: clientPhones,
  }),
  ({ clients, clientPhones }) => ({
    clientPhones,
  }),
);

export const PhonePickerModal: FunctionComponent<PhonePickerModalProps> = enhance(props => {
  const dispatch = useDispatch();
  const [newPhoneValue, setNewPhoneValue] = useState('');

  const [phoneDialogVisible, togglePhoneDialog] = useToggle(false);
  const [selectedPhone, setSelectedPhone] = useState<ClientPhone | null>(null);

  //  load client phones
  useEffect(() => {
    dispatch({
      type: 'clientPhones/loadCollection',
      payload: {
        customerId: props.clientId,
      },
    });
  }, [props.clientId]);

  return (
    <Modal visible {...props} header={<h2>Telefony klienta</h2>}>
      {props.description && (
        <>
          {props.description}
          <Spacer />
        </>
      )}
      <PhonePickerModalContainer>
        {props.clientPhonesStore.data.map(phone => {
          return (
            <React.Fragment key={phone.id}>
              <Group align="space-between">
                <span>{phone.phone}</span>
                <Group width="auto">
                  <Button
                    size={ButtonSize.Small}
                    variant={ButtonVariant.Primary}
                    label="wybierz"
                    disabled={
                      props.loading.effects.clientPhones.deleteItem || props.loading.effects.clientPhones.uypdateItem
                    }
                    onClick={() => {
                      if (props.onSelect) {
                        props.onSelect(phone.phone);
                      }
                    }}
                  />
                  <Button
                    size={ButtonSize.Small}
                    label="zmień"
                    iconName="edit"
                    disabled={
                      props.loading.effects.clientPhones.deleteItem || props.loading.effects.clientPhones.uypdateItem
                    }
                    onClick={() => {
                      setSelectedPhone(phone);
                      togglePhoneDialog(true);
                    }}
                  />
                  <Popconfirm
                    title="Czy na pewno usunąć ten numer?"
                    okText="Tak, usuń"
                    cancelText="Anuluj"
                    onConfirm={() => {
                      const hide = message.loading('Usuwam numer telefonu...', 0);
                      props.clientPhones
                        .deleteItem({
                          customerId: props.clientId,
                          id: phone.id,
                        })
                        .then(() => {
                          message.success('Numer telefonu został usunięty');
                          props.clientPhones.loadCollection({
                            customerId: props.clientId,
                          });
                        })
                        .catch(errorResponse => {
                          message.error(getErrorMessageFromErrorResponse(errorResponse));
                        })
                        .finally(() => {
                          hide();
                        });
                    }}
                  >
                    <Button
                      size={ButtonSize.Small}
                      label="usuń"
                      iconName="trash"
                      disabled={
                        props.loading.effects.clientPhones.deleteItem || props.loading.effects.clientPhones.uypdateItem
                      }
                    />
                  </Popconfirm>
                </Group>
              </Group>
              <Divider />
            </React.Fragment>
          );
        })}
        <Group align="space-between">
          <div style={{width: "70%"}}>
            <Input
              value={newPhoneValue}
              type="text"
              onChange={ev => {
                setNewPhoneValue(ev.currentTarget.value);
              }}
            />
          </div>
          <div>
            <Group width="auto">
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.Primary}
                label="Dodaj"
                loading={props.loading.effects.clientPhones.createItem}
                disabled={props.loading.effects.clientPhones.deleteItem || props.loading.effects.clientPhones.uypdateItem}
                onClick={() => {
                  props.clientPhones
                    .createItem({
                      data: {
                        phone: newPhoneValue,
                      },
                      customerId: props.clientId,
                    })
                    .then(() => {
                      message.success('Numer telefonu został dodany');
                      setNewPhoneValue('');
                      props.onSelect(newPhoneValue);
                    });
                }}
              />
            </Group>
          </div>
        </Group>

        {phoneDialogVisible && (
          <SimpleInputDialog
            open
            loading={props.loading.effects.clientPhones.createItem || props.loading.effects.clientPhones.updateItem}
            errors={props.clientPhonesStore.errors}
            inputValue={selectedPhone !== null ? selectedPhone.phone : ''}
            title={selectedPhone ? `Edycja numeru telefonu` : `Dodawanie numer telefonu`}
            inputLabel="Numer telefonu"
            okText={selectedPhone ? 'Zapisz' : 'Dodaj'}
            onSave={data => {
              const phone = data.values.input;
              if (selectedPhone) {
                props.clientPhones
                  .updateItem({
                    data: {
                      phone,
                    },
                    id: selectedPhone.id,
                    customerId: props.clientId,
                  })
                  .then(() => {
                    message.success('Numer telefon został zaktualizowany');
                    togglePhoneDialog(false);
                  });
              }
            }}
            onCancel={() => {
              togglePhoneDialog(false);
            }}
          />
        )}
      </PhonePickerModalContainer>
    </Modal>
  );
});
