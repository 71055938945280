import React from 'react';

const ErrorMessage = props => {
  const errors = Array.isArray(props.error) ? props.error : [props.error];
  return (
    <div>
      {errors.map(error => (
        <p key={error}>{error}</p>
      ))}
    </div>
  );
};

export { ErrorMessage };
