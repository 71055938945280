import { OrderModel } from 'modules/Orders/models/order.model';
import React, { FunctionComponent } from 'react';
import { Button, ButtonSize, styled, Table } from 'lib/ui';
import { amountAsText } from 'services/helpers/amountAsText';
import { format } from 'date-fns';

export interface OrderClientDetailsTableProps {
  clientId: number;
  onOrderDetailsClick?: (order: OrderModel) => void;
  buttonTitle?: string;
  buttonVisible?: boolean;
  data: OrderModel[];
}

const OrderClientDetailsTableContainer = styled.div`
  margin: ${p => p.theme.space} 0;
  border: none;
  width: 100%;
`;

const formatDateParam = date => format(date, 'YYYY-MM-DD HH:mm');

const OrderClientDetailsTable: FunctionComponent<OrderClientDetailsTableProps> = React.memo(
  props => {
    const columns = [
      {
        title: 'L.p.',
        render: (text, record, index) => (
          <div>
            <span>{index}.</span>
          </div>
        ),
      },
      {
        title: 'Data odebrania',
        render: (text, record, index) => {
          return (
            <div>
              <span>{formatDateParam(record.receiveDate)}</span>
            </div>
          );
        },
      },
      {
        title: 'Faktura VAT',
        render: (text, record, index) => {
          return (
            <div>
              <span>{record.invoiceData ? 'tak' : 'nie'}</span>
            </div>
          );
        },
      },
      {
        title: 'Firma',
        render: (text, record, index) => {
          return (
            <div>
              <span>{record.invoiceData ? record.invoiceData.companyName : ''}</span>
            </div>
          );
        },
      },
      {
        title: 'Restauracja',
        render: (text, record, index) => {
          return (
            <div>
              {record.restaurants.map(restaurant => (
                <span>{restaurant.displayedName}</span>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Kwota',
        render: (text, record, index) => {
          return (
            <div>
              <span>{amountAsText(record.gross)}</span>
            </div>
          );
        },
      },
      {
        title: 'Kierowca',
        render: (text, record, index) => {
          return (
            <div>
              <span>{record.driver ? record.driver.name : ''}</span>
            </div>
          );
        },
      },
      {
        title: '',
        render: (text, record, index) => {
          return (
            <div>
              <Button
                size={ButtonSize.Small}
                label={props.buttonTitle}
                onClick={() => {
                  if (props.onOrderDetailsClick) {
                    props.onOrderDetailsClick(record);
                  }
                }}
              />
            </div>
          );
        },
      },
    ];

    return (
      <OrderClientDetailsTableContainer clientId={props.clientId}>
        <Table columns={columns} bordered={false} rowKey={record => record.id} rows={props.data} />
      </OrderClientDetailsTableContainer>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.data.map(i => i.id).join('') === nextProps.data.map(i => i.id).join('');
  },
);

export { OrderClientDetailsTable };
