import { Empty, message, PageHeader } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth, format, startOfMonth } from 'date-fns';

import { MonthSwitcher } from 'components/MonthSwitcher/MonthSwitcher';
import { EmployeeReckoning } from 'modules/Employees/EmployeesReckoningModel';
import { Loading } from 'lib/ui';
import { PageLayout } from 'components';

import { EmployeeReckoningDay } from './components/EmployeeReckoningDay';

const formatDateParam = date => format(date, 'YYYY-MM-DD');

export const EmployeeReckoningPage = props => {
  const dispatch = useDispatch();
  const { employeeId } = props.match.params;
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [loading, setLoading] = useState<Boolean>(false);

  // reckoning data
  const [data, setData] = useState<EmployeeReckoning | null>();

  const loadData = useCallback(() => {
    setLoading(true);
    setData(null);
    dispatch({
      type: 'employeesReckoning/loadCollection',
      payload: {
        employeeId,
        dateStart: formatDateParam(startDate),
        dateEnd: formatDateParam(endOfMonth(startDate)),
      },
    })
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(err => {
        message.error('Wystąpił nieoczekiwany błąd');
        setLoading(false);
        setData(null);
      });
  }, [employeeId, startDate]);
  useEffect(() => {
    loadData();
  }, [loadData]);

  //  get employees from store
  const employees = useSelector(state => state.employees.dataById);

  if (loading) {
    return <Loading text="Ładowanie danych" />;
  }

  if (!data || data.details.length === 0) {
    return <Empty description="Brak danych" />;
  }

  const employee = employees[employeeId];
  const employeeName = employee ? `${employee.user.firstName} ${employee.user.lastName}` : '';

  return (
    <PageLayout>
      <PageHeader title={`${employeeName}`} subTitle={'Rozliczenia'} />
      <MonthSwitcher date={startDate} onDateChange={setStartDate} />
      <div data-bp="grid">
        <div data-bp="9">
          {data &&
            data.details.map(dayData => {
              return (
                <EmployeeReckoningDay
                  key={dayData.dayStart}
                  employeeId={employeeId}
                  data={dayData}
                  onUpdate={() => loadData()}
                />
              );
            })}
        </div>
      </div>
    </PageLayout>
  );
};
