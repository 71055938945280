export const applyAuthorizationHeader = (headers, localStorageKeyWithToken?: string) => {
  const token = localStorage.getItem(localStorageKeyWithToken || 'token');
  if (!token) {
    return headers;
  }
  return {
    ...headers,
    Authorization: `Token ${token}`,
  };
};
