const base64ToArrayBuffer = (data: string): Uint8Array => {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

const saveByteArray = (fileName: string, data: Uint8Array): void => {
  const mediaType = `application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
  const blob = new Blob([data], { type: mediaType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

export const downloadXls = (data: string, fileName: string): void => {
  const base64 = base64ToArrayBuffer(data);
  const name = `${fileName}.xls`;
  return saveByteArray(name, base64);
};
