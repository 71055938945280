import { Formik } from 'formik';
import { ButtonSubmit, FormField, FormFieldInputNumber, FormFieldLabel } from 'lib/forms';
import { DialogService } from 'lib/services/DialogService';
import { Button, ButtonVariant, Group, Modal } from 'lib/ui';
import React from 'react';

export interface DriverReckoningBonusModalProps {
  amount?: number | null;
  description?: string;
  onClose: (values?: any) => void;
}

export const BalanceWithDescriptionModal: React.FC<DriverReckoningBonusModalProps> = props => {
  return (
    <Formik
      initialValues={{
        bonus: (props.amount || 0) / 100,
        description: props.description || '',
      }}
      onSubmit={(values, bag) => {
        props.onClose({
          bonus: values.bonus * 100,
          description: values.description,
        });
      }}
    >
      {formikProps => {
        return (
          <Modal visible width="300px" header={<h2>Bonus / kara</h2>} onClose={() => props.onClose()}>
            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <FormFieldInputNumber name="bonus" label="Kwota" {...formikProps} />
              </Group>
              <div className={'mt-4'}>
                <Group vertical>
                  <FormField name="description" label="Opis" {...formikProps} />
                </Group>
              </div>
              <div className={'mt-4'}>
                <Group align="space-between">
                  <Button
                    variant={ButtonVariant.Plain}
                    disabled={formikProps.isSubmitting}
                    label={'Anuluj'}
                    onClick={() => DialogService.closeDialog()}
                  />
                  <ButtonSubmit
                    disabled={formikProps.isSubmitting}
                    loading={formikProps.isSubmitting}
                    label={'Zapisz'}
                    onClick={formikProps.handleSubmit}
                    iconName="save"
                  />
                </Group>
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
};
