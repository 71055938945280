import { Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import useToggle from 'react-use/lib/useToggle';
import connect from 'react-redux/es/connect/connect';
import { PageLayout } from 'components';
import { Button, ButtonVariant, DimmerLoading, styled } from 'lib/ui';
import {
  DeliveryZoneDrawer,
  DeliveryZonesTable,
  DeliveryZone,
  PaymentType,
  PaymentTypeDrawer,
  PaymentTypesTable,
} from 'modules/Settings';
import { DeliveryDelaySlider } from 'modules/Settings/components/GeneralSettings/DeliveryDelaySlider';

const ComponentToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-right: 4px;
`;

const TableContainer = styled.div`
  position: relative;
  width: 100%;
`;

const enhance = connect(
  ({ paymentTypes, deliveryZones, loading }) => ({
    paymentTypesData: paymentTypes.data,
    deliveryZonesData: deliveryZones.data,
    loading,
  }),
  ({ paymentTypes, deliveryZones }) => ({
    paymentTypesStore: paymentTypes,
    deliveryZonesStore: deliveryZones,
  }),
);

const SettingsPage = enhance(props => {
  useEffect(() => {
    props.paymentTypesStore.loadCollection();
    props.deliveryZonesStore.loadCollection();
  }, []);

  const [selectedPaymentType, setSelectedPaymentType] = useState<any>(null);
  const [selectedDeliveryZone, setSelectedDeliveryZone] = useState<any>(null);
  const [paymentTypeFormVisible, togglePaymentTypeForm] = useToggle(false);
  const [deliveryZoneFormVisible, toggleDeliveryZoneForm] = useToggle(false);

  const paymentTypes: PaymentType[] = props.paymentTypesData;
  const deliveryZones: DeliveryZone[] = props.deliveryZonesData;

  return (
    <>
      <PageLayout title="Ustawienia">
        <div data-bp="grid">
          {/* Formy płatności / payment types */}
          <div data-bp="6 padding-right">
            <ComponentToolbar>
              <h2>Formy płatności</h2>
              <Button label="Dodaj nową" variant={ButtonVariant.Primary} onClick={togglePaymentTypeForm} />
            </ComponentToolbar>
            <TableContainer>
              <PaymentTypesTable
                paymentTypes={paymentTypes}
                onEditClick={paymentType => {
                  setSelectedPaymentType(paymentType);
                  togglePaymentTypeForm();
                }}
                onStatusChangeClick={paymentType => {
                  props.paymentTypesStore.updateItem({
                    id: paymentType.id,
                    data: {
                      isActive: !paymentType.isActive,
                    },
                  });
                }}
              />
              <DimmerLoading visible={props.loading.models.paymentTypes} />
              {!props.loading.models.paymentTypes && paymentTypes.length === 0 && <Empty description="Brak danych" />}
            </TableContainer>
          </div>

          {/* Strefy dostaw / delivery zones */}
          <div data-bp="6 padding-left">
            <ComponentToolbar>
              <h2>Strefy dostaw</h2>
              <Button label="Dodaj nową" variant={ButtonVariant.Primary} onClick={toggleDeliveryZoneForm} />
            </ComponentToolbar>
            <TableContainer>
              <DeliveryZonesTable
                deliveryZones={deliveryZones}
                onEditClick={deliveryZone => {
                  setSelectedDeliveryZone(deliveryZone);
                  toggleDeliveryZoneForm();
                }}
              />
              <DimmerLoading visible={props.loading.models.deliveryZones} />
              {!props.loading.models.deliveryZones && deliveryZones.length === 0 && <Empty description="Brak danych" />}
            </TableContainer>
          </div>
        </div>

        <div data-bp="grid">
          <div data-bp="6 padding-right">
            <ComponentToolbar>
              <h2>Opóźnienie w dostawie</h2>
            </ComponentToolbar>

            <DeliveryDelaySlider />
          </div>
        </div>
      </PageLayout>

      <PaymentTypeDrawer
        visible={paymentTypeFormVisible}
        onClose={() => {
          props.paymentTypesStore.clearError();
          setSelectedPaymentType(null);
          togglePaymentTypeForm(false);
        }}
        paymentType={selectedPaymentType}
      />
      <DeliveryZoneDrawer
        visible={deliveryZoneFormVisible}
        onClose={() => {
          props.deliveryZonesStore.clearError();
          setSelectedDeliveryZone(null);
          toggleDeliveryZoneForm(false);
        }}
        deliveryZone={selectedDeliveryZone}
      />
    </>
  );
});

export { SettingsPage };
