import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { ensureDateObject, Icon, IconFa } from '../..';

import styles from './DateTime.module.scss';
import { formattedDate, formattedTime } from './DateTimeUtils';

export interface DateTimeProps {
  datetime: string | number | Date;
  withIcons?: boolean;
  withTime?: boolean;
}

const DateTimeDefaultProps = {
  datetime: +new Date(),
  withIcons: false,
  withTime: true,
};

const DateTime: FunctionComponent<DateTimeProps> = ({ datetime, withIcons, withTime }) => {
  const date = ensureDateObject(datetime);

  return (
    <span
      className={classnames(styles.DateTime, {
        [styles.DateTimeWithIcons]: withIcons,
      })}>
      {withIcons && (
        <span>
          <Icon fa={IconFa.Fal} name="calendar-alt" />
        </span>
      )}
      <span>
        {formattedDate(date)}
        {!withIcons && withTime && ' o '}
      </span>
      {withTime && (
        <React.Fragment>
          {withIcons && (
            <span>
              <Icon fa={IconFa.Fal} name="clock" />
            </span>
          )}
          <span>{formattedTime(date)}</span>
        </React.Fragment>
      )}
    </span>
  );
};

DateTime.defaultProps = DateTimeDefaultProps;

export { DateTime };
