import React from 'react';

import { styled } from '../ui/styled-components';

const FieldErrorContainer = styled.div`
  color: ${p => p.theme.colorRed};
  margin: ${p => p.theme.spaceSmallX} 0 0 0;
`;

const FieldError = ({ error }) => <FieldErrorContainer>{error}</FieldErrorContainer>;

export { FieldError };
