import * as Yup from 'yup';

import { stringRequired_2_100 } from 'lib/forms/YupValidation';

const numberRequired = () => Yup.number().required('Pole jest wymagane');

export default Yup.object().shape({
  namePl: stringRequired_2_100(),
  nameEn: stringRequired_2_100(),
  price: numberRequired().min(0, 'Cena nie może być mniejsza od 0'),
});
