import { GridApi } from 'ag-grid';
import { Empty, message } from 'antd';

import { PageLayout } from 'components';
import { RestaurantsSettlementDrawer } from 'components/drawers/RestaurantSettlementDrawer/RestaurantSettlementDrawer';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { ButtonSubmit, SearchInput } from 'lib/forms';
import { Button, ButtonVariant, DimmerLoading, Group } from 'lib/ui';
import { RestaurantSettlementBoxGroup } from 'modules/Restaurant/components/RestaurantsSettlement/RestaurantSettlementBox';
import { RestaurantsSettlementDeliveryDate } from 'modules/Restaurant/components/RestaurantsSettlement/RestaurantsSettlementDeliveryDate';
import { RestaurantsSettlementSelectedItems } from 'modules/Restaurant/components/RestaurantsSettlement/RestaurantsSettlementSelectedItem';
import { RestaurantsSettlementTable } from 'modules/Restaurant/components/RestaurantsSettlement/RestaurantsSettlementTable';
import { RestaurantSettlement } from 'modules/Restaurant/RestaurantsSettlementModel';
import React, { useCallback, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import useToggle from 'react-use/lib/useToggle';
import { downloadXls } from 'services/helpers/convertBlobToXls';
import { RestaurantUtils } from 'modules/Restaurant';
import moment from 'moment';

const formatDateParam = date => format(date, 'YYYY-MM-DD');

const enhance = connect(
  ({ restaurants, restaurantsSettlement }) => ({
    restaurantsState: restaurants,
    restaurantsSettlementState: restaurantsSettlement,
  }),
  ({ restaurants, restaurantsSettlement }) => ({
    restaurants,
    restaurantsSettlement,
  }),
);

const RestaurantsSettlementPage = enhance(props => {
  const firstDayOfCurrentMonth = moment()
    .startOf('month')
    .format('YYYY-MM-DD hh:mm');
  const lastDayOfCurrentMonth = moment()
    .endOf('month')
    .format('YYYY-MM-DD hh:mm');

  const [restaurantsListVisible, toggleRestaurantsListVisible] = useToggle(true);
  const [loading, toggleLoading] = useToggle(false);
  const [selectedSettlementsRestaurants, setSelectedSettlementsRestaurants] = useState<any[]>([]);
  const [settlementDateFrom, setSettlementDateFrom] = useState<any>(firstDayOfCurrentMonth);
  const [settlementDateTo, setSettlementDateTo] = useState<any>(lastDayOfCurrentMonth);
  const [query, setQuery] = useState('');
  const [table, setTable] = useState<GridApi>();
  const [settlementDetailsVisible, toggleSettlementDetailsVisible] = useToggle(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState<RestaurantSettlement>();
  const [settlementData, setSettlementData] = useState<RestaurantSettlement[]>([]);

  //  set focus in input on start
  const [inputRef, setInputRef] = useState();
  const inputCallbackRef = useCallback(node => {
    if (node !== null) {
      setInputRef(node);
    }
  }, []);
  useEffect(() => {
    if (inputRef) {
      // @ts-ignore
      inputRef.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (restaurantsListVisible) {
      toggleLoading();
      props.restaurants
        .loadCollection({
          params: {
            limit: 100000,
          },
        })
        .finally(() => toggleLoading());
    }
  }, [props.restaurants, restaurantsListVisible, toggleLoading]);

  const restaurantsList = props.restaurantsState.data;

  return (
    <>
      <PageLayout title="Rozliczanie restauracji">
        {restaurantsListVisible && (
          <>
            <Formik
              initialValues={{
                dateFrom: formatDateParam(settlementDateFrom),
                dateTo: formatDateParam(settlementDateTo),
                restaurants: selectedSettlementsRestaurants.map(r => r.id),
              }}
              onSubmit={(values: any, actions) => {
                const hide = message.loading('Obliczam rozliczenia...', 0);
                const payload = {
                  action: '',
                  data: {
                    dateFrom: formatDateParam(settlementDateFrom),
                    dateTo: formatDateParam(settlementDateTo),
                    detailedSettlements: true,
                    restaurants: selectedSettlementsRestaurants.map(r => r.id),
                  },
                };
                props.restaurantsSettlement
                  .customCollectionAction(payload)
                  .then(data => setSettlementData(data))
                  .catch(e => message.error('Wystąpił nieoczekiwany bład'))
                  .finally(() => {
                    hide();
                    actions.setSubmitting(false);
                    toggleRestaurantsListVisible();
                  });
              }}
              enableReinitialize
            >
              {formikProps => {
                return (
                  <>
                    <form>
                      <Group align="space-between">
                        <Group>
                          <SearchInput
                            ref={inputCallbackRef}
                            value={query}
                            onChange={setQuery}
                            debounceTime={0}
                            placeholder="Wyszukaj restauracje do rozliczeń"
                          />
                        </Group>
                        <Group align="right" width="auto">
                          <Button
                            label="Restauracje"
                            variant={ButtonVariant.Primary}
                            onClick={() => props.history.push('/restaurants')}
                          />
                        </Group>
                      </Group>
                      <Group align="space-between">
                        <Group wrap>
                          <RestaurantsSettlementDeliveryDate
                            date={settlementDateFrom}
                            onChange={date => setSettlementDateFrom(date)}
                            placeholder="Od"
                          />
                          <RestaurantsSettlementDeliveryDate
                            date={settlementDateTo}
                            onChange={date => setSettlementDateTo(date)}
                            placeholder="Do"
                          />
                          <ButtonSubmit
                            disabled={
                              formikProps.isSubmitting ||
                              !settlementDateTo ||
                              !settlementDateFrom ||
                              selectedSettlementsRestaurants.length === 0
                            }
                            loading={formikProps.isSubmitting}
                            label="Pokaż rozliczenia"
                            onClick={formikProps.handleSubmit}
                            iconName="save"
                          />
                        </Group>
                      </Group>
                      <div style={{ position: 'relative' }}>
                        {restaurantsList.length > 0 && (
                          <div className="u-flex">
                            <RestaurantsSettlementTable
                              reference={d => setTable(d)}
                              restaurants={restaurantsList.filter(restaurant => {
                                return RestaurantUtils.restaurantContainsPhrase(restaurant, query);
                              })}
                              onSelect={selection => {
                                if (selection) {
                                  const sel = selection.map(rowNode => rowNode.data);
                                  if (sel) {
                                    return setSelectedSettlementsRestaurants(sel);
                                  }
                                  return null;
                                }
                                return null;
                              }}
                            />
                            <RestaurantsSettlementSelectedItems
                              onSelect={id => {
                                if (table) {
                                  let selectedNode;
                                  table.forEachNode(node => {
                                    if (node.data.id === id) {
                                      selectedNode = node;
                                    }
                                  });
                                  if (selectedNode) {
                                    table.deselectNode(selectedNode);
                                  }
                                }
                                return setSelectedSettlementsRestaurants(
                                  selectedSettlementsRestaurants.filter(e => e.id !== id),
                                );
                              }}
                              restaurants={selectedSettlementsRestaurants}
                            />
                          </div>
                        )}
                      </div>
                      <DimmerLoading visible={loading} />

                      {restaurantsList.length === 0 && <Empty description="Brak danych" />}
                    </form>
                  </>
                );
              }}
            </Formik>
          </>
        )}
        {!restaurantsListVisible && (
          <>
            <Group align="right" width="auto">
              <Button
                label="Excel"
                onClick={() => {
                  const hide = message.loading('Generuje arkusz z rozliczeniami...', 0);
                  const payload = {
                    action: '',
                    data: {
                      dateFrom: formatDateParam(settlementDateFrom),
                      dateTo: formatDateParam(settlementDateTo),
                      restaurants: selectedSettlementsRestaurants.map(r => r.id),
                      responseFormat: 'xls',
                    },
                  };
                  props.restaurantsSettlement
                    .customCollectionAction(payload)
                    .then(data => downloadXls(data.aggregatedXls, `${settlementDateFrom}/${settlementDateTo}`))
                    .catch(() => message.error('Wystąpił nieoczekiwany bład'))
                    .finally(() => hide());
                }}
                variant={ButtonVariant.Primary}
              />
              <Button
                label="Powrót"
                variant={ButtonVariant.Primary}
                onClick={() => {
                  setSelectedSettlementsRestaurants([]);
                  toggleRestaurantsListVisible();
                }}
              />
            </Group>
            <div style={{ position: 'relative' }}>
              <div className="u-flex">
                <RestaurantSettlementBoxGroup
                  restaurantsSettlement={settlementData}
                  onSelect={e => {
                    toggleSettlementDetailsVisible();
                    setSelectedRestaurant(e);
                  }}
                />
              </div>
            </div>
            {settlementDetailsVisible && (
              <RestaurantsSettlementDrawer
                updateSelectedRestaurant={restaurant => {
                  const newData = settlementData.map(r => {
                    if (r.restaurant.id === restaurant.restaurant.id) {
                      return restaurant;
                    }
                    return r;
                  });

                  setSettlementData(newData);
                  setSelectedRestaurant(restaurant);
                }}
                generateExcel={restaurantId => {
                  const hide = message.loading('Generuje arkusz z rozliczeniem...', 0);
                  const payload = {
                    action: '',
                    data: {
                      dateFrom: formatDateParam(settlementDateFrom),
                      dateTo: formatDateParam(settlementDateTo),
                      restaurants: [restaurantId],
                      responseFormat: 'xls',
                      detailedSettlements: true,
                    },
                  };
                  props.restaurantsSettlement
                    .customCollectionAction(payload)
                    .then(data => {
                      data.forEach(r => {
                        downloadXls(
                          r.settlementData.detailedXls,
                          `${r.restaurant.name}(${formatDateParam(settlementDateFrom)}/${formatDateParam(
                            settlementDateTo,
                          )})`,
                        );
                      });
                    })
                    .catch(e => message.error('Wystąpił nieoczekiwany bład'))
                    .finally(() => hide());
                }}
                item={selectedRestaurant}
                visible={settlementDetailsVisible}
                onEditClick={item => {
                  if (selectedRestaurant) {
                    const payload = {
                      actionMethod: 'patch',
                      action: 'commision-update',
                      data: {
                        dateFrom: formatDateParam(settlementDateFrom),
                        dateTo: formatDateParam(settlementDateTo),
                        orderId: item.data.id,
                        [item.column.colId]: item.value,
                        restaurants: [selectedRestaurant.restaurant.id],
                      },
                    };
                    const hide = message.loading('Aktualizuje...', 0);
                    return props.restaurantsSettlement
                      .customCollectionAction(payload)
                      .then((data: RestaurantSettlement[]) => {
                        const newData = settlementData.map(d => {
                          if (d.restaurant.id === data[0].restaurant.id) {
                            return data[0];
                          }
                          return d;
                        });
                        setSettlementData(newData);
                        setSelectedRestaurant(data[0]);
                      })
                      .catch(e => {
                        message.error('Wystąpił nieoczekiwany bład');
                      })
                      .finally(() => {
                        hide();
                      });
                  }
                  return null;
                }}
                onClose={() => {
                  toggleSettlementDetailsVisible();
                }}
              />
            )}
          </>
        )}
      </PageLayout>
    </>
  );
});

export { RestaurantsSettlementPage };
