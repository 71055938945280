/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { highlightOrders$ } from 'modules/Orders/components/OrderTable/OrdersTable';
import { OrderDrivers } from 'modules/Orders/OrderDriversModel';
import {
  displayAllDrivers,
  displayLinesForDriver,
  displayOnlyDriver,
  removeAllLinesFromMap,
} from 'pages/MapPage/mapHelpers';
import React, { FunctionComponent } from 'react';
import { groupBy } from 'rambdax';

import { Box, Divider, formattedTime, Spacer } from 'lib/ui';

export interface OrderPageDriversProps {
  drivers: OrderDrivers[];
  onDriverTaskUpdate: (driverId: number) => void;
}

export const OrderPageDrivers: FunctionComponent<OrderPageDriversProps> = props => {
  const grouppedDrivers = groupBy((item: OrderDrivers) => (item.isBusy ? 'busy' : 'available'), props.drivers);

  return (
    <>
      <Box title="Wolni" headerWithoutBackground>
        {grouppedDrivers.available &&
          grouppedDrivers.available.map((orderDriver, driverIndex) => {
            return (
              <div
                key={orderDriver.id}
                onMouseEnter={() => {
                  displayOnlyDriver(orderDriver.id);
                }}
                onMouseLeave={() => {
                  displayAllDrivers();
                }}
                className="pointer"
              >
                <div data-bp="grid 12 gap-none">
                  <strong>{orderDriver.name}</strong>
                </div>
                {driverIndex < grouppedDrivers.available.length - 1 && (
                  <>
                    <Spacer size="smallX" />
                    <Divider noMargin />
                    <Spacer size="smallX" />
                  </>
                )}
              </div>
            );
          })}
      </Box>

      <Box title="Zajęci" headerWithoutBackground>
        {grouppedDrivers.busy &&
          grouppedDrivers.busy.map((orderDriver, driverIndex) => {
            const lastOrder = orderDriver.orders[orderDriver.orders.length - 1];
            return (
              <div
                key={orderDriver.id}
                onMouseEnter={() => {
                  displayLinesForDriver(orderDriver.id);
                  highlightOrders$.next(orderDriver.orders.map(order => order.id));
                }}
                onMouseLeave={() => {
                  removeAllLinesFromMap();
                  highlightOrders$.next([]);
                }}
                onClick={() => {
                  props.onDriverTaskUpdate(orderDriver.id);
                }}
                className="pointer"
              >
                <div data-bp="grid 12 gap-none">
                  <strong>
                    {orderDriver.name} ({orderDriver.orders.length})
                  </strong>
                  <span>{lastOrder.arrivalDestination}</span>
                  <span>{formattedTime(lastOrder.arrivalAt)}</span>
                </div>
                {driverIndex < grouppedDrivers.busy.length - 1 && (
                  <>
                    <Spacer size="smallX" />
                    <Divider noMargin />
                    <Spacer size="smallX" />
                  </>
                )}
              </div>
            );
          })}
      </Box>
    </>
  );
};
