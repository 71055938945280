import { Switch } from 'antd';

import { FormFieldLabel, Input, Textarea } from 'lib/forms';
import { Spacer, styled } from 'lib/ui';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormState } from 'modules/Orders/OrderFormState';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { useObservable } from 'react-use';

export interface OrderFormClientNewProps {
  createdClient?: boolean;
}

const newClientInitialData = {
  firstName: '',
  lastName: '',
  email: '',
  hasAccount: false,
  phone: '',
  street: '',
  postalCode: '',
  city: '',
  addressNotes: '',
  notes: '',
};

const OrderFormClientNewContainer = styled.div.attrs({
  className: 'OrderFormClientNewContainer',
})``;

export const OrderFormClientNew: FunctionComponent<OrderFormClientNewProps> = props => {
  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());

  //  initial data for form
  useEffect(() => {
    OrderFormService.setState({
      newClientData: newClientInitialData,
    });
  }, []);

  if (!orderState || !orderState.newClientData) {
    return null;
  }

  return (
    <OrderFormClientNewContainer>
      <h2>Nowy Klient</h2>

      <FormFieldLabel>Imię</FormFieldLabel>
      <Input
        value={orderState.newClientData.firstName}
        disabled={props.createdClient}
        onChange={ev => {
          OrderFormService.setState({
            newClientData: {
              ...newClientInitialData,
              ...orderState.newClientData,
              firstName: ev.currentTarget.value,
            },
          });
        }}
      />
      <Spacer size="small" />

      <FormFieldLabel>Nazwisko</FormFieldLabel>
      <Input
        value={orderState.newClientData.lastName}
        disabled={props.createdClient}
        onChange={ev => {
          OrderFormService.setState({
            newClientData: {
              ...newClientInitialData,
              ...orderState.newClientData,
              lastName: ev.currentTarget.value,
            },
          });
        }}
      />
      <Spacer size="small" />

      <FormFieldLabel>E-mail</FormFieldLabel>
      <Input
        value={orderState.newClientData.email}
        type="email"
        disabled={props.createdClient}
        onChange={ev => {
          OrderFormService.setState({
            newClientData: {
              ...newClientInitialData,
              ...orderState.newClientData,
              email: ev.currentTarget.value,
            },
          });
        }}
      />
      <Spacer size="small" />
      <FormFieldLabel>
        <Switch
          checked={orderState.newClientData.hasAccount}
          size="small"
          disabled={props.createdClient}
          onChange={checked => {
            OrderFormService.setState({
              newClientData: {
                ...newClientInitialData,
                ...orderState.newClientData,
                hasAccount: checked,
              },
            });
          }}
        />
        <span className="ml-2">Utwórz konto on-line</span>
      </FormFieldLabel>
      <Spacer size="small" />

      <FormFieldLabel>Telefon do zamówienia</FormFieldLabel>
      <Input
        value={orderState.newClientData.phone}
        onChange={ev => {
          OrderFormService.setState({
            newClientData: {
              ...newClientInitialData,
              ...orderState.newClientData,
              phone: ev.currentTarget.value,
            },
          });
        }}
      />

      <FormFieldLabel>Ulica, nr budynku, nr lokalu</FormFieldLabel>
      <Input
        value={orderState.newClientData.street}
        onChange={ev => {
          OrderFormService.setState({
            newClientData: {
              ...newClientInitialData,
              ...orderState.newClientData,
              street: ev.currentTarget.value,
            },
          });
        }}
      />
      <Spacer size="small" />

      <FormFieldLabel>Kod pocztowy</FormFieldLabel>
      <Input
        value={orderState.newClientData.postalCode}
        onChange={ev => {
          OrderFormService.setState({
            newClientData: {
              ...newClientInitialData,
              ...orderState.newClientData,
              postalCode: ev.currentTarget.value,
            },
          });
        }}
      />
      <Spacer size="small" />

      <FormFieldLabel>Miejscowość</FormFieldLabel>
      <Input
        value={orderState.newClientData.city}
        onChange={ev => {
          OrderFormService.setState({
            newClientData: {
              ...newClientInitialData,
              ...orderState.newClientData,
              city: ev.currentTarget.value,
            },
          });
        }}
      />
      <Spacer size="small" />

      <FormFieldLabel>Uwagi do adresu</FormFieldLabel>
      <Textarea
        value={orderState.newClientData.addressNotes}
        onChange={ev => {
          OrderFormService.setState({
            newClientData: {
              ...newClientInitialData,
              ...orderState.newClientData,
              addressNotes: ev.currentTarget.value,
            },
          });
        }}
        width="500px"
      />

      <FormFieldLabel>Informacje o kliencie</FormFieldLabel>
      <Textarea
        value={orderState.newClientData.notes}
        disabled={props.createdClient}
        onChange={ev => {
          OrderFormService.setState({
            newClientData: {
              ...newClientInitialData,
              ...orderState.newClientData,
              notes: ev.currentTarget.value,
            },
          });
        }}
        width="500px"
      />
      <Spacer size="small" />
    </OrderFormClientNewContainer>
  );
};
