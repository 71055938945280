import { Empty } from 'antd';
import { isBefore } from 'date-fns';
import { OrderFormBasketSetModel } from 'modules/Orders/OrderFormState';
import { Meal, MealGroup, MealUtils } from 'modules/Restaurant';
import React, { FunctionComponent, useEffect } from 'react';

import { styled, Group, Price, Divider, Button, Spacer, DateTimeRange, Loading, ValueOrDash, Box } from 'lib/ui';
import connect from 'react-redux/es/connect/connect';
import { ColorRed, MealIcons } from 'components';
import { MealIndexBadge } from 'components/MealIndexBadge/MealIndexBadge';
import { getUrlForMediaImage } from 'services/utils/getUrlForMediaImage';

export interface OrderFormMealPickerProps {
  restaurantId?: number;
  query?: string;
  mealGroup: MealGroup | null;
  onSelect: (meal: Meal) => void;
  basketSets?: OrderFormBasketSetModel[];
}

const enhance = connect(
  ({ restaurantsMeals, loading }) => ({
    restaurantsMealsStore: restaurantsMeals,
    loading,
  }),
  ({ restaurantsMeals }) => ({
    restaurantsMeals,
  }),
);

const OrderFormMealPickerContainer = styled.div.attrs({
  className: 'OrderFormMealPickerContainer',
})`
  width: 100%;
`;

const MealItem = styled.div.attrs({ className: 'MealItem' })<{ addedToBasket: boolean }>`
  padding: ${p => p.theme.space};
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: ${p => p.theme.spaceSmall};

  :hover {
    background: ${p => p.theme.colorLightGrey};
  }

  background: ${p => (p.addedToBasket ? p.theme.backgroundHover : p.theme.colorDirtyWhite)};
`;

export const OrderFormMealPicker: FunctionComponent<OrderFormMealPickerProps> = enhance(props => {
  useEffect(() => {
    props.restaurantsMeals.loadCollection({
      restaurantId: props.restaurantId,
    });
  }, []);

  const meals: Meal[] = props.restaurantsMealsStore.data
    .filter(meal => !props.mealGroup || meal.group.id === props.mealGroup.id)
    .filter(meal => !props.query || MealUtils.mealContainsPhrase(meal, props.query));

  /**
   * prepare ids of meals added currently to the basket
   * it will be used to highlight basket meals on the list
   */
  const mealsAddedToBasketIds: number[] = [];
  if (props.basketSets && props.basketSets.length > 0) {
    props.basketSets.forEach(basketSet => {
      if (basketSet.meals && basketSet.meals.length > 0) {
        basketSet.meals.forEach(basketMeal => {
          mealsAddedToBasketIds.push(basketMeal.meal.id);
        });
      }
    });
  }

  return (
    <OrderFormMealPickerContainer>
      <Divider text="Wybierz posiłek" textStrong />

      {props.loading.effects.restaurantsMeals.loadCollection && <Loading text="Ładowanie menu" />}

      {!props.loading.effects.restaurantsMeals.loadCollection && (
        <>
          {meals.length === 0 && <Empty description="Brak posiłków" />}

          {meals.length > 0 && (
            <>
              <Group align="space-between" verticalAlign="top">
                <Group width="70%">Posiłek</Group>
                <Group width="10%" align="right">
                  Cena
                </Group>
                <Group width="20%"></Group>
              </Group>
              <Divider />

              {meals.map(meal => {
                return (
                  <MealItem
                    key={meal.id}
                    addedToBasket={mealsAddedToBasketIds.includes(meal.id)}
                    onClick={() => {
                      props.onSelect(meal);
                    }}
                  >
                    <Group align="space-between" verticalAlign="top">
                      <Group width="70%" vertical>
                        <Group>
                          <MealIndexBadge>
                            <ValueOrDash value={meal.index} />
                          </MealIndexBadge>
                          <Spacer size="smallX" horizontal />
                          <h4>
                            {meal.namePl} / {meal.nameEn}
                          </h4>
                        </Group>
                        {(meal.isAlcohol || meal.isBeverage || meal.isGlutenFree || meal.isLunch || meal.isNew || meal.isVegan || meal.isVegetarian) ? (
                            <>
                              <Spacer size="smallX" />
                              <MealIcons meal={meal} />
                            </>
                          ): null
                        }
                      <Spacer size="smallX" />
                        <p>{meal.descriptionPl}</p>
                        <Spacer size="smallX" horizontal />
                        <p>{meal.descriptionEn}</p>
                        {meal.note && meal.note !== 'None' && (
                          <>
                            <Spacer size="smallX" horizontal />
                            <p style={{ color: 'green' }}>{meal.note}</p>
                          </>
                        )}
                        {meal.disableFrom && !isBefore(meal.disableTo, new Date()) && (
                          <>
                            <Spacer />
                            <ColorRed>
                              Nieaktywny: <DateTimeRange start={meal.disableFrom} end={meal.disableTo} />
                            </ColorRed>
                          </>
                        )}
                      </Group>
                      <Group width="10%" align="right" vertical>
                        <Price amount={meal.price} />
                        <Spacer size="smallX" />
                        <p>
                          {meal.userLikes.length} <i className="fal fa-heart"></i>
                        </p>
                      </Group>
                      <Group width="20%" align="right">
                        {meal.image && (
                            <img
                              src={getUrlForMediaImage(meal.image)}
                              style={{
                                maxWidth: '100%',
                                maxHeight: '160px',
                                marginLeft: '10px',
                              }}
                              alt="Zdjęcie posiłku"
                            />
                          )
                        }
                      </Group>
                    </Group>
                  </MealItem>
                );
              })}
            </>
          )}
        </>
      )}
    </OrderFormMealPickerContainer>
  );
});
