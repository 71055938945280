import { Layout, Menu } from 'antd';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import React from 'react';
import { path } from 'rambdax';

import { store } from 'store';
import { Group, Icon } from 'lib/ui';
import { RolesEnum } from 'modules/Auth/rolesEnum';

import styles from './AppToolbar.module.scss';

const menuItems: Array<{
  title: string;
  url: string;
  key: string;
  requiredRoles: RolesEnum[];
}> = [
  {
    title: 'Zamówienia',
    url: '/orders',
    key: 'orders',
    requiredRoles: [RolesEnum.admin, RolesEnum.operator, RolesEnum.dispatcher],
  },
  {
    title: 'Mapa',
    url: '/map',
    key: 'map',
    requiredRoles: [RolesEnum.admin, RolesEnum.operator],
  },
  {
    title: 'Klienci',
    url: '/clients',
    key: 'clients',
    requiredRoles: [RolesEnum.admin],
  },
  {
    title: 'Restauracje',
    url: '/restaurants',
    key: 'restaurants',
    requiredRoles: [RolesEnum.admin, RolesEnum.operator],
  },
  {
    title: 'Promocje',
    url: '/promotions',
    key: 'promotions',
    requiredRoles: [RolesEnum.admin],
  },
  {
    title: 'Pracownicy',
    url: '/employees',
    key: 'employees',
    requiredRoles: [RolesEnum.admin],
  },
  {
    title: 'Kierowcy',
    url: '/drivers',
    key: 'drivers',
    requiredRoles: [RolesEnum.admin],
  },
  {
    title: 'Grafik',
    url: '/schedules',
    key: 'schedules',
    requiredRoles: [RolesEnum.admin],
  },
  {
    title: 'Ustawienia',
    url: '/settings',
    key: 'settings',
    requiredRoles: [RolesEnum.admin],
  },
  {
    title: 'Extra dodatki',
    url: '/extra-extras',
    key: 'extra-extras',
    requiredRoles: [RolesEnum.admin],
  },
];

const enhance = compose(
  connect(
    ({ AuthModel: { user } }) => ({
      user,
    }),
    ({ AuthModel: { logout } }) => ({
      logout,
    }),
  ),
  withRouter,
);

const AppToolbar = enhance(({ logout, user, ...props }) => {
  const currentUrl = path('location.pathname', props);
  const selectedMenuItem = menuItems.find(item => {
    return item.url === currentUrl;
  });

  return (
    <Layout.Header className={styles.AppToolbar}>
      <div className={styles.logo}>
        <NavLink to={`/`}>
          <img src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="" />
        </NavLink>
      </div>

      <Group align="space-between">
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={selectedMenuItem && [selectedMenuItem.key]}
          style={{ lineHeight: '64px' }}
        >
          {menuItems.map((menuItem, index) => {
            const hasAccess = store.select.AuthModel.hasRole(menuItem.requiredRoles)(store.getState());
            if (!hasAccess) {
              return null;
            }

            return (
              <Menu.Item key={menuItem.key}>
                <NavLink to={menuItem.url}>{menuItem.title}</NavLink>
              </Menu.Item>
            );
          })}
        </Menu>
        <Menu theme="dark" mode="horizontal" selectedKeys={[]} style={{ lineHeight: '64px' }}>
          <Menu.Item onClick={logout}>
            <Group smallSpacing>
              <span>{user.username}</span>
              <Icon name="sign-out-alt" />
            </Group>
          </Menu.Item>
        </Menu>
      </Group>
    </Layout.Header>
  );
});

export { AppToolbar };
