import React, { FunctionComponent, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { AgGridTable, Button, ButtonSize, formattedDate, formattedTime, Icon, IconFa, styled, Tooltip } from 'lib/ui';
import { IDatasource, IGetRowsParams } from 'ag-grid';
import { PAGINATION_LIMIT } from 'modules/Drivers';
import { message } from 'antd';
import { Events } from 'lib/services';

export interface DriversMessagesTableProps {
  onItemClick: Function;
  searchPhrase?: string;
}

const DriversMessagesTableContainer = styled.div``;

const enhance = connect(
  null,
  ({ driversMessages }) => ({
    driversMessagesStore: driversMessages,
  }),
);

let resetGrid = () => {};

const defaultProps = { searchPhrase: '', onItemClick: () => {}, driversMessages: [] };

const DriversMessagesTable: FunctionComponent<DriversMessagesTableProps> = enhance(props => {
  const propsRef = useRef(defaultProps);
  const resetStartRowRef = useRef(false);

  propsRef.current = props;

  const dataSource: IDatasource = {
    getRows: async (params: IGetRowsParams) => {
      let offset = params.startRow;

      if (resetStartRowRef.current) {
        resetStartRowRef.current = false;
        offset = 0;
      }

      const loadDriversMessagesResponse = await props.driversMessagesStore.loadCollection({
        params: {
          search: propsRef.current.searchPhrase || undefined,
          limit: PAGINATION_LIMIT,
          offset,
        },
      });

      params.successCallback(loadDriversMessagesResponse.results, loadDriversMessagesResponse.count);
    },
  };

  useEffect(() => {
    resetGrid();
  }, [props.searchPhrase]);

  useEffect(() => {
    message.destroy();

    Events.on('Model.driversMessages.createItem.success', data => resetGrid());

    Events.on('Model.driversMessages.createItem.error', () => resetGrid());

    return () => {
      Events.off(['Model.driversMessages.createItem.success', 'Model.driversMessages.createItem.error'], true);
    };
  }, []);

  const columns = [
    {
      headerName: 'Data',
      field: 'sendDate',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: cellProps => {
        if (!cellProps.data) {
          return <Icon name="spinner" fa={IconFa.Fal} spin />;
        }

        return (
          <p>
            {formattedDate(cellProps.data.sendDate)} {formattedTime(cellProps.data.sendDate)}
          </p>
        );
      },
    },
    {
      headerName: 'Treść',
      field: 'content',
      sortable: false,
      filter: 'agTextColumnFilter',
      cellRendererFramework: cellProps => {
        if (!cellProps.data) {
          return <Icon name="spinner" fa={IconFa.Fal} spin />;
        }

        return <p className="text-truncate">{cellProps.data.content}</p>;
      },
    },
    {
      headerName: 'Odbiorcy',
      field: 'drivers',
      cellRendererFramework: cellProps => {
        if (!cellProps.data) {
          return <Icon name="spinner" fa={IconFa.Fal} spin />;
        }

        const preparedDriversNames = cellProps.data.sendTo
          ? `${cellProps.data.sendTo
              .map(driver => driver.name)
              .slice(0, 3)
              .join(', ')}${cellProps.data.sendTo.length > 3 ? '...' : ''}`
          : '';
        const tooltipDriversNames = cellProps.data.sendTo ? cellProps.data.sendTo.map(driver => driver.name) : [];

        return (
          <Tooltip
            text={
              <p className="text-pre-line">
                {tooltipDriversNames.length > 1 ? tooltipDriversNames.join(', ') : tooltipDriversNames}
              </p>
            }
          >
            <p className="text-truncate">{preparedDriversNames}</p>
          </Tooltip>
        );
      },
    },
    {
      headerName: 'Przeczytana',
      field: 'readByPercentage',
      cellRendererFramework: cellProps => {
        if (!cellProps.data) {
          return <Icon name="spinner" fa={IconFa.Fal} spin />;
        }

        return <p>{`${cellProps.data.percentageReadBy ? `${cellProps.data.percentageReadBy}%` : ''}`}</p>;
      },
    },
    {
      headerName: '',
      field: 'detailsButton',
      cellRendererFramework: cellProps => {
        if (!cellProps.data) {
          return <Icon name="spinner" fa={IconFa.Fal} spin />;
        }

        return (
          <Button size={ButtonSize.Small} onClick={() => props.onItemClick(cellProps.data)}>
            Szczegóły
          </Button>
        );
      },
    },
  ];

  return (
    <DriversMessagesTableContainer>
      <AgGridTable
        columnDefs={columns}
        pagination
        rowModelType="infinite"
        cacheBlockSize={PAGINATION_LIMIT}
        paginationPageSize={PAGINATION_LIMIT}
        maxConcurrentDatasourceRequests={1}
        maxBlocksInCache={1}
        cacheOverflowSize={0}
        onGridReady={params => {
          resetGrid = () => {
            params.api.refreshInfiniteCache();
            resetStartRowRef.current = true;
          };

          params.api.setDatasource(dataSource);
        }}
      />
    </DriversMessagesTableContainer>
  );
});

DriversMessagesTable.defaultProps = defaultProps;

export { DriversMessagesTable };
