import { Empty, message } from 'antd';

import { PageLayout, PromotionModal } from 'components';
import { Events } from 'lib/services';
import { Button, ButtonVariant, DimmerLoading, Group, Loading } from 'lib/ui';
import { Promotion } from 'modules/Promotions';
import { PromotionsTable } from 'modules/Promotions/components/PromotionsTable';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useToggle from 'react-use/lib/useToggle';

export interface PromotionsPageProps {}

const PromotionsPage: React.FC<PromotionsPageProps> = props => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  //  load collection at page start
  useEffect(() => {
    setLoading(true);
    dispatch({
      type: 'promotions/loadCollection',
    });
  }, []);

  const promotions = useSelector(store => store.promotions);

  //  modals visibility & toggles
  const [formModalVisible, toggleFormModal] = useToggle(false);
  //  edited / selected item
  const [editedItem, setEditedItem] = useState<Promotion | undefined>(undefined);

  //  Events
  useEffect(() => {
    Events.on('Model.promotions.createItem.success', data => {
      message.success('Promocja została dodana');
      toggleFormModal(false);
      setEditedItem(undefined);
    });
    Events.on('Model.promotions.updateItem.success', data => {
      message.success('Promocja została zaktualizowana');
      toggleFormModal(false);
      setEditedItem(undefined);
      dispatch({
        type: 'promotions/loadCollection',
      });
    });
    Events.on('Model.promotions.update_position.success', data => {
      message.success('Kolejność promocji została zaktualizowana');
      dispatch({
        type: 'promotions/loadCollection',
      });
    });
    Events.on('Model.promotions.loadCollection.success', data => {
      setLoading(false);
    });

    return () => {
      Events.off('Model.promotions.createItem.success', true);
      Events.off('Model.promotions.updateItem.success', true);
      Events.off('Model.promotions.update_position.success', true);
      Events.off('Model.promotions.loadCollection.success', true);
    };
  }, []);

  return (
    <>
      <PageLayout title="Promocje">
        <Group align="space-between">
          <Group align="right">
            <Button
              label="Dodaj promocję"
              variant={ButtonVariant.Primary}
              onClick={() => {
                setEditedItem(undefined);
                toggleFormModal();
              }}
            />
          </Group>
        </Group>

        {!promotions.data.length && !loading && <Empty description="Brak danych" />}

        {!promotions.data.length && loading && <Loading text="Ładowanie danych" />}

        {promotions.data.length > 0 && (
          <div style={{ position: 'relative' }}>
            <PromotionsTable
              items={promotions.data}
              onEditClick={item => {
                setEditedItem(item);
                toggleFormModal(true);
              }}
              onStatusChangeClick={promotion => {
                dispatch({
                  type: 'promotions/updateItem',
                  payload: {
                    id: promotion.id,
                    data: {
                      isActive: !promotion.isActive,
                    },
                  },
                });
              }}
              onOrderChange={rowIds => {
                setLoading(true);
                dispatch({
                  type: 'promotions/customCollectionAction',
                  payload: {
                    action: 'update_position',
                    data: {
                      promotionsList: rowIds.map((id, index) => ({ id, position: index + 1 })),
                    },
                  },
                });
              }}
            />
            <DimmerLoading visible={loading} />
          </div>
        )}
      </PageLayout>

      {formModalVisible && (
        <PromotionModal
          visible={formModalVisible}
          item={editedItem}
          errors={promotions.errors}
          onSubmit={(values, bag) => {
            //  build paylod as FormData to properly upload image
            const formData = new FormData();
            Object.keys(values).forEach(valueKey => {
              if (typeof values[valueKey] !== 'undefined') {
                formData.append(valueKey, values[valueKey]);
              }
            });
            //  request
            if (editedItem && editedItem.id) {
              dispatch({
                type: 'promotions/updateItem',
                payload: {
                  id: editedItem.id,
                  data: formData,
                },
              }).catch(() => bag.setSubmitting(false));
            } else {
              dispatch({
                type: 'promotions/createItem',
                payload: {
                  data: formData,
                },
              }).catch(() => bag.setSubmitting(false));
            }
          }}
          onClose={() => {
            toggleFormModal(false);
          }}
        />
      )}
    </>
  );
};

export { PromotionsPage };
