import React, { useEffect, useState } from 'react';
import { Input } from '..';

import { useDebounce } from '../../hooks';
import { Icon } from '../../ui';
import { SearchInputClearButtonContainer } from './SearchInputClearButtonContainer';

import { SearchInputContainer } from './SearchInputContainer';

export interface SearchInputProps {
  value?: string;
  placeholder?: string;
  debounceTime?: number;
  onChange: (query: string) => void;
  onEnter?: (query: string) => void;
  onBlur?: () => void;
  onClearButton?: () => void;
  clearOnEscape?: boolean;
}

const SearchInputDefaultProps = {
  debounceTime: 250,
  value: '',
  placeholder: '',
  clearOnEscape: false,
};

const SearchInput = React.forwardRef((props: SearchInputProps, ref) => {
  const [innerValue, setInnerValue] = useState(props.value || '');
  const innerValueDebounced = useDebounce(innerValue, props.debounceTime);

  useEffect(() => {
    if (props.debounceTime) {
      props.onChange(innerValueDebounced);
    }
  }, [innerValueDebounced, props.debounceTime, props.onChange]);

  useEffect(() => {
    if (!props.debounceTime) {
      props.onChange(innerValue);
    }
  }, [innerValue, props.debounceTime, props.onChange]);

  return (
    <SearchInputContainer>
      <Input
        value={innerValue}
        // @ts-ignore
        ref={ref}
        onChange={ev => {
          setInnerValue(ev.target.value);
        }}
        onBlur={() => {
          if (props.onBlur) {
            props.onBlur();
          }
        }}
        onKeyDown={event => {
          if (event.key === 'Escape' && props.clearOnEscape) {
            setInnerValue('');
          }
          if (event.key === 'Enter' && props.onEnter) {
            props.onEnter(innerValue);
          }
        }}
        placeholder={props.placeholder}
      />
      {innerValue.length > 0 && (
        <SearchInputClearButtonContainer
          onClick={() => {
            if (props.onClearButton) {
              props.onClearButton();
            }
            setInnerValue('');
          }}
        >
          <Icon name="times" />
        </SearchInputClearButtonContainer>
      )}
    </SearchInputContainer>
  );
});

SearchInput.defaultProps = SearchInputDefaultProps;

export { SearchInput };
