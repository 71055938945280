import { Drawer as DrawerAntd, PageHeader } from 'antd';
import { DrawerProps as DrawerAntdProps } from 'antd/lib/drawer';
import React, { FunctionComponent, ReactNode, useCallback, useRef } from 'react';

import { Divider, Icon, IconSize } from '../..';
import { useKeyPressDown } from '../../../hooks';

import { DrawerInner } from './DrawerInner';
import { DrawerContent } from './DrawerContent';

export interface DrawerProps extends DrawerAntdProps {
  children?: ReactNode;
  onClose: () => void;
  visible: boolean;
  closeOnEsc?: boolean;
  displayCloseButton?: boolean;
  mask?: boolean;
  title?: string;
  subTitle?: React.ReactNode;
  align?: string;
  width?: number | string;
  height?: number | string;
}

const Drawer: FunctionComponent<DrawerProps> = ({
  visible,
  onClose,
  title,
  subTitle,
  children,
  align,
  width,
  height,
  closeOnEsc,
  displayCloseButton,
  mask,
  placement,
  ...rest
}) => {
  const escapeCallback = useCallback(() => {
    if (visible && closeOnEsc) {
      onClose();
    }
  }, [visible, closeOnEsc, onClose]);
  useKeyPressDown('Escape', escapeCallback, {});

  //  ref for drawer content
  const drawerContentRef = useRef(null);

  const displayPageHeader = title || subTitle || displayCloseButton;

  return (
    <DrawerAntd
      width={width}
      height={height}
      placement={placement || 'right'}
      closable={false}
      visible={visible}
      mask={mask}
      onClose={onClose}
      {...rest}
      bodyStyle={{
        overflow: 'hidden',
        height: '100%',
        ...rest.bodyStyle,
      }}
    >
      <DrawerInner>
        {displayPageHeader && (
          <>
            <PageHeader
              onBack={() => onClose()}
              backIcon={displayCloseButton ? <Icon name={'times'} size={IconSize.Small} /> : null}
              title={title}
              subTitle={subTitle}
              style={{
                textAlign: 'left',
                padding: 0,
              }}
            />
            <Divider />
          </>
        )}
        <DrawerContent align={align} ref={drawerContentRef}>
          {children}
        </DrawerContent>
      </DrawerInner>
    </DrawerAntd>
  );
};

const DrawerDefaultProps = {
  align: 'left',
  left: () => {},
  visible: false,
  closeOnEsc: true,
  displayCloseButton: true,
  mask: true,
  width: 750,
  height: '100%',
};

Drawer.defaultProps = DrawerDefaultProps;

export { Drawer };
