import { AgGridTable, formattedDate, formattedTime, Price, styled, Tooltip } from 'lib/ui';
import { path } from 'rambdax';
import React, { FunctionComponent, useState } from 'react';
import NumericEditor from 'components/drawers/RestaurantSettlementDrawer/NumericEditor';

export interface RestaurantSettlementDrawerTableProps {
  items: any;
  onValueChanged: Function;
}

const RestaurantSettlementDrawerTableContainer = styled.div`
  width: 100%;
`;

const RestaurantSettlementDrawerSumTableContainer = styled.div`
  .table-sum-wrapper {
    min-height: 0 !important;
    width: 100%;
    height: 32px;

    .ag-root {
      border: none;

      .ag-header.ag-pivot-off {
        display: none;
      }
      
      .ag-center-cols-container {
        border-top: 1px solid #BDC3C7!important;
      }
      
      .ag-row.ag-row-no-focus.ag-row-even.ag-row-level-0.ag-row-position-absolute.ag-row-first.ag-row-last {
         border: none;
    }
  }
`;

const RestaurantSettlementDrawerTable: FunctionComponent<RestaurantSettlementDrawerTableProps> = props => {
  const [editingValue, setEditingValue] = useState<any>();
  const currencyFormatter = params => `${params.value / 100} zł`;

  const columns = [
    {
      headerName: 'Data zamówienia',
      field: 'insertDate',
      sortable: true,
      filter: 'agTextColumnFilter',
      minWidth: 170,
      cellRendererFramework: cellProps => (
        <span>
          {formattedDate(cellProps.data.insertDate)} o {formattedTime(cellProps.data.insertDate)}
        </span>
      ),
    },
    {
      headerName: 'Wartość koszyka',
      field: 'gross',
      cellRendererFramework: cellProps => (
        <span>
          <Price amount={cellProps.data.gross} />
        </span>
      ),
    },
    {
      headerName: 'Potrącenie przed prowizją',
      field: 'beforeCommisionDock',
      editable: true,
      cellEditor: 'numericEditor',
      dataIndex: 'beforeCommisionDock',
      valueFormatter: currencyFormatter,
    },
    {
      headerName: 'Potrącenie po prowizji',
      field: 'afterCommisionDock',
      cellEditor: 'numericEditor',
      valueFormatter: currencyFormatter,
      cellEditorSelectorFramework: 'numericCellEditor',
      editable: true,
      dataIndex: 'afterCommisionDock',
    },
    {
      headerName: 'Komentarz',
      field: 'dockDescription',
      editable: true,
      dataIndex: 'dockDescription',
    },
  ];

  const bottomData = [
    {
      headerName: '',
      field: '',
      minWidth: 170,
    },
    {
      headerName: '',
      field: 'basket',
      cellRendererFramework: cellProps => {
        return <Price amount={cellProps.data.ordersGrossSum} />;
      },
    },
    {
      headerName: '',
      field: 'beforeCommisionDock',
      cellRendererFramework: cellProps => {
        return <Price amount={cellProps.data.beforeCommisionDockSum} />;
      },
    },
    {
      headerName: '',
      field: 'afterCommisionDock',
      cellRendererFramework: cellProps => {
        return <Price amount={cellProps.data.afterCommisionDockSum} />;
      },
    },
    {
      headerName: '',
      field: '',
    },
  ];

  const topOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
    suppressHorizontalScroll: true,
  };

  const bottomOptions = {
    alignedGrids: [],
    defaultColDef: {
      flex: 1,
      minWidth: 100,
    },
  };

  return (
    <RestaurantSettlementDrawerTableContainer>
      <AgGridTable
        columnDefs={columns}
        rowData={props.items.orders}
        deltaRowDataMode
        gridOptions={topOptions}
        getRowNodeId={data => data.id}
        defaultColDef={{ resizable: true }}
        onCellEditingStarted={e =>
          setEditingValue(e.value !== null && e.value !== undefined ? e.value.toString() : e.value)
        }
        onCellEditingStopped={e => {
          if (editingValue) {
            if (e.value === '' || e.value.toString() !== editingValue.toString()) {
              props.onValueChanged(e);
            }
          }
        }}
        singleClickEdit
        frameworkComponents={{
          agColumnHeader: props => {
            const tooltipText = path<string>('column.userProvidedColDef.headerTooltip', props);
            return (
              <Tooltip text={tooltipText}>
                <div className="text-truncate">{props.displayName}</div>
              </Tooltip>
            );
          },
          numericEditor: NumericEditor,
        }}
      />
      <RestaurantSettlementDrawerSumTableContainer>
        <div className={'table-sum-wrapper'}>
          <AgGridTable
            gridOptions={bottomOptions}
            columnDefs={bottomData}
            deltaRowDataMode
            getRowNodeId={data => data.id}
            rowData={[props.items]}
            headerHeight={0}
            rowStyle={{ fontWeight: 'bold', minHeight: '0' }}
          />
        </div>
      </RestaurantSettlementDrawerSumTableContainer>
    </RestaurantSettlementDrawerTableContainer>
  );
};

export { RestaurantSettlementDrawerTable };
