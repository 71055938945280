import { styled } from '../../styled-components';

// {
//   strong?: boolean;
// }
export const DividerText = styled.div.attrs({ className: 'DividerText' })`
  display: inline-block;
  padding: ${p => p.theme.spaceSmall} ${p => p.theme.space};
  font-weight: ${p => (p.strong ? p.theme.fontWeightBold : p.theme.fontWeightDefault)};
`;
