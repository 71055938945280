/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FormikProps, FormikValues } from 'formik';
import { AddressField, FormField, FormTextareaField } from 'lib/forms';
import { Group } from 'lib/ui';
import React, { FunctionComponent } from 'react';

export interface CompanyFieldProps extends FormikProps<FormikValues> {
  companyName: string;
  vatNumber: string;
  notes: string;
  label?: string;
  type?: string;
  vertical?: boolean;
  onEnter?: Function;
}

const CompanyFormDefaultProps = {};

export const CompanyField: FunctionComponent<CompanyFieldProps> = props => {
  return (
    <Group width="100%" vertical={props.vertical}>
      <FormField name={props.companyName} {...props} label="Firma" width={'100%'} />
      <FormField name={props.vatNumber} {...props} label="NIP" width={'100%'} />
      <AddressField
        label="Adres"
        cityFieldName="addressCity"
        postalCodeFieldName="addressPostalCode"
        houseNumberFieldName="addressHouseNumber"
        streetFieldName="addressData"
        detailsFieldName="addressDetails"
        vertical
        {...props}
      />
      <FormTextareaField name={props.notes} {...props} label="Uwagi" width={'100%'} />
    </Group>
  );
};

CompanyField.defaultProps = CompanyFormDefaultProps;
