/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Empty, message } from 'antd';

import { PageLayout } from 'components';
import { SearchInput } from 'lib/forms';
import { Button, ButtonVariant, DimmerLoading, Group, Header, Loading } from 'lib/ui';
import { ClientDrawer, ClientModal, ClientsTable } from 'modules/Clients';
import React, { useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import useToggle from 'react-use/lib/useToggle';

const enhance = connect(
  ({ clients, loading }) => ({
    clientsStore: clients,
    loading,
  }),
  ({ clients }) => ({
    clients,
  }),
);

const ClientsPage = enhance(props => {
  //  currently selected item (for edit or to see details)
  const [item, setItem] = useState(null);
  //  form visible & toggle
  const [formVisible, toggleForm] = useToggle(false);
  //  details visible & toggle
  const [detailsVisible, toggleDetails] = useToggle(false);

  // search query
  const [query, setQuery] = useState('');
  const [lastUsedQuery, setLastUsedQuery] = useState('');
  const [loading, toggleLoading] = useToggle(false);

  const loadClients = (searchQuery?: string) => {
    const queryString = searchQuery || query;
    if (queryString && !loading) {
      toggleLoading(true);
      setLastUsedQuery(queryString);
      props.clients
        .loadCollection({
          params: {
            search: queryString,
          },
        })
        .then(() => {
          toggleLoading(false);
        });
    }
  };

  const items = props.clientsStore.data.filter(item => item.id !== 1);

  return (
    <PageLayout title="Klienci">
      <Group align="space-between">
        <Group vertical>
          <Group>
            <SearchInput
              value={query}
              onChange={setQuery}
              onEnter={currentQuery => {
                setQuery(currentQuery);
                loadClients(currentQuery);
              }}
              placeholder="Wyszukaj klienta"
            />
            <Button
              label="Szukaj"
              variant={ButtonVariant.Primary}
              disabled={loading}
              onClick={() => {
                loadClients();
              }}
            />
          </Group>
          <span>Podaj imę, nazwisko, email, nazwę uyżytkownika, telefon lub adres szukanego klienta.</span>
        </Group>
        <Group align="right" width="auto">
          <Button
            label="Dodaj klienta"
            variant={ButtonVariant.Primary}
            onClick={() => {
              setItem(null);
              toggleForm(true);
            }}
          />
        </Group>
      </Group>

      {lastUsedQuery && (
        <Header>
          Wyniki wyszukiwania dla frazy: <em>{lastUsedQuery}</em>
        </Header>
      )}

      {!items.length && !props.loading.effects.clients.loadCollection && (
        <Empty description="Brak danych do wyświetlenia" />
      )}

      {!items.length && props.loading.effects.clients.loadCollection && <Loading text="Ładowanie danych" />}

      {items.length > 0 && (
        <div style={{ position: 'relative' }}>
          <ClientsTable
            items={items}
            onItemClick={item => {
              setItem(item);
              toggleDetails(true);
            }}
            onItemEditClick={item => {
              setItem(item);
              toggleForm(true);
            }}
            onFlagChange={(item, flagName) => {
              const hide = message.loading(`Aktualizuję dane klienta: ${item.user.firstName} `, 0);
              props.clients
                .updateItem({
                  id: item.id,
                  data: {
                    [flagName]: !item[flagName],
                  },
                })
                .then(() => {
                  message.success(`Klient ${item.user.firstName} zaktualizowany.`);
                })
                .catch(() => {
                  message.error(`Nie udało się zaktualizować klienta: ${item.user.firstName}`);
                })
                .finally(hide);
            }}
          />
          <DimmerLoading visible={props.loading.effects.clients.loadCollection} />
        </div>
      )}

      {formVisible && (
        <ClientModal
          item={item}
          visible
          onClose={() => {
            toggleForm(false);
          }}
        />
      )}

      {detailsVisible && (
        <ClientDrawer
          item={item}
          visible
          onClose={() => {
            toggleDetails(false);
          }}
          onEditClick={() => {
            toggleDetails(false);
            toggleForm(true);
          }}
          onOrdersHistoryClick={clientId => {
            props.history.push(`/clients/${clientId}/orders`);
          }}
        />
      )}
    </PageLayout>
  );
});

export { ClientsPage };
