import React, { useEffect, useState } from 'react';
import { Empty, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { PageLayout } from 'components';
import { Loading } from 'lib/ui';

const ClientsOrderPage = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<Boolean>(false);

  const { clientId } = props.match.params;
  const clients = useSelector(state => state.clients.dataById);
  const client = clients && clients[clientId];

  useEffect(() => {
    setData([]);
    dispatch({
      type: 'clients/loadItem',
      payload: {
        id: clientId,
      },
    });

    dispatch({
      type: 'orders/loadCollection',
      payload: {
        customer: clientId,
      },
    })
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(err => {
        message.error('Wystąpił nieoczekiwany błąd');
        setLoading(false);
        setData([]);
      });
  }, [clientId, dispatch]);

  if (loading) {
    return <Loading text="Ładowanie danych" />;
  }

  if (!data || !client || data.length === 0) {
    return <Empty description="Brak danych" />;
  }

  return (
    <PageLayout title={`${client.user.firstName} ${client.user.lastName} - historia zamówień`}>
      <p>{data.length} zamówień</p>
    </PageLayout>
  );
};

export { ClientsOrderPage };
