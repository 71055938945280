import { ColumnProps, TableProps as TableAntdProps } from 'antd/es/table';
import React, { FunctionComponent } from 'react';
import { Table as TableAntd } from 'antd';

export interface TableProps extends TableAntdProps<any> {
  bordered?: boolean;
  compact?: boolean;
  columns: ColumnProps<any>[];
  rows: any[];
}

const Table: FunctionComponent<TableProps> = ({ columns, rows, compact, bordered }) => {
  // console.log('Table columns', columns);
  // console.log('Table rows', rows);

  return (
    <TableAntd
      columns={columns}
      dataSource={rows}
      pagination={false}
      size={compact ? 'small' : 'default'}
      bordered={bordered}
    />
  );
};

Table.defaultProps = {
  compact: true,
  columns: [],
  rows: [],
};

export { Table };
