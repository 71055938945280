import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import classnames from 'classnames';

import { Button, ButtonSize, ButtonVariant, Overlay, OverlayProps } from '../..';
import { useKeyPressDown } from '../../../hooks';

import styles from './Modal.module.scss';

export interface ModalProps extends OverlayProps {
  onClose?: Function;
  onEsc?: Function;
  closeOnEsc?: boolean;
  closable?: boolean;
  visible?: boolean;
  zIndex?: number;
  full?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  position?: 'top' | 'center' | 'bottom';
  width?: string;
}

const Modal: FunctionComponent<ModalProps> = props => {
  const { visible, onClose, closable, closeOnEsc, onEsc } = props;

  const escapeCallback = useCallback(() => {
    if (!visible) {
      return;
    }
    if (onEsc) {
      onEsc();
    }
    if (closable && closeOnEsc && onClose) {
      onClose();
    }
  }, [visible, closable, onClose, onEsc, closeOnEsc]);
  useKeyPressDown('Escape', escapeCallback, {});

  const modalWidth = useMemo(() => {
    if (props.width) {
      return props.width;
    }
    if (props.full) {
      return '90%';
    }
    return 'auto';
  }, [props.width, props.full]);

  if (!props.visible) {
    return null;
  }

  return (
    <Overlay zIndex={props.zIndex} ms={props.ms} color={props.color} verticalAlign={props.position}>
      <div
        className={classnames(styles.ModalContainer, {
          [styles.full]: props.full,
        })}
        style={{
          width: modalWidth,
        }}
      >
        <div className={styles.ModalCloseButton}>
          <Button
            iconName="times"
            size={ButtonSize.Large}
            onClick={() => {
              if (props.closable && props.onClose) {
                props.onClose();
              }
            }}
            variant={ButtonVariant.Plain}
          />
        </div>
        <div className={styles.Modal}>
          {props.header && <div className={styles.ModalHeader}>{props.header}</div>}
          <div className={styles.ModalBody}>{props.children}</div>
          {props.footer && <div className={styles.ModalFooter}>{props.footer}</div>}
        </div>
      </div>
    </Overlay>
  );
};

Modal.defaultProps = {
  visible: false,
  full: false,
  closeOnEsc: true,
  closable: true,
  onClose: () => {},
  onEsc: () => {},
  position: 'center',
};

export { Modal };
