import { message } from 'antd';
import React, { FunctionComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { Button, ButtonVariant, Drawer, DrawerProps, Group, modalConfirm, Spacer } from 'lib/ui';
import { FormFieldLabel } from 'lib/forms';
import { weekdays } from 'lib/ui/utils';
import { EmployeeRoles } from '..';

const enhance = connect(
  ({ employees }) => ({
    employeesStore: employees,
  }),
  ({ employees }) => ({
    employees,
  }),
);
export interface EmployeeDrawerProps extends DrawerProps {
  item: any;
  onEditClick: Function;
}

const EmployeeDrawer: FunctionComponent<EmployeeDrawerProps> = enhance(({ item, ...props }) => {
  const closeDrawer = () => {
    props.onClose();
  };
  const itemRole = EmployeeRoles.find(role => role.key === item.role);

  return (
    <Drawer
      displayCloseButton
      closeOnEsc
      onClose={closeDrawer}
      visible={props.visible}
      title={`${'Szczegóły pracownika'}`}
      destroyOnClose
    >
      <Group vertical>
        <Group>
          <span>Imię i nazwisko:</span>
          <span>
            <strong>
              {item.user.firstName} {item.user.lastName}
            </strong>
          </span>
        </Group>
        <Group>
          <span>Telefon:</span>
          <span>
            <strong>{item.phone}</strong>
          </span>
        </Group>
        <Group>
          <span>Login:</span>
          <span>
            <strong>{item.user.email}</strong>
          </span>
        </Group>
        <Group>
          <span>Rola:</span>
          <span>
            <strong>{itemRole && itemRole.label}</strong>
          </span>
        </Group>
        <Group verticalAlign="top">
          <span className="u-flex-no-shrink">Uwagi:</span>
          <span className="text-pre-line">
            <strong>{item.notes}</strong>
          </span>
        </Group>

        <Spacer />

        <Group align="space-between">
          {weekdays.map(day => (
            <Group vertical width="90px" key={day.shortnameEn}>
              <FormFieldLabel>{day.shortnamePl}</FormFieldLabel>
              <strong>{(item[`hourRate${day.shortnameEn}`] / 100).toFixed(2)}</strong>
            </Group>
          ))}
        </Group>

        <Spacer />
        <Group align="space-between">
          <Button variant={ButtonVariant.Primary} label="Zmień" onClick={props.onEditClick} />
          <Button
            label="Usuń pracownika"
            onClick={() => {
              modalConfirm({
                title: 'Czy na pewno usunąć pracownika?',
                content: ' ',
                okText: 'Usuń',
                yesCallback: () => {
                  const hide = message.loading('Usuwam pracownika...', 0);
                  props.employees
                    .deleteItem({
                      id: item.id,
                    })
                    .then(() => {
                      message.success('Pracownik usunięty');
                      closeDrawer();
                    })
                    .catch(() => {
                      message.error('Wystąpił nieoczekiwany bład');
                    })
                    .finally(hide);
                },
              });
            }}
          />
        </Group>
      </Group>
    </Drawer>
  );
});

export { EmployeeDrawer };
