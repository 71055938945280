import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

export interface MealExtras {
  id: number;
  extras: number;
  priceChange: number;
}

// SPICY_LEVEL_DICT = ((0, "Łagodna"), (10, "Średnia"), (20, "Ostra"), (30, "Extra ostra"))
export type SpicyLevel = 0 | 10 | 20 | 30;

// todo - add type
export interface Meal {
  id: number;
  namePl: string;
  nameEn: string;
  descriptionPl: string;
  descriptionEn: string;
  price: number;
  index: string;
  oldWwwId: string;
  note: string;
  spicyLevel: SpicyLevel;
  isVegetarian: boolean;
  isVegan: boolean;
  isBeverage: boolean;
  isAlcohol: boolean;
  isGlutenFree: boolean;
  isNew: boolean;
  isLunch: boolean;
  position: boolean;
  image: string;
  disableFrom: string;
  disableTo: string;
  requiredExtras: number;
  extras: MealExtras[];
  userLikes: string[];
}

export const RestaurantMealModel = SimpleRestModel({
  collectionName: 'restaurantsMeals',
  HttpApi,
  getApiPath: (payload, action: string) => {
    switch (action) {
      case 'loadCollection':
      case 'createItem':
      default:
        return `restaurants/${payload.restaurantId}/meals/`;
      case 'loadItem':
      case 'updateItem':
      case 'deleteItem':
        return `restaurants/${payload.restaurantId}/meals/${payload.id}/`;
      case 'customCollectionAction':
        return `restaurants/${payload.restaurantId}/meals/${payload.action}/`;
      case 'customItemAction':
        return `restaurants/${payload.restaurantId}/meals/${payload.id}/${payload.action}/`;
    }
  },
});

export const MealUtils = {
  mealContainsPhrase: (meal: Meal, query: string): boolean => {
    const _query = query.toLowerCase();
    const mealIndex = meal.index || '';
    const content = `${meal.namePl.toLowerCase()}${meal.nameEn.toLowerCase()}${meal.descriptionPl.toLowerCase()}${meal.descriptionEn.toLowerCase()}${mealIndex.toLowerCase()}`;
    return content.indexOf(_query) > -1;
  },
};
