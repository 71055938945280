import { message } from 'antd';
import { PageLayout } from 'components';
import { Textarea } from 'lib/forms';
import { Box, Button, ButtonVariant, Spacer, Group } from 'lib/ui';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormState } from 'modules/Orders/OrderFormState';
import { OrderFormDriverBonus } from 'pages/OrderFormPage/OrderFormPageStep5/components/OrderFormDriverBonus';
import { compose } from 'rambdax';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { useObservable, useToggle } from 'react-use';
import { OrderFormBasket } from '../components/OrderFormBasket';
import { OrderFormBasketDeliveryDate } from '../components/OrderFormBasketDeliveryDate';
import { OrderFormCompany } from '../components/OrderFormCompany';
import { OrderFormClient } from '../OrderFormPageStep2/OrderFormClient';
import { OrderFormSteps } from '../OrderFormSteps';
import { OrderFormPaymentTypePicker } from './components/OrderFormPaymentTypePicker';
import { OrderFormPromotionPicker } from './components/OrderFormPromotionPicker';

const enhance = compose(
  connect(
    ({ orders, loading }) => ({
      loading,
      ordersStore: orders,
    }),
    ({ orders }) => ({
      orders,
    }),
  ),
);

export const OrderFormPageStep5 = enhance(props => {
  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());
  const [isLoading, toggleLoading] = useToggle(false);

  if (!orderState) {
    return null;
  }

  const saveOrder = () => {
    toggleLoading();
    const data = OrderFormService.getModelForCreate();
    const hide = message.loading('Zapisuję zamówienie...', 0);
    props.orders
      .createItem({
        data,
      })
      .then(res => {
        message.success('Zamówienie zapisane');
        props.history.push('/orders');
      })
      .catch(err => {
        if (err && err.data && err.data.detail) {
          message.error(err.data.detail);
        } else if (err && err.data && err.data.error) {
          message.error(err.data.error);
        } else {
          message.error('Wystąpił błąd podczas zapisywania zamówienia');
        }
      })
      .finally(() => {
        hide();
        toggleLoading();
      });
  };

  const clientNote = orderState.clientNote || (orderState.client ? orderState.client.notes : '');

  return (
    <PageLayout title="Tworzenie nowego zamówienia" header={<OrderFormSteps activePage={5} />}>
      <Group verticalAlign="top">
        <Group displayBlock vertical>
          <OrderFormBasket editable={false} key={'orderBasket'} />
          {!orderState.withDirectPickUp && (
            <Box title="Bonus / kara">
              <OrderFormDriverBonus orderState={orderState} />
            </Box>
          )}
          <Button label="Zapisz zamówienie" variant={ButtonVariant.Primary} onClick={saveOrder} loading={isLoading} />
        </Group>
        <Group displayBlock vertical>
          <Box title={orderState.withDirectPickUp ? 'Termin odbioru osobistego' : 'Termin dostawy'}>
            <OrderFormBasketDeliveryDate
              date={orderState.deliveryDate}
              onChange={date => {
                OrderFormService.setState({
                  deliveryDate: date,
                });
              }}
            />
          </Box>
          <Box title="Klient">
            <OrderFormClient addressEditable={false} shortForm />
          </Box>
          <Box title="Płatność">
            <OrderFormPaymentTypePicker selected={orderState.paymentType} />
          </Box>
        </Group>
        <Group displayBlock vertical>
          <Box title="Dane do FV">
            <OrderFormCompany />
          </Box>
          <Box title="Ogólne uwagi do zamówienia">
            <Textarea
              style={{ width: '100%', height: '100px' }}
              value={orderState.note}
              onChange={ev =>
                OrderFormService.setState({
                  note: ev.currentTarget.value,
                })
              }
              height="200px"
            />
          </Box>
          <Box title="Informacja poufna">
            <Textarea
              style={{ width: '100%', height: '100px' }}
              value={orderState.privateNote}
              onChange={ev =>
                OrderFormService.setState({
                  privateNote: ev.currentTarget.value,
                })
              }
              height="200px"
            />
          </Box>
          <Box title="Informacje o kliencie">
            <Textarea
              style={{ width: '100%', height: '100px' }}
              value={clientNote}
              onChange={ev =>
                OrderFormService.setState({
                  clientNote: ev.currentTarget.value,
                })
              }
            />
          </Box>
          <Box title="Inne">
            <OrderFormPromotionPicker selected={orderState.promotion} />
          </Box>
        </Group>
      </Group>
      <div data-bp="grid 4">
        <div>
          <div data-bp="grid 12">
            <div></div>
          </div>
        </div>

        <div data-bp="grida">
          <div data-bp="12"></div>

          <div data-bp="12"></div>

          <div data-bp="12">
            <Spacer />
          </div>
        </div>

        <div>
          <div data-bp="grid vertical-start">
            <div data-bp="12"></div>

            <div data-bp="12">
              <Spacer />
            </div>
            <div data-bp="12">
              <Spacer />
            </div>

            <div data-bp="12"></div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
});
