import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

export enum PaymentTypeType {
  Gotowka = 0,
  Platnosc_online_payu = 10,
  Platnosc_odroczona_przelew = 20,
}

export const PaymentTypeTypes = [
  { name: 'Gotówka', value: PaymentTypeType.Gotowka },
  { name: 'Płatność online - PayU', value: PaymentTypeType.Platnosc_online_payu },
  { name: 'Płatność odroczona - przelew', value: PaymentTypeType.Platnosc_odroczona_przelew },
];

export enum PaymentTypeVisbility {
  OnlineAndDriver = 0,
  OnlyWWW = 10,
  OnlyDriver = 20,
}

export const PaymentTypeVisibilities = [
  { name: 'WWW i kierowca', value: PaymentTypeVisbility.OnlineAndDriver },
  { name: 'Tylko www', value: PaymentTypeVisbility.OnlyWWW },
  { name: 'Tylko kierowca', value: PaymentTypeVisbility.OnlyDriver },
];

export interface PaymentType {
  id: number;
  position: number;
  namePl: string;
  nameEn: string;
  displayedName: string;
  isActive: boolean;
  paymentType: PaymentTypeType;
  paymentVisibility: PaymentTypeVisbility;
  icon: string | null;
}

export const PaymentTypesModel = SimpleRestModel({
  collectionName: 'paymentTypes',
  apiPath: 'settings/payment_types',
  HttpApi,
});
