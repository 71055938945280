import { MutableRefObject } from 'react';
import image from '../../../assets/icons/marker-images/marker-icon-green.png'
export type LatLng = number | string;

export const WARSAW_CENTER_LAT_LNG = [52.237049, 21.017532];
export const CUSTOM_MARKER_SET_LABEL = `Własna lokalizacja`;
export const ENTRANCE_MARKER_SET_LABEL = 'Dodaj klatkę - drugie wejście';
export const ENTRANCE_MARKER_DELETE_LABEL = 'Usuń klatkę - drugie wejście';

interface MapLabLayers {
  geoResultsMarkers: any;
  customMarker: any;
  entranceMarker: any;
}

interface MapLabRefs {
  map: MutableRefObject<any>;
  mapControls: MutableRefObject<any>;
  isCustomLocationSelected: MutableRefObject<boolean>;
  customMarker: MutableRefObject<any>;
  entranceMarker: MutableRefObject<any>;
}

interface MapLabCallbacks {
  setSelectedLatLon: Function;
  setEntranceLatLon: Function;
  setSaveButtonDisabled: Function;
  setEntranceButtonDisabled: Function;
}

/**
 * This service/helper class is used and initiated in MapLabModal.
 * It is coupled with MapLabModal which is not really good practice...
 * Maybe when there will more time, this will be changed to some better solution
 * but... most probably there will be no such extra time ;)
 * So let's just think about better solutions in the future
 */
export class MapLabService {
  private layers: MapLabLayers = {
    geoResultsMarkers: null,
    customMarker: null,
    entranceMarker: null
  };

  private refs: MapLabRefs = {
    map: { current: {} },
    mapControls: { current: {} },
    isCustomLocationSelected: { current: false },
    customMarker: { current: {} },
    entranceMarker: { current: {} }
  };

  private callbacks: MapLabCallbacks = {
    setSelectedLatLon: (): void => {},
    setEntranceLatLon: (): void => {},
    setSaveButtonDisabled: (): void => {},
    setEntranceButtonDisabled: (): void => {},
  };

  private hasAllDependencies = false;

  setDependencies = (refs: MapLabRefs, layers: MapLabLayers, callbacks: MapLabCallbacks) => {
    this.refs = { ...refs };
    this.layers = { ...layers };
    this.callbacks = { ...callbacks };
    this.hasAllDependencies = true;
  };

  getHasAllDependencies = () => this.hasAllDependencies;

  getLayers = () => this.layers;

  getRefs = () => this.refs;

  /**
   * - center map on selected location marker
   * - highlight the selected location name/button under the map
   *
   * @param location
   * @param locationIndex
   */
  selectLocationForGeoCoderResult = (location: any, locationIndex: number): void => {
    // add layer with markers found - if it is not in map
    if (!this.refs.map.current.hasLayer(this.layers.geoResultsMarkers)) {
      this.refs.map.current.addLayer(this.layers.geoResultsMarkers);
    }

    // remove custom marker layer - if the map has it
    if (this.refs.map.current.hasLayer(this.layers.customMarker)) {
      this.refs.map.current.removeLayer(this.layers.customMarker);
    }

    // remove entrance marker layer - if the map has it
    if (this.refs.map.current.hasLayer(this.layers.entranceMarker)) {
      this.refs.map.current.removeLayer(this.layers.entranceMarker);
    }

    const { bbox } = location;

    // center the map on address
    this.refs.map.current.fitBounds([
      [bbox._southWest.lat, bbox._southWest.lng],
      [bbox._northEast.lat, bbox._northEast.lng],
    ]);

    // disable click event for map to prevent setting the custom marker...
    this.refs.map.current.off('click');

    this.setSelectedLocationControl(locationIndex);

    this.callbacks.setSaveButtonDisabled(false);
    this.callbacks.setEntranceButtonDisabled(false);

    this.refs.isCustomLocationSelected.current = false;

    this.callbacks.setSelectedLatLon(location);

  };

  selectLocationForCustomMarker = (): void => {
    this.setSelectedLocationControl(-1);

    // setup click on map - user can add custom marker
    this.refs.map.current.removeLayer(this.layers.geoResultsMarkers);

    if (this.refs.customMarker.current) {
      this.layers.customMarker.addLayer(this.refs.customMarker.current);
      this.refs.map.current.addLayer(this.layers.customMarker);
    }

    this.prepareMapForCustomMarker();

    this.refs.isCustomLocationSelected.current = true;
  };

  /**
   * Highlight the selected location under the map and additionally updates the title/description.
   * Highlight is removed from all other addresses.
   *
   * @param indexToHighlight
   * @param newTitleForHighlightedElement
   */
  setSelectedLocationControl = (indexToHighlight = -1, newTitleForHighlightedElement = ''): void => {
    if (!this.getHasAllDependencies() || !this.refs.mapControls.current) {
      return;
    }

    const locations = this.refs.mapControls.current.querySelectorAll('span[class*="locationItem"]');

    if (locations.length) {
      // remove highlights from all element (avoid potential problem with )
      locations.forEach(locationElement => (locationElement.style.fontWeight = 'normal'));

      // highlight element if given index is more than -1
      if (indexToHighlight > -1) {
        locations[indexToHighlight].style.fontWeight = 'bold';
        if (newTitleForHighlightedElement) {
          locations[indexToHighlight].querySelector('span').innerHTML = newTitleForHighlightedElement;
        }
      }
    }
  };

  /**
   * Returns
   * @param address
   * @return { lat, lon}
   */
  getLatLonFromGeoData = (address): { lat: LatLng; lon: LatLng } => {
    const latLon = {
      lat: 0,
      lon: 0,
    };

    if (address && address.center) {
      latLon.lat = this.formatLatLonValue(address.center.lat);
      latLon.lon = this.formatLatLonValue(address.center.lng);
    }

    return latLon;
  };

  getEntranceLatLonFromGeoData = (address): { entranceLat: LatLng; entranceLon: LatLng } => {
    const entranceLatLon = {
      entranceLat: 0,
      entranceLon: 0
    };

    if (address && address.center) {
      entranceLatLon.entranceLat = this.formatLatLonValue(address.center.lat);
      entranceLatLon.entranceLon = this.formatLatLonValue(address.center.lng);
    }

    return entranceLatLon;
  };

  formatLatLonValue = (rawValue: LatLng): number => {
    if (rawValue) {
      // cut it to 6 digits and ensure the TS compiler will not complain about the types
      rawValue = +parseFloat(`${rawValue}`).toFixed(6);
    } else {
      rawValue = 0;
    }

    return rawValue;
  };

  /**
   * Get address center object from marker object
   *
   * @param marker
   */
  getAddressCenterFromMarker = (marker): { center: { lat: LatLng; lng: LatLng } } => {
    const latLng = marker.getLatLng();
    return {
      center: {
        lat: latLng.lat,
        lng: latLng.lng,
      },
    };
  };

  /**
   * Creates custom marker on the map
   *
   * @param latLng
   */
  createCustomMarker = (latLng: { lat: number; lng: number }) => {
    if (!this.layers.customMarker) {
      return;
    }

    this.layers.customMarker.clearLayers();

    const customMarker = new window.L.marker(latLng, { draggable: true });
    customMarker.bindPopup(
      `${CUSTOM_MARKER_SET_LABEL} | ${this.formatLatLonValue(latLng.lat)}, ${this.formatLatLonValue(latLng.lng)}`,
    );

    customMarker.on('dragend', () => {
      const dragendLatLng = this.getAddressCenterFromMarker(customMarker);

      customMarker
        .getPopup()
        .setContent(
          `${CUSTOM_MARKER_SET_LABEL} | ${this.formatLatLonValue(dragendLatLng.center.lat)}, ${this.formatLatLonValue(
            dragendLatLng.center.lng,
          )}`,
        )
        .update();

      // pass -1 -> unselect all geo-locations controls
      this.setSelectedLocationControl(-1);

      this.callbacks.setSelectedLatLon(dragendLatLng);
    });

    this.layers.customMarker.addLayer(customMarker);

    this.refs.map.current.addLayer(this.layers.customMarker);

    const currentLatLng = this.getAddressCenterFromMarker(customMarker);

    this.callbacks.setSelectedLatLon(currentLatLng);

    this.callbacks.setSaveButtonDisabled(false);

    return customMarker;
  };

  createEntranceMarker = (latLng: { lat: number; lng: number }) => {
    if (!this.layers.entranceMarker) {
      return;
    }

    const icon = new window.L.icon({
      iconUrl: image,
      iconSize: [25, 41],
      iconAnchor: [12, 41]
    });

    this.layers.entranceMarker.clearLayers();

    const entranceMarker = new window.L.marker(latLng, { draggable: true, icon });
    entranceMarker.bindPopup(
        `${ENTRANCE_MARKER_DELETE_LABEL} | ${this.formatLatLonValue(latLng.lat)}, ${this.formatLatLonValue(latLng.lng)}`,
    );

    entranceMarker.on('dragend', () => {
      const dragendLatLng = this.getAddressCenterFromMarker(entranceMarker);

      entranceMarker
          .getPopup()
          .setContent(
              `${ENTRANCE_MARKER_DELETE_LABEL} | ${this.formatLatLonValue(dragendLatLng.center.lat)}, ${this.formatLatLonValue(
                  dragendLatLng.center.lng,
              )}`,
          )
          .update();

      // pass -1 -> unselect all geo-locations controls
      this.setSelectedLocationControl(-1);

      this.callbacks.setEntranceLatLon(dragendLatLng);
    });

    this.layers.entranceMarker.addLayer(entranceMarker);

    this.refs.map.current.addLayer(this.layers.entranceMarker);

    const currentLatLng = this.getAddressCenterFromMarker(entranceMarker);

    this.callbacks.setEntranceLatLon(currentLatLng);

    this.callbacks.setEntranceButtonDisabled(false);

    return entranceMarker;
  };

  removeEntranceMarker = (): void => {
    this.layers.entranceMarker.clearLayers();
    this.callbacks.setEntranceLatLon(undefined);
    this.setSelectedLocationControl(-1);
    this.refs.map.current.removeLayer(this.layers.entranceMarker);
  };

  /**
   * If custom marker is already created, then centers map on it
   * otherwise centers map on Warsaw
   */
  prepareMapForCustomMarker = (): void => {
    // center on marker  OR on the Warsaw :)
    if (this.refs.customMarker.current) {
      const currentLatLng = this.getAddressCenterFromMarker(this.refs.customMarker.current);
      this.refs.map.current.setView([currentLatLng.center.lat, currentLatLng.center.lng], 17);

      this.callbacks.setSelectedLatLon(currentLatLng);

      this.callbacks.setSaveButtonDisabled(false);
      this.callbacks.setEntranceButtonDisabled(false);
    } else {
      this.refs.map.current.setView(WARSAW_CENTER_LAT_LNG, 14);

      this.callbacks.setSelectedLatLon({});

      this.callbacks.setSaveButtonDisabled(true);
      this.callbacks.setEntranceButtonDisabled(true);
    }
  };

  findIndexOfSelectedGeocoderResult = (
    geocoderResults: any[],
    givenAddressHasLatLon: boolean,
    addressLat,
    addressLon,
  ): number => {
    let selectedLatLonIndex = -1;

    if (this.getHasAllDependencies()) {
      addressLat = this.formatLatLonValue(addressLat);
      addressLon = this.formatLatLonValue(addressLon);

      // look for selected result among given geo results...
      for (let i = 0; i < geocoderResults.length; i++) {
        if (selectedLatLonIndex === -1 && givenAddressHasLatLon) {
          const geoResult = geocoderResults[i] as any;

          // check if current lon/lat is the one already set for the edited address
          const { lat, lon } = this.getLatLonFromGeoData(geoResult);

          if (lat === addressLat && lon === addressLon) {
            selectedLatLonIndex = i;
            break;
          }
        }
      }
    }

    return selectedLatLonIndex;
  };

  createMarkersForGeocoderResults = (geocoderResults: any[], selectedIndex: number = -1): void => {
    for (let i = 0; i < geocoderResults.length; i++) {
      if (selectedIndex > -1 && selectedIndex != i) {
        // there is selected address and current georesult is not the one - let's skip this cycle
        continue;
      }

      /**
       * if we are here then there is no selected result OR current georesult is the selected one
       *
       * Prepare and draw the marker
       */
      const geoResult = geocoderResults[i] as any;

      const { center } = geoResult;
      const marker = window.L.marker([center.lat, center.lng], { draggable: true });

      /**
       * declare 'customProps' to store what needed
       * and just avoid hours off digging through documentation and stackverflow
       * and waisting tones of time just to find plenty of NOT-WORKING solutions
       */
      marker.customProps = {
        name: '',
        index: i,
      };

      // if possible, prepare the name to show in marker popup and under the map...
      const nameToDisplay = this.getNameToDisplayFromGeoresult(geoResult);
      if (nameToDisplay) {
        marker.bindPopup(`${nameToDisplay} | ${marker.getLatLng()}`);
        marker.customProps.name = nameToDisplay;
      }

      marker.on('dragend', e => {
        const markerLatLng = this.getAddressCenterFromMarker(marker);

        const { lat, lon } = this.getLatLonFromGeoData(markerLatLng);
        marker
          .getPopup()
          .setContent(`${marker.customProps.name} | ${lat}, ${lon}`)
          .update();

        this.setSelectedLocationControl(marker.customProps.index, marker.customProps.name);

        this.callbacks.setSelectedLatLon(markerLatLng);
      });

      this.layers.geoResultsMarkers.addLayer(marker);
    }
  };

  /**
   * Returns name for display
   * The name is created from given georesult (geocode result) object
   *
   * @param geoResult
   */
  getNameToDisplayFromGeoresult = (geoResult: any): string => {
    if (!geoResult.address || !geoResult.name) {
      return 'Brak opisu';
    }
    
    if (geoResult.name && geoResult.name !== '') {
      return geoResult.name;
    }
    
    return `${geoResult.address.city}, ${geoResult.address.county} ${geoResult.address.state} (${geoResult.address.country})`;
  };

  /**
   * Street names in database contain also additional notes
   *
   * This method takes street name returned from the database,
   * filters it and returns actual street name:
   * - only the name + the number (with letter(s))
   * - no additional 'notes' after the actual street
   *
   * @param rawStreetName
   */
  getActualStreetName = (rawStreetName: string): string => {
    const actualStreetNameRegex = new RegExp('^[\\p{L}\\s+]+([0-9]+[a-z]*)*', 'u');
    const matches = rawStreetName.trim().match(actualStreetNameRegex);
    return matches && matches.length ? matches[0] : rawStreetName;
  };
}
