/* eslint-disable import/no-unresolved */
import { OrderModel } from 'modules/Orders/models/order.model';
import { getOrderTypeName } from 'modules/Orders/utils/getOrderTypeName';
import React from 'react';

import { formattedShortDateWithYear, formattedTime, Group, Price, styled, ValueOrDash } from 'lib/ui';

export interface OrderTimelineBoxesProps {
  order: OrderModel;
  withDirectPickUp?: boolean;
}

const TimelineBox = styled.div.attrs({ className: 'TimelineBox' })`
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${p => p.theme.spaceSmall};
  background-color: ${p => p.theme.colorDirtyWhite};
  text-align: center;
`;

export const OrderTimelineBoxes: React.FC<OrderTimelineBoxesProps> = props => {
  const { order } = props;

  return (
    <Group align="space-between" verticalAlign="top">
      <TimelineBox>
        <strong>Złożenie zamówienia</strong>
        {order.insertDate && (
          <span>
            {formattedShortDateWithYear(order.insertDate)} {formattedTime(order.insertDate)}
          </span>
        )}
        <span>{getOrderTypeName(order.orderType)}</span>
      </TimelineBox>
      {!props.withDirectPickUp && (
        <TimelineBox>
          <strong>Potw. kierowcy</strong>
          {order.driverConfirmDate && (
            <span>
              {formattedShortDateWithYear(order.driverConfirmDate)} {formattedTime(order.driverConfirmDate)}
            </span>
          )}
          {order.driver && <span>{order.driver.name}</span>}
          {!order.driverConfirmDate && <ValueOrDash value={null} />}
        </TimelineBox>
      )}
      <TimelineBox>
        <strong>Odbiór restauracja</strong>
        {order.pickupDates && order.pickupDates.length > 0 && (
          <span>
            {formattedShortDateWithYear(order.pickupDates[0].pickupDate)}{' '}
            {formattedTime(order.pickupDates[0].pickupDate)}
          </span>
        )}
        {/* <span>{order.restaurants.map(restaurant => restaurant.displayedName)}</span> */}
        {(!order.pickupDates || !order.pickupDates.length) && <ValueOrDash value={null} />}
      </TimelineBox>
      <TimelineBox>
        <strong>Odbiór klient</strong>
        {order.receiveDate && (
          <span>
            {formattedShortDateWithYear(order.receiveDate)} {formattedTime(order.receiveDate)}
          </span>
        )}
        {!order.receiveDate && <ValueOrDash value={null} />}
        <Price amount={order.paymentAmount} />
      </TimelineBox>
    </Group>
  );
};
