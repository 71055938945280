import { Box, Divider, Group, Loading, styled } from 'lib/ui';
import { Meal, MealGroup } from 'modules/Restaurant';
import React, { FunctionComponent, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';

export interface OrderFormMealGroupPickerProps {
  restaurantId?: number;
  onSelect: (selectedMealGroup: MealGroup) => void;
}

const enhance = connect(
  ({ restaurantsMealGroup, loading }) => ({
    restaurantsMealGroupStore: restaurantsMealGroup,
    loading,
  }),
  ({ restaurantsMealGroup }) => ({
    restaurantsMealGroup,
  }),
);

const OrderFormMealGroupPickerContainer = styled.div.attrs({
  className: 'OrderFormMealGroupPickerContainer',
})`
  width: 100%;
`;

const OrderFormMealGroupItemContainer = styled.div.attrs({
  className: 'OrderFormMealGroupItemContainer',
})<{
  selected: boolean;
}>`
  margin: ${p => p.theme.spaceSmallX};
  padding: ${p => p.theme.spaceSmallX};
  font-weight: ${p => (p.selected ? p.theme.fontWeightBold : p.theme.fontWeightDefault)};
  background: ${p => (p.selected ? p.theme.colorPrimaryLightest : 'transparent')};
  cursor: pointer;
  border-radius: ${p => p.theme.borderRadius};

  :hover {
    background: ${p => p.theme.backgroundHover};
  }
`;

export const OrderFormMealGroupPicker: FunctionComponent<OrderFormMealGroupPickerProps> = enhance(props => {
  const [selectedMealGroup, setSelectedMealGroup] = useState<MealGroup | null>(null);

  useEffect(() => {
    props.restaurantsMealGroup.loadCollection({
      restaurantId: props.restaurantId,
    });
  }, []);

  useEffect(() => {
    props.onSelect(selectedMealGroup);
  }, [selectedMealGroup]);

  return (
    <OrderFormMealGroupPickerContainer>
      <Divider text="Grupy posiłków" />

      {props.loading.effects.restaurantsMealGroup.loadCollection && <Loading text="Ładowanie grup posiłków" />}

      {!props.loading.effects.restaurantsMealGroup.loadCollection && (
        <Group wrap>
          {props.restaurantsMealGroupStore.data.map((mealGroup: MealGroup) => {
            return (
              <OrderFormMealGroupItemContainer
                onClick={() => {
                  setSelectedMealGroup(selectedMealGroup && selectedMealGroup.id === mealGroup.id ? null : mealGroup);
                }}
                selected={selectedMealGroup !== null && selectedMealGroup.id === mealGroup.id}
                key={mealGroup.id}
              >
                {mealGroup.namePl}
              </OrderFormMealGroupItemContainer>
            );
          })}
        </Group>
      )}
    </OrderFormMealGroupPickerContainer>
  );
});
