import { message, Select } from 'antd';
import { Formik } from 'formik';

import { ButtonSubmit, FormError, FormField, FormFieldLabel, FormSwitchField } from 'lib/forms';
import { Events } from 'lib/services';
import { Button, ButtonUpload, Divider, Group, Icon, IconFa, IconSize, modalConfirm, Spacer } from 'lib/ui';

import { statusMessage } from 'modules/MessageHelper';

import {
  mapFormValuesToPaymentData,
  mapPaymentTypeDataToFormValues,
  PaymentTypeTypes,
  PaymentTypeVisibilities,
} from 'modules/Settings';
import { path } from 'rambdax';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'recompose';
import { getUrlForMediaImage } from 'services/utils/getUrlForMediaImage';
import validationSchema from './PaymentTypeForm.ValidationSchema';

const enhance = compose(
  connect(
    ({ paymentTypes }) => ({
      paymentTypes,
    }),
    ({ paymentTypes }) => ({
      paymentTypesStore: paymentTypes,
    }),
  ),
);

export interface PaymentTypeFormProps {
  children?: ReactNode;
  paymentType?: any;
  onModalToggle: (visible: boolean) => void;
  onClose: () => void;
}

const PaymentTypeForm: FunctionComponent<PaymentTypeFormProps> = enhance(props => {
  const paymentTypeData = props.paymentType || {};

  useEffect(() => {
    message.destroy();

    Events.on('Model.paymentTypes.createItem.success', data => {
      statusMessage(<p>Płatność została dodana</p>);

      props.onClose();
    });

    Events.on('Model.paymentTypes.updateItem.success', () => {
      statusMessage(
        <>
          <p>Płatność została zmieniona.</p>
          <p>Od teraz jej nazwa będzie nowa.</p>
          <p>Historyczne zamówienia pozostają bez zmian.</p>
        </>,
      );
    });

    Events.on('Model.paymentTypes.deleteItem.success', () => {
      statusMessage(<p>Płatność została usunięta i nie można już z niej korzystać</p>);
      props.onClose();
    });

    Events.on(
      [
        'Model.paymentTypes.createItem.error',
        'Model.paymentTypes.updateItem.error',
        'Model.paymentTypes.deleteItem.error',
      ],
      () => {
        statusMessage(<p>Wystąpił nieoczekiwany błąd.</p>, 'error');
      },
    );

    return () => {
      Events.off(
        [
          'Model.paymentTypes.createItem.success',
          'Model.paymentTypes.updateItem.success',
          'Model.paymentTypes.deleteItem.success',
          'Model.paymentTypes.createItem.error',
          'Model.paymentTypes.updateItem.error',
          'Model.paymentTypes.deleteItem.error',
        ],
        true,
      );
    };
  }, [props]);

  return (
    <>
      <Formik
        initialValues={mapPaymentTypeDataToFormValues(paymentTypeData)}
        validationSchema={validationSchema}
        validateOnBlur
        onSubmit={(values, actions) => {
          const formData = new FormData();
          const mappedValues = mapFormValuesToPaymentData(values);
          Object.keys(mappedValues).forEach(valueKey => {
            if (typeof mappedValues[valueKey] !== 'undefined') {
              formData.append(valueKey, mappedValues[valueKey]);
            }
          });

          if (paymentTypeData.id) {
            // UPDATE PaymentType...
            message.loading('Zapisuję zmiany...', 0);
            props.paymentTypesStore
              .updateItem({
                data: formData,
                id: paymentTypeData.id,
              })
              .then(data => {
                actions.resetForm(mapPaymentTypeDataToFormValues(data));
              })
              .finally(() => actions.setSubmitting(false));
          } else {
            // CREATE PaymentType...
            message.loading('Dodaję...', 0);
            props.paymentTypesStore
              .createItem({ data: formData })
              .then(data => {
                actions.resetForm(mapPaymentTypeDataToFormValues(data));
              })
              .finally(() => actions.setSubmitting(false));
          }
        }}
        enableReinitialize
      >
        {formikProps => {
          return (
            <>
              {props.paymentTypes.errors && (
                <>
                  <FormError errors={props.paymentTypes.errors} />
                  <Spacer />
                </>
              )}
              <form onSubmit={formikProps.handleSubmit}>
                <Group vertical>
                  <FormField name="namePl" label="Nazwa polska" {...formikProps} />

                  <Group align="space-between">
                    <FormField name="nameEn" label="Nazwa angielska" {...formikProps} />
                  </Group>

                  <Group align="space-between">
                    <FormField name="displayedName" label="Oznaczenie" width={'150px'} maxLength={2} {...formikProps} />

                    <FormSwitchField name="isActive" label="Aktywna" {...formikProps} />
                  </Group>

                  <Group vertical width="300px">
                    <FormFieldLabel>Typ płatności</FormFieldLabel>
                    <Select
                      labelInValue
                      value={formikProps.values.paymentType}
                      onChange={value => {
                        formikProps.setFieldValue('paymentType', value);
                      }}
                    >
                      {PaymentTypeTypes.map(role => (
                        <Select.Option key={role.value}>{role.name}</Select.Option>
                      ))}
                    </Select>
                  </Group>

                  <Group vertical width="300px">
                    <FormFieldLabel>Widoczność</FormFieldLabel>
                    <Select
                      labelInValue
                      value={formikProps.values.paymentVisibility}
                      onChange={value => {
                        formikProps.setFieldValue('paymentVisibility', value);
                      }}
                    >
                      {PaymentTypeVisibilities.map(role => (
                        <Select.Option key={role.value}>{role.name}</Select.Option>
                      ))}
                    </Select>
                  </Group>

                  <Group vertical>
                    <Spacer />
                    <ButtonUpload
                      label={`${formikProps.values.icon ? 'Zmień ikonę' : 'Dodaj ikonę'} ${
                        formikProps.values.icon && typeof formikProps.values.icon === 'object'
                          ? path('values.icon.name', formikProps)
                          : ''
                      }`}
                      onChange={file => formikProps.setFieldValue('icon', file)}
                    />
                    {formikProps.values.icon && typeof formikProps.values.icon === 'string' && (
                      <img
                        src={getUrlForMediaImage(formikProps.values.icon)}
                        style={{
                          maxWidth: '100%',
                          maxHeight: '80px',
                        }}
                        alt="Ikona typu płatności"
                      />
                    )}
                  </Group>
                </Group>

                <Divider />

                <Group>
                  <div style={{ flexGrow: 1 }}>
                    <ButtonSubmit
                      disabled={!formikProps.dirty || formikProps.isSubmitting}
                      loading={formikProps.isSubmitting}
                      label="Zapisz"
                      onClick={formikProps.handleSubmit}
                      iconName="save"
                    />
                  </div>
                  {props.paymentType && props.paymentType.id && (
                    <Button
                      label="Usuń"
                      iconName="trash"
                      onClick={() => {
                        modalConfirm({
                          title: `Usuwasz typ płatności? (${props.paymentType.namePl})`,
                          content: (
                            <>
                              <p>Usunięcie typu płatności oznacza:</p>
                              <p>1. Klienci nie będą już mogli wybrać tej formy płatności.</p>
                              <p>
                                2. Historyczne zamówienia, które były opłacone w ten sposób nadal będą miały zapisaną tą
                                formę płatności.
                              </p>
                              <p>3. Operatorzy telefoniczni nie będą mogli wybrać tej formy płatności.</p>
                              <p>4. Administrator nie będzie widział więcej tej formy płatności.</p>
                            </>
                          ),
                          okText: 'Ok, rozumiem i usuwam',
                          yesCallback: () => {
                            const hide = message.loading('Usuwam...', 0);
                            props.paymentTypesStore
                              .deleteItem({
                                id: props.paymentType.id,
                              })
                              .catch(() => message.error('Wystąpił nieoczekiwany błąd.'))
                              .finally(hide);
                          },
                        });
                      }}
                    />
                  )}
                </Group>
              </form>

              {paymentTypeData.id && (
                <>
                  <Divider />
                  <Icon name="info" fa={IconFa.Fas} size={IconSize.Small} />
                  <Spacer horizontal size="small" />
                  Historyczne zamówienia nadal będą miały starą nazwę typu płatności.
                </>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
});

PaymentTypeForm.defaultProps = {};

export { PaymentTypeForm };
