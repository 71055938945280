import { message } from 'antd';
import { Communication } from 'components';
import { Formik } from 'formik';
import { FormError } from 'lib/forms';
import { Button, ButtonVariant, Group, Modal, ModalProps, styled } from 'lib/ui';
import { Company } from 'modules/Companies';
import { CompanyField } from 'modules/Companies/components/CompanyField';
import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { getErrorMessageFromErrorResponse } from 'services/utils/getErrorMessageFromErrorResponse';

export interface CompanyModalProps extends ModalProps {
  value: Company | null;
  open?: boolean;
  loading?: boolean;
  title?: string;
  cancelText?: string;
  errors?: any;
  okText?: string;
  clientId?: string | number | null;
  onCancel: () => void;
  onSave: (company: any) => void;
}

const CompanyModalDefaultProps = {};

const CompanyModalContainer = styled.div.attrs({
  className: 'CompanyModalContainer',
})``;
export const CompanyModal: FunctionComponent<CompanyModalProps> = props => {
  const dispatch = useDispatch();
  const companyId = props.value && props.value.id;

  if (!props.open) {
    return null;
  }

  return (
    <CompanyModalContainer>
      <Formik
        initialValues={{
          addressData: props.value && props.value.addressData ? props.value.addressData : '',
          addressPostalCode: props.value && props.value.addressPostalCode ? props.value.addressPostalCode : '',
          addressCity: props.value && props.value.addressCity ? props.value.addressCity : '',
          companyName: props.value && props.value.companyName ? props.value.companyName : '',
          vatNumber: props.value && props.value.vatNumber ? props.value.vatNumber : '',
          notes: props.value && props.value.notes ? props.value.notes : '',
        }}
        onSubmit={(values, bag) => {
          dispatch({
            type: `companies/${companyId ? 'updateItem' : 'createItem'}`,
            payload: {
              data: {
                addressData: values.addressCity,
                addressPostalCode: values.addressPostalCode,
                addressCity: values.addressCity,
                companyName: values.companyName,
                vatNumber: values.vatNumber,
                customer: props.clientId,
                notes: values.notes,
              },
              id: companyId || undefined,
            },
          })
            .then(data => {
              message.success(`Dane do faktury zostały ${companyId ? 'zaktualizowane' : 'dodane'}`);
              props.onSave({
                values: data,
                ...bag,
              });
            })
            .catch(errorResponse => {
              message.error(getErrorMessageFromErrorResponse(errorResponse));
            });
        }}
        render={formikProps => {
          return (
            <form onSubmit={formikProps.handleSubmit}>
              <Modal
                closable={!props.loading}
                visible={props.open}
                header={<h2>{props.title || '...'}</h2>}
                onClose={props.onCancel}
                width={`480px`}
                footer={
                  <Group align="right">
                    <Button
                      disabled={props.loading}
                      onClick={() => props.onCancel()}
                      label={props.cancelText || 'Anuluj'}
                    />
                    <Button
                      variant={ButtonVariant.Primary}
                      loading={props.loading}
                      onClick={formikProps.handleSubmit}
                      label={props.okText || 'Zapisz'}
                    />
                  </Group>
                }
              >
                {props.value && props.value.id && <Communication text={'Zmieniasz dane na stałe.'} color="red" />}

                {props.errors && <FormError errors={props.errors} />}

                <CompanyField
                  notes="notes"
                  label="test"
                  companyName="companyName"
                  vatNumber="vatNumber"
                  vertical
                  {...formikProps}
                />
              </Modal>
            </form>
          );
        }}
      />
    </CompanyModalContainer>
  );
};

CompanyModal.defaultProps = CompanyModalDefaultProps;
