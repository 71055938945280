import React, { FunctionComponent } from 'react';
import { Switch } from 'antd';

import { FormFieldContainer, FormFieldLabel } from '.';

export interface FormSwitchFieldProps {
  touched: any;
  errors: any;
  values: any;
  name: string;
  label?: string;
  hint?: string;
  setFieldValue: Function;
  setFieldTouched: Function;
  onChange?: (checked: boolean) => void;
}

const FormSwitchField: FunctionComponent<FormSwitchFieldProps> = props => {
  // const isTouched = path(props.name, props.touched);
  // const error = isTouched && path(props.name, props.errors);

  return (
    <FormFieldContainer>
      {props.label && <FormFieldLabel hint={props.hint}>{props.label}</FormFieldLabel>}
      <Switch
        defaultChecked={!!props.values[props.name]}
        checked={!!props.values[props.name]}
        onChange={checked => {
          props.setFieldValue(props.name, checked);
          props.setFieldTouched(props.name, true);
          if (props.onChange) {
            props.onChange(checked);
          }
        }}
      />
    </FormFieldContainer>
  );
};

export { FormSwitchField };
