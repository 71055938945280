import { styled } from '../../styled-components';

export const InputStyled = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
`;
