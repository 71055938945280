import { styled } from '../../styled-components';

const Header = styled('h1')`
  font-size: 1rem;
  text-align: ${props => props.align};
  color: ${props => props.theme.colorPrimaryDark};
  margin: ${props => props.theme.space} ${props => props.theme.space} 0 0;
  border-bottom: ${props => (props.withBorder ? `1px solid ${props.theme.colorLightGrey}` : `none`)};
`;

Header.defaultProps = {
  withBorder: false,
  align: 'left',
};

export { Header };
