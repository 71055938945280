import { Typography } from 'antd';

import {
  Button,
  ButtonSize,
  Divider,
  Group,
  Price,
  Spacer,
  styled,
} from 'lib/ui';
import { Meal } from 'modules/Restaurant';
import React, { FunctionComponent } from 'react';

export interface OrderFormMealsProps {
  meals?: Meal[];
  onMealSelect: (meal: Meal) => void;
}

const OrderFormMealsContainer = styled.div.attrs({
  className: 'OrderFormMealsContainer',
})`
  margin: ${p => p.theme.spaceSmall} 0;
`;

const MealContainer = styled.div.attrs({ className: 'MealContainer' })`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
export const OrderFormMeals: FunctionComponent<OrderFormMealsProps> = props => {
  if (!props.meals) {
    return null;
  }

  return (
    <OrderFormMealsContainer>
      {props.meals.map((meal, index) => (
        <MealContainer key={meal.id}>
          <Group vertical>
            <Typography.Text>
              {meal.namePl} (<Price amount={meal.price} />)
            </Typography.Text>
            <span className="text-pre-line">
              <Typography.Text type="secondary">
                {meal.descriptionPl}
              </Typography.Text>
            </span>
            {props.meals && index < props.meals.length && <Divider />}
          </Group>
          <Spacer horizontal />
          <Button
            iconName="plus"
            size={ButtonSize.Small}
            onClick={event => {
              event.stopPropagation();
              props.onMealSelect(meal);
            }}
          />
        </MealContainer>
      ))}
    </OrderFormMealsContainer>
  );
};
