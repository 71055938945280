import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormState } from 'modules/Orders/OrderFormState';
import React, { FunctionComponent } from 'react';

import { styled, Price, Group, Spacer } from 'lib/ui';

export interface OrderBasketSummaryProps {
  orderState: OrderFormState;
}

const OrderBasketSummaryContainer = styled.div.attrs({
  className: 'OrderBasketSummaryContainer',
})``;

const SummaryContainer = styled.div.attrs({ className: 'SummaryContainer' })`
  background: #ddd;
  margin: -${p => p.theme.space};
  margin-top: ${p => p.theme.space};
  margin-bottom: ${p => p.theme.space};
  padding: ${p => p.theme.space};
`;

export const OrderFormBasketSummary: FunctionComponent<OrderBasketSummaryProps> = props => {
  const orderSummary = OrderFormService.getBasketSummary();

  return (
    <OrderBasketSummaryContainer>
      <div>
        <Spacer />
        {orderSummary.sets.map(setSummary => (
          <React.Fragment key={`${setSummary.sum}${setSummary.name}`}>
            <Group align="space-between">
              <span>{setSummary.name}</span>
              <Price amount={setSummary.sum} />
            </Group>
            <Spacer size="small" />
          </React.Fragment>
        ))}

        {orderSummary.extraExtrasSum > 0 && (
          <>
            <Group align="space-between">
              <span>Ekstra dodatki</span>
              <Price amount={orderSummary.extraExtrasSum} />
            </Group>
            <Spacer size="small" />
          </>
        )}

        {!props.orderState.withDirectPickUp
        && (
          <>
            <Group align="space-between">
              <span>Dostawa</span>
              <Price amount={OrderFormService.getDeliveryCost()} />
            </Group>
            <Spacer size="small" />
          </>
        )}
        <Group align="space-between">
          <span>Podsuma</span>
          <Price amount={orderSummary.sum} />
        </Group>
        <Spacer size="small" />

        {props.orderState.client && (
          <Group align="space-between">
            <span>Rabat {props.orderState.client.discount}%</span>
            <Price amount={OrderFormService.getDiscountSum()} />
          </Group>
        )}
      </div>

      <SummaryContainer>
        <Group align="space-between">
          <h3>Razem</h3>
          <h3>
            <Price amount={orderSummary.sum - OrderFormService.getDiscountSum()} />
          </h3>
        </Group>
        <Group align="space-between">
          <span>Nadpłata</span>
          <Price amount={OrderFormService.getClientBalance()} />
        </Group>
        <Spacer />
        <Group align="space-between">
          <h3 style={{ color: '#cf1e2f' }}>Do zapłaty</h3>
          <h3 style={{ color: '#cf1e2f' }}>
            <Price amount={OrderFormService.getFinalSum()} />
          </h3>
        </Group>
      </SummaryContainer>
    </OrderBasketSummaryContainer>
  );
};
