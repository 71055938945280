import { AgGridTable, styled } from 'lib/ui';
import React, { FunctionComponent } from 'react';

export interface RestaurantsSettlementTableProps {
  restaurants: any[];
  onSelect: Function;
  reference: Function;
}

const RestaurantsSettlementTableDefaultProps = {};

const RestaurantsSettlementTableContainer = styled.div`
  width: 50%;
  padding: 1rem;
`;

const RestaurantsSettlementTable: FunctionComponent<RestaurantsSettlementTableProps> = ({
  restaurants,
  onSelect,
  reference,
}) => {
  const columns = [
    {
      headerName: 'Zaznacz',
      maxWidth: 150,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: 'Nazwa',
      field: 'name',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: props => <a>{props.data.name}</a>,
    },
  ];

  return (
    <RestaurantsSettlementTableContainer>
      <AgGridTable
        onGridReady={e => reference(e.api)}
        columnDefs={columns}
        rowData={restaurants}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={event => {
          const selectedRowNodes = event.api.getSelectedNodes();
          onSelect(selectedRowNodes);
        }}
        pagination
        paginationPageSize={25}
      />
    </RestaurantsSettlementTableContainer>
  );
};

RestaurantsSettlementTable.defaultProps = RestaurantsSettlementTableDefaultProps;

export { RestaurantsSettlementTable };
