/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/no-unresolved */
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Divider,
  Group,
  Price,
  Spacer,
  styled,
  TimeLeft,
  ValueOrDash,
  formattedShortDateWithYear,
  formattedTime,
} from 'lib/ui';
import { BasketForOrder } from 'modules/Orders/components/BasketForOrder/BasketForOrder';
import { OrderTimelineBoxes } from 'modules/Orders/components/OrderDetailsDialog/OrderTimelineBoxes';
import { OrderStatusDialog } from 'modules/Orders/components/OrderTable/OrderStatusDialog';
import { OrderModel } from 'modules/Orders/models/order.model';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import React from 'react';
import { useHistory } from 'react-router';
import { useToggle } from 'react-use';

const HighlightBox = styled.div.attrs({ className: 'HighlightBox' })`
  background: #70b8ff;
  box-shadow: 0 5px 3px #70b8ff;
  padding: ${p => p.theme.spaceSmall};
  margin: ${p => p.theme.spaceSmall} 0;
`;

export interface OrderDetailsProps {
  order: OrderModel;
  isEditable: boolean;
  onNavigate: () => void;
  onChange: () => void;
  withDirectPickUp?: boolean;
}

export const OrderDetails: React.FC<OrderDetailsProps> = props => {
  const [statusDialogOpened, toggleStatusDialog] = useToggle(false);
  const history = useHistory();

  const navigateToEdit = (page: number) => {
    props.onNavigate();
    OrderFormService.resetState();
    history.push(`/orders/edit/${props.order.id}/${page}`);
  };

  return (
    <>
      <OrderTimelineBoxes withDirectPickUp={props.withDirectPickUp} order={props.order} />
      <Spacer />
      {props.order.status < 40 && (
        <>
          <div>
            {props.order.deliveryDate && (
              <Group>
                <strong>Termin realizacji: </strong>
                <span>
                  {formattedShortDateWithYear(props.order.deliveryDate)} {formattedTime(props.order.deliveryDate)}
                </span>
              </Group>
            )}

            {!props.order.receiveDate && props.order.deliveryDate && (
              <TimeLeft
                targetDatetime={props.order.deliveryDate}
                colorGreen="#72ac03"
                colorRed="#cf1e2f"
                displayOnlyIfDelayed
                prefixForDelay="Opóźnienie: "
              />
            )}

            {props.order.receiveDate && props.order.deliveryDate && (
              <TimeLeft
                targetDatetime={props.order.deliveryDate}
                relativeDatetime={props.order.receiveDate}
                colorGreen="#72ac03"
                colorRed="#cf1e2f"
                displayOnlyIfDelayed
                prefixForDelay="Opóźnienie: "
              />
            )}
          </div>
          <div className="u-flex-space-between u-flex">
            <Button
                variant={ButtonVariant.Primary}
                size={ButtonSize.Small}
                label="Zmień"
                onClick={() => navigateToEdit(2)}
            />
            <Button
              size={ButtonSize.Small}
              label="Zmień status"
              onClick={() => {
                toggleStatusDialog();
              }}
            />
          </div>
        </>
      )}

      <Divider />
      <Spacer />

      <div data-bp="grid 6">
        <div>
          <h3>Koszyk</h3>

          <BasketForOrder withDirectPickUp={props.withDirectPickUp} order={props.order} />
          {props.isEditable && (
            <>
              <Spacer size="small" />
              <Group>
                <Button
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.Small}
                  label="Zmień zamówienie"
                  onClick={() => navigateToEdit(2)}
                />
              </Group>
            </>
          )}
        </div>
        <div>
          {props.isEditable && <></>}

          <h3>Bonus/kara</h3>
          <Spacer size="small" />
          {props.order.bonus && (
            <Group>
              <Price amount={Number(props.order.bonus)} />
            </Group>
          )}
          {!props.order.bonus && <ValueOrDash value={null} />}

          {!props.withDirectPickUp && (
            <>
              <h3>Bonus/kara kierowcy</h3>
              <Spacer size="small" />
              {props.order.driverBonusAmount !== null && (
                <Group vertical>
                  <Price amount={Number(props.order.driverBonusAmount)} />
                  {props.order.driverBonusDescription && <span>{props.order.driverBonusDescription}</span>}
                </Group>
              )}
              {!props.order.driverBonusAmount && <ValueOrDash value={null} />}
            </>
          )}
          <Spacer size="small" />

          <h3>Odbiorca</h3>
          <Spacer size="small" />
          {props.order.withDirectPickUp && (
            <HighlightBox>
              <Group vertical>
                <strong>Zamówienie z odbiorem osobistym w restauracji</strong>
              </Group>
            </HighlightBox>
          )}
          {props.order.deliveryAddress && !props.order.withDirectPickUp && (
            <HighlightBox>
              <Group vertical>
                {!props.order.customer.userIsActive && <p>Zamówienie bez logowania</p>}
                <strong>{props.order.deliveryAddress.email}</strong>
                <strong>{props.order.deliveryAddress.phone}</strong>
                <span>
                  {props.order.deliveryAddress.street} {props.order.deliveryAddress.houseNumber},{' '}
                  {props.order.deliveryAddress.details} {props.order.deliveryAddress.postalCode}{' '}
                  {props.order.deliveryAddress.city}
                </span>
              </Group>
            </HighlightBox>
          )}
          {props.isEditable && (
            <Group>
              <Button
                variant={ButtonVariant.Primary}
                size={ButtonSize.Small}
                label="Zmień"
                onClick={() => navigateToEdit(1)}
              />
            </Group>
          )}

          <Spacer />

          <h3>Faktura</h3>

          {!props.order.invoiceData && <ValueOrDash value={null} />}
          {props.order.invoiceData && (
            <HighlightBox>
              <Group vertical>
                <strong>{props.order.invoiceData.companyName}</strong>
                <span>
                  {props.order.invoiceData.addressData} {props.order.invoiceData.addressPostalCode}{' '}
                  {props.order.invoiceData.addressCity}
                </span>
                <span>NIP: {props.order.invoiceData.vatNumber}</span>
              </Group>
            </HighlightBox>
          )}
          {props.isEditable && (
            <Group>
              <Button
                variant={ButtonVariant.Primary}
                size={ButtonSize.Small}
                label="Zmień"
                onClick={() => navigateToEdit(1)}
              />
            </Group>
          )}

          <Spacer size="small" />

          <h3>Uwagi do zamówienia</h3>
          <span className="text-pre-wrap">
            <ValueOrDash value={props.order.notes != null ? props.order.notes : "Brak"} />
          </span>
          {props.isEditable && (
            <Group>
              <Button
                variant={ButtonVariant.Primary}
                size={ButtonSize.Small}
                label="Zmień"
                onClick={() => navigateToEdit(3)}
              />
            </Group>
          )}

          <Spacer size="small" />

          <h3>Informacja poufna</h3>
          <span className="text-pre-wrap">
            <ValueOrDash value={props.order.privateNotes} />
          </span>
          {props.isEditable && (
            <Group>
              <Button
                variant={ButtonVariant.Primary}
                size={ButtonSize.Small}
                label="Zmień"
                onClick={() => navigateToEdit(3)}
              />
            </Group>
          )}
        </div>
      </div>

      {statusDialogOpened && (
        <OrderStatusDialog
          order={props.order}
          onClose={hasChanged => {
            toggleStatusDialog();
            if (hasChanged) {
              props.onChange();
            }
          }}
        />
      )}
    </>
  );
};
