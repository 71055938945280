import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';
import { User } from 'modules/User';

export const EmployeesModel = SimpleRestModel({
  collectionName: 'employees',
  HttpApi,
});

export const EmployeeRoles = [
  {
    key: 20,
    label: 'Administrator',
  },
  {
    key: 10,
    label: 'Dyspozytor',
  },
  {
    key: 0,
    label: 'Operator',
  },
];

export interface Employee {
  hourRateFri: number;
  hourRateMon: number;
  hourRateSat: number;
  hourRateSun: number;
  hourRateThu: number;
  hourRateTue: number;
  hourRateWed: number;
  id: number;
  isActive: boolean;
  isDeleted: boolean;
  notes: string;
  role: 0 | 10 | 20;
  user: User;
}
