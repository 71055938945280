import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

export interface Promotion {
  id?: number;
  image: string;
  nameEn: string;
  namePl: string;
  descriptionEn: string;
  descriptionPl: string;
  dateStart: string;
  dateEnd: string;
  urlPl: string;
  urlEn: string;
  isActive: boolean;
  position: number;
}

export const PromotionModel = SimpleRestModel({
  collectionName: 'promotions',
  HttpApi,
  apiPath: 'restaurants/promotions',
});
