import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services/HttpApi';
import { OrderModel } from 'modules/Orders/models/order.model';
import { Restaurant } from 'modules/Restaurant/RestaurantModel';
import { Meal } from 'modules/Restaurant/RestaurantMealModel';

export interface RestaurantSettlement {
  settlementData: {
    ordersCount: number;
    ordersGrossSum: number;
    beforeCommisionDockSum: number;
    afterCommisionDockSum: number;
    restaurantPayment: number;
    orders: OrderModel[];
  };
  restaurant: Restaurant;
  detailedXls?: string;
}

export const RestaurantsSettlementModel = SimpleRestModel({
  collectionName: 'restaurantsSettlement',
  apiPath: 'restaurants/settlement',
  HttpApi,
});
