import classnames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Icon, IconSize, Spacer } from '../..';

import styles from './Box.module.scss';

export interface BoxProps {
  title?: string;
  subtitle?: string;
  linkHref?: string;
  linkText?: string;
  hoverable?: boolean;
  raised?: boolean;
  paper?: boolean;
  dirty?: boolean;
  stretched?: boolean;
  noMargin?: boolean;
  noPadding?: boolean;
  noShadow?: boolean;
  headerWithoutBackground?: boolean;
  headerOneLine?: boolean;
  children?: ReactNode;
}

const BoxDefaultProps = {
  linkText: 'więcej',
};

// TODO: don't use native headers - use custom element

const Box: FunctionComponent<BoxProps> = (props: BoxProps) => {
  return (
    <div
      className={classnames(styles.Root, {
        [styles.hoverable]: props.hoverable,
        [styles.withPaddingBottom]: props.linkHref,
        [styles.raised]: props.raised,
        [styles.paper]: props.paper,
        [styles.dirty]: props.dirty,
        [styles.stretched]: props.stretched,
        [styles.noShadow]: props.noShadow,
        [styles.noPadding]: props.noPadding,
        [styles.noMargin]: props.noMargin,
      })}
    >
      {(props.title || props.subtitle) && (
        <div
          className={classnames(styles.header, {
            [styles.headerWithBackground]: !props.headerWithoutBackground,
            [styles.headerOneLine]: props.headerOneLine,
          })}
        >
          {props.title && <h2 className={props.subtitle ? styles.BoxTitle : undefined}>{props.title}</h2>}
          {props.subtitle && <h3 className={props.subtitle ? styles.BoxSubtitle : undefined}>{props.subtitle}</h3>}
        </div>
      )}

      <div>{props.children}</div>

      {props.linkHref && (
        <Link to={props.linkHref} className={styles.BoxFooter}>
          {props.linkText} <Spacer horizontal size="small" />
          <Icon name="chevron-right" size={IconSize.Small} />
        </Link>
      )}
    </div>
  );
};

Box.defaultProps = BoxDefaultProps;

export { Box };
