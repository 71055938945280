// import { DividerTextOrientation } from '../..';
import { styled } from '../../styled-components';

// {
//   hidden?: boolean;
//   textOrientation?: DividerTextOrientation;
// }
export const DividerWithText = styled.div.attrs({
  className: 'DividerWithText',
})`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin: ${p => (p.noMargin ? '0' : p.theme.space)} 0;
  visibility: ${p => (p.hidden ? 'hidden' : 'visible')};
`;
