import React, { FunctionComponent } from 'react';

export interface TimeProps {
  seconds: number;
  daysSuffix?: string;
  minutesSuffix?: string;
  hoursSuffix?: string;
}

const TimeDefaultProps = {
  daysSuffix: 'd',
  minutesSuffix: 'm',
  hoursSuffix: 'h',
};

export const Time: FunctionComponent<TimeProps> = props => {
  const minutes = Math.floor(props.seconds / 60);

  return (
    <span>
      {minutes} {props.minutesSuffix}
    </span>
  );
};

Time.defaultProps = TimeDefaultProps;
