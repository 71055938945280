import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

export interface DeliveryZone {
  id: number;
  distanceFrom: number;
  distanceTo: number;
  price: number;
}

export const DeliveryZonesModel = SimpleRestModel({
  collectionName: 'deliveryZones',
  apiPath: 'settings/delivery_zones',
  HttpApi,
});
