import { Steps } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import { styled } from 'lib/ui';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

export interface OrderFormStepsProps {
  activePage: number;
}

const OrderFormStepsDefaultProps = {};

const OrderFormStepsContainer = styled.div.attrs({
  className: 'OrderFormStepsContainer',
})``;

const stepStyles = {
  cursor: 'pointer',
};

export const OrderFormSteps: FunctionComponent<OrderFormStepsProps> = withRouter(props => {
  const getStepStatus: (step: number) => 'process' | 'wait' | 'finish' = step => {
    if (step === props.activePage) {
      return 'process';
    }
    if (props.activePage > step) {
      return 'finish';
    }
    return 'wait';
  };
  const finishedSteps = {
    1: getStepStatus(1) === 'finish',
    2: getStepStatus(2) === 'finish',
    3: getStepStatus(3) === 'finish',
    4: getStepStatus(4) === 'finish',
  };

  const orderPage2Url = useSelector(state => state.UiModel.orderStep2Url);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.activePage === 2) {
      dispatch({
        type: 'UiModel/setOrder2StepUrl',
        payload: props.history.location.pathname,
      });
    }
  }, []);

  return (
    <OrderFormStepsContainer>
      <Steps>
        <Steps.Step
          status={getStepStatus(1)}
          onClick={() => {
            if (finishedSteps[1]) {
              props.history.push('/orders/new/1');
            }
          }}
          title="Klient"
          style={finishedSteps[1] ? stepStyles : {}}
        />
        <Steps.Step
          status={getStepStatus(2)}
          onClick={() => {
            if (finishedSteps[2]) {
              props.history.push(orderPage2Url);
            }
          }}
          title="Dane kontaktowe"
          style={finishedSteps[2] ? stepStyles : {}}
        />
        <Steps.Step
          status={getStepStatus(3)}
          onClick={() => {
            if (finishedSteps[3]) {
              props.history.push('/orders/new/3');
            }
          }}
          title="Restauracja"
          style={finishedSteps[3] ? stepStyles : {}}
        />
        <Steps.Step
          status={getStepStatus(4)}
          onClick={() => {
            if (finishedSteps[4]) {
              props.history.push('/orders/new/4');
            }
          }}
          title="Koszyk"
          style={finishedSteps[4] ? stepStyles : {}}
        />
        <Steps.Step
          status={getStepStatus(5)}
          onClick={() => {
            if (finishedSteps[5]) {
              props.history.push('/orders/new/5');
            }
          }}
          title="Podsumowanie i płatność"
        />
      </Steps>
    </OrderFormStepsContainer>
  );
});

OrderFormSteps.defaultProps = OrderFormStepsDefaultProps;
