import { Switch } from 'antd';

import { AgGridTable, Button, ButtonSize, Group } from 'lib/ui';
import { PaymentType, PaymentTypeTypes } from 'modules/Settings';
import React, { FunctionComponent } from 'react';
import connect from 'react-redux/es/connect/connect';

const enhance = connect(null, ({ paymentTypes }) => ({
  paymentTypesStore: paymentTypes,
}));

export interface PaymentTypesTableProps {
  paymentTypes?: PaymentType[];
  onEditClick?: Function;
  onStatusChangeClick?: Function;
}

const PaymentTypesTable: FunctionComponent<PaymentTypesTableProps> = enhance(
  ({ paymentTypes, onEditClick, onStatusChangeClick, paymentTypesStore }) => {
    let dragDropUpdateTimeout;

    const columns = [
      {
        headerName: 'Oznaczenie',
        field: 'displayedName',
        sortable: true,
        rowDrag: paymentTypes && paymentTypes.length > 1,
        cellRendererFramework: props => {
          return (
            <>
              <span data-bp="margin-right">{props.data.displayedName}</span>
            </>
          );
        },
        suppressSizeToFit: false,
      },
      {
        headerName: 'Typ płatności',
        field: 'paymentType',
        sortable: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: props => {
          const paymentTypeData = PaymentTypeTypes.find(_paymentType => _paymentType.value === props.data.paymentType);

          return paymentTypeData ? <span>{paymentTypeData.name}</span> : <span>-</span>;
        },
        suppressSizeToFit: false,
      },
      {
        headerName: 'Nazwa PL',
        field: 'namePl',
        sortable: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: props => <a onClick={() => onEditClick(props.data)}>{props.data.namePl}</a>,
        suppressSizeToFit: false,
      },
      {
        headerName: 'Nazwa EN',
        field: 'nameEn',
        sortable: true,
        filter: 'agTextColumnFilter',
        cellRendererFramework: props => <a onClick={() => onEditClick(props.data)}>{props.data.nameEn}</a>,
        suppressSizeToFit: false,
      },
      {
        headerName: 'Status',
        cellRendererFramework: props => (
          <Switch checked={props.data.isActive} size="small" onClick={() => onStatusChangeClick(props.data)} />
        ),
        suppressSizeToFit: false,
      },
      {
        headerName: 'Akcje',
        cellRendererFramework: props => {
          return (
            <Group>
              <Button
                size={ButtonSize.Small}
                label={'edytuj'}
                iconName="edit"
                onClick={event => onEditClick(props.data)}
              />
            </Group>
          );
        },
        suppressSizeToFit: false,
      },
    ];
    return (
      <AgGridTable
        columnDefs={columns}
        rowData={paymentTypes}
        rowSelection="multiple"
        rowDragManaged
        enableCellChangeFlash
        onViewportChanged={params => params.api.sizeColumnsToFit()}
        onGridSizeChanged={params => params.api.sizeColumnsToFit()}
        onGridReady={params => params.api.sizeColumnsToFit()}
        onRowDragMove={event => {
          // this is the row the mouse is hovering over
          const { overNode } = event;

          // the data we want to move
          const movedNode = event.node;

          if (!overNode || movedNode.id === overNode.id) {
            return;
          }

          const payloadForUpdate: {
            id: number;
            position: number;
          }[] = [];

          event.api.forEachNode((node, index) => {
            payloadForUpdate.push({
              id: node.data.id,
              position: index,
            });
          });

          clearTimeout(dragDropUpdateTimeout);
          dragDropUpdateTimeout = setTimeout(() => {
            paymentTypesStore
              .customCollectionAction({
                data: {
                  paymentTypesList: payloadForUpdate,
                },
                action: 'update_position',
              })
              .then(() => {
                event.api.forEachNode((node, index) => {
                  node.setDataValue('position', index);
                });
              });
          }, 1000);
        }}
      />
    );
  },
);

PaymentTypesTable.defaultProps = {};

export { PaymentTypesTable };
