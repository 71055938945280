import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

// min/max in %
export const DELIVERY_DELAY_MIN = 0;
export const DELIVERY_DELAY_MAX = 300;

export interface GeneralSettings {
  id: number;
  deliveryDelay: number;
}

/**
 * For now there is only one settings result with fixed id === 1 :)
 * but API is prepared for many more settings e.g. dependent on time periods
 * so maybe in the future, if needed, this fixed ID value will be removed
 * BUT for now, use this fixed id in the API calls
 */
export const GENERAL_SETTINGS_FIXED_ID = 1;

export const GeneralSettingsModel = SimpleRestModel({
  collectionName: 'generalSettings',
  HttpApi,
  apiPath: 'settings/general_settings',
});
