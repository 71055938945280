import React, { useEffect, useState } from 'react';
import { useObservable, useToggle } from 'react-use';
import connect from 'react-redux/es/connect/connect';

import { PageLayout } from 'components';
import { Group, Spacer } from 'lib/ui';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormBasketMeal, OrderFormState } from 'modules/Orders/OrderFormState';
import { MealGroup } from 'modules/Restaurant';

import { OrderClientSummary } from '../components/OrderClientSummary/OrderClientSummary';
import { MealInputSearch } from '../components/MealInputSearch/MealInputSearch';
import { OrderFormSteps } from '../OrderFormSteps';
import { OrderFormBasket } from '../components/OrderFormBasket';
import { OrderFormMealGroupPicker } from './OrderFormMealGroupPicker';
import { OrderFormMealPicker } from './OrderFormMealPicker';
import { OrderFormMealWithExtrasPicker } from './OrderFormMealWithExtrasPicker';

const enhance = connect(
  ({ restaurantsExtras, loading }) => ({
    restaurantsExtrasStore: restaurantsExtras,
    loading,
  }),
  ({ restaurantsExtras }) => ({
    restaurantsExtras,
  }),
);

export const OrderFormPageStep4 = enhance(props => {
  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());

  const [mealQuery, setMealQuery] = useState('');

  const [selectedMealGroup, setSelectedMealGroup] = useState<MealGroup | null>(null);
  const [mealPickerVisible, toggleMealPicker] = useToggle(false);
  const [selectedMeal, setSelectedMeal] = useState<OrderFormBasketMeal | null>(null);
  const [selectedMealExtrasIndex, setSelectedMealExtrasIndex] = useState<number | null>(null);

  useEffect(() => {
    if (orderState && orderState.restaurant) {
      props.restaurantsExtras.loadCollection({
        restaurantId: orderState.restaurant.id,
      });
    }
    if (orderState && orderState.restaurantMealSelectedOnStep3) {
      setSelectedMeal(orderState.restaurantMealSelectedOnStep3);
      toggleMealPicker(true);
      OrderFormService.setState({
        restaurantMealSelectedOnStep3: null,
      });
    }
  }, [orderState, props.restaurantsExtras, toggleMealPicker]);

  if (!orderState || !orderState.restaurant) {
    return null;
  }

  // checkbox przy nazwie zestawu jako "aktywny"
  // dodawanie zestawu kolejnego
  // zmiana nazwy zestawu (zmień obok nazwy)

  return (
    <PageLayout title="Tworzenie nowego zamówienia" header={<OrderFormSteps activePage={4} />}>
      <Group align="space-between">
        <h2>
          Restauracja: <strong>{orderState.restaurant.name}</strong>
        </h2>
      </Group>

      <Spacer />

      <Group align="space-between" verticalAlign="top">
        <Group vertical width="60%">
          <OrderFormMealGroupPicker
            restaurantId={orderState.restaurant.id}
            onSelect={mealGroup => {
              setSelectedMealGroup(mealGroup);
            }}
          />

          <OrderFormMealPicker
            basketSets={orderState.basket.sets}
            restaurantId={orderState.restaurant.id}
            mealGroup={selectedMealGroup}
            query={mealQuery}
            onSelect={meal => {
              setSelectedMeal(meal);
              toggleMealPicker();
            }}
          />
        </Group>

        <Group vertical width="35%">
          <MealInputSearch
            onQueryChange={setMealQuery}
            onSearch={setMealQuery}
            onReset={() => {
              setMealQuery('');
            }}
            hideSearchButton
          />
          <Spacer />
          <OrderFormBasket
            editable
            key={'orderBasket'}
            onMealClick={basketMeal => {
              setSelectedMeal(basketMeal.meal);
              setSelectedMealExtrasIndex(null);
              toggleMealPicker();
            }}
            onMealExtrasClick={(basketMeal, extrasIndex) => {
              setSelectedMeal(basketMeal.meal);
              setSelectedMealExtrasIndex(extrasIndex);
              toggleMealPicker();
            }}
            onSubmit={() => {
              props.history.push('/orders/new/5');
            }}
          />
          <Spacer />
          <OrderClientSummary />
        </Group>
      </Group>

      <Spacer />

      {mealPickerVisible && selectedMeal && (
        <OrderFormMealWithExtrasPicker
          extras={[]}
          meal={selectedMeal}
          extrasIndex={selectedMealExtrasIndex}
          restaurantMealExtras={props.restaurantsExtrasStore.data}
          onClose={toggleMealPicker}
        />
      )}
    </PageLayout>
  );
});
