/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { message, PageHeader } from 'antd';

import { PageLayout } from 'components';
import { useInterval } from 'lib/hooks/useInterval';
import { Button, Group } from 'lib/ui';
import { MapDataModel } from 'pages/MapPage/MapDataModel';
import { MapView } from './MapView';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HttpApi } from 'services/HttpApi';

export const MapPage = props => {
  const [loading, setLoading] = useState<Boolean>(false);

  // map data
  const [data, setData] = useState<MapDataModel>();

  const loadData = useCallback(() => {
    setLoading(true);
    HttpApi.get<MapDataModel>('map-data/')
      .then(data => {
        if (!data || (data.currentOrders.length === 0 && data.drivers.length === 0 && data.restaurants.length === 0)) {
          message.info('Brak danych do wyświetlenia');
        }
        setData(data);
        setLoading(false);
      })
      .catch(err => {
        const messageText = err.data && err.data.detail;
        message.error(messageText || 'Wystąpił nieoczekiwany błąd');
        setLoading(false);
        setData(undefined);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useInterval(() => {
    loadData();
  }, 60 * 1000);

  return (
    <PageLayout>
      <Group align="space-between">
        <PageHeader title={'Mapa'}></PageHeader>
        <Button onClick={loadData} label="Odśwież" loading={!!loading} />
      </Group>

      <MapView data={data} />
    </PageLayout>
  );
};
