import React, { FunctionComponent } from 'react';
import { FormikProps, FormikValues } from 'formik';
import { path } from 'rambdax';

import { Input, FormFieldLabel, FieldError, FormFieldContainer } from '.';

export interface FormFieldProps extends FormikProps<FormikValues> {
  name: string;
  label?: string;
  hint?: string;
  placeholder?: string;
  type?: string;
  onEnter?: Function;
  width?: number | string;
  maxLength?: number;
  disabled?: boolean;
}

const FormField: FunctionComponent<FormFieldProps> = props => {
  const isTouched = path(props.name, props.touched);
  const error = isTouched && path(props.name, props.errors);
  const value = path<string>(props.name, props.values);

  return (
    <FormFieldContainer width={props.width}>
      {props.label && <FormFieldLabel hint={props.hint}>{props.label}</FormFieldLabel>}
      <div>
        <Input
          placeholder={props.placeholder}
          name={props.name}
          onChange={ev => {
            props.setFieldValue(props.name, ev.currentTarget.value);
          }}
          onBlur={_ => {
            props.setFieldTouched(props.name, true);
          }}
          onKeyDown={ev => {
            if (ev.key === 'Enter' && props.onEnter) {
              ev.preventDefault();
              props.onEnter(ev);
            }
          }}
          disabled={props.disabled}
          value={value}
          type={props.type || 'text'}
          maxLength={props.maxLength ? props.maxLength : -1}
          width={props.width}
        />
      </div>
      {error && <FieldError error={error} />}
    </FormFieldContainer>
  );
};

export { FormField };
