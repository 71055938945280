import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Header, styled } from 'lib/ui';

export interface AuthBasePageProps {
  children?: ReactNode;
  title?: string;
}

const StyledPage = styled.div`
  max-width: 300px;
  padding: ${props => props.theme.space};
  margin: 0 auto;
  flex-grow: 1;
`;

const PageHeaderStyled = styled(Header)`
  margin-left: 0;
`;

const AuthBasePage: FunctionComponent<AuthBasePageProps> = (
  props: AuthBasePageProps,
) => {
  return (
    <StyledPage>
      {props.title && <PageHeaderStyled>{props.title}</PageHeaderStyled>}
      {props.children}
    </StyledPage>
  );
};

export { AuthBasePage };
