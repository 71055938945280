import { message } from 'antd';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'recompose';
import { Formik } from 'formik';

import { ButtonSubmit, FormError, FormField, FormFieldInputNumber, FormSwitchField } from 'lib/forms';
import { Events } from 'lib/services';
import { Button, Divider, Group, modalConfirm, Spacer } from 'lib/ui';

import { ExtraExtraMap } from 'modules/ExtraExtras/ExtraExtraUtils';

import validationSchema from './ExtraExtraForm.ValidationSchema';
import { statusMessage } from 'modules/MessageHelper';

const enhance = compose(
  connect(
    ({ extraExtras }) => ({
      extraExtras,
    }),
    ({ extraExtras }) => ({
      extraExtrasStore: extraExtras,
    }),
  ),
);

export interface ExtraExtraFormProps {
  children?: ReactNode;
  extraExtra?: any;
  onModalToggle: (visible: boolean) => void;
  onClose: () => void;
}

const ExtraExtraForm: FunctionComponent<ExtraExtraFormProps> = enhance(props => {
  const extraExtraData = props.extraExtra || {};

  useEffect(() => {
    message.destroy();

    Events.on('Model.extraExtras.createItem.success', data => {
      statusMessage(<p>Ekstra dodatek został zapisany</p>);

      props.onClose();
    });

    Events.on('Model.extraExtras.updateItem.success', () => {
      statusMessage(
        <>
          <p>Ekstra dodatek został zmienony.</p>
        </>,
      );
    });

    Events.on('Model.extraExtras.deleteItem.success', () => {
      statusMessage(<p>Ekstra dodatek został usunięty</p>);
      props.onClose();
    });

    Events.on(
      [
        'Model.extraExtras.createItem.error',
        'Model.extraExtras.updateItem.error',
        'Model.extraExtras.deleteItem.error',
      ],
      () => {
        statusMessage(<p>Wystąpił nieoczekiwany błąd.</p>, 'error');
      },
    );

    return () => {
      Events.off(
        [
          'Model.extraExtras.createItem.success',
          'Model.extraExtras.updateItem.success',
          'Model.extraExtras.deleteItem.success',
          'Model.extraExtras.createItem.error',
          'Model.extraExtras.updateItem.error',
          'Model.extraExtras.deleteItem.error',
        ],
        true,
      );
    };
  }, []);

  return (
    <>
      <Formik
        initialValues={extraExtraData}
        validationSchema={validationSchema}
        validateOnBlur
        onSubmit={(values, actions) => {
          const payload = ExtraExtraMap.viewDataToApiPayload(values);

          if (extraExtraData.id) {
            /**
             * UPDATE ExtraExtra...
             */
            message.loading('Zapisuję zmiany...', 0);
            props.extraExtrasStore
              .updateItem({
                data: payload,
                id: extraExtraData.id,
              })
              .finally(() => actions.setSubmitting(false));
          } else {
            /**
             * CREATE ExtraExtra...
             */
            message.loading('Dodaję...', 0);
            props.extraExtrasStore.createItem({ data: payload }).finally(() => actions.setSubmitting(false));
          }
        }}
        enableReinitialize
      >
        {formikProps => {
          return (
            <>
              {props.extraExtras.errors && (
                <>
                  <FormError errors={props.extraExtras.errors} />
                  <Spacer />
                </>
              )}
              <form onSubmit={formikProps.handleSubmit}>
                <Group vertical>
                  <Group>
                    <FormField name="namePl" label="Nazwa polska" {...formikProps} />

                    <Spacer horizontal size="large" />

                    <FormFieldInputNumber name="price" label="Cena" {...formikProps} />
                  </Group>

                  <Group>
                    <FormField name="nameEn" label="Nazwa angielska" {...formikProps} />

                    <Spacer horizontal size="large" />

                    <FormSwitchField name="isActive" label="Aktywny" {...formikProps} />
                  </Group>
                </Group>

                <Divider />

                <Group>
                  <div style={{ flexGrow: 1 }}>
                    <ButtonSubmit
                      disabled={!formikProps.dirty || formikProps.isSubmitting}
                      loading={formikProps.isSubmitting}
                      label="Zapisz"
                      onClick={formikProps.handleSubmit}
                      iconName="save"
                    />
                  </div>
                  {props.extraExtra && props.extraExtra.id && (
                    <Button
                      label="Usuń"
                      iconName="trash"
                      onClick={() => {
                        modalConfirm({
                          title: `Usuwasz ekstra dodatek? (${props.extraExtra.namePl})`,
                          content: <p>Czy na pewno?</p>,
                          okText: 'Tak, usuwam',
                          yesCallback: () => {
                            const hide = message.loading('Usuwam...', 0);
                            props.extraExtrasStore
                              .deleteItem({
                                id: props.extraExtra.id,
                              })
                              .catch(() => message.error('Wystąpił nieoczekiwany błąd.'))
                              .finally(hide);
                          },
                        });
                      }}
                    />
                  )}
                </Group>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
});

ExtraExtraForm.defaultProps = {};

export { ExtraExtraForm };
