import { Modal, Price, Spacer, ValueOrDash } from 'lib/ui';
import { CompanyView } from 'modules/Companies';
import { OrderModel } from 'modules/Orders/models/order.model';
import React from 'react';

export interface OrderInvoiceDataDialogProps {
  order: OrderModel;
  onClose: () => void;
}

export const OrderInvoiceDataDialog: React.FC<OrderInvoiceDataDialogProps> = props => {
  //  fake Company model to reuse CompanyView
  const company: any = props.order.invoiceData;

  return (
    <Modal visible width={'800px'} onClose={props.onClose}>
      <div data-bp="grid 6">
        <span>Adres E-mail:</span>
        <ValueOrDash value={props.order.deliveryAddress.email} />
        <span>Kwota zamówienia:</span>
        <Price amount={props.order.gross} />
        <span>Uwagi do faktury:</span>
        <span>{company.notes}</span>
      </div>
      <Spacer />
      <CompanyView company={company} />
    </Modal>
  );
};
