import { Box, styled } from 'lib/ui';
import { CuisineType } from 'modules/Restaurant';
import React, { FunctionComponent, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { OrderFormDeliveryDelayMessage } from 'pages/OrderFormPage/OrderFormPageStep3/OrderFormDeliveryDelayMessage';

export interface OrderFormCuisineTypePickerProps {
  onSelect?: (cuisineType: CuisineType) => void;
}

const enhance = connect(
  ({ restaurantsCuisineType, loading }) => ({
    restaurantsCuisineTypeStore: restaurantsCuisineType,
    loading,
  }),
  ({ restaurantsCuisineType }) => ({
    restaurantsCuisineType,
  }),
);

const OrderFormCuisineTypePickerContainer = styled.div.attrs({
  className: 'OrderFormCuisineTypePickerContainer',
})`
  margin-left: ${p => p.theme.space};
`;

const CuisineTypeItem = styled.div.attrs({ className: 'CuisineTypeItem' })<{
  selected: boolean;
}>`
  padding: ${p => p.theme.spaceSmallX} 0;
  font-weight: ${p => (p.selected ? p.theme.fontWeightBold : p.theme.fontWeightDefault)};
  background: ${p => (p.selected ? p.theme.colorPrimaryLightest : 'transparent')};
  cursor: pointer;

  :hover {
    background: ${p => p.theme.backgroundHover};
  }
`;

export const OrderFormCuisineTypePicker: FunctionComponent<OrderFormCuisineTypePickerProps> = enhance(props => {
  const [selectedCuisineType, setSelectedCuisineType] = useState<CuisineType | null>(null);

  useEffect(() => {
    props.restaurantsCuisineType.loadCollection();
  }, []);

  useEffect(() => {
    props.onSelect(selectedCuisineType);
  }, [selectedCuisineType]);

  return (
    <OrderFormCuisineTypePickerContainer>
      <OrderFormDeliveryDelayMessage bottomSpaceSize="default" />

      <Box title="Kuchnie" noShadow noMargin headerWithoutBackground>
        {props.restaurantsCuisineTypeStore.data.map(cuisineType => {
          return (
            <CuisineTypeItem
              onClick={() => {
                setSelectedCuisineType(
                  selectedCuisineType && selectedCuisineType.id === cuisineType.id ? null : cuisineType,
                );
              }}
              selected={selectedCuisineType !== null && selectedCuisineType.id === cuisineType.id}
              key={cuisineType.id}
            >
              {cuisineType.namePl}
            </CuisineTypeItem>
          );
        })}
      </Box>
    </OrderFormCuisineTypePickerContainer>
  );
});
