import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services/HttpApi';

export const CompaniesModel = SimpleRestModel({
  collectionName: 'companies',
  HttpApi,
});

export interface Company {
  id: number;
  companyName: string;
  vatNumber: string;
  addressCity: string;
  addressPostalCode: string;
  addressData: string;
  isActive: boolean;
  notes: string;
}
