import { styled } from '../../styled-components';
// {
//   simple?: boolean;
//   inline?: boolean;
// }
export const EmptyStateContentStyled = styled.div.attrs({ className: '' })`
  margin-top: ${p => (p.inline ? 0 : p.theme.spaceLarge)};
  margin-left: ${p => (p.inline ? p.theme.spaceLarge : 0)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
