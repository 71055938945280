/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { InputNumber, message, Select, Slider } from 'antd';
import { MapLabModal, RestaurantBasicInputModal } from 'components';
import { Formik } from 'formik';

import {
  RestaurantAddressField,
  ButtonSubmit,
  FormError,
  FormField,
  FormFieldContainer,
  FormFieldLabel,
  FormRadioField,
  FormSwitchField,
  FormTextareaField,
  TimeWeekPicker,
} from 'lib/forms';
import { Events } from 'lib/services';
import { Button, ButtonSize, ButtonUpload, Divider, Group, modalConfirm, Spacer } from 'lib/ui';

import {
  AVERAGE_MEAL_PREPARE_TIME_MAX,
  AVERAGE_MEAL_PREPARE_TIME_MIN,
  mapFormValuesToRestaurantData,
  mapRestaurantDataToFormValues,
  RESTAURANT_COMMISION_MAX,
  RESTAURANT_COMMISION_MIN,
} from 'modules/Restaurant';
import { path } from 'rambdax';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import { useToggle } from 'react-use';
import { compose } from 'recompose';
import { fileUpload } from 'services/fileUpload';
import { getUrlForMediaImage } from 'services/utils/getUrlForMediaImage';

import validationSchema from './RestaurantForm.ValidationSchema';

const enhance = compose(
  connect(
    ({ restaurantsCuisineType: { data: restaurantsCuisineTypes }, restaurants }) => ({
      restaurantsCuisineTypes,
      restaurantsStore: restaurants,
    }),
    ({
      restaurantsCuisineType: { loadCollection: loadrestaurantsCuisineType, createItem: createrestaurantsCuisineType },
      restaurants,
    }) => ({
      loadrestaurantsCuisineType,
      createrestaurantsCuisineType,
      restaurants,
    }),
  ),
);

export interface RestaurantFormProps {
  children?: ReactNode;
  restaurant?: any;
  onModalToggle: (visible: boolean) => void;
  onClose: () => void;
}

const RestaurantFormDefaultProps = {};

// @todo - refactor to use data from store not passed through props
const RestaurantForm: FunctionComponent<RestaurantFormProps> = enhance(props => {
  const restaurantData = props.restaurant || {};
  // console.log('RestaurantForm.props', props);
  const [cuisineTypeModalOpened, toggleCuisineTypeModal] = useToggle(false);
  const [mapModalVisible, toggleMapModal] = useToggle(false);

  // TODO - save formikProps in ref; to allow set errors from API on fields
  // if (props.restaurantsStore.errors) {
  //   Object.keys(props.restaurantsStore.errors)
  //     .filter(key => key !== 'nonFieldErrors')
  //     .forEach(fieldKey => {
  //       const errorMessage = props.restaurantsStore.errors[fieldKey];
  //       //  todo - refactor this - it causes rerender for every error
  //       if (!formikProps.errors[fieldKey]) {
  //         formikProps.setFieldError(fieldKey, errorMessage[0]);
  //       }
  //     });
  // }

  useEffect(() => {
    Events.on('Model.restaurants.createItem.success', data => {
      if (data) {
        return props.restaurants.loadItem({ id: data.id });
      }
      message.success('Restauracja została dodana');
    });
    return () => {
      Events.off('Model.restaurants.createItem.success', true);
    };
  }, [props.restaurants]);

  const logoUrl = restaurantData ? path<string>(`restaurantsStore.dataById.${restaurantData.id}.logo`, props) : null;

  return (
    <>
      <Formik
        initialValues={mapRestaurantDataToFormValues(restaurantData)}
        validationSchema={validationSchema}
        validateOnBlur
        onSubmit={(values, bag) => {
          const payload = mapFormValuesToRestaurantData(values);
          if (restaurantData.id) {
            props.restaurants
              .updateItem({
                data: payload,
                id: restaurantData.id,
              })
              .then(() => {
                bag.setSubmitting(false);
                message.success('Zmiany zostały zapisane');
                return props.restaurants.loadItem({ id: restaurantData.id });
              })
              .catch(() => {
                bag.setSubmitting(false);
              });
          } else {
            props.restaurants
              .createItem({ data: payload })
              .then(() => {
                bag.setSubmitting(false);
              })
              .catch(() => {
                bag.setSubmitting(false);
              });
          }
        }}
        enableReinitialize
      >
        {formikProps => {
          const FormSubmitButton = (
            <ButtonSubmit
              disabled={!formikProps.dirty || formikProps.isSubmitting}
              loading={formikProps.isSubmitting}
              label="Zapisz"
              onClick={formikProps.handleSubmit}
              iconName="save"
            />
          );
          return (
            <>
              {props.restaurantsStore.errors && (
                <>
                  <FormError errors={props.restaurantsStore.errors} />
                  <Spacer />
                </>
              )}
              <form onSubmit={formikProps.handleSubmit}>
                <Group vertical>
                  <Group align="space-between">
                    <FormField name="name" label="Nazwa restauracji" {...formikProps} />
                    <div>{FormSubmitButton}</div>
                  </Group>
                  <Group align="space-between">
                    <FormField name="displayedName" label="Nazwa wyświetlana" {...formikProps} />
                    <div>
                      <Group>
                        <FormSwitchField name="isActive" label="Aktywna" {...formikProps} />
                        <FormSwitchField name="isNew" label="Nowa" {...formikProps} />
                        <FormSwitchField name="hasLunch" label="Lunch" {...formikProps} />
                      </Group>
                    </div>
                  </Group>
                  <Group>
                    <FormField name="contactName" label="Osoba kontaktowa" {...formikProps} />
                    <FormField name="contactPhone" label="Telefon" {...formikProps} />
                    <FormField name="contactFax" label="Fax" {...formikProps} />
                  </Group>
                  <Group>
                    <FormField
                      name="contactEmail"
                      label="Adresy e-mail (podaj adresy oddzielone przecinkiem)"
                      {...formikProps}
                    />
                  </Group>
                  <Group>
                    <FormRadioField
                      name="prefferedOrdersContact"
                      label="Preferowany typ przyjmowania zleceń"
                      options={[
                        { value: 0, label: 'Fax' },
                        { value: 10, label: 'Email' },
                      ]}
                      {...formikProps}
                    />
                  </Group>
                  <Group vertical>
                    <Divider text="Godziny przyjmowania zleceń" />
                    <TimeWeekPicker name="openHours" {...formikProps} />
                  </Group>
                  <Group vertical>
                    <Divider text="Czas przygotowania" />
                    <div data-bp="grid">
                      <div data-bp="9">
                        <Slider
                          {...formikProps}
                          value={formikProps.values.averageMealPrepareTime}
                          min={AVERAGE_MEAL_PREPARE_TIME_MIN}
                          max={AVERAGE_MEAL_PREPARE_TIME_MAX}
                          onChange={newAverageMealPrepareTime => {
                            formikProps.setFieldValue('averageMealPrepareTime', +newAverageMealPrepareTime);
                          }}
                        />
                      </div>
                      <div data-bp="3">
                        <InputNumber
                          {...formikProps}
                          value={formikProps.values.averageMealPrepareTime}
                          min={AVERAGE_MEAL_PREPARE_TIME_MIN}
                          max={AVERAGE_MEAL_PREPARE_TIME_MAX}
                          name="averageMealPrepareTime"
                          onChange={value => {
                            let newAverageMealPrepareTime = +`${value}`.replace(/[^0-9]+/, '');

                            if (newAverageMealPrepareTime < AVERAGE_MEAL_PREPARE_TIME_MIN) {
                              newAverageMealPrepareTime = AVERAGE_MEAL_PREPARE_TIME_MIN;
                            } else if (newAverageMealPrepareTime > AVERAGE_MEAL_PREPARE_TIME_MAX) {
                              newAverageMealPrepareTime = AVERAGE_MEAL_PREPARE_TIME_MAX;
                            }
                            formikProps.setFieldValue('averageMealPrepareTime', +newAverageMealPrepareTime);
                          }}
                          precision={0}
                        />
                        <Spacer horizontal size="small" />
                        min
                      </div>
                    </div>
                  </Group>
                  <Group vertical>
                    <Divider text="Kuchnia" />
                    <div style={{ width: '100%' }}>
                      <Group>
                        <Select
                          mode="multiple"
                          labelInValue
                          value={formikProps.values.cuisineTypes}
                          onChange={value => {
                            formikProps.setFieldValue('cuisineTypes', value);
                          }}
                        >
                          {(props.restaurantsCuisineTypes || []).map(item => {
                            return (
                              <Select.Option key={item.id} title={item.namePl}>
                                {item.namePl} / {item.nameEn}
                              </Select.Option>
                            );
                          })}
                        </Select>
                        <div style={{ height: '32px', overflow: 'hidden' }}>
                          <Button
                            iconName="plus"
                            onClick={() => {
                              toggleCuisineTypeModal(true);
                              props.onModalToggle(true);
                            }}
                          />
                        </div>
                      </Group>
                    </div>
                  </Group>

                  <Divider text="Dodatkowe informacje" />

                  <Group align="space-between">
                    <FormTextareaField name="descriptionPl" label="Opis PL" {...formikProps} />
                    <FormTextareaField name="descriptionEn" label="Opis EN" {...formikProps} />
                  </Group>
                  <Group align="space-between">
                    <RestaurantAddressField
                      label="Adres"
                      cityFieldName="city"
                      postalCodeFieldName="postalCode"
                      streetFieldName="street"
                      houseNumberFieldName="houseNumber"
                      {...formikProps}
                    />
                  </Group>
                  {/* set address only for already created restaurants */}
                  {restaurantData.id && (
                    <Button
                      iconName="map-marked-alt"
                      size={ButtonSize.Small}
                      label={'Pokaż na mapie'}
                      onClick={() => {
                        props.onModalToggle(true);
                        toggleMapModal(true);
                      }}
                    />
                  )}

                  <Spacer />
                  <div>
                    <FormField name="pageUrl" label="Adres strony internetowej" {...formikProps} />
                  </div>
                  <Spacer />

                  <Group align="space-between" verticalAlign="top">
                    <FormTextareaField name="notes" label="Uwagi" {...formikProps} />
                    <FormFieldContainer>
                      <FormFieldLabel>Prowizja</FormFieldLabel>
                      <Group>
                        <InputNumber
                          {...formikProps}
                          value={formikProps.values.commision}
                          min={RESTAURANT_COMMISION_MIN}
                          max={RESTAURANT_COMMISION_MAX}
                          name="commision"
                          onChange={value => {
                            let commisionValue = +`${value}`.replace(/[^0-9]+/, '');

                            if (commisionValue < RESTAURANT_COMMISION_MIN) {
                              commisionValue = RESTAURANT_COMMISION_MIN;
                            } else if (commisionValue > RESTAURANT_COMMISION_MAX) {
                              commisionValue = RESTAURANT_COMMISION_MAX;
                            }
                            formikProps.setFieldValue('commision', +commisionValue);
                          }}
                          precision={0}
                        />
                        <Spacer size="small" />%
                      </Group>
                    </FormFieldContainer>
                  </Group>

                  <Spacer />

                  <Group>
                    {logoUrl && (
                      <img
                        src={getUrlForMediaImage(logoUrl)}
                        style={{
                          maxWidth: '100%',
                          maxHeight: '80px',
                        }}
                        alt="Logo restauracji"
                      />
                    )}
                    {props.restaurant && (
                      <ButtonUpload
                        label={props.restaurant.logo ? 'Zmień logo' : 'Dodaj logo'}
                        onChange={file => {
                          if (file) {
                            fileUpload(file, {
                              path: `/restaurants/${props.restaurant.id}/`,
                              fieldName: 'logo',
                              methodName: 'PATCH',
                            })
                              .then(() => {
                                message.success('Logo zostało dodane');
                              })
                              .catch(() => {
                                message.error('Wystąpił błąd');
                              })
                              .finally(() => {
                                props.restaurants.loadItem({
                                  id: props.restaurant.id,
                                });
                              });
                          }
                        }}
                      />
                    )}
                  </Group>
                </Group>

                <Divider />
                <Group>
                  <div style={{ flexGrow: 1 }}>{FormSubmitButton}</div>
                  {props.restaurant && props.restaurant.id && (
                    <Button
                      label="Usuń"
                      iconName="trash"
                      onClick={() => {
                        modalConfirm({
                          title: `Czy na pewno usunąć wybraną restaurację? (${props.restaurant.displayedName})`,
                          content: ' ',
                          yesCallback: () => {
                            const hide = message.loading('Usuwam...', 0);
                            props.restaurants
                              .deleteItem({
                                id: props.restaurant.id,
                              })
                              .then(() => {
                                message.success('Restauracja została usunięta');
                                props.restaurants.loadCollection();
                                props.onClose();
                              })
                              .catch(() => {
                                message.error('Wystąpił nieoczekiwany błąd.');
                              })
                              .finally(() => {
                                hide();
                              });
                          },
                        });
                      }}
                    />
                  )}
                </Group>
              </form>
              <RestaurantBasicInputModal
                visible={cuisineTypeModalOpened}
                onClose={() => {
                  toggleCuisineTypeModal(false);
                  // setSelectedAddressForMap(null);
                  props.onModalToggle(false);
                }}
                onSubmit={value => {
                  props.createrestaurantsCuisineType({ data: value }).then(data => {
                    toggleCuisineTypeModal(false);
                    formikProps.setFieldValue('cuisineTypes', [
                      ...formikProps.values.cuisineTypes,
                      {
                        key: `${data.id}`,
                        label: `${data.namePl} / ${data.nameEn}`,
                      },
                    ]);
                    props.onModalToggle(false);
                  });
                }}
              />
              {mapModalVisible && (
                <MapLabModal
                  visible
                  street={formikProps.values.street}
                  houseNumber={formikProps.values.houseNumber}
                  postalCode={formikProps.values.postalCode}
                  city={formikProps.values.city}
                  addressLon={formikProps.values.lon}
                  addressLat={formikProps.values.lat}
                  details={formikProps.values.details}
                  storeName="restaurants"
                  onSaveGeoCoordinates={(lat, lon) => {
                    return props.restaurants
                      .updateItem({
                        data: {
                          lat,
                          lon,
                        },
                        id: restaurantData.id,
                      })
                      .then(() => {
                        formikProps.setFieldValue('lat', lat);
                        formikProps.setFieldValue('lon', lon);
                      });
                  }}
                  onClose={() => {
                    toggleMapModal(false);
                    props.onModalToggle(false);
                  }}
                />
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
});

RestaurantForm.defaultProps = RestaurantFormDefaultProps;

export { RestaurantForm };
