import React, { FunctionComponent, useRef } from 'react';

import { ButtonStyled } from './ButtonStyled';
import { ButtonProps } from '../Button/Button';
import { InputStyled } from './InputStyled';

export interface ButtonUploadProps extends ButtonProps {
  onChange: (file: File | null) => void;
}

const ButtonUploadDefaultProps = {
  label: 'Dodaj plik',
  iconName: 'upload',
};

export const ButtonUpload: FunctionComponent<ButtonUploadProps> = props => {
  const inputElement = useRef<HTMLInputElement | null>(null);

  const { onChange, ...buttonProps } = props;

  return (
    <ButtonStyled
      {...buttonProps}
      onClick={() => {
        if (inputElement.current) {
          const clickEvent = new MouseEvent('click');
          inputElement.current.dispatchEvent(clickEvent);
        }
      }}>
      <InputStyled
        ref={inputElement}
        type="file"
        onChange={e => {
          const { files } = e.target;
          if (files && files.length) {
            props.onChange(files[0]);
          } else {
            props.onChange(null);
          }
        }}
      />
    </ButtonStyled>
  );
};

ButtonUpload.defaultProps = ButtonUploadDefaultProps;
