import { styled } from '../ui/styled-components';

const InputStyled = styled.input`
  display: block;
  padding: ${props => props.theme.spaceSmallX} ${props => props.theme.spaceSmall};
  width: ${props => (props.width ? props.width : '300px')};
  max-width: 100%;
  border: 1px solid ${props => props.theme.colorGrey};
  font-size: 1rem;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.colorWhite};
  transition: all 0.25s ease-in-out;
  outline: none;

  :focus {
    border-color: ${props => props.theme.colorPrimary};
  }
`;

export { InputStyled as Input };
