import { Empty, message } from 'antd';
import { PageLayout } from 'components';
import { FormError, Textarea } from 'lib/forms';
import { Events } from 'lib/services';
import { Button, ButtonVariant, Divider, DividerTextOrientation, Group, Spacer } from 'lib/ui';
import { Client } from 'modules/Clients';
import { statusMessage } from 'modules/MessageHelper';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormState } from 'modules/Orders/OrderFormState';
import { compose } from 'rambdax';
import React, { useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { useObservable, useToggle } from 'react-use';
import { OrderFormSteps } from '../OrderFormSteps';
import { OrderFormClient } from './OrderFormClient';
import { OrderFormClientNew } from './OrderFormClientNew';

import { OrderFormCompany } from '../components/OrderFormCompany';

const enhance = compose(
  connect(
    ({ orders, loading, clients, clientPhones, clientAddress }) => ({
      loading,
      ordersStore: orders,
      clientsStore: clients,
      clientPhonesStore: clientPhones,
      clientAddressStore: clientAddress,
    }),
    ({ orders, clients, clientPhones, clientAddress }) => ({
      orders,
      clients,
      clientPhones,
      clientAddress,
    }),
  ),
);

export const OrderFormPageStep2 = enhance(props => {
  const { clientId } = props.match.params;

  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());

  //  load sub collection on startup
  useEffect(() => {
    if (clientId) {
      props.clientPhones.loadCollection({
        customerId: clientId,
      });
      props.clientAddress.loadCollection({
        customerId: clientId,
      });
      props.clients.loadItem({ id: clientId });
    }
  }, [clientId, props.clientAddress, props.clientPhones]);

  // reset errors on enter
  useEffect(() => {
    props.clients.clearError();
    props.clientPhones.clearError();
    props.clientAddress.clearError();
  }, [clientId]);

  const [loading, toggleLoading] = useToggle(false);
  const [loadingPhone, toggleLoadingPhone] = useToggle(false);
  const [loadingAddress, toggleLoadingAddress] = useToggle(false);

  const [newCreatedClient, setNewCreatedClient] = useState<Client | undefined>(undefined);
  const [createdAddress, setCreatedAddress] = useState(null);
  const [createdPhone, setCreatedPhone] = useState(null);
  const [goNext, toggleGoNext] = useToggle(false);
  const [buttonNextDisabled, toggleButtonNextDisabled] = useToggle(true);

  // todo: refactor below effects - too many conditions, extract logic to a service maybe?

  //  create new client
  useEffect(() => {
    if (!orderState) {
      return;
    }

    if (!newCreatedClient && goNext && orderState.newClientData) {
      toggleLoading(true);
      //  create client
      props.clients
        .createItem({
          data: {
            ...orderState.newClientData,
            createAccount: orderState.newClientData.hasAccount,
          },
        })
        .then(data => {
          setNewCreatedClient(data);
          message.success('Klient został dodany');
          props.clients.clearError();
          toggleLoading(false);
        })
        .catch(() => {
          message.error('Podczas zapisu wystąpiły błędy.');
          toggleLoading(false);
          toggleGoNext(false);
        });
    }

    /**
     * Disable 'next' button if there is no address OR chosen address has no lat/lon coords OR there is no phone selected
     */
    toggleButtonNextDisabled(
      !orderState.newClientData &&
        (((!orderState.address || !orderState.address.lat || !orderState.address.lon) &&
          !orderState.withDirectPickUp) ||
          !orderState.phone),
    );
  }, [goNext, newCreatedClient, orderState, toggleButtonNextDisabled, toggleLoading, props.clients, toggleGoNext]);

  //  create address when customer is created
  useEffect(() => {
    if (!loadingAddress && newCreatedClient && !createdAddress && goNext && orderState && orderState.newClientData) {
      toggleLoadingAddress(true);
      props.clientAddress
        .createItem({
          data: {
            addressCity: orderState.newClientData.city,
            addressPostalCode: orderState.newClientData.postalCode,
            addressData: orderState.newClientData.street,
            notes: orderState.newClientData.addressNotes,
          },
          customerId: newCreatedClient.id,
        })
        .then(data => {
          message.success('Adres został przypisany do klienta');
          toggleLoading(false);
          OrderFormService.setState({ address: data });
          setCreatedAddress(data);
          toggleLoadingAddress(false);
        })
        .catch(() => {
          message.error('Podczas zapisu wystąpiły błędy.');
          toggleLoading(false);
          toggleGoNext(false);
          toggleLoadingAddress(false);
        });
    }
  }, [
    goNext,
    newCreatedClient,
    orderState,
    loadingAddress,
    createdAddress,
    toggleLoadingAddress,
    props.clientAddress,
    toggleLoading,
    toggleGoNext,
  ]);

  //  create phone
  useEffect(() => {
    if (!loadingPhone && newCreatedClient && !createdPhone && goNext && orderState && orderState.newClientData) {
      toggleLoadingPhone(true);
      props.clientPhones
        .createItem({
          data: {
            phone: orderState.newClientData.phone,
          },
          customerId: newCreatedClient.id,
        })
        .then(data => {
          message.success('Telefon został przypisany do klienta');
          toggleLoading(false);
          setCreatedPhone(data);
          OrderFormService.setState({ phone: data.phone });
          toggleLoadingPhone(false);
        })
        .catch(() => {
          message.error('Podczas zapisu wystąpiły błędy.');
          toggleLoading(false);
          toggleGoNext(false);
          toggleLoadingPhone(false);
        });
    }
  }, [
    goNext,
    newCreatedClient,
    orderState,
    loadingPhone,
    createdPhone,
    toggleLoadingPhone,
    props.clientPhones,
    toggleLoading,
    toggleGoNext,
  ]);

  // redirect from 'new' to 'userId' (after creation)
  useEffect(() => {
    if (newCreatedClient && createdAddress && createdPhone) {
      OrderFormService.setState({
        client: newCreatedClient,
      });
      props.history.push(`/orders/new/2/${newCreatedClient.id}`);
    }
  }, [newCreatedClient, createdAddress, createdPhone, props.history]);

  // when fetched user data - save it in orderState
  useEffect(() => {
    const userData = props.clientsStore.dataById[clientId];
    if (!userData) {
      return;
    }

    OrderFormService.setState({
      client: userData,
    });
  }, [props.clientsStore.dataById]);

  // if (!orderState.client) {
  //   props.history.push('/orders/new/1');
  // }

  if (!orderState) {
    return null;
  }

  const clientNotes = orderState.clientNote || (orderState.client ? orderState.client.notes : '');

  const errors = {
    ...props.clientsStore.errors,
    ...props.clientPhonesStore.errors,
    ...props.clientAddressStore.errors,
  };

  return (
    <PageLayout title="Tworzenie nowego zamówienia" header={<OrderFormSteps activePage={2} />}>
      <Group align="space-between" verticalAlign="top">
        <div>
          {errors && (
            <>
              <FormError errors={errors} />
              <Spacer />
            </>
          )}

          {clientId && <OrderFormClient clientId={clientId} />}
          {!clientId && <OrderFormClientNew createdClient={!!newCreatedClient} />}

          <Spacer />

          <Divider text="Dane do FV" textOrientation={DividerTextOrientation.Left} />

          <OrderFormCompany clientId={clientId} />

          <Spacer />

          {clientId && (
            <>
              <Divider text="Informacje o kliencie" textOrientation={DividerTextOrientation.Left} />

              <Textarea
                value={clientNotes}
                style={{
                  width: '100%',
                  borderColor: '#ccc',
                }}
                onChange={ev =>
                  OrderFormService.setState({
                    clientNote: ev.currentTarget.value,
                  })
                }
              />

              <Spacer />
            </>
          )}

          <Button
            label="Dalej"
            disabled={buttonNextDisabled}
            loading={loading}
            variant={ButtonVariant.Primary}
            onClick={() => {
              if (clientId) {
                props.history.push('/orders/new/3');
              } else if (orderState.newClientData) {
                toggleGoNext(true);
              }
            }}
          />
        </div>

        <div style={{ width: '50%' }}>
          <h2 style={{ textAlign: 'center' }}>Ostatnie 20 zamówień klienta</h2>
          <Empty description={'Brak zamówień'} />
        </div>
      </Group>
    </PageLayout>
  );
});
