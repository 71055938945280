import React, { FunctionComponent, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'recompose';
import { Spacer, styled } from 'lib/ui';
import { GENERAL_SETTINGS_FIXED_ID } from 'modules/Settings/GeneralSettingsModel';

const MessageContainer = styled.div`
  color: ${p => p.theme.colorWhite}
  background: ${p => p.theme.colorWarning}
  padding: 10px;
  min-width: 300px;
`;

type BottomSpaceSize = 'none' | 'default' | 'small' | 'smallX' | 'large';

interface OrderFormDeliveryDelayMessageProps {
  // same as 'size' for Spacer
  bottomSpaceSize: BottomSpaceSize;
}

const deliveryDelayMessageDefaultProps = {
  bottomSpaceSize: 'small' as BottomSpaceSize,
};

const enhance = compose(
  connect(
    ({ generalSettings }) => ({
      generalSettingsData: generalSettings.dataById[GENERAL_SETTINGS_FIXED_ID],
    }),
    ({ generalSettings }) => ({
      generalSettingsStore: generalSettings,
    }),
  ),
);

const OrderFormDeliveryDelayMessage: FunctionComponent<OrderFormDeliveryDelayMessageProps> = enhance(props => {
  const [deliveryDelay, setDeliveryDelay] = useState(
    (props.generalSettingsData && +props.generalSettingsData.deliveryDelay) || 0,
  );

  // load settings
  useEffect(() => {
    props.generalSettingsStore
      .loadItem({
        id: GENERAL_SETTINGS_FIXED_ID,
      })
      .then(res => {
        setDeliveryDelay(res.deliveryDelay);
      });
  }, []);

  return (
    <div data-bp="grid">
      <div data-bp="12">
        {deliveryDelay > 0 && (
          <>
            <MessageContainer>
              <p>Uwaga!</p>
              Podany czas dostawy uwzględnia <br />
              aktualne opóźnienia na poziomie {deliveryDelay}%
            </MessageContainer>
            {props.bottomSpaceSize !== 'none' && <Spacer size={props.bottomSpaceSize} />}
          </>
        )}
      </div>
    </div>
  );
});

OrderFormDeliveryDelayMessage.defaultProps = deliveryDelayMessageDefaultProps;

export { OrderFormDeliveryDelayMessage };
