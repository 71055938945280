import { Address } from 'components';
import { Group, styled } from 'lib/ui';
import React, { FunctionComponent } from 'react';
import { Company } from '../CompaniesModel';

export interface CompanyProps {
  company: Company;
  selected?: boolean;
  clickable?: boolean;
  tiny?: boolean;
  onClick?: () => void;
}

const CompanyDefaultProps = {};

const CompanyContainer = styled.div.attrs({ className: 'CompanyContainer' })<{
  selected: boolean;
  clickable: boolean;
}>`
  cursor: ${p => (p.clickable ? 'pointer' : 'default')};
  margin-bottom: ${p => p.theme.spaceSmall};
  padding: ${p => p.theme.space} ${p => p.theme.spaceSmall};
  background: ${p => (p.selected ? p.theme.colorPrimary : p.theme.colorDirtyWhite)};
  border: 1px solid transparent;
  border-color: ${p => (p.selected ? p.theme.colorPrimary : 'transparent')};
  border-radius: 4px;
  color: ${p => (p.selected ? p.theme.colorWhite : '')};

  :hover {
    background: ${p => (p.clickable ? p.theme.backgroundHover : p.theme.colorPrimary)};
  }
`;

export const CompanyView: FunctionComponent<CompanyProps> = props => {
  const { company } = props;
  if (props.tiny) {
    return (
      <CompanyContainer onClick={props.onClick} selected={!!props.selected} clickable={!!props.clickable}>
        <strong>{company.companyName}</strong>
        <br />
        <span key={'nip_label'}>NIP</span>: 
        {company.vatNumber}
        <br /><br />
        <Address
          tiny
          street={company.addressData}
          city={company.addressCity}
          postalCode={company.addressPostalCode}
          houseNumber=''
          details=''
        />
      </CompanyContainer>
    );
  }

  return (
    <CompanyContainer onClick={props.onClick} selected={!!props.selected} clickable={!!props.clickable}>
      <Group vertical smallXSpacing>
        <span>Firma</span>
        <strong>{company.companyName}</strong>

        {company.vatNumber && [
          <span key={'nip_label'}>NIP</span>,
          <strong key={company.vatNumber}>{company.vatNumber}</strong>,
        ]}
        <span>Adres</span>
        <strong>
          <Address
            tiny
            street={company.addressData}
            city={company.addressCity}
            postalCode={company.addressPostalCode}
            houseNumber=''
            details=''
          />
        </strong>
      </Group>
    </CompanyContainer>
  );
};

CompanyView.defaultProps = CompanyDefaultProps;
