import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

export interface MessageDriver {
  id: number;
  name: string;
}

export interface DriverMessage {
  id: number;
  sendDate: number;
  content: string;
  driversRead: MessageDriver[];
  percentageReadBy: number;
  sendTo: MessageDriver[];
}

export const DriversMessagesModel = SimpleRestModel({
  collectionName: 'driversMessages',
  apiPath: 'drivers/messages',
  HttpApi,
});
