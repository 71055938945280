/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FunctionComponent, useState } from 'react';
import { AddressDialog, Button, modalConfirm, Spacer } from 'lib/ui';
import { ClientAddress } from 'modules/Clients';
import { useToggle } from 'react-use';
import { message } from 'antd';
import { MapLabModal, Address } from 'components';
import connect from 'react-redux/es/connect/connect';
import { getErrorMessageFromErrorResponse } from 'services/utils/getErrorMessageFromErrorResponse';
import { AddressComponentDialog } from './AddressComponentDialog';

const enhance = connect(
  ({ clientAddress, loading }) => ({
    clientAddressStore: clientAddress,
    loading,
  }),
  ({ clientAddress }) => ({
    clientAddress,
  }),
);

interface AddressComponentProps {
  clientId: number;
  data: ClientAddress[];
  preview: boolean;
  onChoose?: (addressObject: ClientAddress) => void;
  onSave?: Function;
}

const AddressComponent: FunctionComponent<AddressComponentProps> = enhance(props => {
  const [addressComponentDialogVisible, toggleAddressComponentDialog] = useToggle(false);
  const [addressDialogVisible, toggleAddressDialog] = useToggle(false);
  const [selectedAddress, setSelectedAddress] = useState<ClientAddress | null>(null);
  const [mapLabModalVisible, toggleMapLabModalDialog] = useToggle(false);
  const [selectedAddressForMap, setSelectedAddressForMap] = useState<ClientAddress | null>(null);

  const previewData = props.preview ? props.data.slice(0, 3) : props.data;
  let containerStyle;
  if (props.preview) {
    containerStyle = { display: 'flex', alignItems: 'stretch', width: '100%' };
  } else {
    containerStyle = { display: 'grid', gridTemplateColumns: 'repeat(8, 0fr)' };
  }
  return (
    <div style={containerStyle}>
      {previewData.length > 0 &&
        previewData.map((addressObject: ClientAddress) => (
          <React.Fragment key={addressObject.id}>
            <Address
              key={addressObject.id}
              street={addressObject.street}
              houseNumber={addressObject.houseNumber}
              postalCode={addressObject.postalCode}
              city={addressObject.city}
              lon={addressObject.lon}
              lat={addressObject.lat}
              details={addressObject.details}
              editable
              onChoose={() => {
                if (props.onChoose) {
                  props.onChoose(addressObject);
                }
              }}
              onDelete={() => {
                modalConfirm({
                  title: `Czy na pewno usunąć adres?`,
                  content: '',
                  yesCallback: () => {
                    const hide = message.loading('Usuwam adres...', 0);
                    props.clientAddress
                      .deleteItem({
                        customerId: props.clientId,
                        id: addressObject.id,
                      })
                      .then(() => {
                        message.success('Adres został usunięty');
                        // props.clientAddress
                        //   .loadCollection({
                        //     customerId: props.clientId,
                        //   })
                        if (props.onSave) props.onSave();
                      })
                      .catch(errorResponse => {
                        message.error(getErrorMessageFromErrorResponse(errorResponse));
                      })
                      .finally(() => {
                        hide();
                      });
                  },
                });
              }}
              onEdit={() => {
                setSelectedAddress(addressObject);
                toggleAddressDialog(true);
              }}
              onShowOnMap={() => {
                setSelectedAddressForMap(addressObject);
                toggleMapLabModalDialog(true);
              }}
            />
            <Spacer horizontal />
          </React.Fragment>
        ))}

      {addressDialogVisible && (
        <AddressDialog
          open
          loading={props.loading.effects.clientAddress.createItem || props.loading.effects.clientAddress.updateItem}
          errors={props.clientAddressStore.errors}
          value={
            selectedAddress !== null
              ? {
                  street: selectedAddress.street,
                  houseNumber: selectedAddress.houseNumber,
                  details: selectedAddress.details,
                  postalCode: selectedAddress.postalCode,
                  city: selectedAddress.city,
                }
              : undefined
          }
          title={selectedAddress ? `Edycja adresu` : `Dodawanie adresu`}
          okText={selectedAddress ? 'Zapisz' : 'Dodaj'}
          withNotes
          onSave={data => {
            if (!selectedAddress) {
              props.clientAddress
                .createItem({
                  data: {
                    street: data.values.street,
                    postalCode: data.values.postalCode,
                    city: data.values.city,
                    houseNumber: data.values.houseNumber,
                    details: data.values.details,
                  },
                  customerId: props.clientId,
                })
                .then(() => {
                  message.success('Adres został dodany');
                  if (props.onSave) props.onSave();
                  toggleAddressDialog(false);
                });
            } else {
              props.clientAddress
                .updateItem({
                  data: {
                    street: data.values.street,
                    postalCode: data.values.postalCode,
                    city: data.values.city,
                    houseNumber: data.values.houseNumber,
                    details: data.values.details,
                  },
                  id: selectedAddress.id,
                  customerId: props.clientId,
                })
                .then(() => {
                  message.success('Adres został zaktualizowany');
                  if (props.onSave) props.onSave();
                  toggleAddressDialog(false);
                });
            }
          }}
          onCancel={() => {
            toggleAddressDialog(false);
          }}
        />
      )}
      {mapLabModalVisible && (
        <MapLabModal
          visible
          city={selectedAddressForMap ? selectedAddressForMap.city : ''}
          postalCode={selectedAddressForMap ? selectedAddressForMap.postalCode : ''}
          street={selectedAddressForMap ? selectedAddressForMap.street : ''}
          details={selectedAddressForMap ? selectedAddressForMap.details : ''}
          houseNumber={selectedAddressForMap ? selectedAddressForMap.houseNumber : ''}
          addressLat={(selectedAddressForMap && selectedAddressForMap.lat) || ''}
          addressLon={(selectedAddressForMap && selectedAddressForMap.lon) || ''}
          storeName="clientAddress"
          onSaveGeoCoordinates={(lat, lon, entranceLat, entranceLon) => {
            if (selectedAddressForMap) {
              return props.clientAddress
                .updateItem({
                  data: {
                    lat,
                    lon,
                    entranceLat,
                    entranceLon,
                  },
                  id: selectedAddressForMap.id,
                  customerId: props.clientId,
                })
                .then(() => {
                  if (props.onSave) props.onSave();
                });
            }
            return Promise.resolve();
          }}
          onClose={() => {
            toggleMapLabModalDialog(false);
            setSelectedAddressForMap(null);
          }}
        />
      )}

      {props.data.length > 3 && props.preview && (
        <div style={{ alignSelf: 'center', display: 'flex' }}>
          <Button onClick={() => toggleAddressComponentDialog(true)}>&#9632;&#9632;&#9632;</Button>
        </div>
      )}

      {props.preview && addressComponentDialogVisible && (
        <AddressComponentDialog
          addressData={props.data}
          onDelete={addressObject => {
            modalConfirm({
              title: `Czy na pewno usunąć adres?`,
              content: '',
              yesCallback: () => {
                const hide = message.loading('Usuwam adres...', 0);
                props.clientAddress
                  .deleteItem({
                    customerId: props.clientId,
                    id: addressObject.id,
                  })
                  .then(() => {
                    message.success('Adres został usunięty');
                    // props.clientAddress
                    //   .loadCollection({
                    //     customerId: props.clientId,
                    //   })
                    if (props.onSave) props.onSave();
                  })
                  .catch(() => {
                    message.error('Wystąpił nieoczekiwany błąd.');
                  })
                  .finally(() => {
                    hide();
                  });
              },
            });
          }}
          onEdit={addressObject => {
            setSelectedAddress(addressObject);
            toggleAddressDialog(true);
          }}
          onShowMap={addressObject => {
            setSelectedAddressForMap(addressObject);
            toggleMapLabModalDialog(true);
          }}
          title={'Adresy'}
          open
          clientId={props.clientId}
          onCancel={() => toggleAddressComponentDialog(false)}
        />
      )}
    </div>
  );
});

export { AddressComponent };
