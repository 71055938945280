import { BalanceWithDescriptionModal } from 'components/modals/BalanceWithDescriptionModal';
import { FormFieldLabel } from 'lib/forms';
import { DialogService } from 'lib/services/DialogService';
import { Button, ButtonSize, ButtonVariant, Group, Price, Spacer } from 'lib/ui';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormState } from 'modules/Orders/OrderFormState';
import React from 'react';

export interface OrderFormDriverBonusProps {
  orderState: OrderFormState;
}

export const OrderFormDriverBonus: React.FC<OrderFormDriverBonusProps> = props => {
  return (
    <>
      <FormFieldLabel>Kierowca: bonus/kara:</FormFieldLabel>
      <div>
        Kwota: <Price amount={props.orderState.driverBonusAmount} />
      </div>
      <div>
        Opis: <span>{props.orderState.driverBonusDescription}</span>
      </div>
      <Spacer size="small" />
      <Group>
        <Button
          variant={ButtonVariant.Default}
          size={ButtonSize.Small}
          onClick={() => {
            DialogService.open(BalanceWithDescriptionModal, {
              amount: props.orderState.driverBonusAmount,
              description: props.orderState.driverBonusDescription
                ? props.orderState.driverBonusDescription
                : undefined,
              onClose: values => {
                if (!values) {
                  return;
                }

                OrderFormService.setState({
                  driverBonusAmount: values.bonus,
                  driverBonusDescription: values.description,
                });
              },
            });
          }}
        >
          zmień
        </Button>
      </Group>
    </>
  );
};
