import { Empty, Select } from 'antd';

import { DateRangePickerModal, PageLayout, RestaurantDrawer } from 'components';
import { SearchInput } from 'lib/forms';
import { Button, ButtonVariant, DimmerLoading, Group, modalConfirm, Spacer } from 'lib/ui';
import { RestaurantsTable } from 'modules/Restaurant';
import React, { useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import useToggle from 'react-use/lib/useToggle';

const enhance = connect(
  ({ restaurants: { data }, loading, restaurantsCuisineType }) => ({
    data,
    loading,
    restaurantsCuisineTypeStore: restaurantsCuisineType,
  }),
  ({ restaurants, restaurantsCuisineType }) => ({
    restaurants,
    restaurantsCuisineType,
  }),
);

const RestaurantsPage = enhance(props => {
  const [restaurantFormDrawerOpened, toggleRestaurantFormDrawer] = useToggle(false);
  const [restaurantDrawerOpened, toggleRestaurantDrawer] = useToggle(false);
  const [dateRangePickerOpened, toggleDateRangePickerOpened] = useToggle(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState<any>(null);
  const [selectedRestaurants, setSelectedRestaurants] = useState<any[]>([]);
  const [targetTab, setTargetTab] = useState<string>('1');
  const [restaurantsListVisible, toggleRestaurantsList] = useToggle(true);

  // search query
  const [query, setQuery] = useState('');
  const [activeQuery, setActiveQuery] = useState('');

  useEffect(() => {
    if (restaurantsListVisible) {
      props.restaurants.loadCollection({
        params: {
          limit: 100000,
        },
      });
      props.restaurantsCuisineType.loadCollection();
    }
  }, [props.restaurants, props.restaurantsCuisineType, restaurantsListVisible]);

  //  restaurants filters
  const [selectedCuisineId, setCuisine] = useState('all');

  const restaurants = props.data
    .filter(
      restaurant =>
        selectedCuisineId === 'all' ||
        restaurant.cuisineTypes.find(cuisineType => String(cuisineType.id) === String(selectedCuisineId)),
    )
    .filter(restaurant => {
      const name = `${restaurant.name}${restaurant.displayedName}`;
      return activeQuery ? name.toLowerCase().includes(activeQuery.toLowerCase()) : true;
    });
  const cuisineTypes = props.restaurantsCuisineTypeStore.data.map(cuisineType => ({
    ...cuisineType,
    key: `${cuisineType.id}`,
    label: cuisineType.namePl,
  }));
  const selectedCuisine = cuisineTypes.find(cuisineType => cuisineType.key === selectedCuisineId);

  return (
    <>
      <PageLayout title="Restauracje">
        {restaurantsListVisible && (
          <>
            <Group align="space-between" verticalAlign="top">
              <Group vertical>
                <Group>
                  <Button
                    disabled={selectedRestaurants.length === 0}
                    label="Nieaktywna dziś"
                    onClick={() => {
                      modalConfirm({
                        title: `Czy na pewno ustawić jako nieaktywne dzisiaj wybrane restauracje? ${selectedRestaurants.length}`,
                        content: selectedRestaurants.map(r => r.displayedName).join(', '),
                        yesCallback: () => {
                          props.restaurants
                            .customCollectionAction({
                              data: {
                                restaurantIds: selectedRestaurants.map(r => r.id),
                              },
                              action: 'disable-today',
                            })
                            .then(() => {
                              props.restaurants.loadCollection();
                            });
                        },
                      });
                    }}
                  />
                  <Button
                    disabled={selectedRestaurants.length === 0}
                    label="Zablokuj"
                    onClick={() => {
                      modalConfirm({
                        title: `Czy na pewno zablokować wybrane restauracje? (${selectedRestaurants.length})`,
                        content: selectedRestaurants.map(r => r.displayedName).join(', '),
                        yesCallback: () => {
                          props.restaurants
                            .customCollectionAction({
                              data: {
                                restaurantIds: selectedRestaurants.map(r => r.id),
                              },
                              action: 'block',
                            })
                            .then(() => {
                              props.restaurants.loadCollection();
                            });
                        },
                      });
                    }}
                  />
                  <Button
                    disabled={selectedRestaurants.length === 0}
                    label="Nieaktywna do..."
                    onClick={() => {
                      toggleDateRangePickerOpened(true);
                    }}
                  />
                  {dateRangePickerOpened && (
                    <DateRangePickerModal
                      visible={dateRangePickerOpened}
                      header={<span> Okres nieaktywności dla wybranych restauracji</span>}
                      onClose={() => {
                        toggleDateRangePickerOpened(false);
                      }}
                      onSubmit={(values, bag) => {
                        toggleDateRangePickerOpened(false);
                        modalConfirm({
                          title: `Czy na pewno ustawić nieaktywne wybrane restauracje (${selectedRestaurants.length}) na wybrany okres czasu?`,
                          content: ` `,
                          yesCallback: () => {
                            props.restaurants
                              .customCollectionAction({
                                data: {
                                  restaurantIds: selectedRestaurants.map(r => r.id),
                                  start: values.start,
                                  end: values.end,
                                },
                                action: 'disable-dates',
                              })
                              .then(() => {
                                props.restaurants.loadCollection();
                                bag.setSubmitting(false);
                              });
                          },
                          noCallback: () => {
                            bag.setSubmitting(false);
                          },
                        });
                      }}
                    />
                  )}
                  <Button
                    disabled={selectedRestaurants.length === 0}
                    label="Anuluj nieaktywności"
                    onClick={() => {
                      modalConfirm({
                        title: `Czy na pewno ustawić anulować nieaktywność wybranych restauracji? ${selectedRestaurants.length}`,
                        content: selectedRestaurants.map(r => r.displayedName).join(', '),
                        yesCallback: () => {
                          props.restaurants
                            .customCollectionAction({
                              data: {
                                restaurantIds: selectedRestaurants.map(r => r.id),
                              },
                              action: 'remove-disability',
                            })
                            .then(() => {
                              props.restaurants.loadCollection();
                            });
                        },
                      });
                    }}
                  />

                  <Select
                    value={selectedCuisineId}
                    placeholder="Wszystkie kuchnie"
                    onChange={value => {
                      setCuisine(value);
                    }}
                    style={{ width: '200px' }}
                    dropdownMenuStyle={{ width: '300px' }}
                    showSearch
                    filterOption={(input, option) => {
                      const children = `${option.props.children}`;
                      return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  >
                    <Select.Option key={'all'}>Wszystkie kuchnie</Select.Option>
                    {cuisineTypes.map(cuisineType => (
                      <Select.Option key={cuisineType.key} title={cuisineType.namePl}>
                        {cuisineType.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Group>
                <Spacer size="small" />
                <Group>
                  <SearchInput
                    value={query}
                    onChange={setQuery}
                    onEnter={currentQuery => {
                      setQuery(currentQuery);
                      setActiveQuery(currentQuery);
                    }}
                    onClearButton={() => {
                      setQuery('');
                      setActiveQuery('');
                    }}
                    placeholder="Wyszukaj restaurację"
                  />
                  <Button
                    label="Szukaj"
                    variant={ButtonVariant.Primary}
                    onClick={() => {
                      setActiveQuery(query);
                    }}
                  />
                </Group>
                <Spacer size="small" />
              </Group>
              <Group align="right" width="auto">
                <Button
                  label="Rozliczenia"
                  variant={ButtonVariant.Primary}
                  onClick={() => props.history.push('/restaurants/settlement')}
                />
                <Button
                  label="Dodaj restaurację"
                  variant={ButtonVariant.Primary}
                  onClick={toggleRestaurantFormDrawer}
                />
              </Group>
            </Group>

            <div style={{ position: 'relative' }}>
              {restaurants.length > 0 && (
                <RestaurantsTable
                  restaurants={restaurants}
                  onDetailClick={restaurant => {
                    setSelectedRestaurant(restaurant);
                    toggleRestaurantDrawer();
                  }}
                  onEditClick={restaurant => {
                    setSelectedRestaurant(restaurant);
                    setTargetTab('1');
                    toggleRestaurantFormDrawer();
                  }}
                  onOfferClick={restaurant => {
                    setSelectedRestaurant(restaurant);
                    setTargetTab('2');
                    toggleRestaurantFormDrawer();
                  }}
                  onStatusChangeClick={restaurant => {
                    props.restaurants.updateItem({
                      id: restaurant.id,
                      data: {
                        isActive: !restaurant.isActive,
                      },
                    });
                  }}
                  onSelectionChanged={selection => {
                    setSelectedRestaurants(selection.map(rowNode => rowNode.data));
                  }}
                />
              )}
              <DimmerLoading visible={props.loading.models.restaurants} />
            </div>
            {restaurants.length === 0 && <Empty description="Brak danych" />}
            <RestaurantDrawer
              visible={restaurantFormDrawerOpened}
              onClose={() => {
                props.restaurants.clearError();
                setSelectedRestaurant(null);
                toggleRestaurantFormDrawer(false);
              }}
              restaurant={selectedRestaurant}
              activeTab={selectedRestaurant ? targetTab : '1'}
            />
          </>
        )}
      </PageLayout>
    </>
  );
});

export { RestaurantsPage };
