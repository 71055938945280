/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/no-unresolved */
import { message } from 'antd';

import { Button, ButtonVariant, Divider, Group, Loading, Modal, modalConfirm, Price, Spacer } from 'lib/ui';
import { OrderDetails } from 'modules/Orders/components/OrderDetailsDialog/OrderDetails';
import { OrderHistory } from 'modules/Orders/components/OrderDetailsDialog/OrderHistory';
import { OrderPayu } from 'modules/Orders/components/OrderDetailsDialog/OrderPayu';
import { OrderModel } from 'modules/Orders/models/order.model';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { getOrderTypeName } from 'modules/Orders/utils/getOrderTypeName';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getErrorMessageFromErrorResponse } from 'services/utils/getErrorMessageFromErrorResponse';

export interface OrderDetailsDialogProps {
  visible: boolean;
  isEditable: boolean;
  orderId: number;
  onClose: (hasChanged?: boolean) => void;
  withDirectPickUp?: boolean;
}

export const OrderDetailsDialog: React.FC<OrderDetailsDialogProps> = (props): React.ReactElement => {
  const [order, setOrder] = useState<OrderModel | null>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState<'details' | 'history' | 'payu'>('details');

  const navigateToEdit = (page: number): void => {
    OrderFormService.resetState();
    props.onClose();
    history.push(`/orders/edit/${props.orderId}/${page}`);
  };
  const loadOrderDetails = useCallback((): void => {
    dispatch({
      type: `${props.isEditable ? 'currentOrders' : 'orders'}/loadItem`,
      payload: {
        id: props.orderId,
      },
    })
      .then((data): void => {
        setOrder(data);
      })
      .catch((err): void => {
        message.error('Błąd przy pobieraniu zamówienia.');
        console.log(err);
      });
  }, [props.orderId, props.isEditable, dispatch]);

  const removeDriver = () => {
    const hide = message.loading('Usuwam kierowcę...', 0);
    dispatch({
      type: 'currentOrders/updateItem',
      payload: {
        id: props.orderId,
        data: { driver: null },
      },
    })
      .then((data): void => {
        message.success('Kierowca został usunięty');
        setOrder(data);
      })
      .catch((errorResponse): void => {
        message.error(getErrorMessageFromErrorResponse(errorResponse));
      })
      .finally((): void => {
        hide();
        props.onClose(true);
      });
  };

  // fetch order details
  useEffect((): void => {
    loadOrderDetails();
  }, [loadOrderDetails]);

  if (!order) {
    return (
      <Modal
        visible={props.visible}
        width={'800px'}
        header={
          <div className="u-flex u-flex-space-between">
            <h2>Szczegóły zamówienia nr: {props.orderId}</h2>
          </div>
        }
        onClose={props.onClose}
        zIndex={1000}
      >
        <Loading />
      </Modal>
    );
  }

  return (
    <Modal
      visible={props.visible}
      width={'800px'}
      header={
        <div className="u-flex u-flex-space-between">
          <h2>Szczegóły zamówienia nr: {props.orderId}</h2>
          <Group width={'auto'} vertical verticalAlign="top">
            <Group>
              <span>{getOrderTypeName(order.orderType)}</span>
              <Spacer horizontal size="small" />
              <strong>
                <Group>
                  <Price old amount={order.originalGross} />
                  <Price amount={order.gross} />
                </Group>
              </strong>
            </Group>
            <span className="pointer" onClick={(): void => navigateToEdit(3)}>
              {order.paymentData.namePl}
            </span>
          </Group>
        </div>
      }
      onClose={props.onClose}
      zIndex={1000}
    >
      {' '}
      {currentTab === 'details' && (
        <OrderDetails
          order={order}
          withDirectPickUp={props.withDirectPickUp}
          isEditable={props.isEditable}
          onChange={loadOrderDetails}
          onNavigate={props.onClose}
        />
      )}
      {currentTab === 'history' && <OrderHistory order={order} />}
      {currentTab === 'payu' && <OrderPayu order={order} />}
      <Spacer size="large" />
      <Spacer size="large" />
      <Divider />
      <Group align="space-between">
        <Group>
          <Button variant={ButtonVariant.Primary} label="Zamknij okno" onClick={(): void => props.onClose()} />
          {currentTab === 'details' && order.orderHistory && order.orderHistory.length > 0 && (
            <>
              <Button
                label="Historia zamówienia"
                onClick={(): void => setCurrentTab('history')}
                style={{ marginLeft: 5 }}
              />
              {order.driver !== null && (
                <Button label="Usuń kierowcę" onClick={removeDriver} style={{ marginLeft: 5 }} />
              )}
            </>
          )}
          {currentTab === 'details' && order.paymentData.paymentStatus && (
            <Button label="PayU Link" onClick={(): void => setCurrentTab('payu')} />
          )}
          {currentTab !== 'details' && <Button label="powrót" onClick={(): void => setCurrentTab('details')} />}
        </Group>

        {order.status !== 40 && (
          <Button
            variant={ButtonVariant.Plain}
            label="Usuń zamówienie"
            onClick={(): void => {
              modalConfirm({
                title: `Na pewno usunąć zamówienie?`,
                content: (
                  <>
                    <p>Pamiętaj żeby po usunięciu zamówienia</p>
                    <ol>
                      <li>Sprawdzić saldo klienta</li>
                      <li>Sprawdzić marszrutę kierowcy</li>
                      <li>Poinformować kierowcę o zmianach.</li>
                    </ol>
                  </>
                ),
                okText: 'Tak, usuwam',
                cancelText: 'Nie, pomyłka',
                yesCallback: (): void => {
                  const hide = message.loading('Usuwam zamówienie...', 0);
                  dispatch({
                    type: 'currentOrders/deleteItem',
                    payload: {
                      id: order.id,
                    },
                  })
                    .then((): void => {
                      message.success('Zamówienie zostało usunięte');
                      props.onClose(true);
                    })
                    .catch((errorResponse): void => {
                      message.error(getErrorMessageFromErrorResponse(errorResponse));
                    })
                    .finally((): void => {
                      hide();
                    });
                },
              });
            }}
          />
        )}
      </Group>
    </Modal>
  );
};
