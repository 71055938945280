import { Tabs } from 'antd';
import { FormFieldLabel, Textarea } from 'lib/forms';
import { Button, ButtonSize, ButtonVariant, Divider, Group, Modal, ModalProps, Price, Spacer, Counter } from 'lib/ui';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { BasketMealExtras, OrderFormBasketMeal } from 'modules/Orders/OrderFormState';
import { RestaurantExtras } from 'modules/Restaurant';
import React, { FunctionComponent, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';

export interface OrderFormMealWithExtrasPickerProps extends ModalProps {
  // restaurantId?: number;
  meal: OrderFormBasketMeal;
  counter?: number; // meals counter in the baskeSet
  extrasIndex?: number | null;
  restaurantMealExtras: RestaurantExtras[];
  extras: Array<{
    extras: RestaurantExtras;
    counter: number;
  }>;
}

const enhance = connect(
  ({ orders, restaurants, loading }) => ({
    ordersStore: orders,
    restaurantsStore: restaurants,
    loading,
  }),
  ({ restaurants, orders }) => ({
    restaurants,
    orders,
  }),
);

export const OrderFormMealWithExtrasPicker: FunctionComponent<OrderFormMealWithExtrasPickerProps> = enhance(props => {
  const [note, setNote] = useState('');
  const [mealCounter, setMealCounter] = useState(props.counter || 1);

  const [availableExtras, setAvailableExtras] = useState<BasketMealExtras[]>([]);
  const [extras, setExtras] = useState<Array<BasketMealExtras[]>>([]);

  const { requiredExtras } = props.meal;

  //  update list of available extras (based on those assigned to meal)
  //  set note for selected meal
  useEffect(() => {
    setAvailableExtras(
      props.restaurantMealExtras
        .filter(extras => props.meal.extras.find(mealExtra => mealExtra.extras === extras.id))
        .map(restaurantExtras => {
          const mealExtras = props.meal.extras.find(mealExtra => mealExtra.extras === restaurantExtras.id);
          return {
            id: mealExtras.id,
            name: restaurantExtras.namePl,
            counter: 0,
            priceChange: mealExtras.priceChange,
          };
        }),
    );

    const basketMeal = OrderFormService.getBasketMealFromCurrentSet(props.meal);
    if (basketMeal) {
      setMealCounter(basketMeal.counter);
      setExtras(basketMeal.extras);
      setNote(basketMeal.note);
    }
  }, [props.meal, props.restaurantMealExtras]);

  //  update array of extras for selected meal
  useEffect(() => {
    if (!availableExtras.length) {
      return;
    }

    if (mealCounter > extras.length) {
      setExtras([...extras, availableExtras]);
    } else if (mealCounter < extras.length) {
      setExtras(extras.slice(0, mealCounter - 1));
    }
  }, [mealCounter, availableExtras, extras]);

  return (
    <Modal
    width="480px"
      visible
      {...props}
      footer={
        <Group align="space-between">
          <Group width="auto" noShrink>
            <span>{mealCounter} x </span>
            <Price amount={props.meal.price} />
            <Button
              size={ButtonSize.Small}
              iconName={'minus'}
              onClick={() => {
                setMealCounter(mealCounter - 1);
              }}
            />
            <Button
              size={ButtonSize.Small}
              iconName={'plus'}
              onClick={() => {
                setMealCounter(mealCounter + 1);
              }}
            />
          </Group>
          <Spacer horizontal/>
          <Button
            variant={ButtonVariant.Primary}
            label="Dodaj do koszyka"
            onClick={() => {
              OrderFormService.addOrUpdateBasketMealInCurrentSet(props.meal, mealCounter, extras, note);
              props.onClose();
            }}
          />
        </Group>
      }
    >
      <Group vertical align="space-between">
        <Group>
          <h2>{props.meal.namePl}</h2>
        </Group>
        <Group>
          <p>{props.meal.descriptionPl}</p>
        </Group>
      </Group>

      {mealCounter > 0 && props.meal.requiredExtras > 0 && (
        <>
          <h2>
            Dodatki {requiredExtras} / {availableExtras.length}
          </h2>
          <Spacer />

          <Tabs hideAdd type="editable-card" defaultActiveKey={`mealExtra${props.extrasIndex || 0}`}>
            {extras.map((basketMealExtras, index) => {
              const selectedExtras = basketMealExtras.reduce((acc, extrasItem) => {
                return acc + extrasItem.counter;
              }, 0);

              return (
                <Tabs.TabPane key={`mealExtra${index}`} closable={false} tab={index + 1}>
                  <h3>Wybierz dodatki</h3>
                  {basketMealExtras.map((extrasItem, extrasItemIndex) => (
                    <React.Fragment key={`${index}${extrasItem.id}`}>
                      <Group align="space-between">
                        <div>{extrasItem.name}</div>
                        <Group align="right" width="auto" noShrink>
                          <Price amount={extrasItem.priceChange} />
                          <Spacer size="large" />
                          <Counter
                            minValue={0}
                            maxValue={props.meal.requiredExtras}
                            onChange={counter => {
                              const newExtras = [...extras];
                              const newBasketMealExtras = [...basketMealExtras];
                              newBasketMealExtras[extrasItemIndex] = {
                                ...extrasItem,
                                counter,
                              };
                              newExtras[index] = newBasketMealExtras;
                              setExtras(newExtras);
                            }}
                            counter={extrasItem.counter}
                            canDecrese
                            canIncreace={selectedExtras < requiredExtras}
                          />
                        </Group>
                      </Group>
                      <Spacer />
                    </React.Fragment>
                  ))}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
          <Spacer />
        </>
      )}

      <FormFieldLabel>Uwagi</FormFieldLabel>
      <Textarea
        name="text"
        onChange={ev => {
          setNote(ev.currentTarget.value);
        }}
        value={note}
        width="100%"
      />
      <Spacer />
    </Modal>
  );
});
