import { message, Select } from 'antd';
import { Formik } from 'formik';
import {
  ButtonSubmit,
  FormError,
  FormField,
  FormFieldContainer,
  FormFieldInputNumber,
  FormFieldLabel,
  FormSwitchField,
  FormTextareaField,
} from 'lib/forms';

import { Divider, Drawer, DrawerProps, Group, Spacer } from 'lib/ui';
import { weekdays } from 'lib/ui/utils';
import React, { FunctionComponent } from 'react';
import connect from 'react-redux/es/connect/connect';

import { EmployeeRoles } from '../';

const enhance = connect(
  ({ employees }) => ({
    employeesStore: employees,
  }),
  ({ employees }) => ({
    employees,
  }),
);
export interface EmployeeFormDrawerProps extends DrawerProps {
  item?: any;
}

const EmployeeFormDrawer: FunctionComponent<EmployeeFormDrawerProps> = enhance(({ item, ...props }) => {
  const itemName = item && (item.displayedName || item.name);
  const closeDrawer = () => {
    props.employees.clearError();
    props.onClose();
  };

  const getInitialValues = () => {
    const v = {
      firstName: item ? item.user.firstName : '',
      lastName: item ? item.user.lastName : '',
      login: item ? item.user.email : '',
      phone: item ? item.phone : '',
      password: '',
      role: item ? EmployeeRoles.find(role => role.key === item.role) : EmployeeRoles[2],
      notes: item ? item.notes : '',
      isActive: item ? item.isActive : false,
    };
    weekdays.forEach(day => {
      const rateIndex = `hourRate${day.shortnameEn}`;
      v[rateIndex] = item && item[rateIndex] ? item[rateIndex] / 100 : 12;
    });
    return v;
  };

  return (
    <Drawer
      displayCloseButton
      closeOnEsc
      onClose={closeDrawer}
      visible={props.visible}
      title={`${itemName || 'Tworzenie nowego pracownika'}`}
      destroyOnClose
    >
      <Formik
        initialValues={getInitialValues()}
        onSubmit={(values: any, bag) => {
          const payload = {
            ...values,
            hourRateFri: values.hourRateFri * 100,
            hourRateMon: values.hourRateMon * 100,
            hourRateSat: values.hourRateSat * 100,
            hourRateSun: values.hourRateSun * 100,
            hourRateThu: values.hourRateThu * 100,
            hourRateTue: values.hourRateTue * 100,
            hourRateWed: values.hourRateWed * 100,
            role: values.role.key,
            username: values.login,
          };

          if (item && item.id) {
            props.employees
              .updateItem({
                data: payload,
                id: item.id,
              })
              .then(() => {
                bag.setSubmitting(false);
                message.success('Zmiany zostały zapisane');
                // props.employees.loadItem({ id: item.id });
              })
              .catch(() => {
                bag.setSubmitting(false);
              });
          } else {
            props.employees
              .createItem({ data: payload })
              .then(() => {
                bag.setSubmitting(false);
                message.success('Pracownik został dodany');
                closeDrawer();
                return props.employees.loadCollection();
              })
              .catch(() => {
                bag.setSubmitting(false);
              });
          }
        }}
        enableReinitialize
      >
        {formikProps => {
          const FormSubmitButton = (
            <ButtonSubmit
              disabled={!formikProps.dirty || formikProps.isSubmitting}
              loading={formikProps.isSubmitting}
              label="Zapisz"
              onClick={formikProps.handleSubmit}
              iconName="save"
            />
          );
          return (
            <>
              {props.employeesStore.errors && <FormError errors={props.employeesStore.errors} />}
              <form onSubmit={formikProps.handleSubmit}>
                <Group vertical>
                  <Group align="space-between">
                    <FormField name="firstName" label="Imię" {...formikProps} />
                    <div>{FormSubmitButton}</div>
                  </Group>

                  <Group align="space-between">
                    <FormField name="lastName" label="Nazwisko" {...formikProps} />
                  </Group>

                  <Group align="space-between">
                    <FormField name="phone" label="Telefon" {...formikProps} />
                  </Group>

                  <FormField name="login" label="Login" {...formikProps} />
                  <FormField name="password" label="Hasło" {...formikProps} type="password" />

                  <FormFieldContainer>
                    <div style={{ width: '100%' }}>
                      <FormFieldLabel>Rola</FormFieldLabel>
                      <Select
                        labelInValue
                        value={formikProps.values.role}
                        onChange={value => {
                          formikProps.setFieldValue('role', value);
                        }}
                      >
                        {EmployeeRoles.map(role => (
                          <Select.Option key={role.key}>{role.label}</Select.Option>
                        ))}
                      </Select>
                    </div>
                  </FormFieldContainer>

                  <FormTextareaField name="notes" label="Uwagi" {...formikProps} />

                  <Divider text="Stawki" />

                  <Group align="space-between">
                    {weekdays.map(day => (
                      <Group vertical width="90px" key={day.shortnameEn}>
                        <FormFieldLabel>{day.shortnamePl}</FormFieldLabel>
                        <FormFieldInputNumber name={`hourRate${day.shortnameEn}`} {...formikProps} />
                      </Group>
                    ))}
                  </Group>

                  <FormSwitchField name="isActive" label="Aktywny" {...formikProps} />
                </Group>

                <Spacer />
                {FormSubmitButton}
              </form>
            </>
          );
        }}
      </Formik>
    </Drawer>
  );
});

export { EmployeeFormDrawer };
