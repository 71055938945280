import React, { FunctionComponent } from 'react';

import { Icon, IconSize } from '../..';

import { EmptyStateContainerStyled } from './EmptyStateContainerStyled';
import { EmptyStateContentStyled } from './EmptyStateContentStyled';
import { EmptyStateIconStyled } from './EmptyStateIconStyled';

export interface EmptyStateProps {
  message?: string;
  icon?: string;
  withIcon?: boolean;
  simple?: boolean;
  inline?: boolean;
  children?: any;
}

const EmptyStateDefaultProps = {
  message: 'Brak danych',
  icon: 'empty-set',
  withIcon: true,
  simple: false,
  inline: false,
  children: null,
};

const EmptyState: FunctionComponent<EmptyStateProps> = (props: EmptyStateProps) => {
  return (
    <EmptyStateContainerStyled simple={props.simple} inline={props.inline}>
      {props.withIcon && props.icon && (
        <EmptyStateIconStyled>
          <Icon name={props.icon} size={IconSize.LargeXX} />
        </EmptyStateIconStyled>
      )}
      <EmptyStateContentStyled simple={props.simple} inline={props.inline}>
        {props.message}
      </EmptyStateContentStyled>

      {props.children && (
        <EmptyStateContentStyled simple={props.simple} inline={props.inline}>
          {props.children}
        </EmptyStateContentStyled>
      )}
    </EmptyStateContainerStyled>
  );
};

EmptyState.defaultProps = EmptyStateDefaultProps;

export { EmptyState };
