import { message } from 'antd';
import { OrderModel } from 'modules/Orders/models/order.model';

import { OrderFormService } from 'modules/Orders/OrderFormService';
import { mapOrderToFormOrderState } from 'modules/Orders/utils/mapOrderToFormOrder';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useCurrentOrderForEdit = (orderId: string, omitCache: boolean = false) => {
  const orderState = OrderFormService.getStateValue();
  const [order, setOrder] = useState<OrderModel | null>();
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  // fetch order details
  useEffect(() => {
    if (omitCache || !orderState || String(orderId) !== String(orderState.orderId)) {
      setLoading(true);
      dispatch({
        type: 'currentOrders/loadItem',
        payload: {
          id: orderId,
        },
      })
        .then(data => {
          const order = data;
          dispatch({
            type: 'orders/customCollectionAction',
            payload: {
              actionMethod: 'post',
              action: 'searchRestaurants',
              data: {
                deliveryAddress: {
                  ...order.deliveryAddress,
                },
                restaurantId: data.restaurants[0].id,
              },
            },
          })
            .then(data => {
              const orderState = mapOrderToFormOrderState(order);
              OrderFormService.setState(orderState);
              OrderFormService.saveRestaurantWithDeliveryCost(data[0]);
              setOrder(order);
            })
            .catch(err => {
              message.error('Błąd przy pobieraniu kosztu dostawy.');
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(err => {
          message.error('Błąd przy pobieraniu zamówienia.');
          setLoading(false);
        });
    } else {
      setOrder(<OrderModel>{});
    }
  }, [orderId, setLoading]);

  return { order, isLoading };
};
