import { styled } from '../styled-components';

export const UiContainerForScrollable = styled.div.attrs({
  className: 'UiContainerForScrollable',
})<{ height?: string }>`
  overflow: hidden;
  height: ${p => (p.height ? p.height : '100%')};
  display: flex;
  flex-direction: column;
`;

export const UiScrollableListMeta = styled.div.attrs({
  className: 'UiScrollableListMeta',
})`
  flex-shrink: 0;
`;

export const UiScrollableList = styled.div.attrs({
  className: 'UiScrollableList',
})<{ withoutRightPadding?: boolean }>`
  overflow: auto;
  padding-right: ${p => (p.withoutRightPadding ? 0 : p.theme.space)};
`;
