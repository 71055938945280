import { DatePicker, Empty } from 'antd';
import { format, subDays } from 'date-fns';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';

import { SearchInput } from 'lib/forms';
import { AppDateTimePickerDefaults } from 'components/AppUtils';
import { Button, ButtonVariant, DimmerLoading, Group, Loading, Spacer } from 'lib/ui';
import { ArchiveOrdersTable } from 'modules/Orders/components/ArchiveOrdersTable/ArchiveOrdersTable';
import { OrderDetailsDialog } from 'modules/Orders/components/OrderDetailsDialog/OrderDetailsDialog';
import { PageLayout } from 'components';

export interface OrdersArchivePageProps {}

export const OrdersArchivePage: React.FC<OrdersArchivePageProps> = props => {
  const dispatch = useDispatch();
  const [orderDetailsOpened, toggleOrderDetails] = useToggle(false);
  const [order, setOrder] = useState<any>({});
  // search query
  const innerQuery = useRef('');
  const [query, setQuery] = useState('');
  const [startDate, setStartDate] = useState(subDays(new Date(), 21));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    dispatch({
      type: 'currentOrders/reset',
    });
  }, []);

  const isLoading = useSelector(state => state.loading.effects.orders.loadCollection);

  return (
    <>
      <PageLayout title="Archiwum zamówień">
        <Group align="space-between">
          <Group width="auto">
            <SearchInput
              value={query}
              onChange={value => (innerQuery.current = value)}
              placeholder="Wyszukaj zamówienie"
              onEnter={() => setQuery(innerQuery.current)}
              onClearButton={() => {
                innerQuery.current = '';
                setQuery('');
              }}
            />
            <Button
              label="Szukaj"
              variant={ButtonVariant.Primary}
              disabled={isLoading}
              onClick={() => setQuery(innerQuery.current)}
            />
          </Group>
          <Group align="right" width="auto">
            <span>Od: </span>
            <DatePicker
              format={AppDateTimePickerDefaults.DateFormat}
              value={moment(startDate)}
              onChange={value => setStartDate(value ? value.toDate() : new Date())}
              suffixIcon={null}
            />
            <span>Do: </span>
            <DatePicker
              format={AppDateTimePickerDefaults.DateFormat}
              value={moment(endDate)}
              onChange={value => setEndDate(value ? value.toDate() : new Date())}
              suffixIcon={null}
            />
          </Group>
        </Group>

        <Spacer />

        <ArchiveOrdersTable
          query={query}
          onOrderDetails={order => {
            setOrder(order);
            toggleOrderDetails();
          }}
          endDate={endDate}
          startDate={startDate}
        />
      </PageLayout>

      {orderDetailsOpened && (
        <OrderDetailsDialog
          isEditable={false}
          orderId={order.id}
          visible={orderDetailsOpened}
          onClose={() => {
            toggleOrderDetails();
          }}
        />
      )}
    </>
  );
};
