/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AddressComponent } from 'components';
import { Button, ButtonSize, Group, Spacer, styled, Table } from 'lib/ui';
import React, { FunctionComponent } from 'react';
import connect from 'react-redux/es/connect/connect';

const enhance = connect(
  ({ clientAddress, loading }) => ({
    clientAddressStore: clientAddress,
    loading,
  }),
  ({ clientAddress }) => ({
    clientAddress,
  }),
);

export interface ClientsTableProps {
  items: any[];
  onAddressPick?: Function;
  onRefresh: Function;
  onAddressNew?: Function;
  onDirectPickUp?: Function;
}

const OrderClientsTableContainer = styled.div`
  margin: ${p => p.theme.space} 0;
`;

const OrderClientsTable: FunctionComponent<ClientsTableProps> = enhance(
  props => {
    const columns = [
      {
        title: 'Imię',
        dataIndex: 'user.firstName',
      },
      {
        title: 'Nazwisko',
        dataIndex: 'user.lastName',
      },
      {
        title: 'Telefon/y',
        render: (text, record, index) => {
          return (
            <div>
              {record.phones.map(phone => (
                <p key={phone.id}>
                  {phone.number}
                  {phone.isMain && <strong>główny</strong>}
                </p>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Adresy',
        render: (text, record, index) => {
          const bool = record.addresses.length > 1;
          return (
            <div>
              <div style={{ marginBottom: bool ? '1rem' : 0 }}>
                <Group align="space-between" verticalAlign="top" key={record.id}>
                  {!props.loading.effects.clientAddress.loadCollection && (
                    <>
                      {record.addresses.length > 0 && (
                        <>
                          <AddressComponent
                            onSave={props.onRefresh}
                            data={record.addresses}
                            onChoose={addressObject => {
                              if (props.onAddressPick) {
                                props.onAddressPick(record, addressObject);
                              }
                            }}
                            clientId={record.id}
                            preview={false}
                          />
                        </>
                      )}
                    </>
                  )}
                </Group>
              </div>
              <Spacer />
              <Group width={'15%'} align={'right'}>
                <Group width={'10%'} align="space-between" verticalAlign="top">
                  <Button
                    label="Nowy adres"
                    size={ButtonSize.Small}
                    onClick={() => {
                      if (props.onAddressNew) {
                        props.onAddressNew(record);
                      }
                    }}
                  />
                </Group>
                <Group width={'15%'} align="space-between" verticalAlign="top">
                  <Button
                    label="Odbiór osobisty"
                    size={ButtonSize.Small}
                    onClick={() => {
                      if (props.onDirectPickUp) {
                        props.onDirectPickUp(record);
                      }
                    }}
                  />
                </Group>
              </Group>
            </div>
          );
        },
      },
    ];

    return (
      <OrderClientsTableContainer>
        <Table columns={columns} rows={props.items} />
      </OrderClientsTableContainer>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.items.map(i => i.id).join('') === nextProps.items.map(i => i.id).join('');
  },
);

export { OrderClientsTable };
