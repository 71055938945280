import { InputNumber, message, Switch, Typography } from 'antd';
import { RestaurantBasicInputModal } from 'components';
import { FormFieldLabel } from 'lib/forms';
import { Events } from 'lib/services';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  DimmerLoading,
  Divider,
  EmptyState,
  Group,
  Loading,
  modalConfirm,
  Price,
  Spacer,
} from 'lib/ui';
import React, { FunctionComponent, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import useToggle from 'react-use/lib/useToggle';
import { compose } from 'recompose';

export interface RestaurantFormExtrasProps {
  restaurant?: any;
  onModalToggle: Function;
}

const RestaurantFormExtrasDefaultProps = {};

const enhance = compose(
  connect(
    ({ restaurantsExtras, restaurantsExtras: { errors: errorItem }, loading }) => ({
      restaurantsExtrasStore: restaurantsExtras,
      errorItem,
      loading,
    }),
    ({ restaurantsExtras, restaurants }) => ({
      restaurantsExtras,
      restaurants,
    }),
  ),
);

const RestaurantFormExtras: FunctionComponent<RestaurantFormExtrasProps> = enhance(props => {
  const { restaurant } = props;
  //  edited / selected item
  const [editedItem, setEditedItem] = useState<any>(null);

  useEffect(() => {
    if (restaurant) {
      props.restaurantsExtras.loadCollection({
        restaurantId: restaurant.id,
      });
    }
    return () => {
      props.restaurantsExtras.reset();
    };
  }, [props.restaurant]);

  const [itemModalOpened, toggleItemModal] = useToggle(false);
  const [itemSaving, toggleItemSaving] = useToggle(false);
  useEffect(() => {
    Events.on('Model.restaurantsExtras.createItem.success', () => {
      message.success('Dodatek został dodany');
      toggleItemModal(false);
      toggleItemSaving(false);
    });
    Events.on('Model.restaurantsExtras.updateItem.success', () => {
      message.success('Dodatek został zaktualizowany');
      toggleItemModal(false);
      toggleItemSaving(false);
      setEditedItem(null);
    });
    Events.on('Model.restaurantsExtras.deleteItem.success', () => {
      message.success('Dodatek został usunięty');
    });
    return () => {
      Events.off('Model.restaurantsExtras.createItem.success', true);
      Events.off('Model.restaurantsExtras.updateItem.success', true);
      Events.off('Model.restaurantsExtras.deleteItem.success', true);
    };
  }, [toggleItemModal, toggleItemSaving]);
  useEffect(() => {
    if (props.errorItem) {
      toggleItemSaving(false);
    }
  }, [props.errorItem, toggleItemSaving]);
  useEffect(() => {
    if (itemModalOpened) {
      props.onModalToggle(true);
    } else {
      props.onModalToggle(false);
      props.restaurantsExtras.clearError();
    }
  }, [itemModalOpened]);

  //  package price
  const [enabledPackagedPrice, toggleEnablePackagePrice] = useToggle(restaurant.packagePrice !== null);
  const [packagePrice, setPackagePrice] = useState(
    restaurant.packagePrice !== null ? restaurant.packagePrice / 100 : 0,
  );
  const [packagePriceLoading, setPackagePriceLoading] = useState(false);

  const items = props.restaurantsExtrasStore.data;
  // console.log('props', props);

  return (
    <>
      <Group>
        <Group vertical>
          <FormFieldLabel>
            <Switch
              checked={enabledPackagedPrice}
              size="small"
              onChange={checked => toggleEnablePackagePrice(checked)}
            />
            <span className="ml-2">Opłata za opakowanie</span>
          </FormFieldLabel>
          {enabledPackagedPrice && (
            <FormFieldLabel>
              <InputNumber
                name="packagePrice"
                disabled={packagePriceLoading}
                onChange={value => {
                  // @ts-ignore
                  setPackagePrice(value);
                }}
                value={packagePrice}
                precision={2}
              />
            </FormFieldLabel>
          )}
        </Group>
        <Button
          variant={ButtonVariant.Primary}
          loading={packagePriceLoading}
          label={'Zapisz'}
          onClick={() => {
            setPackagePriceLoading(true);
            props.restaurants
              .updateItem({
                id: props.restaurant.id,
                data: {
                  packagePrice: enabledPackagedPrice ? packagePrice * 100 : null,
                },
              })
              .then(() => {
                message.success('Opłata została zapisana');
              })
              .catch(() => {
                message.error('Wystąpił błąd');
              })
              .finally(() => {
                setPackagePriceLoading(false);
              });
          }}
        />
      </Group>

      <Divider />

      <Group>
        <Button
          label="Dodaj dodatek"
          onClick={() => {
            toggleItemModal(true);
          }}
        />
      </Group>

      <Spacer />

      {!props.restaurantsExtrasStore.data.length && !props.loading.effects.restaurantsExtras.loadCollection && (
        <EmptyState message={'Brak dodatków'} simple />
      )}

      {!props.restaurantsExtrasStore.data.length && props.loading.effects.restaurantsExtras.loadCollection && (
        <Loading text="Ładowanie dodatków" />
      )}

      <div style={{ position: 'relative' }}>
        <DimmerLoading visible={itemSaving} />
        {items.map(item => (
          <div data-id={item.id} key={item.id} style={{ padding: '6px 0' }}>
            <Group align="space-between">
              <span style={{ width: '100%' }}>
                {item.namePl} / {item.nameEn}
              </span>
              <Group align="right">
                <Typography.Text strong>
                  <Price amount={item.defaultPriceChange} />
                </Typography.Text>

                <Switch
                  checked={item.isActive}
                  size="small"
                  onClick={() => {
                    const name = item.namePl;
                    const hide = message.loading(`Zmieniam status dodatku: ${name}`, 0);
                    props.restaurantsExtras
                      .updateItem({
                        restaurantId: props.restaurant.id,
                        id: item.id,
                        data: {
                          isActive: !item.isActive,
                        },
                      })
                      .then(() => {
                        hide();
                      });
                  }}
                />
                <Button
                  size={ButtonSize.Small}
                  iconName={'edit'}
                  onClick={() => {
                    setEditedItem(item);
                    toggleItemModal(true);
                  }}
                />
                <Button
                  size={ButtonSize.Small}
                  iconName={'trash'}
                  onClick={() => {
                    modalConfirm({
                      yesCallback: () => {
                        props.restaurantsExtras.deleteItem({
                          id: item.id,
                          restaurantId: restaurant.id,
                        });
                      },
                    });
                  }}
                />
              </Group>
            </Group>
          </div>
        ))}
      </div>

      <RestaurantBasicInputModal
        visible={itemModalOpened}
        item={
          editedItem
            ? {
                ...editedItem,
                number: editedItem.defaultPriceChange / 100,
              }
            : {}
        }
        onClose={() => {
          toggleItemModal(false);
        }}
        withoutDescription
        withNumber
        numberLabel="Domyślny wpływ na cenę"
        onSubmit={(values, bag) => {
          if (editedItem && editedItem.id) {
            props.restaurantsExtras.updateItem({
              restaurantId: props.restaurant.id,
              id: editedItem.id,
              data: {
                ...values,
                defaultPriceChange: values.number * 100,
              },
            });
          } else {
            props.restaurantsExtras.createItem({
              restaurantId: props.restaurant.id,
              data: {
                ...values,
                defaultPriceChange: values.number * 100,
              },
            });
          }
        }}
      />
    </>
  );
});

RestaurantFormExtras.defaultProps = RestaurantFormExtrasDefaultProps;

export { RestaurantFormExtras };
