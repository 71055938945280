import React, { FunctionComponent, useState } from 'react';

import { Drawer, DrawerProps } from 'lib/ui';
import { ExtraExtra } from 'modules/ExtraExtras/ExtraExtrasModel';
import { ExtraExtraForm } from 'modules/ExtraExtras/components/ExtraExtraForm';

export interface ExtraExtraDrawerProps extends DrawerProps {
  extraExtra?: ExtraExtra;
}

const ExtraExtraDrawerDefaultProps = {
  extraExtra: {
    id: 0,
    price: 0,
    namePl: '',
    nameEn: '',
    isActive: false,
    isDeleted: false,
  },
};

const ExtraExtraDrawer: FunctionComponent<ExtraExtraDrawerProps> = ({ onClose, visible, extraExtra }) => {
  // from store
  const [closable, setClosable] = useState(true);
  const extraExtraName = extraExtra && (extraExtra.namePl || extraExtra.nameEn);

  return (
    <Drawer
      displayCloseButton
      closeOnEsc={closable}
      onClose={onClose}
      visible={visible}
      title={`${extraExtraName || 'Tworzenie nowego ekstra dodatku'}`}
      destroyOnClose
    >
      <ExtraExtraForm
        extraExtra={extraExtra}
        onClose={onClose}
        onModalToggle={visible => {
          setClosable(!visible);
        }}
      />
    </Drawer>
  );
};

ExtraExtraDrawer.defaultProps = ExtraExtraDrawerDefaultProps;

export { ExtraExtraDrawer };
