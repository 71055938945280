import React from 'react';
import { Modal } from 'antd';

export interface modalConfirmOptions {
  //  inputs
  title?: React.ReactNode;
  content?: React.ReactNode;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  //  outputs
  yesCallback: () => void;
  noCallback?: () => void;
}

const modalConfirm = ({ title, content, yesCallback, noCallback, okText, cancelText }: modalConfirmOptions) => {
  Modal.confirm({
    okText: okText || 'Tak, na pewno',
    cancelText: cancelText || 'Anuluj',
    title: title || 'Czy na pewno usunąć?',
    content: content || 'Ta operacja jest nieodwracalna. Nie będzie można przywrócić usuniętych' + ' danych',
    onOk() {
      if (yesCallback) {
        yesCallback();
      }
    },
    onCancel() {
      if (noCallback) {
        noCallback();
      }
    },
  });
};

export { modalConfirm };
