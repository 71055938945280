import { message } from 'antd';
import { AppDateTimePickerDefaults } from 'components/AppUtils';
import { format, isFuture } from 'date-fns';
import { Button, ButtonSize, ButtonVariant, formattedTime, Group, Price, Spacer } from 'lib/ui';
import { EmployeeReckoningDayModel } from 'modules/Employees/EmployeesReckoningModel';

import { DayContent, DayHeader } from 'pages/DriversReckoningPage/DriverReckoningDay';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';

export interface EmployeeReckoningDayProps {
  employeeId: number;
  data: EmployeeReckoningDayModel;
  onUpdate: () => void;
}

export const EmployeeReckoningDay: React.FC<EmployeeReckoningDayProps> = props => {
  const dispatch = useDispatch();
  const [collapsed, toggleCollapsed] = useToggle(!!props.data.isPaid);
  const [isLoading, setIsLoading] = useToggle(false);
  const [isPaid, setIsPaid] = useState(props.data.isPaid);
  const paymentDetails = props.data.paymentDetails.forDay;

  const isFutureDate = isFuture(props.data.dayStart);
  if (isFutureDate) {
    return null;
  }

  let headerColor: string = '';
  if (!isPaid && props.data.paymentDetails.forDay.total > 0) {
    headerColor = 'cf1e2f30';
  }
  if (!isPaid) {
    headerColor = 'eee';
  }
  if (isPaid) {
    headerColor = 'b0d5f3';
  }

  return (
    <div>
      <DayHeader
        color={headerColor}
        clickable={!isLoading && paymentDetails.total > 0}
        onClick={() => {
          toggleCollapsed();
        }}
      >
        <Group align="space-between">
          <Group>
            <strong>{format(props.data.dayStart, AppDateTimePickerDefaults.DateFormat)}</strong>
            {isPaid === false && <span>(nierozliczony)</span>}
            {isPaid === null && <span>(nie pracował)</span>}
            {isPaid && (
              <>
                <span>(rozliczony)</span>
                <Price amount={paymentDetails.total} />
              </>
            )}
            {isPaid === false && paymentDetails.countRbh > 0 && (
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.Primary}
                loading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  dispatch({
                    type: 'employeesReckoning/customCollectionAction',
                    payload: {
                      action: 'pay-for-day',
                      data: {
                        employeeId: props.employeeId,
                        date: format(props.data.dayStart, 'YYYY-MM-DD'),
                      },
                    },
                  })
                    .then(data => {
                      const messageText = data && data.detail;
                      message.success(messageText || 'Dzień został oznaczony jako rozliczony');
                      setIsPaid(true);
                    })
                    .catch(err => {
                      const messageText = err.data && err.data.detail;
                      message.error(messageText || 'Wystąpił nieoczekiwany błąd');
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
              >
                oznacz jako rozliczony
              </Button>
            )}
          </Group>
        </Group>
      </DayHeader>
      {paymentDetails.countRbh > 0 && (
        <DayContent collapsed={collapsed}>
          <Spacer size="small" />
          {paymentDetails.workStart && paymentDetails.workEnd && (
            <span>
              Praca: {formattedTime(paymentDetails.workStart)} - {formattedTime(paymentDetails.workEnd)}
            </span>
          )}
          <Spacer size="small" />
          <div>
            <strong>
              Razem: {paymentDetails.countRbh}h * <Price amount={paymentDetails.amountPerHour} /> ={' '}
              <Price amount={paymentDetails.total} />
            </strong>
          </div>
        </DayContent>
      )}
      <Spacer />
    </div>
  );
};
