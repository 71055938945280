import * as Yup from 'yup';

export const stringRequired_2_100 = () => {
  return Yup.string()
    .min(2, 'Pole musi zawierać minimum dwa znaki')
    .max(100, 'Pole nie może zawierać więcej niż 100 znaków')
    .required('Pole jest wymagane');
};

export const stringRequired = () => {
  return Yup.string().required('Pole jest wymagane');
};
