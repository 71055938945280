import { createModel } from "@rematch/core";

import { Events } from "lib/services";
import { HttpApi } from "services/HttpApi";
import { store } from "store";

export interface IUser {
  dateJoined: string;
  email: string;
  firstName: string;
  groups: string[];
  id: number;
  isActive: boolean;
  lastLogin: string;
  username: string;
  lastName: string;
}

export interface AuthModelState {
  isLoggedIn: boolean;
  user: IUser;
  token: string;
  smsToken: string;
  nextUrl: string;
}

const defaultEmptyState = {
  isLoggedIn: false,
  user: null,
  token: null,
  smsToken: null,
  nextUrl: '',
};

export const AuthModel = createModel<AuthModelState>({
  state: {
    initialized: false,
    ...defaultEmptyState,
  },
  selectors: (slice, createSelector, hasProps) => ({
    hasRole: hasProps((models, roles) => {
      return createSelector(
        slice(authState => authState.user && authState.user),
        (userState: IUser, props) => {
          const forAllRoles = !roles || roles.length === 0;
          return (
            forAllRoles ||
            (!!userState && userState.groups && userState.groups.some((userRole: string) => roles.includes(userRole)))
          );
        },
      );
    }),
  }),
  reducers: {
    setUserInfo(state, payload) {
      return {
        ...state,
        user: {
          ...payload,
          settings: payload && payload.settings ? JSON.parse(payload.settings) : {},
        },
        isLoggedIn: !!(payload && payload.id),
        initialized: true,
      };
    },
    logout(state, payload) {
      return {
        ...state,
        ...defaultEmptyState,
      };
    },
    setError(state, payload) {
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        token: null,
        error: payload,
      };
    },
    clearError(state, payload) {
      return {
        ...state,
        error: null,
      };
    },
    setToken(state, payload) {
      localStorage.setItem('token', payload);
      return {
        ...state,
        token: payload,
      };
    },
    setSmsToken(state, payload) {
      return {
        ...state,
        smsToken: payload,
      };
    },
    setNextUrl(state, payload) {
      return {
        ...state,
        nextUrl: payload,
      };
    },
    setState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: dispatch => ({
    async login(payload: { username: string; password: string; smsCode?: string }, rootState) {
      store.dispatch.AuthModel.setError(null);
      await HttpApi.post('/auth/token/login/', {
        data: {
          email: payload.username,
          password: payload.password,
          smsCode: payload.smsCode ? payload.smsCode : undefined,
          smsToken: payload.smsCode ? rootState.AuthModel.smsToken : undefined,
        },
        ommitToken: true,
      })
        .then((data: any) => {
          if (data.authToken) {
            store.dispatch.AuthModel.setToken(data.authToken);
          } else if (data.smsToken) {
            store.dispatch.AuthModel.setSmsToken(data.smsToken);
          }
        })
        .catch(error => {
          if (error.data) {
            store.dispatch.AuthModel.setError(error.data);
          } else if (error.status || error.statusText) {
            store.dispatch.AuthModel.setError(`${error.status}: ${error.statusText}`);
          } else {
            store.dispatch.AuthModel.setError([error.toString()]);
          }
        });
    },
    async logout(payload, rootState) {
      await HttpApi.post('/auth/token/logout/')
        .then(data => {
          localStorage.removeItem('token');
          store.dispatch.AuthModel.setUserInfo(null);
        })
        .catch(error => {
          store.dispatch.AuthModel.setError(error);
        });
    },
    async getUserInfo(token, rootState) {
      await HttpApi.get('/user-data/')
        .then(data => {
          store.dispatch.AuthModel.setUserInfo(data);
        })
        .catch(error => {
          store.dispatch.AuthModel.setUserInfo(null);
          store.dispatch.AuthModel.setError({
            nonFieldErrors: 'Twoja sesja wygasła. Zaloguj się ponownie.',
          });
        });
    },
    async register(payload, rootState) {
      store.dispatch.AuthModel.setError(null);
      await HttpApi.post('/auth/users/create/', {
        data: {
          email: payload.email,
          password: payload.password,
          username: payload.username,
        },
        ommitToken: true,
      })
        .then(data => {
          Events.emit('AuthModel.register.success');
        })
        .catch(error => {
          if (error.data) {
            store.dispatch.AuthModel.setError(error.data);
          } else if (error.status || error.statusText) {
            store.dispatch.AuthModel.setError({
              nonFieldErrors: `${error.status}: ${error.statusText}`,
            });
          } else {
            store.dispatch.AuthModel.setError({
              nonFieldErrors: error.toString(),
            });
          }
        });
    },
    async resetPassword(payload, rootState) {
      store.dispatch.AuthModel.setError(null);
      await HttpApi.post('/auth/password/reset/', {
        data: {
          email: payload.email,
        },
        ommitToken: true,
      })
        .then(data => {
          Events.emit('AuthModel.resetPassword.success');
        })
        .catch(error => {
          if (error.data) {
            store.dispatch.AuthModel.setError(error.data);
          } else if (error.status || error.statusText) {
            store.dispatch.AuthModel.setError({
              nonFieldErrors: `${error.status}: ${error.statusText}`,
            });
          } else {
            store.dispatch.AuthModel.setError({
              nonFieldErrors: error.toString(),
            });
          }
        });
    },
    async resetPasswordConfirm(payload, rootState) {
      store.dispatch.AuthModel.setError(null);
      await HttpApi.post('/auth/password/reset/confirm/', {
        data: {
          uid: payload.uid,
          token: payload.token,
          newPassword: payload.password,
        },
        ommitToken: true,
      })
        .then(data => {
          Events.emit('AuthModel.resetPasswordConfirm.success');
        })
        .catch(error => {
          if (error.data) {
            store.dispatch.AuthModel.setError(error.data);
          } else if (error.status || error.statusText) {
            store.dispatch.AuthModel.setError({
              nonFieldErrors: `${error.status}: ${error.statusText}`,
            });
          } else {
            store.dispatch.AuthModel.setError({
              nonFieldErrors: error.toString(),
            });
          }
        });
    },
  }),
});
