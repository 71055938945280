import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';
import { Meal } from './RestaurantMealModel';

// min/max in minutes
export const AVERAGE_MEAL_PREPARE_TIME_MIN = 0;
export const AVERAGE_MEAL_PREPARE_TIME_MAX = 60;

// min/max commision
export const RESTAURANT_COMMISION_MIN = 0;
export const RESTAURANT_COMMISION_MAX = 100;

// todo - add type
export interface Restaurant {
  id: number;
  name: string;
  displayedName: string;
  isActive: boolean;
  isNew: boolean;
  hasLunch: boolean;
  commision: number;
  notes: string;
  descriptionPl: string;
  descriptionEn: string;
  addressCity: string;
  meals: Meal[];
  averageMealPrepareTime?: number;
  cuisineTypes?: any;
  // ...
}

export const RestaurantModel = SimpleRestModel({
  collectionName: 'restaurants',
  HttpApi,
});

export const RestaurantUtils = {
  restaurantContainsPhrase: (restaurant: Restaurant, query: string): boolean => {
    const _query = query.toLowerCase();
    const content = `${restaurant.name.toLowerCase()}`;
    return content.indexOf(_query) > -1;
  },
};
