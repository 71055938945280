import { AgGridTable, Button, ButtonSize, Group } from 'lib/ui';
import React, { FunctionComponent } from 'react';
import { DeliveryZoneMap, DeliveryZone } from 'modules/Settings';

export interface DeliveryZonesTableProps {
  deliveryZones?: DeliveryZone[];
  onEditClick: Function;
}

const DeliveryZonesTable: FunctionComponent<DeliveryZonesTableProps> = ({ deliveryZones, onEditClick }) => {
  const columns = [
    {
      headerName: 'Od',
      field: 'distanceFrom',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: props => <a onClick={() => onEditClick(props.data)}>{props.data.distanceFrom}</a>,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Do',
      field: 'distanceTo',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: props => <a onClick={() => onEditClick(props.data)}>{props.data.distanceTo}</a>,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Cena',
      field: 'price',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: props => `${props.data.price} zł`,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Akcje',
      cellRendererFramework: props => {
        return (
          <Group>
            <Button
              size={ButtonSize.Small}
              label={'edytuj'}
              iconName="edit"
              onClick={event => onEditClick(props.data)}
            />
          </Group>
        );
      },
      suppressSizeToFit: false,
    },
  ];

  const deliveryZonesMapped = (deliveryZones || []).map(zone => DeliveryZoneMap.apiDataToViewData(zone));

  return (
    <AgGridTable
      columnDefs={columns}
      rowData={deliveryZonesMapped}
      enableCellChangeFlash={true}
      onViewportChanged={params => params.api.sizeColumnsToFit()}
      onGridSizeChanged={params => params.api.sizeColumnsToFit()}
      onGridReady={params => params.api.sizeColumnsToFit()}
    />
  );
};

DeliveryZonesTable.defaultProps = {};

export { DeliveryZonesTable };
