import React, { FunctionComponent, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'recompose';
import { InputNumber, message, Slider } from 'antd';
import { Events } from 'lib/services';
import { statusMessage } from 'modules/MessageHelper';
import { useDebounce } from 'lib/hooks';
import {
  DELIVERY_DELAY_MAX,
  DELIVERY_DELAY_MIN,
  GENERAL_SETTINGS_FIXED_ID,
} from 'modules/Settings/GeneralSettingsModel';
import { Spacer } from 'lib/ui';

interface DeliveryDelaySliderProps {
  debounceTime?: number;
}

const enhance = compose(
  connect(
    ({ generalSettings, loading }) => ({
      generalSettingsData: generalSettings.dataById[GENERAL_SETTINGS_FIXED_ID],
      loading,
    }),
    ({ generalSettings }) => ({
      generalSettingsStore: generalSettings,
    }),
  ),
);

const deliveryDelaySliderDefaultProps = {
  debounceTime: 1000,
};

const DeliveryDelaySlider: FunctionComponent<DeliveryDelaySliderProps> = enhance(props => {
  const [initialDeliveryDelay, setInitialDeliveryDelay] = useState(null);

  const [deliveryDelay, setDeliveryDelay] = useState(
    (props.generalSettingsData && +props.generalSettingsData.deliveryDelay) || null,
  );

  const deliveryDelayDebounced = useDebounce(deliveryDelay, props.debounceTime);

  // set events
  useEffect(() => {
    message.destroy();

    Events.on('Model.generalSettings.updateItem.success', () => {
      message.destroy();
      message.success('Maksymalny czas opóźnienia został zmieniony.', 3);
    });

    Events.on(['Model.generalSettings.updateItem.error'], () => {
      statusMessage(<p>Wystąpił nieoczekiwany błąd.</p>, 'error');
    });

    return () => {
      message.destroy();
      Events.off(['Model.generalSettings.updateItem.success', 'Model.generalSettings.updateItem.error'], true);
    };
  }, []);

  // load settings
  useEffect(() => {
    props.generalSettingsStore
      .loadItem({
        id: GENERAL_SETTINGS_FIXED_ID,
      })
      .then(res => {
        setInitialDeliveryDelay(res.deliveryDelay);
      });
  }, []);

  // update deliveryDelay after the slider has changed
  useEffect(() => {
    if (initialDeliveryDelay !== null && (deliveryDelayDebounced || deliveryDelayDebounced === 0)) {
      message.loading('Zapisuję zmiany...', 0);
      props.generalSettingsStore.updateItem({
        data: {
          deliveryDelay: +deliveryDelayDebounced,
        },
        id: GENERAL_SETTINGS_FIXED_ID,
      });
    }
  }, [deliveryDelayDebounced]);

  let currentValue = deliveryDelay;
  if (!currentValue && currentValue !== 0) {
    currentValue = Number(initialDeliveryDelay);
  } else {
    currentValue = +currentValue;
  }

  return (
    <div data-bp="grid">
      <div data-bp="9">
        <Slider
          disabled={props.loading.models.generalSettings}
          value={currentValue}
          min={DELIVERY_DELAY_MIN}
          max={DELIVERY_DELAY_MAX}
          onChange={newDeliveryDelay => {
            if (!deliveryDelay || +newDeliveryDelay !== +deliveryDelay) {
              setDeliveryDelay(+newDeliveryDelay);
            }
          }}
        />
      </div>
      <div data-bp="3">
        <InputNumber
          disabled={props.loading.models.generalSettings}
          value={currentValue}
          min={DELIVERY_DELAY_MIN}
          max={DELIVERY_DELAY_MAX}
          onChange={value => {
            let newDeliveryDelay = +`${value}`.replace(/[^0-9]+/, '');

            if (newDeliveryDelay < DELIVERY_DELAY_MIN) {
              newDeliveryDelay = DELIVERY_DELAY_MIN;
            } else if (newDeliveryDelay > DELIVERY_DELAY_MAX) {
              newDeliveryDelay = DELIVERY_DELAY_MAX;
            }

            if (!deliveryDelay || +newDeliveryDelay !== +deliveryDelay) {
              setDeliveryDelay(+newDeliveryDelay);
            }
          }}
          precision={0}
        />
        <Spacer horizontal size="small" />%
      </div>
    </div>
  );
});

DeliveryDelaySlider.defaultProps = deliveryDelaySliderDefaultProps;

export { DeliveryDelaySlider };
