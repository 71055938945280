/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';

import { Modal, Button, ButtonVariant, Group, ModalProps, Spacer } from '..';

import { FieldError, FormError, Textarea, AddressField } from '../../forms';

export interface AddressDialogProps extends ModalProps {
  onCancel: () => void;
  onSave: (data: any) => void;
  open?: boolean;
  loading?: boolean;
  title?: string;
  description?: string;
  errors?: any;
  value?: {
    city: string;
    postalCode: string;
    houseNumber: string;
    street: string;
    details: string;
  };
  withNotes?: boolean;
  cancelText?: string;
  okText?: string;
}
// {
//   street: string;
//   postalCode: string;
//   city: string;
//   notes: string;
// }

export const AddressDialog: FunctionComponent<AddressDialogProps> = props => {
  if (!props.open) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        street: props.value && props.value.street ? props.value.street : '',
        houseNumber: props.value && props.value.houseNumber ? props.value.houseNumber : '',
        postalCode: props.value && props.value.postalCode ? props.value.postalCode : '',
        city: props.value && props.value.city ? props.value.city : '',
        details: props.value && props.value.details ? props.value.details : '',
      }}
      onSubmit={(values, bag) => {
        props.onSave({
          values,
          ...bag,
        });
      }}
      render={formikProps => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <Modal
              closable={!props.loading}
              visible={props.open}
              header={<h2>{props.title || '...'}</h2>}
              onClose={props.onCancel}
              footer={
                <Group align="right">
                  <Button disabled={props.loading} onClick={props.onCancel} label={props.cancelText || 'Anuluj'} />
                  <Button
                    variant={ButtonVariant.Primary}
                    loading={props.loading}
                    onClick={formikProps.handleSubmit}
                    label={props.okText || 'Zapisz'}
                  />
                </Group>
              }
            >
              {props.errors && <FormError errors={props.errors} />}
              <AddressField
                label="Adres"
                cityFieldName="city"
                postalCodeFieldName="postalCode"
                houseNumberFieldName="houseNumber"
                streetFieldName="street"
                detailsFieldName="details"
                vertical
                {...formikProps}
              />

            </Modal>
          </form>
        );
      }}
    />
  );
};
