export const ExtraExtraMap = {
  apiDataToViewData: apiData => ({
    ...apiData,
    namePl: apiData.namePl || '',
    nameEn: apiData.nameEn || '',
    isActive: typeof apiData.isActive !== 'undefined' ? apiData.isActive : false,
    isDeleted: typeof apiData.isDeleted !== 'undefined' ? apiData.isDeleted : false,
    price: apiData.price ? apiData.price / 100 : 0,
  }),

  viewDataToApiPayload: viewData => ({
    ...viewData,
    price: viewData.price ? (viewData.price * 100).toFixed(2) : 0,
  }),
};
