import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

export interface DriversReckoningDayOrderDetails {
  id: number;
  gross: number;
  status: 10 | 20 | 30;
  isPaidForDriver: false;
  deliveryAddress: any;
  paymentAmount: number;
  paymentDetails: {
    id: number;
    nameEn: string;
    namePl: string;
    displayedName: string;
  };
  extraExtrasPrice: number;
  driverBonusAmount: number | null;
  driverBonusDescription: string;
}

export interface DriversReckoningDay {
  isPaid: boolean;
  dayStart: string;
  dayEnd: string;
  paymentConditions: {
    isPaidRbh: boolean;
    isPaidDistance: boolean;
    isPaidDay: boolean;
    isPaidDelivery: boolean;
    isPaidDeliveryAll: boolean;
    dayRateMon: number;
    dayRateTue: number;
    dayRateWed: number;
    dayRateThu: number;
    dayRateFri: number;
    dayRateSat: number;
    dayRateSun: number;
    hourRate: number;
    distanceRate: number;
    deliveryRate: number;
  };
  driverPaymentSum: number;
  ordersCount: number;
  ordersDetails: DriversReckoningDayOrderDetails[];
  paymentDetails: {
    forDay: {
      weekdayName: string;
      rate: number;
      total: number;
    };
    forDelivery: {
      amountPerOrder: number[];
      total: number;
    };
    forDeliveryAll: {
      amountPerOrder: number[];
      total: number;
    };
    forRbh: {
      dateStart?: Date;
      dateEnd?: Date;
      amountPerHour: number;
      countRbh: number;
      total: number;
    };
    forDistance: {
      distance: number;
      amountPerKm: number;
      total: number;
    };
    discount: {
      total: number;
      count: number;
    };
  };
}

export type DriversReckoning = {
  details: DriversReckoningDay[];
  globalData: {
    bonuses: {
      nook: {
        count: number;
        amount: number;
      };
      ok: {
        count: number;
        amount: number;
      };
    };
    countDistance: number;
    countRbh: number;
    orders: {
      count: number;
      paid: {
        amount: number;
        days: number;
      };
      unpaid: {
        days: number;
        amount: number;
      };
    };
  };
};

export const DriversReckoningModel = SimpleRestModel({
  collectionName: 'driversReckoning',
  HttpApi,
  getApiPath: (payload, action: string) => {
    switch (action) {
      case 'loadCollection':
      default:
        return `closed-orders/driver/${payload.driverId}/date_start/${payload.dateStart}/date_end/${payload.dateEnd}`;
    }
  },
});
