import React, { useEffect, useState } from 'react';
import useToggle from 'react-use/lib/useToggle';
import { connect } from 'react-redux';
import { Empty, message } from 'antd';

import { PageLayout } from 'components';
import { EmployeesTable, EmployeeFormDrawer, EmployeeDrawer } from 'modules/Employees';
import { Button, ButtonVariant, Group, Loading } from 'lib/ui';

const enhance = connect(
  ({ employees, loading }) => ({
    employeesStore: employees,
    loading,
  }),
  ({ employees }) => ({
    employees,
  }),
);

const EmployeesPage = enhance(props => {
  //  load employees on enter
  useEffect(() => {
    props.employees.loadCollection();
  }, []);

  //  currently selected item (for edit or to see details)
  const [item, setItem] = useState(null);
  //  form visible & toggle
  const [formVisible, toggleForm] = useToggle(false);
  //  employee details visible & toggle
  const [detailsVisible, toggleDetails] = useToggle(false);

  const employees = props.employeesStore.data;

  return (
    <PageLayout title="Pracownicy">
      <Group align="right">
        <Button
          label="Dodaj pracownika"
          variant={ButtonVariant.Primary}
          onClick={() => {
            setItem(null);
            toggleForm(true);
          }}
        />
      </Group>

      {!employees.length && !props.loading.effects.employees.loadCollection && <Empty description="Brak danych" />}

      {!employees.length && props.loading.effects.employees.loadCollection && <Loading text="Ładowanie danych" />}

      {employees.length > 0 && (
        <EmployeesTable
          items={employees}
          onItemClick={item => {
            setItem(item);
            toggleDetails(true);
          }}
          onStatusChangeClick={item => {
            const hide = message.loading(`Aktualizuję status pracownika: ${item.user.firstName} `, 0);
            props.employees
              .updateItem({
                id: item.id,
                data: {
                  isActive: !item.isActive,
                },
              })
              .then(() => {
                message.success(`Pracownik ${item.user.firstName} zaktualizowany.`);
              })
              .catch(() => {
                message.error(`Nie udało się zaktualizować pracownika: ${item.user.firstName}`);
              })
              .finally(hide);
          }}
          onEmployeeReckoning={emplyeeId => {
            props.history.push(`/employees/${emplyeeId}/reckoning`);
          }}
        />
      )}

      {formVisible && (
        <EmployeeFormDrawer
          item={item}
          visible
          onClose={() => {
            toggleForm(false);
          }}
        />
      )}

      {detailsVisible && (
        <EmployeeDrawer
          item={item}
          visible
          onClose={() => {
            toggleDetails(false);
          }}
          onEditClick={() => {
            toggleDetails(false);
            toggleForm(true);
          }}
        />
      )}
    </PageLayout>
  );
});

export { EmployeesPage };
