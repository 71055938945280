import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import plPL from 'antd/lib/locale-provider/pl_PL';
import { locale } from 'moment';
import momentPlLocale from 'moment/locale/pl';

import { store } from 'store';
import { ThemeProvider } from 'lib/ui';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { APP_THEME } from './theme';

locale('pl', momentPlLocale);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={APP_THEME}>
      <ConfigProvider locale={plPL}>
        <App />
      </ConfigProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
