/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Empty, message } from 'antd';

import { PageLayout } from 'components';
import { addDays, format } from 'date-fns';

import { SearchInput } from 'lib/forms';
import { DialogService } from 'lib/services/DialogService';
import { DimmerLoading, Group, Header, Loading } from 'lib/ui';
import { OrderDetailsDialog } from 'modules/Orders/components/OrderDetailsDialog/OrderDetailsDialog';
import { OrdersPageNavigation } from 'modules/Orders/components/OrdersPageNavigation/OrdersPageNavigation';
import { OrdersTable } from 'modules/Orders/components/OrderTable/OrdersTable';
import { OrdersTableLegend } from 'modules/Orders/components/OrderTable/OrdersTableLegend';
import { MapDataModel } from 'pages/MapPage/MapDataModel';
import { removeAllLinesFromMap } from 'pages/MapPage/mapHelpers';
import { MapView } from 'pages/MapPage/MapView';
import { DriverTasksDialog } from 'pages/OrdersPage/DriverTasksDialog/DriverTasksDialog';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import { HttpApi } from 'services/HttpApi';
import { store } from 'store';
import { OrderPageDrivers } from './components/OrderPageDrivers';

export const OrdersPage = () => {
  const history = useHistory();
  const [orderDetailsOpened, toggleOrderDetails] = useToggle(false);
  const [order, setOrder] = useState<any>({});
  const [futureOrders, setFutureOrders] = useState([]);
  // search query
  const [query, setQuery] = useState('');
  // interval trigger
  const [triggerLoad, setTriggerLoad] = useState<number>(0);
  const timerId = useRef<any>(null);
  // map data
  const [mapData, setMapData] = useState<MapDataModel>();

  const orders = useSelector(state => state.currentOrders.data);
  const drivers = useSelector(state => state.drivers.data);
  const orderDrivers = useSelector(state => state.orderDrivers.data);
  const loading = useSelector(state => state.loading);

  const loadData = useCallback(() => {
    store.dispatch.currentOrders.loadCollection({
      params: {
        search: query,
        status: [0, 10, 20, 30],
        // eslint-disable-next-line @typescript-eslint/camelcase
        delivery_date__lte: format(new Date(), 'YYYY-MM-DD'),
      },
    });
    store.dispatch.orderDrivers.loadCollection();
    HttpApi.get<MapDataModel>('map-data/')
      .then(data => {
        setMapData(data);
      })
      .catch(err => {
        message.error('Wystąpił nieoczekiwany błąd przy pobieraniu danych mapy');
        setMapData(undefined);
      });
    HttpApi.get('/orders/currentOrders/', {
      params: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        delivery_date__gte: format(addDays(new Date(), 1), 'YYYY-MM-DD'),
        search: query,
        status: [0, 10, 20, 30],
      },
    })
      .then(data => {
        setFutureOrders(data.results);
      })
      .catch(err => {
        message.error('Wystąpił nieoczekiwany błąd przy pobieraniu danych przyszłych zamówień');
        setFutureOrders([]);
      });
  }, [query]);

  useEffect(() => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    loadData();

    timerId.current = setTimeout(() => {
      setTriggerLoad(+new Date());
    }, 1000 * 60);

    return () => clearTimeout(timerId.current);
  }, [query, triggerLoad]);

  useEffect(() => {
    store.dispatch.currentOrders.reset();
    store.dispatch.orderDrivers.loadCollection();
  }, []);

  return (
    <>
      <PageLayout title="Zamówienia">
        <OrdersPageNavigation
          withRefreshButton
          onRefresh={() => setTriggerLoad(+new Date())}
          refreshing={loading.effects.currentOrders.loadCollection || loading.effects.orderDrivers.loadCollection}
          withAddButton
          onAdd={() => history.push('/orders/new/1')}
        />

        <div data-bp="grid">
          <div data-bp="5">
            <Group align="space-between">
              <Group width="auto">
                <SearchInput value={query} onChange={setQuery} placeholder="Wyszukaj zamówienie" />
              </Group>
            </Group>

            <Header>Zamówienia bieżące</Header>

            {!orders.length && !loading.effects.currentOrders.loadCollection && <Empty description="Brak zamówień" />}
            {!orders.length && loading.effects.currentOrders.loadCollection && <Loading text="Ładowanie danych" />}

            {orders.length > 0 && (
              <div style={{ position: 'relative' }}>
                <OrdersTable
                  orders={orders}
                  drivers={orderDrivers}
                  allDrivers={drivers}
                  onDriverSelect={(driver, order, customizeTasks) => {
                    store.dispatch.currentOrders
                      .updateItem({
                        id: order.id,
                        data: {
                          driver: driver.id,
                        },
                      })
                      .then((data: any) => {
                        const { driverTasks } = data;
                        const driverMapData = mapData && mapData.drivers.find(driverMap => driverMap.id === driver.id);

                        if (customizeTasks) {
                          DialogService.open(DriverTasksDialog, {
                            tasks: driverTasks,
                            closable: false,
                            driverMapData,
                            driverId: driver.id,
                            onClose: success => {
                              setTriggerLoad(+new Date());
                              if (success) {
                                message.success('Zamówienie przypisane do kierowcy wraz z nowymi zadananiami');
                              }
                              // hacky way to refresh map
                              // @ts-ignore
                              setMapData({ ...mapData });
                            },
                          });
                        } else {
                          message.success('Zamówienie przypisane do kierowcy');
                          setTriggerLoad(+new Date());
                        }
                      })
                      .catch(() => {
                        message.error('Wystąpił błąd podczas aktualizacji zamówienia');
                        setTriggerLoad(+new Date());
                      });
                  }}
                  onOrderStatusChange={() => {
                    setTriggerLoad(+new Date());
                  }}
                  onOrderDetails={order => {
                    setOrder(order);
                    toggleOrderDetails();
                  }}
                  refreshOrders={() => {
                    setTriggerLoad(+new Date());
                  }}
                />
                <DimmerLoading visible={loading.effects.currentOrders.loadCollection} />
              </div>
            )}

            <Header>Zamówienia na kolejne dni</Header>

            {futureOrders.length > 0 && (
              <div style={{ position: 'relative' }}>
                <OrdersTable
                  orders={futureOrders}
                  drivers={orderDrivers}
                  allDrivers={drivers}
                  onDriverSelect={(driver, order, customizeTasks) => {
                    store.dispatch.currentOrders
                      .updateItem({
                        id: order.id,
                        data: {
                          driver: driver.id,
                        },
                      })
                      .then((data: any) => {
                        const { driverTasks } = data;
                        const driverMapData = mapData && mapData.drivers.find(driverMap => driverMap.id === driver.id);

                        if (customizeTasks) {
                          DialogService.open(DriverTasksDialog, {
                            tasks: driverTasks,
                            closable: false,
                            driverMapData,
                            driverId: driver.id,
                            onClose: success => {
                              if (success) {
                                message.success('Zamówienie przypisane do kierowcy wraz z nowymi zadananiami');
                                setTriggerLoad(+new Date());
                              }
                              // hacky way to refresh map
                              // @ts-ignore
                              setMapData({ ...mapData });
                            },
                          });
                        } else {
                          message.success('Zamówienie przypisane do kierowcy');
                        }
                      })
                      .catch(() => {
                        setTriggerLoad(+new Date());
                      });
                  }}
                  onOrderStatusChange={() => {
                    setTriggerLoad(+new Date());
                  }}
                  onOrderDetails={order => {
                    setOrder(order);
                    toggleOrderDetails();
                  }}
                />
              </div>
            )}

            {futureOrders.length === 0 && <p>Brak zamówień na kolejne dni</p>}

            <OrdersTableLegend />
          </div>
          <div data-bp="2">
            <OrderPageDrivers
              drivers={orderDrivers}
              onDriverTaskUpdate={driverId => {
                const driverMapData = mapData && mapData.drivers.find(driver => driverId === driver.id);
                removeAllLinesFromMap();
                if (driverMapData) {
                  DialogService.open(DriverTasksDialog, {
                    tasks: driverMapData.tasks,
                    driverMapData,
                    driverId,
                    closable: true,
                    onClose: success => {
                      if (success) {
                        message.success('Zadania dla kierowcy zaktualizowane');
                        setTriggerLoad(+new Date());
                      }
                      // hacky way to refresh map
                      // @ts-ignore
                      setMapData({ ...mapData });
                    },
                  });
                }
              }}
            />
          </div>

          <div data-bp="5">
            <MapView data={mapData} />
          </div>
        </div>

        <div data-bp="5">
          <Link to="/orders/archive">Archiwum zamówień</Link>
        </div>
      </PageLayout>

      {orderDetailsOpened && (
        <OrderDetailsDialog
          isEditable
          orderId={order.id}
          visible={orderDetailsOpened}
          onClose={hasChanged => {
            toggleOrderDetails();
            setTriggerLoad(+new Date());
            if (hasChanged) {
              loadData();
            }
          }}
        />
      )}
    </>
  );
};
