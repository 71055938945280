import { DatePicker } from 'antd';

import { styled } from 'lib/ui';
import { TimePickerDefaults } from 'lib/ui/utils';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { AppDateTimePickerDefaults } from 'components/AppUtils';

export interface RestaurantsSettlementDeliveryDateProps {
  date: Date | null;
  onChange: (date: Date | null) => void;
  placeholder: string;
}

const RestaurantsSettlementDeliveryDateContainer = styled.div.attrs({
  className: 'RestaurantsSettlementDeliveryDateContainer',
})`
  padding: 0.5rem;
  padding-left: 0;
`;

export const RestaurantsSettlementDeliveryDate: FunctionComponent<RestaurantsSettlementDeliveryDateProps> = props => {
  return (
    <RestaurantsSettlementDeliveryDateContainer>
      <DatePicker
        showTime={{
          format: TimePickerDefaults.TimeFormat,
          minuteStep: TimePickerDefaults.MinuteStep,
        }}
        format={AppDateTimePickerDefaults.DateFormat}
        value={props.date ? moment(props.date) : undefined}
        placeholder={props.placeholder}
        onChange={value => props.onChange(value ? value.toDate() : null)}
        suffixIcon={null}
      />
    </RestaurantsSettlementDeliveryDateContainer>
  );
};
