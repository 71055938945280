import { Switch } from 'antd';
import { differenceInDays, isAfter } from 'date-fns';

import {
  AgGridTable,
  Button,
  ButtonSize,
  formattedShortDate,
  formattedTime,
  Group,
  Icon,
  IconFa,
  IconSize,
  Spacer,
  styled,
  Tooltip,
} from 'lib/ui';
import React, { FunctionComponent } from 'react';
import { ColorRed } from 'components';
import { getDayLabel } from 'lib/ui/utils';
import { APP_THEME } from 'theme';

export interface RestaurantsTableProps {
  restaurants: any[];
  onDetailClick?: Function;
  onEditClick?: Function;
  onOfferClick?: Function;
  onCalculateClick?: Function;
  onStatusChangeClick?: Function;
  onSelectionChanged?: Function;
}

const RestaurantsTableDefaultProps = {};

const RestaurantsTableContainer = styled.div``;

const RestaurantsTable: FunctionComponent<RestaurantsTableProps> = ({
  restaurants,
  onDetailClick,
  onEditClick,
  onOfferClick,
  onCalculateClick,
  onStatusChangeClick,
  onSelectionChanged,
}) => {
  const columns = [
    {
      headerName: '-',
      maxWidth: 60,
      checkboxSelection: params => {
        // console.log('params.node', params.node);
        // console.log('params.data', params.data);
        return true;
      },
    },
    {
      headerName: 'Nazwa',
      field: 'name',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: props => (
        <a
          onClick={() => {
            if (onOfferClick) {
              onOfferClick(props.data);
            }
          }}
        >
          {props.data.name}

          {props.data.lon && props.data.lat && (
            <Tooltip text={`lat: ${props.data.lat}, lon: ${props.data.lon}`}>
              <Spacer size="small" horizontal theme={APP_THEME} />
              <Icon name="fa-map-marked" fa={IconFa.Fas} size={IconSize.Small} />
            </Tooltip>
          )}

          <Tooltip text={`Czas przygotowania: ${props.data.averageMealPrepareTime} min.`}>
            <Spacer size="small" horizontal theme={APP_THEME} />
            {props.data.averageMealPrepareTime} min.
          </Tooltip>
        </a>
      ),
    },
    {
      headerName: 'Nazwa wyświetlana',
      field: 'displayedName',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Nieaktywność',
      cellRendererFramework: props => {
        if (props.data.disableFrom && props.data.disableTo) {
          const from = new Date(props.data.disableFrom);
          const to = new Date(props.data.disableTo);
          const formattedRange = `${formattedShortDate(from)} ${formattedTime(from)} -
              ${formattedShortDate(to)} ${formattedTime(to)}`;
          const today = new Date();

          //  ignore past ranges
          if (isAfter(today, to)) {
            return null;
          }

          //  currently "unavailable"
          if (isAfter(today, from)) {
            const diff = differenceInDays(to, today);
            return (
              <ColorRed>
                {formattedRange} (do końca: {diff > 0 && `${diff} ${getDayLabel(diff)}`}
                {diff === 0 && `mniej niż dzień`})
              </ColorRed>
            );
          }

          //  unavailable in future
          const diff = differenceInDays(from, today);
          return (
            <span>
              {formattedRange} (za: {diff > 0 && `${diff} ${getDayLabel(diff)}`}
              {diff === 0 && `mniej niż dzień`})
            </span>
          );
        }
        return null;
      },
    },
    {
      headerName: 'Status',
      minWidth: 80,
      maxWidth: 80,
      cellRendererFramework: props => (
        <Switch
          checked={props.data.isActive}
          size="small"
          onClick={() => {
            if (onStatusChangeClick) {
              onStatusChangeClick(props.data);
            }
          }}
        />
      ),
    },
    {
      headerName: 'Akcje',
      cellRendererFramework: props => {
        const row = props.data;
        return (
          <Group align={'right'}>
            {/* <Button */}
            {/* size={ButtonSize.Small} */}
            {/* iconName="power-off" */}
            {/* onClick={() => { */}
            {/* if (onStatusChangeClick) { */}
            {/* onStatusChangeClick(row); */}
            {/* } */}
            {/* }} */}
            {/* /> */}
            <Button
              size={ButtonSize.Small}
              label={'oferta'}
              onClick={event => {
                if (onOfferClick) {
                  onOfferClick(row);
                }
              }}
            />
            {/* <Button */}
            {/* size={ButtonSize.Small} */}
            {/* label={'szczegóły'} */}
            {/* iconName="info" */}
            {/* onClick={event => { */}
            {/* if (onDetailClick) { */}
            {/* onDetailClick(row); */}
            {/* } */}
            {/* }} */}
            {/* /> */}
            <Button
              size={ButtonSize.Small}
              label={'edytuj'}
              iconName="edit"
              onClick={event => {
                if (onEditClick) {
                  onEditClick(row);
                }
              }}
            />
          </Group>
        );
      },
    },
  ];
  return (
    <RestaurantsTableContainer>
      <AgGridTable
        columnDefs={columns}
        rowData={restaurants}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={event => {
          const selectedRowNodes = event.api.getSelectedNodes();
          if (onSelectionChanged) {
            onSelectionChanged(selectedRowNodes);
          }
        }}
        pagination
        paginationPageSize={25}
      />
    </RestaurantsTableContainer>
  );
};

RestaurantsTable.defaultProps = RestaurantsTableDefaultProps;

export { RestaurantsTable };
