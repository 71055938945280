import {
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Divider,
  Drawer,
  DrawerProps,
  Group,
  Header,
  Price,
  Spacer,
  styled,
} from 'lib/ui';
import connect from 'react-redux/es/connect/connect';
import React, { FunctionComponent, useState } from 'react';
import { RestaurantSettlement } from 'modules/Restaurant/RestaurantsSettlementModel';
import { FormTextareaField } from 'lib/forms';
import { Formik } from 'formik';
import { message } from 'antd';
import { RestaurantSettlementDrawerTable } from 'components/drawers/RestaurantSettlementDrawer/RestaurantSettlementDrawerTable';

const enhance = connect(
  ({ restaurants, restaurantsSettlement }) => ({
    restaurantsState: restaurants,
    restaurantsSettlementState: restaurantsSettlement,
  }),
  ({ restaurants, restaurantsSettlement }) => ({
    restaurants,
    restaurantsSettlement,
  }),
);

export interface RestaurantsSettlementDrawerProps extends DrawerProps {
  item?: RestaurantSettlement;
  onEditClick: Function;
  generateExcel: Function;
  updateSelectedRestaurant: Function;
}

const DrawerSubtitleStyles = styled.div`
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const RestaurantPaymentContainer = styled.div`
  position: absolute;
  text-align: center;
  top: 0;
  right: 0;
`;

const RestaurantsSettlementDrawer: FunctionComponent<RestaurantsSettlementDrawerProps> = enhance(
  ({ item, ...props }) => {
    // from store
    const [closable, setClosable] = useState(true);
    return (
      <Drawer
        closeOnEsc={closable}
        visible={props.visible}
        width={'50%'}
        subTitle={
          <DrawerSubtitleStyles>
            <Button
              label="Excel"
              onClick={() => props.generateExcel(item.restaurant.id)}
              variant={ButtonVariant.Primary}
            />
          </DrawerSubtitleStyles>
        }
        onClose={props.onClose}
        title={item.restaurant.name}
        destroyOnClose
      >
        <Group vertical>
          <Group wrap>
            <Group vertical wrap width={'40%'}>
              <Group>
                <span>
                  <strong>Zamówienia:</strong>
                </span>
                <span>{item.settlementData.ordersCount}</span>
              </Group>
              <Group>
                <span>
                  <strong>Prowizja:</strong>
                </span>
                <span>{item.restaurant.commision} %</span>
              </Group>
              <Group verticalAlign="top" align="space-between">
                <Formik
                  onSubmit={(values: any, actions) => {
                    props.restaurants
                      .updateItem({
                        id: item.restaurant.id,
                        data: {
                          notes: values.notes,
                        },
                      })
                      .then(result => {
                        message.success('Notatki zostały zaktualizowane');
                        props.updateSelectedRestaurant({ restaurant: result, settlementData: item.settlementData });
                      })
                      .catch(() => {
                        message.error('Wystąpił błąd');
                      })
                      .finally(() => {
                        actions.setSubmitting(false);
                      });
                  }}
                  initialValues={{ notes: item.restaurant.notes }}
                >
                  {restaurantNotesProps => {
                    return (
                      <form onSubmit={restaurantNotesProps.handleSubmit}>
                        <Group vertical>
                          <FormTextareaField name="notes" label="Notatki..." {...restaurantNotesProps} />
                          <Button
                            disabled={restaurantNotesProps.isSubmitting}
                            variant={ButtonVariant.Primary}
                            size={ButtonSize.Small}
                            loading={restaurantNotesProps.isSubmitting}
                            onClick={restaurantNotesProps.handleSubmit}
                            label="Zapisz"
                          />
                        </Group>
                      </form>
                    );
                  }}
                </Formik>
              </Group>
            </Group>
            <Group vertical wrap width={'59%'}>
              <RestaurantPaymentContainer>
                <Box title="Do zapłaty">
                  <h2>
                    <Price amount={item.settlementData.restaurantPayment} />
                  </h2>
                </Box>
              </RestaurantPaymentContainer>
            </Group>
          </Group>
          <Divider />
          <Spacer />
          <Group>
            <Group>
              {item.settlementData.orders && item.settlementData.orders.length > 0 && (
                <RestaurantSettlementDrawerTable
                  onValueChanged={e => props.onEditClick(e)}
                  items={item.settlementData}
                />
              )}
              {!item.settlementData.orders ||
                (item.settlementData.orders.length === 0 && <Header>Brak sprzedaży w wybranym okresie</Header>)}
            </Group>
          </Group>
        </Group>
      </Drawer>
    );
  },
);

export { RestaurantsSettlementDrawer };
