import { message } from 'antd';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'recompose';
import { Formik } from 'formik';

import { ButtonSubmit, FormError, FormFieldInputNumber } from 'lib/forms';
import { Events } from 'lib/services';
import { Button, Divider, Group, modalConfirm, Spacer } from 'lib/ui';

import { DeliveryZoneMap } from 'modules/Settings';

import validationSchema from './DeliveryZoneForm.ValidationSchema';
import { statusMessage } from 'modules/MessageHelper';

const enhance = compose(
  connect(
    ({ deliveryZones }) => ({
      deliveryZones,
    }),
    ({ deliveryZones }) => ({
      deliveryZonesStore: deliveryZones,
    }),
  ),
);

export interface DeliveryZoneFormProps {
  children?: ReactNode;
  deliveryZone?: any;
  onModalToggle: (visible: boolean) => void;
  onClose: () => void;
}

const DeliveryZoneForm: FunctionComponent<DeliveryZoneFormProps> = enhance(props => {
  const deliveryZoneData = props.deliveryZone || {};

  useEffect(() => {
    message.destroy();

    Events.on('Model.deliveryZones.createItem.success', data => {
      statusMessage(<p>Strefa dostaw została dodana</p>);
      props.onClose();
    });

    Events.on('Model.deliveryZones.updateItem.success', () => {
      statusMessage(<p>Strefa dostaw została zmieniona</p>);
    });

    Events.on('Model.deliveryZones.deleteItem.success', () => {
      statusMessage(<p>Strefa dostaw została usunięta</p>);

      props.onClose();
    });

    Events.on(
      [
        'Model.deliveryZones.createItem.error',
        'Model.deliveryZones.updateItem.error',
        'Model.deliveryZones.deleteItem.error',
      ],
      () => {
        statusMessage(<p>Wystąpił nieoczekiwany błąd</p>, 'error');
      },
    );

    return () => {
      Events.off(
        [
          'Model.deliveryZones.createItem.success',
          'Model.deliveryZones.updateItem.success',
          'Model.deliveryZones.deleteItem.success',
          'Model.deliveryZones.createItem.error',
          'Model.deliveryZones.updateItem.error',
          'Model.deliveryZones.deleteItem.error',
        ],
        true,
      );
    };
  }, []);

  return (
    <>
      <Formik
        initialValues={deliveryZoneData}
        validationSchema={validationSchema}
        validateOnBlur
        onSubmit={(values, actions) => {
          const payload = DeliveryZoneMap.viewDataToApiPayload(values);

          if (deliveryZoneData.id) {
            /**
             * UPDATE deliveryZone...
             */
            message.loading('Zapisuję zmiany...', 0);
            props.deliveryZonesStore
              .updateItem({
                data: payload,
                id: deliveryZoneData.id,
              })
              .finally(() => actions.setSubmitting(false));
          } else {
            /**
             * CREATE deliveryZone...
             */
            message.loading('Dodaję...', 0);
            props.deliveryZonesStore.createItem({ data: payload }).finally(() => actions.setSubmitting(false));
          }
        }}
        enableReinitialize
      >
        {formikProps => {
          // check errors returned from the API and if there are any, then prepare them for display...
          let formErrors: any = '';
          if (props.deliveryZones.errors) {
            if (typeof props.deliveryZones.errors === 'object') {
              formErrors = {
                ...props.deliveryZones.errors,
              };
            } else {
              formErrors = {
                '-': props.deliveryZones.errors,
              };
            }
          }

          return (
            <>
              {formErrors && (
                <>
                  <FormError errors={formErrors} />
                  <Spacer />
                </>
              )}
              <form onSubmit={formikProps.handleSubmit}>
                <Group vertical>
                  <Group>
                    <FormFieldInputNumber name="distanceFrom" label="Od" precision={0} {...formikProps} />

                    <Spacer horizontal size="large" />

                    <FormFieldInputNumber name="distanceTo" label="Do" precision={0} {...formikProps} />
                  </Group>
                  <FormFieldInputNumber name="price" label="Cena" {...formikProps} />
                </Group>

                <Divider />
                <Group>
                  <div style={{ flexGrow: 1 }}>
                    <ButtonSubmit
                      disabled={!formikProps.dirty || formikProps.isSubmitting}
                      loading={formikProps.isSubmitting}
                      label="Zapisz"
                      onClick={formikProps.handleSubmit}
                      iconName="save"
                    />
                  </div>
                  {props.deliveryZone && props.deliveryZone.id && (
                    <Button
                      label="Usuń"
                      iconName="trash"
                      onClick={() => {
                        modalConfirm({
                          title: `Usuwasz strefę dostaw? (${props.deliveryZone.namePl})`,
                          content: <p>Czy na pewno?</p>,
                          okText: 'Tak, usuwam',
                          yesCallback: () => {
                            message.loading('Usuwam...', 0);
                            props.deliveryZonesStore.deleteItem({
                              id: props.deliveryZone.id,
                            });
                          },
                        });
                      }}
                    />
                  )}
                </Group>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
});

DeliveryZoneForm.defaultProps = {};

export { DeliveryZoneForm };
