import React, { useRef } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { useLifecycles } from "react-use";
import { Formik } from "formik";

import { Button, ButtonVariant, Divider, Group } from "lib/ui";
import { ButtonSubmit, FormError, FormField } from "lib/forms";
import { Events } from "lib/services";

import { AuthBasePage } from "../AuthBasePage";

const enhance = connect(
  state => ({
    auth: state.AuthModel,
    loading: state.loading.effects.AuthModel.resetPassword,
  }),
  state => ({ AuthModel: { resetPasswordConfirm, clearError } }) => ({
    resetPasswordConfirm,
    clearError,
  }),
);

const ResetPasswordConfirmPage = enhance(props => {
  const redirectToLogin = useRef();

  useLifecycles(
    () => {
      Events.on('AuthModel.resetPassword.success', () => {
        redirectToLogin.current = true;
      });
    },
    () => {
      Events.off('AuthModel.resetPassword.success', true);
      props.clearError();
    },
  );

  if (redirectToLogin.current) {
    // @todo - display some message
    return <Redirect to={'/login'} />;
  }

  const {
    loading,
    match: { params },
  } = props;

  return (
    <AuthBasePage title="Reset hasła">
      <Formik
        initialValues={{
          password: '',
        }}
        validate={values => {
          const errors = {};
          if (!values.password) {
            errors.password = 'Podaj hasło';
          }
          return errors;
        }}
        onSubmit={(values, bag) => {
          props
            .resetPasswordConfirm({
              uid: params.uid,
              token: params.token,
              password: values.password,
            })
            .then(() => {
              bag.setSubmitting(false);
            });
        }}
      >
        {formikProps => (
          <div>
            {props.auth.error && <FormError errors={props.auth.error} />}
            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <Group>
                  <FormField name="password" placeholder="" label="Nowe hasło" type="password" {...formikProps} />
                </Group>
              </Group>
              <div className={'mt-5'}>
                <ButtonSubmit
                  disabled={formikProps.isSubmitting || !formikProps.isValid || loading}
                  loading={loading || formikProps.isSubmitting}
                  label="Ustaw nowe hasło"
                  onClick={formikProps.handleSubmit}
                  iconName="key"
                />
              </div>
            </form>
          </div>
        )}
      </Formik>
      <Divider hidden />
      <Group align="center">
        <Button variant={ButtonVariant.Plain}>
          <Link to="/login">Wróć do strony logowania</Link>
        </Button>
      </Group>
    </AuthBasePage>
  );
});

export { ResetPasswordConfirmPage };
