import { SearchInput } from 'lib/forms';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { styled, Button, ButtonVariant, Spacer } from 'lib/ui';

export interface MealInputSearchProps {
  onQueryChange?: (query: string) => void;
  onSearch: (query: string) => void;
  onReset: () => void;
  hideSearchButton?: boolean;
}

const MealInputSearchDefaultProps = {};

const MealInputSearchContainer = styled.div.attrs({
  className: 'MealInputSearchContainer',
})`
  padding: ${p => p.theme.spaceSmall} 0;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const MealInputSearch: FunctionComponent<
  MealInputSearchProps
> = props => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (props.onQueryChange) {
      props.onQueryChange(query);
    }
    if (!query) {
      props.onReset();
    }
  }, [query]);

  return (
    <MealInputSearchContainer>
      <SearchInput
        onChange={setQuery}
        value={query}
        placeholder="wpisz nazwę potrawy"
        onEnter={() => {
          props.onSearch(query);
        }}
      />
      <Spacer horizontal />
      {!props.hideSearchButton && (
        <Button
          variant={ButtonVariant.Primary}
          label="Szukaj potrawy"
          onClick={() => {
            props.onSearch(query);
          }}
        />
      )}
    </MealInputSearchContainer>
  );
};

MealInputSearch.defaultProps = MealInputSearchDefaultProps;
