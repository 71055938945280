import { styled } from '../ui/styled-components';

// {
//   width?: number | string;
//   height?: number | string;
// }
export const Textarea = styled('textarea')`
  display: block;
  padding: ${props => props.theme.spaceSmallX} ${props => props.theme.spaceSmall};
  width: ${p => (p.width ? p.width : '300px')};
  height: ${p => (p.height ? p.height : '110px')};
  max-width: 100%;
  border: 1px solid ${props => props.theme.colorGrey};
  font-size: ${props => props.theme.fontSizeSmall};
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.colorWhite};
  transition: all 0.25s ease-in-out;
  outline: none;

  :focus {
    border-color: ${props => props.theme.colorPrimary};
  }
`;
