import { AgGridColumnProps } from 'ag-grid-react';
import { message } from 'antd';
import {
  AgGridTable,
  Button,
  ButtonSize,
  formattedShortDateWithYear,
  formattedTime,
  Group,
  Icon,
  IconFa,
  IconSize,
  Tooltip,
} from 'lib/ui';
import { getLabelForStatus } from 'modules/Orders/components/OrderTable/getLabelForStatus';
import { OrderStatusDialog } from 'modules/Orders/components/OrderTable/OrderStatusDialog';
import { OrderModel } from 'modules/Orders/models/order.model';
import {
  getClientNameFromOrder,
  getFormattedPriceFromOrder,
  getRestaurantAddressFromOrder,
  getRestaurantNameFromOrder,
} from 'modules/Orders/utils/order.getters';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';

export interface OrdersWithDirectPickupTableProps {
  orders: OrderModel[];
  onRefresh: () => void;
  onOrderDetails?: (order: any) => void;
}

export const OrdersWithDirectPickupTable: React.FC<OrdersWithDirectPickupTableProps> = props => {
  const dispatch = useDispatch();
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [statusDialogOpened, toggleStatusDialog] = useToggle(false);

  const columns: AgGridColumnProps[] = [
    {
      headerName: 'Restauracja',
      tooltipValueGetter: params => params.value,
      cellRendererFramework: rowProps => {
        const restaurantNames = rowProps.data.restaurants
          ? rowProps.data.restaurants.map(r => r.displayedName).join(', ')
          : '';
        return (
          <Tooltip text={restaurantNames}>
            <div
              className="pointer"
              onClick={() => {
                if (props.onOrderDetails) {
                  props.onOrderDetails(rowProps.data);
                }
              }}
            >
              {rowProps.data.originalGross && <Icon size={IconSize.Small} name="edit" fa={IconFa.Fal} />}
              <span className="text-truncate ml-1">{restaurantNames}</span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: 'Adres restauracji',
      tooltipValueGetter: params => params.value,
      valueGetter: params => getRestaurantAddressFromOrder(params.data),
    },
    {
      headerName: 'Kwota',
      tooltipValueGetter: params => params.value,
      valueGetter: params => getFormattedPriceFromOrder(params.data),
    },
    {
      headerName: 'Czas złożenia zam.',
      tooltipValueGetter: params => params.value,
      valueGetter: params =>
        `${formattedShortDateWithYear(params.data.insertDate)} ${formattedTime(params.data.insertDate)}`,
    },
    {
      headerName: 'Klient',
      tooltipValueGetter: params => params.value,
      valueGetter: params => getClientNameFromOrder(params.data),
    },
    {
      headerName: 'Status',
      // valueGetter: params => getLabelForStatus(params.data.status),
      cellRendererFramework: cellProps => {
        const order = cellProps.data;

        return (
          <Group>
            <Button
              size={ButtonSize.Small}
              label={getLabelForStatus(order.status)}
              onClick={() => {
                setSelectedOrder(order);
                toggleStatusDialog();
              }}
            />
            <Button
              size={ButtonSize.Small}
              label="Zamówienie odebrane"
              onClick={() => {
                dispatch({
                  type: 'currentOrders/updateItem',
                  payload: {
                    id: order.id,
                    data: {
                      pickedUpByCustomer: true,
                    },
                  },
                })
                  .then(() => {
                    message.success('Zamówienie zostało zaktualizowane');
                    props.onRefresh();
                  })
                  .catch(() => {
                    message.error('Wystąpił nieoczekiwany błąd');
                  });
              }}
            />
          </Group>
        );
      },
    },
  ];

  const rows = props.orders;

  return (
    <div>
      <AgGridTable columnDefs={columns} rowData={rows} />

      {statusDialogOpened && (
        <OrderStatusDialog
          order={selectedOrder}
          onClose={hasChanged => {
            toggleStatusDialog();
            if (hasChanged) {
              props.onRefresh();
            }
          }}
        />
      )}
    </div>
  );
};
