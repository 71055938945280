import React from 'react';
import { message } from 'antd';
import { Button } from 'lib/ui';

/**
 * @TODO-AP this file should be moved somewhere - but I'm not sure where is proper folder for it
 *        a 'lib' maybe?
 *        to be discussed
 */

export const statusMessage = (messageToShow: JSX.Element, messageType: 'success' | 'error' = 'success'): void => {
  message.destroy();
  message[messageType](
    <>
      {messageToShow}
      <Button label="Zamknij" onClick={message.destroy} />
    </>,
    0,
  );
};
