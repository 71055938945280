import React, { FunctionComponent, useRef, useState } from 'react';
import { Tabs } from 'antd';
import connect from 'react-redux/es/connect/connect';
import { Drawer, DrawerProps } from 'lib/ui';

import { RestaurantForm } from './RestaurantForm';
import { RestaurantFormMenu } from './RestaurantFormMenu';
import { RestaurantFormAssets } from './RestaurantFormAssets';
import { RestaurantFormExtras } from './RestaurantFormExtras';

const enhance = connect();
// connect(
//   ({ restaurantsCuisineType: { data: restaurantsCuisineTypes } }) => ({
//     restaurantsCuisineTypes,
//   }),
//   ({ restaurantsCuisineType: { load: loadrestaurantsCuisineType } }) => ({
//     loadrestaurantsCuisineType,
//   }),
// ),

export interface RestaurantDrawerProps extends DrawerProps {
  restaurant?: any;
  activeTab?: string;
}

const RestaurantDrawerDefaultProps = {
  restaurant: {
    name: '',
    displayedName: '',
  },
};

const RestaurantDrawer: FunctionComponent<RestaurantDrawerProps> = enhance(
  ({ onClose, visible, restaurant, activeTab, restaurantsCuisineTypes }) => {
    // from store
    const [closable, setClosable] = useState(true);
    const restaurantName = restaurant && (restaurant.displayedName || restaurant.name);

    return (
      <Drawer
        displayCloseButton
        closeOnEsc={closable}
        onClose={onClose}
        visible={visible}
        title={`${restaurantName || 'Tworzenie nowej restauracji'}`}
        destroyOnClose
      >
        <Tabs defaultActiveKey={activeTab || '1'}>
          <Tabs.TabPane tab="Informacje" key="1">
            <RestaurantForm
              restaurant={restaurant}
              onClose={onClose}
              onModalToggle={visible => {
                setClosable(!visible);
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Menu" disabled={!restaurant} key="2">
            {restaurant && (
              <RestaurantFormMenu
                restaurant={restaurant}
                onModalToggle={visible => {
                  setClosable(!visible);
                }}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Zdjęcia i filmy" disabled={!restaurant} key="3">
            {restaurant && (
              <RestaurantFormAssets
                restaurant={restaurant}
                onModalToggle={visible => {
                  setClosable(!visible);
                }}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Dodatki" disabled={!restaurant} key="4">
            {restaurant && (
              <RestaurantFormExtras
                restaurant={restaurant}
                onModalToggle={visible => {
                  setClosable(!visible);
                }}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    );
  },
);

RestaurantDrawer.defaultProps = RestaurantDrawerDefaultProps;

export { RestaurantDrawer };
