import { styled } from '../styled-components';

export const ItemContainer = styled.div.attrs({ className: 'ItemContainer ' })`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${p => p.theme.spaceSmallX} ${p => p.theme.spaceSmall};
  line-height: 1rem;
  cursor: pointer;

  :hover {
    background: ${p => p.theme.backgroundHover};
  }
`;
export const ItemIconContainer = styled.div.attrs({
  className: 'ItemIconContainer ',
})`
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
// {
//   selected: boolean;
// }
export const ItemName = styled.div.attrs({ className: 'ItemName ' })`
  text-align: left;
  font-weight: ${p => (p.selected ? p.theme.fontWeightBold : p.theme.fontWeightDefault)};
`;
export const SimplePickerTitle = styled.div.attrs({
  className: 'SimplePickerTitle ',
})`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
