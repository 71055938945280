import axios, {
  AxiosRequestConfig,
  // AxiosResponse,
  // AxiosError,
  // AxiosInstance,
} from 'axios';
import { omit } from 'rambdax';
import { applyAuthorizationHeader } from './helpers/applyAuthorizationHeader';

export interface httpClientOptions extends AxiosRequestConfig {
  localStorageKeyWithToken?: string;
  onUnauthorized?: Function;
  debug?: boolean;
}
export interface httpClientRequestOptions extends AxiosRequestConfig {
  ommitToken?: boolean;
  debug?: boolean;
}

export const httpClient = (options: httpClientOptions) => {
  // console.log('%c httpClient ', 'background:yellow; color: black; padding:2px 20px;', options);

  const buildHeaders = (headers, ommitToken) => {
    const requestHeaders = applyAuthorizationHeader(
      {
        'X-Requested-With': 'XMLHttpRequest',
        ...headers,
      },
      options.localStorageKeyWithToken,
    );
    if (ommitToken) {
      delete requestHeaders.Authorization;
    }
    return requestHeaders;
  };

  const logResponse = response => {
    if (!options.debug && !response.config.debug) {
      return;
    }

    console.log(
      `%c ::: axios.interceptors.RESPONSE
      ${response.status} -> ${response.config.url}
      `,
      'color: green; padding:2px 1px;',
      response.data,
    );
  };

  const logRequest = request => {
    if (!options.debug && !request.debug) {
      return;
    }

    console.log(
      `%c ::: axios.interceptors.REQUEST
      ${request.baseUrl}${request.url}
      `,
      'color: green; padding:2px 1px;',
      request,
    );
  };

  const logError = response => {
    if (!options.debug && !response.config.debug) {
      return;
    }

    console.log(
      `%c ::: axios.interceptors.response.error
      ${response.status} -> ${response.config.url}
      `,
      'color: red; padding:2px 1px;',
      response.data,
    );
  };

  //  create axios instance
  const axiosConfig = omit(['localStorageKeyWithToken', 'onUnauthorized', 'debug'], options);
  const instance = axios.create({
    validateStatus: status => {
      return status < 500; // Reject only if the status code is greater than or equal to 500
    },
    ...axiosConfig,
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    config => {
      logRequest(config);
      return config;
    },
    error => {
      // console.log('interceptors.request.error', error);
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    response => {
      logResponse(response);

      if (response.status === 401 && options.onUnauthorized) {
        options.onUnauthorized(response);
      }

      if (response.status >= 300) {
        return Promise.reject(response);
      }

      return response.data;
    },
    error => {
      logError(error);
      // console.log('interceptors.response.error', error);
      // Do something with response error
      return Promise.reject(error);
    },
  );

  return {
    request<T = any>(requestOptions: httpClientRequestOptions): Promise<T> {
      const { ommitToken, debug, ...axiosRequestOptions } = requestOptions;

      // @ts-ignore
      return instance.request<T>({
        ...axiosRequestOptions,
        // url: buildUrl(requestOptions),
        headers: buildHeaders(requestOptions.headers, ommitToken),
        params: requestOptions.params,
        data: requestOptions.data,
      });
    },
    get<T = any>(url: string, requestOptions?: httpClientRequestOptions): Promise<T> {
      return this.request<T>({
        method: 'get',
        url,
        ...requestOptions,
      });
    },
    post<T = any>(url: string, requestOptions?: httpClientRequestOptions): Promise<T> {
      return this.request<T>({
        method: 'post',
        url,
        ...requestOptions,
      });
    },
    put<T = any>(url: string, requestOptions?: httpClientRequestOptions): Promise<T> {
      return this.request<T>({
        method: 'put',
        url,
        ...requestOptions,
      });
    },
    patch<T = any>(url: string, requestOptions?: httpClientRequestOptions): Promise<T> {
      return this.request<T>({
        method: 'patch',
        url,
        ...requestOptions,
      });
    },
    delete<T = any>(url: string, requestOptions?: httpClientRequestOptions): Promise<T> {
      return this.request<T>({
        method: 'delete',
        url,
        ...requestOptions,
      });
    },
  };
};
