import React from 'react';

import { styled } from '../../styled-components';

const GridBoxContainer = styled.div.attrs({
  className: 'GridBoxContainer',
})<{}>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${p => p.theme.space};

  @include above(lg) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${p => p.theme.spaceLarge};
  }

  @include above(lgx) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${p => p.theme.spaceLargeX};
  }
`;

const GridBox = props => <GridBoxContainer>{props.children}</GridBoxContainer>;

export { GridBox };
