import { Formik } from "formik";
import { ButtonSubmit, FormError, FormField } from "lib/forms";

import { Group } from "lib/ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { useUnmount } from "react-use";

import { AuthBasePage } from "../AuthBasePage";

const LoginPage = props => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.AuthModel);
  const isLoading = useSelector(state => state.loading.effects.AuthModel.login);
  const [displaySmsCode, toggleSmsCode] = useState<boolean>(false);

  useEffect(() => {
    if (auth.token) {
      dispatch({
        type: 'AuthModel/getUserInfo',
      });
    }
  }, [auth.token]);

  useEffect(() => {
    if (auth.smsToken) {
      toggleSmsCode(true);
    }
  }, [auth.smsToken]);

  useUnmount(() => {
    dispatch({ type: 'AuthModel/clearError' });
  });

  if (auth.isLoggedIn) {
    return <Redirect to={auth.nextUrl ? auth.nextUrl : '/'} />;
  }

  return (
    <AuthBasePage title="Logowanie">
      <Formik
        initialValues={{
          username: '',
          password: '',
          smsCode: null,
        }}
        validate={values => {
          const errors: {
            username?: string;
            password?: string;
            smsCode?: string;
          } = {};
          if (!values.username) {
            errors.username = 'Enter your username';
          }
          if (!values.password) {
            errors.password = 'Enter password';
          }
          return errors;
        }}
        onSubmit={(values, bag) => {
          dispatch({
            type: 'AuthModel/login',
            payload: {
              username: values.username,
              password: values.password,
              smsCode: values.smsCode,
            },
          }).then(data => {
            bag.setSubmitting(false);
          });
        }}
      >
        {formikProps => (
          <div>
            {auth.error && <FormError errors={auth.error} />}
            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <Group>
                  <FormField
                    name="username"
                    placeholder=""
                    label="Username"
                    disabled={isLoading || displaySmsCode}
                    onEnter={formikProps.handleSubmit}
                    {...formikProps}
                  />
                </Group>
                <Group>
                  <FormField
                    name="password"
                    placeholder=""
                    label="Password"
                    type="password"
                    disabled={isLoading || displaySmsCode}
                    onEnter={formikProps.handleSubmit}
                    {...formikProps}
                  />
                </Group>
                {displaySmsCode && (
                  <Group>
                    <FormField
                      name="smsCode"
                      placeholder=""
                      label="Kod SMS"
                      maxLength={4}
                      disabled={isLoading}
                      onEnter={formikProps.handleSubmit}
                      {...formikProps}
                    />
                  </Group>
                )}
              </Group>
              <div className={'mt-4'}>
                <ButtonSubmit
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  loading={isLoading || formikProps.isSubmitting}
                  label="Login"
                  onClick={formikProps.handleSubmit}
                  iconName="sign-in"
                />
              </div>
            </form>
          </div>
        )}
      </Formik>
      <div className="mt-3">
        <Group align="center">
          <p>
            Nie pamiętasz hasła? <Link to="/reset-password">Zresetuj hasło</Link>
          </p>
        </Group>
      </div>
    </AuthBasePage>
  );
};

export { LoginPage };
