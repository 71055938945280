import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

export interface RestaurantExtras {
  id: number;
  namePl: string;
  nameEn: string;
  isActive: boolean;
  defaultPriceChange: number;
  restaurant: number;
}

export const RestaurantExtrasModel = SimpleRestModel({
  collectionName: 'restaurantsExtras',
  HttpApi,
  getApiPath: (payload, action: string) => {
    switch (action) {
      case 'loadCollection':
      case 'createItem':
      default:
        return `restaurants/${payload.restaurantId}/extras/`;
      case 'loadItem':
      case 'updateItem':
      case 'deleteItem':
        return `restaurants/${payload.restaurantId}/extras/${payload.id}/`;
      case 'customCollectionAction':
        return `restaurants/${payload.restaurantId}/extras/${payload.action}/`;
      case 'customItemAction':
        return `restaurants/${payload.restaurantId}/extras/${payload.id}/${payload.action}/`;
    }
  },
});
