import React, { Component } from 'react';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;

export default class NumericEditor extends Component {

  public input: any = React.createRef();

  constructor(props) {
    super(props);

    this.state = this.createInitialState(props);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  createInitialState(props) {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
      startValue = '';
    } else if (props.charPress) {
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      startValue = props.value;
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: startValue,
      renderValue: startValue / 100,
      highlightAllOnFocus,
    };
  }

  afterGuiAttached() {
    this.input.current.focus();
  }

  getValue() {
    return this.state['value'];
  }

  isCancelBeforeStart() {
    return null;
  }

  onKeyDown(event) {
    if (this.actionKey(event) || this.deleteOrBackspace(event)
        || event.ctrlKey && event.keyCode === 65
        || event.ctrlKey && event.keyCode === 67
        || event.ctrlKey && event.keyCode === 88) {
      event.stopPropagation();
      return;
    }

    if (!this.isKeyPressedNumeric(event)) {
      if (event.preventDefault) event.preventDefault();
    }
  }

  actionKey(event) {
    return [37, 39, 16].indexOf(event.keyCode) > -1;
  }

  handleChange(event) {
    let value = event.target.value.toString();
    value = parseFloat(value).toFixed(2);
    this.setState({ value: value * 100, renderValue: event.target.value });
  }

  getCharCodeFromEvent(event) {
    event = event || window.event;
    return typeof event.which === 'undefined' ? event.keyCode : event.which;
  }

  isCharNumeric(charStr) {
    if (charStr === '.' && (!this.state['renderValue'].toString().includes('.'))) {
      return true;
    }
    return /\d/.test(charStr);
  }

  isKeyPressedNumeric(event) {
    const charCode = this.getCharCodeFromEvent(event);
    let charStr = event.key ? event.key : String.fromCharCode(charCode);
    if (charStr === ',') {
      charStr = '.';
    }
    return this.isCharNumeric(charStr);
  }

  render() {
    return <input ref={this.input} onKeyDown={e => this.onKeyDown(e)}
                  value={this.state['renderValue']}
                  onChange={this.handleChange}
                  style={{ width: '100%' }} />;
  }

  deleteOrBackspace(event) {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
  }
}
