/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Badge } from 'antd';
import { Button, ButtonVariant, Group, Spacer } from 'lib/ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { theme } from 'theme';

export interface OrdersPageNavigationProps {
  withRefreshButton?: boolean;
  withAddButton?: boolean;
  refreshing?: boolean;
  onRefresh?: () => void;
  onAdd?: () => void;
}

export const OrdersPageNavigation: React.FC<OrdersPageNavigationProps> = props => {
  const data = useSelector(state => state.currentOrders.dataWithExtras);

  if (!data) {
    return null;
  }

  return (
    <div>
      <Spacer size="small" />
      <Group align="space-between">
        <Group>
          <NavLink to={'/orders/'} activeClassName="active">
            <Button>
              Bieżące <Badge count={data.allCount} style={{ backgroundColor: theme.colorPrimaryLighter }} />
            </Button>
          </NavLink>
          <Spacer horizontal />
          <NavLink to={'/orders/withInvoice'} activeClassName="active">
            <Button>
              Faktury <Badge count={data.allWithInvoiceCount} style={{ backgroundColor: theme.colorPrimaryLighter }} />
            </Button>
          </NavLink>
          <Spacer horizontal />
          <NavLink to={'/orders/withDirectPickup'} activeClassName="active">
            <Button>
              Odbiór osobisty{' '}
              <Badge count={data.allWithDirectPickUp} style={{ backgroundColor: theme.colorPrimaryLighter }} />
            </Button>
          </NavLink>
        </Group>
        <Group align="right">
          {props.withRefreshButton && (
            <Button
              label="Odśwież"
              iconName="sync"
              loading={props.refreshing}
              onClick={() => {
                if (props.onRefresh) {
                  props.onRefresh();
                }
              }}
            />
          )}
          {props.withRefreshButton && (
            <Button
              label="Dodaj zamówienie"
              variant={ButtonVariant.Primary}
              onClick={() => {
                if (props.onAdd) {
                  props.onAdd();
                }
              }}
            />
          )}
        </Group>
      </Group>
      <Spacer />
    </div>
  );
};
