import { styled } from 'lib/ui';

const DayBox = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  cursor: pointer;
`;

export const Scheduled = styled(DayBox).attrs({ className: 'FullDay' })<{
  width?: string;
  marginLeft?: string;
  marginRight?: string;
}>`
  background: #139bd5;
  width: ${p => (p.width ? p.width : '100%')};
  margin-left: ${p => (p.marginLeft ? p.marginLeft : '')};
  margin-right: ${p => (p.marginRight ? p.marginRight : '')};
`;

export const Free = styled(DayBox).attrs({ className: 'FullDay' })``;

export const Off = styled(DayBox).attrs({ className: 'FullDay' })`
  background: #ccc;
`;

export const DayPaid = styled(DayBox).attrs({ className: 'DayPaid' })`
  background: #72ac03;
  cursor: default;
`;
