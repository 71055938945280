import React, { useEffect } from 'react';

import { PageLayout } from 'components';
import { CoreService } from 'services/CoreService';

export interface Auth403Props {
  path?: string;
}

export const Auth403: React.FC<Auth403Props> = props => {
  useEffect(() => {
    CoreService.sendAbuseEvent({
      eventUrl: props.path,
    });
  }, []);

  return (
    <PageLayout title="Brak dostępu">
      <div>Dostęp zabroniony</div>
    </PageLayout>
  );
};
