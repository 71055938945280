import React, { FunctionComponent, ReactNode, useState } from 'react';

import { Icon } from '../Icon/Icon';
import { Tooltip } from '../Tooltip/Tooltip';
import { styled } from '../../styled-components';

export interface HintProps {
  children?: ReactNode;
}

const HintContainer = styled.div`
  cursor: help;
`;

export const Hint: FunctionComponent<HintProps> = props => {
  // we need to manually set tooltip visible because somehow tooltip does not
  // work for somme components, for example Icon
  // TODO: maybe it could be investigate later?
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <HintContainer onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
      <Tooltip text={props.children} visible={tooltipVisible}>
        <Icon name="info-circle" />
      </Tooltip>
    </HintContainer>
  );
};
