import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, ButtonVariant, Divider, Group } from 'lib/ui';
import { Formik } from 'formik';
import { ButtonSubmit, FormField, FormError } from 'lib/forms';
import { Events } from 'lib/services';

import { AuthBasePage } from '../AuthBasePage';

const enhance = connect(
  state => ({
    auth: state.AuthModel,
    loading: state.loading.effects.AuthModel.resetPassword,
  }),
  state => ({ AuthModel: { resetPassword, clearError } }) => ({
    resetPassword,
    clearError,
  }),
);

const ResetPasswordPage = enhance(props => {
  useEffect(() => {
    Events.on('AuthModel.resetPassword.success', () => {
      // @todo - display message
    });

    return () => {
      Events.off('AuthModel.resetPassword.success', true);
      props.clearError();
    };
  });

  const { loading } = props;

  return (
    <AuthBasePage title="Reset hasła">
      <Formik
        initialValues={{
          email: '',
        }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Wpisz adres e-mail';
          }
          return errors;
        }}
        onSubmit={(values, bag) => {
          props.resetPassword(values).then(() => {
            bag.setSubmitting(false);
          });
        }}
      >
        {formikProps => (
          <div>
            {props.auth.error && <FormError errors={props.auth.error} />}
            <form onSubmit={formikProps.handleSubmit}>
              <Group vertical>
                <Group>
                  <FormField name="email" placeholder="john@example.com" label="Adres e-mail" {...formikProps} />
                </Group>
              </Group>
              <div className={'mt-5'}>
                <ButtonSubmit
                  disabled={formikProps.isSubmitting || !formikProps.isValid || loading}
                  loading={loading || formikProps.isSubmitting}
                  label="Resetuj hasło"
                  onClick={formikProps.handleSubmit}
                  iconName="key"
                />
              </div>
            </form>
          </div>
        )}
      </Formik>
      <Divider hidden />
      <Group align="center">
        <Button variant={ButtonVariant.Plain}>
          <Link to="/login">Wróć do strony logowania</Link>
        </Button>
      </Group>
    </AuthBasePage>
  );
});

export { ResetPasswordPage };
