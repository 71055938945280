import { CompanyModal } from 'components';
import { Button, Group, Spacer, styled } from 'lib/ui';
import { CompanyView } from 'modules/Companies/components/CompanyView';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormState } from 'modules/Orders/OrderFormState';
import { path } from 'rambdax';
import React, { FunctionComponent, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useObservable, useToggle } from 'react-use';

export interface OrderFormCompanyProps {
  clientId?: number | string;
}

const OrderFormCompanyDefaultProps = {};

const OrderFormCompanyContainer = styled.div.attrs({
  className: 'OrderFormCompanyContainer',
})``;
const CompaniesList = styled.div.attrs({
  className: 'CompaniesList',
})`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
const CompaniesListItem = styled.div.attrs({
  className: 'CompaniesListItem',
})`
  width: calc(50% - ${p => p.theme.spaceSmall});
  min-width: 200px;
  max-width: 250px;
  margin: 0 ${p => p.theme.spaceSmall} ${p => p.theme.spaceSmall} 0;
`;

const ChosenCompanyItem = styled.div.attrs({
  className: 'ChosenCompanyItem',
})`
  width: 100%;
  margin: 0;
`;

const CompanyChooseInformation = styled.div.attrs({
  className: 'CompanyChooseInformation',
})`
  padding: ${p => p.theme.spaceSmall};
  background-color: red;
  border-radius: 4px;
  margin-bottom: ${p => p.theme.space};
`;

const CompanyChoosenInformation = styled.div.attrs({
  className: 'CompanyChoosenInformation',
})`
  padding: ${p => p.theme.spaceSmall};
  background-color: ${p => p.theme.colorDirtyWhite}
  border-radius: 4px;
  margin-bottom: ${p => p.theme.space};
`;




const enhance = connect(
  ({ companies, loading }) => ({
    companiesStore: companies,
    loading,
  }),
  ({ companies }) => ({
    companies,
  }),
);

export const OrderFormCompany: FunctionComponent<OrderFormCompanyProps> = enhance(props => {
  const dispatch = useDispatch();
  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());
  const clientId = props.clientId || path('client.id', orderState);

  //  form company visible
  const [formCompanyVisible, toggleFormCompany] = useToggle(false);
  const [addingNewCompany, toggleAddingNewCompany] = useToggle(false);

  useEffect(() => {
    //  load companies (search)
    if (clientId) {
      dispatch({
        type: 'companies/loadCollection',
        payload: {
          params: {
            customer: clientId,
            limit: 99,
          },
        },
      });
    }
  }, [clientId]);

  if (!orderState) {
    return null;
  }

  return (
    <OrderFormCompanyContainer>
      {formCompanyVisible && (
        <CompanyModal
          title={'Dane do faktury'}
          open
          value={addingNewCompany ? null : orderState.company}
          clientId={orderState.client && orderState.client.id}
          onCancel={() => toggleFormCompany()}
          onSave={company => {
            toggleFormCompany(false);
            toggleAddingNewCompany(false);
            OrderFormService.setState({
              company: company.values,
            });
          }}
        />
      )}
      {!formCompanyVisible && orderState.company && (
        <Group vertical>
          <Group>
          <CompanyChoosenInformation>
            <h3>Wybrano firmę do faktury</h3>
          </CompanyChoosenInformation>
          </Group>
          <ChosenCompanyItem>
            <CompanyView company={orderState.company} selected={true} />
          </ChosenCompanyItem>
          <Group>
            <Button
              label="Zmień dane wybranej firmy"
              onClick={() => {
                toggleAddingNewCompany(false);
                toggleFormCompany();
              }}
            />
            <Button
              label="Wybierz inną firmę"
              onClick={() => {
                OrderFormService.setState({
                  company: null,
                });
              }}
            />
          </Group>
        </Group>
      )}
      {!formCompanyVisible && !orderState.company && (
        <>
          <CompaniesList>
            <Group>
              <CompanyChooseInformation>
                <h3 style={{color: 'white'}}>Zapytaj o fakturę</h3>
                <p style={{color: 'white', padding: '0px', margin: '10px 0px 0px'}}>Kliknij na firmę klienta lub dodaj nową jeśli klient chce fakturę do zamówienia.</p>
              </CompanyChooseInformation>
            </Group>
            {props.companiesStore.data.map(company => (
              <CompaniesListItem key={company.id}>
                <CompanyView
                  tiny
                  clickable
                  selected={orderState.company ? orderState.company.id === company.id : false}
                  company={company}
                  onClick={() => {
                    OrderFormService.setState({
                      company,
                    });
                  }}
                />
              </CompaniesListItem>
            ))}
          </CompaniesList>
        </>
      )}
      {!formCompanyVisible && !orderState.company && (
        <>
          <Spacer />
          <Group>
            <Button
              label="Dodaj nową firmę"
              onClick={() => {
                toggleFormCompany(true);
                toggleAddingNewCompany(true);
              }}
            />
          </Group>
        </>
      )}
    </OrderFormCompanyContainer>
  );
});

OrderFormCompany.defaultProps = OrderFormCompanyDefaultProps;
