import React from 'react';
import { Button, ButtonVariant, Group } from 'lib/ui';
import { Link } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { PageLayout } from 'components';

const enhance = connect(
  ({ AuthModel: { isLoggedIn } }) => ({
    isLoggedIn,
  }),
  ({ AuthModel: { logout } }) => ({
    logout,
  }),
);

const HomePage = enhance(props => {
  // console.log('HomePage props', props);
  return (
    <PageLayout title="Pulpit">
      <Group>
        {!props.isLoggedIn && (
          <React.Fragment>
            <Button variant={ButtonVariant.Primary}>
              <Link to="/login">Login</Link>
            </Button>
            <Button>
              <Link to="/register">Register</Link>
            </Button>
          </React.Fragment>
        )}
        {props.isLoggedIn && <Button onClick={props.logout} variant={ButtonVariant.Default} label="Logout" />}
      </Group>
    </PageLayout>
  );
});

export { HomePage };
