import { styled } from 'lib/ui';

export const OrderMark = styled.div.attrs({ className: 'OrderMark' })`
  padding: 0 2px;
  font-size: 1.1em;
  margin-right: 2px;

  &:hover {
    background-color: ${p => p.theme.colorLightGrey};
  }
`;
