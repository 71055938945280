import { Price, Spacer, styled, ValueOrDash } from 'lib/ui';
import { OrderModel } from 'modules/Orders/models/order.model';
import { getOrderSetAmount } from 'modules/Orders/utils/getOrderSetAmount';
import { getOrderSum } from 'modules/Orders/utils/getOrderSum';
import { getOrderSumWithoutDiscount } from 'modules/Orders/utils/getOrderSumWithoutDiscount';
import React from 'react';

export interface BasketForOrderProps {
  order: OrderModel;
  withDirectPickUp?: boolean;
}

const BasketForOrderContainer = styled.div.attrs({ className: 'BasketForOrderContainer' })`
  margin-top: ${p => p.theme.space};
  border: 1px solid ${p => p.theme.colorGrey};
  padding: ${p => p.theme.space} ${p => p.theme.spaceSmall};
  width: 80%;
`;

const BasketForOrderSetsSumBox = styled.div.attrs({ className: 'BasketForOrderSetsSumBox' })`
  background-color: ${p => p.theme.colorDirtyWhite};
  margin: ${p => p.theme.spaceSmall} -${p => p.theme.spaceSmall} -${p => p.theme.space} -${p => p.theme.spaceSmall};
  padding: ${p => p.theme.spaceLarge} ${p => p.theme.space};
`;

const BasketForOrderSumBox = styled.div.attrs({ className: 'BasketForOrderSumBox' })`
  background-color: ${p => p.theme.colorPrimaryLightest};
  margin: ${p => p.theme.spaceSmall} -${p => p.theme.spaceSmall} -${p => p.theme.space} -${p => p.theme.spaceSmall};
  padding: ${p => p.theme.spaceLarge} ${p => p.theme.space};
`;

const BasketForOrderMealNote = styled.div.attrs({ className: 'BasketForOrderMealNote' })`
  margin-left: ${p => p.theme.space};
  padding: ${p => p.theme.spaceSmall} ${p => p.theme.spaceSmall};
  font-size: 0.9em;
  font-style: italic;
  white-space: pre-wrap;
`;

const BasketForOrderMealExtras = styled.div.attrs({ className: 'BasketForOrderMealNote' })`
  margin-left: ${p => p.theme.space};
  padding: ${p => p.theme.spaceSmall} ${p => p.theme.spaceSmall};
  font-size: 0.9em;
`;

export const BasketForOrder: React.FC<BasketForOrderProps> = props => {
  const { order } = props;

  return (
    <BasketForOrderContainer>
      {order.basket.sets.map(set => {
        return (
          <div key={`${set.name}${set.meals.length}`}>
            <h4>{set.name}</h4>
            {set.meals.map(setMeal => {
              return (
                <React.Fragment key={`${set.name}${setMeal.counter}${setMeal.meal.id}`}>
                  <Spacer size="small" />
                  <div className="u-flex u-flex-space-between">
                    <div className="u-flex">
                      <span>{setMeal.counter}x </span>
                      <Spacer size="smallX" horizontal />
                      <span>{setMeal.meal.namePl}</span>
                    </div>
                    <Price amount={setMeal.meal.price} />
                  </div>

                  {setMeal.note && (
                    <>
                      <BasketForOrderMealNote>{setMeal.note}</BasketForOrderMealNote>
                    </>
                  )}

                  {setMeal.meal.extras.map(mealExtraItem => {
                    // return mealExtra.map(mealExtraItem => (
                    return (
                      <BasketForOrderMealExtras
                        key={`${set.name}${setMeal.counter}${setMeal.meal.id}${mealExtraItem.counter}${mealExtraItem.id}`}
                      >
                        <div className="u-flex u-flex-space-between">
                          <div className="u-flex">
                            <span>{mealExtraItem.counter}x </span>
                            <Spacer size="smallX" horizontal />
                            <span>{mealExtraItem.namePl}</span>
                          </div>
                          <Price amount={mealExtraItem.priceChange} />
                        </div>
                      </BasketForOrderMealExtras>
                    );
                    // ));
                  })}
                </React.Fragment>
              );
            })}
            <Spacer size="small" />
          </div>
        );
      })}

      <BasketForOrderSetsSumBox>
        {order.basket.sets.map(set => (
          <React.Fragment key={`${set.name}${set.meals.length}`}>
            <Spacer size="small" />
            <strong className="u-flex u-flex-space-between">
              <span>{set.name}</span>
              <span>
                <Price amount={getOrderSetAmount(set)} />
              </span>
            </strong>
          </React.Fragment>
        ))}

        {!props.withDirectPickUp && (
          <>
            <Spacer size="small" />
            <strong className="u-flex u-flex-space-between">
              <span>Dostawa:</span>
              <span>
                <Price amount={order.deliveryAddress.deliveryCost} />
              </span>
            </strong>
          </>
        )}
        <Spacer size="small" />
        <strong className="u-flex u-flex-space-between">
          <span>Podsuma:</span>
          <span>
            <Price amount={getOrderSumWithoutDiscount(order)} />
          </span>
        </strong>

        <Spacer size="small" />
        <strong className="u-flex u-flex-space-between">
          <span>
            Rabat <ValueOrDash value={order.basket.customerData.discount} />
            {order.basket.customerData.discount > 0 && '%'}
          </span>
          <span>
            <Price amount={order.basket.customerData.discountAmount != null ? order.basket.customerData.discountAmount : 0} />
          </span>
        </strong>
      </BasketForOrderSetsSumBox>

      <BasketForOrderSumBox>
        <strong className="u-flex u-flex-space-between">
          <span>Razem:</span>
          <span>
            <Price amount={getOrderSum(order)} />
          </span>
        </strong>
        <Spacer size="small" />
        <strong className="u-flex u-flex-space-between">
          <span>{order.basket.customerData.balance >= 0 ? 'Nadpłata' : 'Niedopłata'}:</span>
          <span>
            <Price amount={order.basket.customerData.balance} />
          </span>
        </strong>
        <Spacer size="small" />
        <strong className="u-flex u-flex-space-between">
          <span>Napiwek dla kierowcy:</span>
          <span>
            <Price amount={order.driverExtraBonus} />
          </span>
        </strong>
        <Spacer size="small" />
        <strong className="u-flex u-flex-space-between">
          <span>Do zapłaty:</span>
          <span>
            <Price amount={getOrderSum(order) - order.basket.customerData.balance} />
          </span>
        </strong>
      </BasketForOrderSumBox>
    </BasketForOrderContainer>
  );
};
