/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Events } from 'lib/services';
import { DialogService } from 'lib/services/DialogService';

import {
  AgGridTable,
  Button,
  ButtonSize,
  ButtonVariant,
  formattedTime,
  Group,
  Icon,
  IconColor,
  IconFa,
  IconSize,
  Price,
  SimplePickerDialog,
  styled,
  TimeLeft,
  Tooltip,
  ValueOrDash,
} from 'lib/ui';
import { Driver } from 'modules/Drivers';
import { OrderModel } from 'modules/Orders/models/order.model';
import { OrderDrivers } from 'modules/Orders/OrderDriversModel';
import { getDeliveryAddressFromOrder, getRestaurantAddressFromOrder } from 'modules/Orders/utils/order.getters';
import { OrderMessageDialog } from 'pages/OrdersPage/components/OrderMessageDialog/OrderMessageDialog';
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { theme } from 'theme';
import { OrderMark } from '../OrderMark';
import { getLabelForStatus } from './getLabelForStatus';

import { OrderStatusDialog } from './OrderStatusDialog';

export interface OrdersTableProps {
  children?: ReactNode;
  onOrderDetails?: (order: any) => void;
  refreshOrders?: () => void;
  orders: OrderModel[];
  drivers: OrderDrivers[];
  allDrivers: Driver[];
  onDriverSelect: (driver: any, order: any, customizeTasks: boolean) => void;
  onOrderStatusChange: () => void;
}

const DriverItem = styled.div.attrs({ className: 'DriverItem' })`
  padding: 0.5rem 0;
  flex-grow: 1;
`;

export const highlightOrders$ = new Subject<string[]>();

export const OrdersTable: FunctionComponent<OrdersTableProps> = props => {
  const [selectedOrder, setSelectedOrder] = useState<undefined | OrderModel>();
  // const [columns, setColumns] = useState<any>();
  const [messageDialogOpened, toggleMessageDialog] = useToggle(false);
  const [statusDialogOpened, toggleStatusDialog] = useToggle(false);

  // states for events between table and map
  const highlightedOrderId = useRef();
  const [highlightedColumns, setHighlightedColumns] = useState<string[]>([]);

  const availableDrivers = [...props.drivers];

  //  set columns
  const columns = [
    {
      headerName: 'Restauracja',
      field: 'id',
      cellRendererFramework: rowProps => {
        const restaurantNames = rowProps.data.restaurants
          ? rowProps.data.restaurants.map(r => r.displayedName).join(', ')
          : '';
        return (
          <Tooltip text={restaurantNames}>
            <div
              className="pointer"
              onClick={() => {
                if (props.onOrderDetails) {
                  props.onOrderDetails(rowProps.data);
                }
              }}
            >
              {rowProps.data.originalGross && <Icon size={IconSize.Small} name="edit" fa={IconFa.Fal} />}
              <span className="text-truncate ml-1">{restaurantNames}</span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: ' ',
      field: 'id-actions',
      cellRendererFramework: props => {
        return (
          <div data-bp="grid" style={{ marginTop: '5px' }}>
            <Button
              size={ButtonSize.Small}
              iconName="envelope"
              iconColor={
                props.data.sentNotifications && props.data.sentNotifications.length
                  ? IconColor.Green
                  : IconColor.Default
              }
              onClick={() => {
                setSelectedOrder(props.data);
                toggleMessageDialog();
              }}
            />
          </div>
        );
      },
      width: 80,
    },
    {
      headerName: 'Adres dostawy',
      field: 'deliveryAddress',
      cellRendererFramework: props => {
        const order = props.data;
        const address = order.withDirectPickUp
          ? getRestaurantAddressFromOrder(order)
          : getDeliveryAddressFromOrder(order);
        return <Tooltip text={address}>{address}</Tooltip>;
      },
    },
    {
      headerName: 'Kwota',
      field: 'gross',
      cellRendererFramework: props => {
        if (props.data.originalGross) {
          return (
            <Group>
              <Price old amount={props.data.originalGross} />
              <Price amount={props.data.gross} />
            </Group>
          );
        }
        return <Price amount={props.data.gross} />;
      },
    },
    {
      headerName: 'Czas dost.',
      cellRendererFramework: props => {
        const row = props.data;
        return (
          <Group>
            <span>{row.deliveryDate ? formattedTime(row.deliveryDate) : '-'}</span>
            <TimeLeft targetDatetime={row.deliveryDate} colorGreen="#72ac03" colorRed="#cf1e2f" withMinusPrefix />
          </Group>
        );
      },
    },
    {
      headerName: ' ',
      cellRendererFramework: props => {
        const order = props.data;
        const payuStatus = order.paymentData.paymentStatus || null;
        let payuStatusColor = '';
        let payuStatusText = '';

        switch (payuStatus) {
          case 'paid':
            payuStatusColor = theme.colorGreen;
            payuStatusText = 'Zamówienie opłacone';
            break;
          case 'partialPaid':
            payuStatusColor = 'violet';
            payuStatusText = 'Zamówienie opłacone częściowo';
            break;
          case 'unpaid':
            payuStatusColor = theme.colorRed;
            payuStatusText = 'Zamówienie nieopłacone';
            break;
          case 'CANCELED':
            payuStatusColor = theme.colorRed;
            payuStatusText =
              'Płatność została anulowana. Płacący nie został obciążony (nie nastąpił przepływ środków między płacącym a Payu).';
            break;
          case 'PENDING':
            payuStatusColor = theme.colorPrimary;
            payuStatusText = 'Płatność jest w trakcie rozliczenia.';
            break;
          case 'COMPLETED':
            payuStatusColor = theme.colorGreen;
            payuStatusText = 'Płatność została zaakceptowana w całości. Środki są dostępne do wypłaty.';
            break;
          case 'WAITING_FOR_CONFIRMATION':
            payuStatusColor = 'violet';
            payuStatusText = 'System PayU oczekuje na akcje ze strony sprzedawcy w celu wykonania płatności. ';
            break;
          default:
            break;
        }

        return (
          <div data-bp="flex">
            {order.paymentData && order.paymentData.onlinePaymentError && (
              <Tooltip text={'Błąd płatności online'}>
                <OrderMark>
                  <Icon name={'exclamation-triangle'} size={IconSize.SmallX} fa={IconFa.Fas} color={IconColor.Red} />
                </OrderMark>
              </Tooltip>
            )}
            {payuStatus && (
              <Tooltip text={payuStatusText}>
                <OrderMark>
                  <Icon name={'circle'} size={IconSize.SmallX} fa={IconFa.Fas} color={payuStatusColor} />
                </OrderMark>
              </Tooltip>
            )}
            {order.withDirectPickUp && (
              <OrderMark>
                <Icon name={'utensils'} size={IconSize.SmallX} fa={IconFa.Fas} />
              </OrderMark>
            )}
            {order.customerTimeSet && (
              <OrderMark>
                <Icon name={'clock'} size={IconSize.SmallX} />
              </OrderMark>
            )}
            {order.customer && order.customer.newCustomer && <OrderMark>*</OrderMark>}
            {order.paymentData && (
              <Tooltip text={order.paymentData.namePl}>
                <OrderMark>{order.paymentData.displayedName || '?'}</OrderMark>
              </Tooltip>
            )}
            {order.invoiceData && <OrderMark>F</OrderMark>}
            {order.basket.extraExtras.length > 0 && <OrderMark>N</OrderMark>}
            {order.privateNotes && (
              <OrderMark>
                <Tooltip text={order.privateNotes}>
                  <OrderMark>U</OrderMark>
                </Tooltip>
              </OrderMark>
            )}
          </div>
        );
      },
    },
    {
      headerName: 'Kierowca',
      cellRendererFramework: _props => {
        const order = _props.data;

        if (order.withDirectPickUp) {
          return <ValueOrDash value={null} />;
        }

        if (!order.driver) {
          return (
            <Button
              size={ButtonSize.Small}
              label="wybierz"
              onClick={() => {
                DialogService.open(SimplePickerDialog, {
                  title: 'Wybierz kierowcę',
                  multiple: false,
                  closeOnSave: true,
                  disableDefaultClickOnItem: true,
                  onSave: () => {},
                  items: order.availableDrivers,
                  renderItem: item => {
                    return (
                      <DriverItem>
                        <div data-bp="grid">
                          <div data-bp="1">
                            {item.orders && item.orders.length > 0 && <strong>({item.orders.length})</strong>}
                          </div>
                          <div data-bp="7">{item.name}</div>
                          <div data-bp="4">
                            <Group>
                              <Button
                                label="Przypisz"
                                variant={ButtonVariant.Primary}
                                size={ButtonSize.Small}
                                onClick={$event => {
                                  $event.preventDefault();
                                  $event.stopPropagation();
                                  DialogService.closeDialog();
                                  props.onDriverSelect(item, order, false);
                                }}
                              />
                              <Button
                                label="kolejność"
                                size={ButtonSize.Small}
                                onClick={$event => {
                                  $event.preventDefault();
                                  $event.stopPropagation();
                                  DialogService.closeDialog();
                                  props.onDriverSelect(item, order, true);
                                }}
                              />
                            </Group>
                          </div>
                        </div>
                      </DriverItem>
                    );
                  },
                });
              }}
            />
          );
        }

        return <span>{order.driver.name}</span>;
      },
    },
    {
      headerName: 'Status',
      cellRendererFramework: props => {
        const order = props.data;

        return (
          <Button
            size={ButtonSize.Small}
            label={getLabelForStatus(order.status)}
            onClick={() => {
              setSelectedOrder(order);
              toggleStatusDialog();
            }}
          />
        );
      },
      minWidth: 210,
    },
  ];

  //  listen to events from orders table or map
  useEffect(() => {
    const sub = highlightOrders$
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        debounceTime(100),
      )
      .subscribe(data => {
        setHighlightedColumns(data);
      });
    return () => sub.unsubscribe();
  }, [setHighlightedColumns]);

  //  prepare data for table rows
  const rows = props.orders.map(order => ({
    ...order,
    availableDrivers: [...availableDrivers],
    isHighlighted: highlightedColumns.includes(order.id),
  }));

  if (!columns) {
    return <span>Brak danych</span>;
  }

  const rowClassRules = {
    // apply blue background for delivered orders
    'order-delivered': params => params.data.status === 30,
    'order-highlighted': params => params.data.isHighlighted,
  };

  return (
    <>
      <AgGridTable
        columnDefs={columns}
        rowData={rows}
        getRowNodeId={row => row.id}
        rowClassRules={rowClassRules}
        onCellMouseOver={e => {
          // console.log('e', e);
          const { id, driver } = e.data;
          if (!driver && id !== highlightedOrderId.current) {
            highlightedOrderId.current = id;
            Events.emit('map.displayOrderLines', {
              id,
              restaurantIds: e.data.restaurants.map(r => r.id),
            });
          } else if (driver) {
            Events.emit('map.displayDriverTasks', {
              id,
              driverId: driver.id,
            });
            // const driverData = props.drivers.find(_driver => _driver.id === driver.id);
            // if (driverData) {
            //   highlightOrders$.next(driverData.orders.map(order => order.id));
            // }
          }
        }}
        onCellMouseOut={e => {
          highlightedOrderId.current = undefined;
          Events.emit('map.displayOrderLines', undefined);
          highlightOrders$.next([]);
        }}
      />

      {messageDialogOpened && (
        <OrderMessageDialog
          order={selectedOrder}
          onMessageSend={() => {
            if (props.refreshOrders) {
              props.refreshOrders();
            }
          }}
          onClose={() => {
            toggleMessageDialog(false);
          }}
        />
      )}

      {statusDialogOpened && selectedOrder && (
        <OrderStatusDialog
          order={selectedOrder}
          onClose={hasChanged => {
            toggleStatusDialog();
            if (hasChanged) {
              props.onOrderStatusChange();
            }
          }}
        />
      )}
    </>
  );
};
