import { Checkbox, message, Typography } from 'antd';
import { Formik } from 'formik';
import React, { FunctionComponent, useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';

import { Group, Spacer } from 'lib/ui';
import { ButtonSubmit, FieldError, FormError, FormTextareaField } from 'lib/forms';
import { Driver } from 'modules/Drivers';

import { Events } from 'lib/services';
import { statusMessage } from 'modules/MessageHelper';
import validationSchema from './DriversMessageForm.ValidationSchema';

const enhance = connect(
  ({ drivers, driversMessages }) => ({
    driversState: drivers,
    driversMessagesState: driversMessages,
  }),
  ({ drivers, driversMessages }) => ({
    driversStore: drivers,
    driversMessagesStore: driversMessages,
  }),
);

interface DriversMessageFormProps {
  onClose: () => void;
  driversMessage?: any;
}

/**
 * Manage the checkboxes values
 *
 * @param currentIds number[] - ids of currently selected items
 * @param toggledId number - id of currently toggled item
 * @param checked boolean - true if checbox checked
 */
const getPreparedDriversCheckboxesValues = (currentIds: number[], toggledId: number, checked: boolean): number[] => {
  let preparedIds: number[] = [];

  if (Array.isArray(currentIds)) {
    preparedIds = [...currentIds.filter(id => id > 0)];
  }

  if (checked) {
    preparedIds = [...preparedIds, toggledId];
  } else {
    preparedIds = [...currentIds.filter(id => id !== toggledId)];
  }

  return preparedIds;
};

const DriversMessageForm: FunctionComponent<DriversMessageFormProps> = enhance(props => {
  const driversMessageData = props.driversMessage || {};
  const driversData = props.driversState.data;

  // load all available drivers to choose recipients with the checkboxes
  useEffect(() => {
    props.driversStore.loadCollection();
  }, [props.driversStore]);

  useEffect(() => {
    message.destroy();

    Events.on('Model.driversMessages.createItem.success', data => {
      statusMessage(<p>Wiadomość została zapisana</p>);
      props.onClose();
    });

    Events.on('Model.driversMessages.createItem.error', data => {
      statusMessage(<p>Wystąpił nieoczekiwany błąd</p>, 'error');
      props.onClose();
    });

    return () => {
      Events.off(['Model.driversMessages.createItem.success', 'Model.driversMessages.createItem.error'], true);
    };
  }, [props]);

  /**
   * this method is added here temporary
   *
   * @TODO-AP currently, API returns errors messages in various ways
   *          errors structure in the API responses must be unified
   *          it must be discussed and implemented and then this method will no longer be needed
   */
  const isFirstArrayElementString = errors => errors[0] && typeof errors[0] === 'string';

  return (
    <>
      <Formik
        initialValues={{ ...driversMessageData }}
        validationSchema={validationSchema}
        onSubmit={(values: any, actions) => {
          /**
           * CREATE driversMessage...
           */
          message.loading('Dodaję...', 0);
          const payload = {
            data: { ...values },
          };
          props.driversMessagesStore.createItem(payload).finally(() => actions.setSubmitting(false));
        }}
        enableReinitialize
      >
        {formikProps => {
          /**
           * workaround: prepare errors reported in API response
           * (if there are any errors...)
           *
           * @TODO-AP unify the format/structure of the errors returned from API and get rid of such workarounds
           */
          let driversErrors: any = [];
          let messageErrors = {};
          if (props.driversMessagesState.errors) {
            if (typeof props.driversMessagesState.errors === 'string') {
              driversErrors = [{ nonFieldErrors: props.driversMessagesState.errors }];
            } else {
              messageErrors = { ...props.driversMessagesState.errors };

              if (Object.keys(messageErrors).includes('drivers')) {
                driversErrors = [...props.driversMessagesState.errors.drivers];
              }
            }
          }

          return (
            <>
              {/* global form errors  */}
              {!isFirstArrayElementString(driversErrors) && driversErrors.map(error => <FormError errors={error} />)}
              {isFirstArrayElementString(driversErrors) && <FormError errors={{ drivers: driversErrors }} />}
              {<FormError errors={messageErrors} />}
              {/* /global form errors  */}

              <form onSubmit={formikProps.handleSubmit}>
                <Group vertical>
                  {/* checkboxes with drivers */}
                  {driversData.map((driver, index) => {
                    const driverData: Driver = driver;
                    return (
                      <span key={`driverMessage_${index}`}>
                        <Checkbox
                          checked={formikProps.values.drivers && formikProps.values.drivers.includes(driverData.id)}
                          onChange={e =>
                            formikProps.setFieldValue(
                              `drivers`,
                              getPreparedDriversCheckboxesValues(
                                formikProps.values.drivers,
                                driverData.id,
                                e.target.checked,
                              ),
                            )
                          }
                          name="drivers"
                          value={`${driverData.id}`}
                        />
                        <Typography.Text
                          className={'ml-2'}
                          strong
                        >{`${driverData.user.firstName} ${driverData.user.lastName}`}</Typography.Text>
                      </span>
                    );
                  })}
                  {/* select/unselect All */}
                  <Group>
                    {driversData.length > 2 && (
                      <>
                        {driversData.length &&
                          (!formikProps.values.drivers || formikProps.values.drivers.length < driversData.length) && (
                            <a onClick={() => formikProps.setFieldValue('drivers', driversData.map(d => d.id))}>
                              Zaznacz wszystkich
                            </a>
                          )}
                        {driversData.length &&
                          (formikProps.values.drivers && formikProps.values.drivers.length === driversData.length) && (
                            <a onClick={() => formikProps.setFieldValue('drivers', [])}>Odznacz wszystkich</a>
                          )}
                      </>
                    )}
                    {driversData.length > 1 && (
                      <>
                        {driversData.length &&
                          (!formikProps.values.drivers || formikProps.values.drivers.length < driversData.length) && (
                            <a className={'ml-2'} onClick={() => formikProps.setFieldValue('drivers', driversData.filter(d => d.isWorkingToday).map(dr => dr.id))}>
                              Zaznacz wszystkich dziś pracujących
                            </a>
                          )}
                      </>
                    )}
                  </Group>

                  {/* drivers checkboxes error */}
                  {formikProps.errors && formikProps.errors.drivers && (
                    <FieldError error={formikProps.errors.drivers} />
                  )}
                  <FormTextareaField name="content" label="Treść" {...formikProps} />
                </Group>

                <Spacer />

                <ButtonSubmit
                  disabled={
                    !formikProps.dirty ||
                    formikProps.isSubmitting ||
                    Object.keys(formikProps.errors).length > 0 ||
                    !formikProps.values.drivers
                  }
                  loading={formikProps.isSubmitting}
                  label="Zapisz"
                  onClick={formikProps.handleSubmit}
                  iconName="save"
                />
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
});

export { DriversMessageForm };
