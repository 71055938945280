type ValidDateType = string | Date | number;

export const ensureDateObject = (date: ValidDateType) => {
  if (typeof date === 'string' || typeof date === 'number') {
    return new Date(date);
  }
  return date;
};

export const formattedDate = (date: ValidDateType) =>
  new Intl.DateTimeFormat('pl-PL', {
    formatMatcher: 'basic',
    month: 'long',
    day: '2-digit',
    weekday: 'long',
    year: 'numeric',
  }).format(ensureDateObject(date));

export const formattedShortDateWithWeekDay = (date: ValidDateType) =>
  new Intl.DateTimeFormat('pl-PL', {
    formatMatcher: 'basic',
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(ensureDateObject(date));

export const formattedShortDate = (date: ValidDateType) =>
  new Intl.DateTimeFormat('pl-PL', {
    formatMatcher: 'basic',
    month: '2-digit',
    day: '2-digit',
  }).format(ensureDateObject(date));

export const formattedShortDateWithYear = (date: ValidDateType) =>
  new Intl.DateTimeFormat('pl-PL', {
    formatMatcher: 'basic',
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(ensureDateObject(date));

export const formattedShortDateWithWeekDayName = (date: ValidDateType) =>
  new Intl.DateTimeFormat('pl-PL', {
    formatMatcher: 'basic',
    day: '2-digit',
    weekday: 'long',
  }).format(ensureDateObject(date));

export const formattedTime = (date: ValidDateType) =>
  new Intl.DateTimeFormat('pl-PL', {
    formatMatcher: 'basic',
    hour: '2-digit',
    minute: '2-digit',
  }).format(ensureDateObject(date));
