import { Empty, Typography } from 'antd';
import { FormError } from 'lib/forms';
import { DimmerLoading, Divider, Group, Loading, Price, SortOrder, Spacer, TextWithSortable, Time } from 'lib/ui';
import { SearchRestaurantItemModel } from 'modules/Orders/models/search-restaurant-item.model';
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { CuisineType, Meal } from 'modules/Restaurant';
import React, { FunctionComponent, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { OrderFormMeals } from './OrderFormMeals';
import { OrderFormRestaurantPickerContainer } from './OrderFormRestaurantPickerContainer';
import { RestaurantItem } from './RestaurantItem';
import { RestaurantItemTitle } from './RestaurantItemTitle';

export interface OrderFormRestaurantPickerProps {
  onSelectRestaurant: (searchRestaurantItem: SearchRestaurantItemModel) => void;
  onSelectRestaurantAndMeal: (searchRestaurantItem: SearchRestaurantItemModel, meal: Meal) => void;
  cuisineType: CuisineType | null;
  query: string | null;
}

const enhance = connect(
  ({ orders, restaurants, loading }) => ({
    ordersStore: orders,
    restaurantsStore: restaurants,
    loading,
  }),
  ({ restaurants, orders }) => ({
    restaurants,
    orders,
  }),
);

export const OrderFormRestaurantPicker: FunctionComponent<OrderFormRestaurantPickerProps> = enhance(props => {
  const orderState = OrderFormService.getStateValue();
  const [restaurants, setRestaurants] = useState<SearchRestaurantItemModel[]>([]);
  const [error, setError] = useState<any>();

  const [sortBy, setSortBy] = useState('time');
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

  useEffect(() => {
    props.orders
      .customCollectionAction({
        action: 'searchRestaurants',
        data: {
          limit: 1000,
          mealQuery: props.query,
          cuisineType: props.cuisineType ? props.cuisineType.id : undefined,
          deliveryAddress: orderState.address ? { id: orderState.address.id } : undefined,
          sortBy,
          sortDirection: sortOrder,
        },
      })
      .then(data => {
        setError(null);
        setRestaurants(data);
      })
      .catch(error => {
        setError(error.data.detail);
      });
  }, [props.query, props.cuisineType, sortBy, sortOrder, props.orders, orderState.address]);

  return (
    <OrderFormRestaurantPickerContainer>
      <h2>Wybierz restauracje</h2>

      {restaurants.length === 0 && props.loading.effects.orders.customCollectionAction && (
        <Loading text={'Wyszukuję restauracje...'} />
      )}

      {error && <FormError errors={{ nonFieldErrors: error }} />}

      {!error && restaurants.length === 0 && <Empty description="Nie znaleziono restauracji" />}

      {restaurants.length > 0 && (
        <div style={{ position: 'relative' }}>
          <Group align="space-between" verticalAlign="top">
            <Group width="50%">
              <TextWithSortable
                text="Nazwa restauracji"
                sortOrder={sortOrder}
                isActive={sortBy === 'name'}
                onSortChange={sortOrder => {
                  setSortOrder(sortOrder);
                  setSortBy('name');
                }}
              />
            </Group>
            {!orderState.withDirectPickUp && (
              <>
                <Group width="20%">
                  <TextWithSortable
                    text="Koszt dostawy"
                    sortOrder={sortOrder}
                    isActive={sortBy === 'cost'}
                    onSortChange={sortOrder => {
                      setSortOrder(sortOrder);
                      setSortBy('cost');
                    }}
                  />
                </Group>
                <Group width="20%">
                  <TextWithSortable
                    text="Czas dostawy"
                    sortOrder={sortOrder}
                    isActive={sortBy === 'time'}
                    onSortChange={sortOrder => {
                      setSortOrder(sortOrder);
                      setSortBy('time');
                    }}
                  />
                </Group>
              </>
            )}
            {orderState.withDirectPickUp && (
              <>
                <Group width="40%">Koszt dostawy</Group>
              </>
            )}
          </Group>

          <Divider />

          {restaurants.map(restaurantSearchItem => {
            const hasProperCostTimeData =
              restaurantSearchItem.deliveryInfo.cost < 999999 && restaurantSearchItem.deliveryInfo.time < 999999;
            return (
              <RestaurantItem
                key={restaurantSearchItem.restaurant.id}
                disabled={
                  !hasProperCostTimeData ||
                  (orderState.restaurant && restaurantSearchItem.restaurant.id !== orderState.restaurant.id)
                }
                onClick={() => {
                  if (
                    hasProperCostTimeData &&
                    (!orderState.restaurant || restaurantSearchItem.restaurant.id === orderState.restaurant.id)
                  ) {
                    props.onSelectRestaurant(restaurantSearchItem);
                  }
                }}
              >
                <Group align="space-between" verticalAlign="top">
                  <Group width="50%" vertical>
                    <RestaurantItemTitle>
                      <Group>
                        <Typography.Title level={4} style={{ marginBottom: 0 }}>
                          {restaurantSearchItem.restaurant.name}
                        </Typography.Title>
                        <Spacer size="small" />
                        <Typography.Text style={{ marginBottom: '-3px', marginLeft: '4px' }}>
                          ({restaurantSearchItem.restaurant.averageMealPrepareTime} min.)
                        </Typography.Text>
                      </Group>
                      <Group>{restaurantSearchItem.restaurant.cuisineTypes.map(obj => obj.namePl).join(', ')}</Group>
                    </RestaurantItemTitle>
                    <OrderFormMeals
                      meals={restaurantSearchItem.restaurant.meals}
                      onMealSelect={meal => {
                        if (!orderState.restaurant || restaurantSearchItem.restaurant.id === orderState.restaurant.id) {
                          props.onSelectRestaurantAndMeal(restaurantSearchItem, meal);
                        }
                      }}
                    />
                  </Group>
                  {!orderState.withDirectPickUp && (
                    <>
                      <Group width="20%">
                        {hasProperCostTimeData && <Price amount={restaurantSearchItem.deliveryInfo.cost} />}
                        {!hasProperCostTimeData && <span>Brak danych</span>}
                      </Group>
                      <Group width="20%">
                        {hasProperCostTimeData && (
                          <Time seconds={restaurantSearchItem.deliveryInfo.time} minutesSuffix="min" />
                        )}
                        {!hasProperCostTimeData && <span>Brak danych</span>}
                      </Group>
                    </>
                  )}
                  {orderState.withDirectPickUp && (
                    <>
                      <Group width="40%">Odbiór osobisty - brak opłat za dostawę</Group>
                    </>
                  )}
                </Group>
              </RestaurantItem>
            );
          })}

          <DimmerLoading visible={props.loading.effects.orders.customCollectionAction} />
        </div>
      )}
    </OrderFormRestaurantPickerContainer>
  );
});
