/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { OrderFormService } from 'modules/Orders/OrderFormService';
import { OrderFormState } from 'modules/Orders/OrderFormState';
import React, { FunctionComponent } from 'react';

import { styled, Box } from 'lib/ui';
import { useObservable } from 'react-use';
import { Address } from 'components';

export interface OrderClientSummaryProps {
  editable?: boolean;
}

const OrderClientSummaryContainer = styled.div.attrs({
  className: 'OrderClientSummaryContainer',
})`
  width: 100%;
`;

export const OrderClientSummary: FunctionComponent<OrderClientSummaryProps> = props => {
  const orderState = useObservable<OrderFormState>(OrderFormService.getStateAsObservable());

  if (!orderState || !orderState.client || !orderState.client.user) {
    return null;
  }

  return (
    <OrderClientSummaryContainer>
      <Box dirty title="Klient" noMargin>
        <h3>
          {orderState.client.user.firstName} {orderState.client.user.lastName}
        </h3>
        <div>{orderState.phone}</div>
        {orderState.address && (
          <Address
            tiny
            street={orderState.address.street}
            houseNumber={orderState.address.houseNumber}
            postalCode={orderState.address.postalCode}
            city={orderState.address.city}
            details={orderState.address.details}
          />
        )}
      </Box>
    </OrderClientSummaryContainer>
  );
};
