/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { message, Popconfirm } from 'antd';
import { Address, MapLabModal } from 'components';
import { FormFieldLabel, Input } from 'lib/forms';
import {
  AddressDialog,
  Button,
  ButtonSize,
  ButtonVariant,
  Divider,
  Group,
  Modal,
  ModalProps,
  Spacer,
  styled,
} from 'lib/ui';
import { ClientAddress } from 'modules/Clients';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { useToggle } from 'react-use';
import { useDispatch } from 'react-redux';

export interface AddressPickerModalProps extends ModalProps {
  clientId?: number | string;
  onSelect?: (address: ClientAddress) => void;
  onAddressGeoSave?: (address: ClientAddress) => void;
  description?: ReactNode;
}

const AddressPickerModalContainer = styled.div.attrs({
  className: 'AddressPickerModalContainer',
})``;

const enhance = connect(
  ({ loading, clientAddress }) => ({
    loading,
    clientAddressStore: clientAddress,
  }),
  ({ clientAddress }) => ({
    clientAddress,
  }),
);

const emptyAddressValue = {
  street: '',
  city: '',
  postalCode: '',
  houseNumber: '',
  details: '',
};

export const AddressPickerModal: FunctionComponent<AddressPickerModalProps> = enhance(props => {
  const dispatch = useDispatch();
  const [newAddressValue, setNewAddressValue] = useState(emptyAddressValue);

  const [addressDialogVisible, toggleAddressDialog] = useToggle(false);
  const [selectedAddress, setSelectedAddress] = useState<ClientAddress | null>(null);

  const [selectedAddressForMap, setSelectedAddressForMap] = useState<ClientAddress | null>(null);

  const [mapLabModalVisible, toggleMapLabModalDialog] = useToggle(false);

  const [closable, setClosable] = useState(true);

  const fetchAddresses = () => {
    return dispatch({
      type: 'clientAddress/loadCollection',
      payload: {
        customerId: props.clientId,
      },
    });
  };

  //  load client addresses
  useEffect(() => {
    fetchAddresses();
  }, [props.clientId]);

  return (
    <Modal visible closeOnEsc={closable} {...props} width="600px" header={<h2>Adresy klienta</h2>}>
      {' '}
      {props.description && (
        <>
          {props.description}
          <Spacer />
        </>
      )}
      <AddressPickerModalContainer>
        {props.clientAddressStore.data.map(address => {
          return (
            <React.Fragment key={address.id}>
              <Group align="space-between">
                <div className="flex w-full">
                  <div className="grow-1">
                    <Group vertical width="auto">
                      <Address
                        tiny
                        street={address.street}
                        houseNumber={address.houseNumber}
                        postalCode={address.postalCode}
                        city={address.city}
                        lat={address.lat}
                        lon={address.lon}
                        details={address.details}
                      />
                    </Group>
                  </div>
                  <div className="grow-0">
                    <Group vertical width="auto">
                      <Group width="auto">
                        <Button
                          size={ButtonSize.Small}
                          variant={ButtonVariant.Primary}
                          label="wybierz"
                          disabled={
                            props.loading.effects.clientAddress.deleteItem ||
                            props.loading.effects.clientAddress.uypdateItem ||
                            !address.lon ||
                            !address.lat
                          }
                          onClick={() => {
                            if (props.onSelect) {
                              props.onSelect(address);
                            }
                          }}
                        />
                        <Button
                          size={ButtonSize.Small}
                          iconName="edit"
                          disabled={
                            props.loading.effects.clientAddress.deleteItem ||
                            props.loading.effects.clientAddress.updateItem
                          }
                          onClick={() => {
                            setSelectedAddress(address);
                            toggleAddressDialog(true);
                          }}
                        />
                        <Popconfirm
                          title="Czy na pewno usunąć ten adres?"
                          okText="Tak, usuń"
                          cancelText="Anuluj"
                          onConfirm={() => {
                            const hide = message.loading('Usuwam adres...', 0);
                            props.clientAddress
                              .deleteItem({
                                customerId: props.clientId,
                                id: address.id,
                              })
                              .then(() => {
                                message.success('Adres został usunięty');
                                props.clientAddress.loadCollection({
                                  customerId: props.clientId,
                                });
                              })
                              .catch(() => {
                                message.error('Wystąpił nieoczekiwany błąd.');
                              })
                              .finally(() => {
                                hide();
                              });
                          }}
                        >
                          <Button
                            size={ButtonSize.Small}
                            iconName="trash"
                            disabled={
                              props.loading.effects.clientAddress.deleteItem ||
                              props.loading.effects.clientAddress.uypdateItem
                            }
                          />
                        </Popconfirm>
                      </Group>
                      <Button
                        iconName="map-marked-alt"
                        size={ButtonSize.Small}
                        style={{ width: '100%' }}
                        label={'Pokaż na mapie'}
                        onClick={() => {
                          setSelectedAddressForMap(address);
                          setClosable(false);
                          toggleMapLabModalDialog(true);
                        }}
                      />
                    </Group>
                  </div>
                </div>
              </Group>
              <Divider />
            </React.Fragment>
          );
        })}
        <div>
          <div style={{ display: 'flex', marginBottom: '16px' }}>
            <div style={{ width: '70%', paddingRight: '8px' }}>
              <FormFieldLabel small>Ulica</FormFieldLabel>
              <Input
                value={newAddressValue.street}
                onChange={ev => {
                  setNewAddressValue({
                    ...newAddressValue,
                    street: ev.currentTarget.value,
                  });
                }}
              />
            </div>
            <div style={{ width: '30%' }}>
              <FormFieldLabel small>Nr budynku</FormFieldLabel>
              <Input
                value={newAddressValue.houseNumber}
                onChange={ev => {
                  setNewAddressValue({
                    ...newAddressValue,
                    houseNumber: ev.currentTarget.value,
                  });
                }}
              />
            </div>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <FormFieldLabel small>nr lokalu, piętro etc.</FormFieldLabel>
            <Input
              value={newAddressValue.details}
              onChange={ev => {
                setNewAddressValue({
                  ...newAddressValue,
                  details: ev.currentTarget.value,
                });
              }}
            />
          </div>

          <div style={{ display: 'flex' }}>
            <div style={{ width: '70%', paddingRight: '8px' }}>
              <FormFieldLabel small>Miasto</FormFieldLabel>
              <Input
                value={newAddressValue.city}
                onChange={ev => {
                  setNewAddressValue({
                    ...newAddressValue,
                    city: ev.currentTarget.value,
                  });
                }}
              />
            </div>
            <div style={{ width: '30%' }}>
              <FormFieldLabel small>Kod pocztowy</FormFieldLabel>
              <Input
                value={newAddressValue.postalCode}
                onChange={ev => {
                  setNewAddressValue({
                    ...newAddressValue,
                    postalCode: ev.currentTarget.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <Spacer />
        <Button
          size={ButtonSize.Small}
          variant={ButtonVariant.Primary}
          label="Dodaj nowy adres"
          loading={props.loading.effects.clientAddress.createItem}
          disabled={props.loading.effects.clientAddress.deleteItem || props.loading.effects.clientAddress.updateItem}
          onClick={() => {
            props.clientAddress
              .createItem({
                data: {
                  street: newAddressValue.street,
                  houseNumber: newAddressValue.houseNumber,
                  postalCode: newAddressValue.postalCode,
                  city: newAddressValue.city,
                  details: newAddressValue.details
                },
                customerId: props.clientId,
              })
              .then(data => {
                message.success('Adres został dodany');
              });
          }}
        />

        {addressDialogVisible && (
          <AddressDialog
            open
            loading={props.loading.effects.clientAddress.createItem || props.loading.effects.clientAddress.updateItem}
            errors={props.clientAddressStore.errors}
            value={
              selectedAddress !== null
                ? {
                    street: selectedAddress.street,
                    houseNumber: selectedAddress.houseNumber,
                    postalCode: selectedAddress.postalCode,
                    city: selectedAddress.city,
                    details: selectedAddress.details,
                  }
                : undefined
            }
            title={selectedAddress ? `Edycja adresu` : `Dodawanie adresu`}
            okText={selectedAddress ? 'Zapisz' : 'Dodaj'}
            withNotes
            onSave={data => {
              if (selectedAddress) {
                props.clientAddress
                  .updateItem({
                    data: {
                      street: data.values.street,
                      postalCode: data.values.postalCode,
                      city: data.values.city,
                      houseNumber: data.values.houseNumber,
                      details: data.values.details,
                    },
                    id: selectedAddress.id,
                    customerId: props.clientId,
                  })
                  .then(() => {
                    message.success('Adres został zaktualizowany');
                    toggleAddressDialog(false);
                  });
              }
            }}
            onCancel={() => {
              toggleAddressDialog(false);
            }}
          />
        )}

        {mapLabModalVisible && (
          <MapLabModal
            visible
            street={selectedAddressForMap ? selectedAddressForMap.street : ''}
            houseNumber={selectedAddressForMap ? selectedAddressForMap.houseNumber : ''}
            postalCode={selectedAddressForMap ? selectedAddressForMap.postalCode : ''}
            city={selectedAddressForMap ? selectedAddressForMap.city : ''}
            details={selectedAddressForMap ? selectedAddressForMap.details : ''}
            addressLat={(selectedAddressForMap && selectedAddressForMap.lat) || ''}
            addressLon={(selectedAddressForMap && selectedAddressForMap.lon) || ''}
            storeName="clientAddress"
            onSaveGeoCoordinates={(lat, lon, entranceLat, entranceLon) => {
              if (selectedAddressForMap) {
                return props.clientAddress
                  .updateItem({
                    data: {
                      lat,
                      lon,
                      entranceLat,
                      entranceLon,
                    },
                    id: selectedAddressForMap.id,
                    customerId: props.clientId,
                  })
                  .then(data => {
                    props.onAddressGeoSave(data);
                  });
              }
              return Promise.resolve();
            }}
            onClose={() => {
              toggleMapLabModalDialog(false);
              setSelectedAddressForMap(null);
              setClosable(true);
              fetchAddresses();
            }}
          />
        )}
      </AddressPickerModalContainer>
    </Modal>
  );
});
