import { format, differenceInMinutes } from 'date-fns';
import { highlightOrders$ } from 'modules/Orders/components/OrderTable/OrdersTable';

import { DriverMapData, MapDataModel, OrderMapData, RestaurantMapData } from 'pages/MapPage/MapDataModel';
import { DriverTask, DriverTaskWithOrderLetters } from 'pages/OrdersPage/DriverTasksDialog/DriverTasksDialog';

let mapMarkers: any[] = [];
let restaurantMarkersById = {};
let orderMarkersById = {};
let driverMarkersById = {};
let mapLines: any[] = [];
let currentMap;

const formatDate = date => format(date, 'YYYY-MM-DD HH:mm');

//  clean ups

const removeAllMarkersFromMap = () => {
  mapMarkers.forEach(marker => currentMap.removeLayer(marker));
  mapMarkers = [];
  restaurantMarkersById = {};
  orderMarkersById = {};
  driverMarkersById = {};
};

export const removeAllLinesFromMap = () => {
  mapLines.forEach(line => currentMap.removeLayer(line));
  mapLines = [];
};

//  lines...

const addLines = (points: Array<{ lat: number; lon: number }>) => {
  const line = window.L.polyline(points, { color: 'red' });
  line.addTo(currentMap);
  mapLines.push(line);
};

//  markers

const getDriverMarker = (driverMapData: DriverMapData) => {
  const lastPositionUpdateMinutesAgo = differenceInMinutes(new Date(), driverMapData.positionTime);
  const isPositionOutdated = lastPositionUpdateMinutesAgo > 2;
  const isPositionOld = lastPositionUpdateMinutesAgo > 15;
  const driverIcon = window.L.divIcon({
    className: `map-icon map-driver-icon ${driverMapData.isBusy && 'map-driver-icon--busy'} ${isPositionOutdated &&
      'map-driver-icon--outdated'} ${isPositionOld && 'map-driver-icon--old'}`,
    html: `${driverMapData.isBusy ? driverMapData.ordersCount : ''} ${driverMapData.name}`,
    iconSize: [24, 24],
  });
  const driverMarker = window.L.marker([driverMapData.lat, driverMapData.lon], {
    icon: driverIcon,
  });
  return driverMarker;
};

const addDriverMarker = (lat, lon, driverMapData: DriverMapData) => {
  const driverMarker = getDriverMarker(driverMapData);
  driverMarker.addTo(currentMap); // add to map
  const points = [
    { lat, lon },
    ...driverMapData.tasks.map(task => ({
      lat: task.lat,
      lon: task.lon,
    })),
  ];
  driverMarker.points = points;
  driverMarkersById[driverMapData.id] = driverMarker;
  driverMarker.on('mouseover', () => {
    addLines(points);
    highlightOrders$.next(
      driverMapData.tasks
        .filter(task => task.taskType === 'customer')
        .filter(task => task.currentOrder)
        .map(task => task.currentOrder.id),
    );
  });
  driverMarker.on('mouseout', () => {
    removeAllLinesFromMap();
    highlightOrders$.next([]);
  });
  mapMarkers.push(driverMarker);
};

const addOrderMarker = (lat, lon, currentOrderModel: OrderMapData) => {
  const driverIcon = window.L.divIcon({
    className: `map-icon map-order-icon ${currentOrderModel.isAssignedToDriver ? 'map-order-icon--assigned' : ''}`,
    html: ' ',
    iconSize: [24, 24],
  });
  const marker = window.L.marker([lat, lon], {
    icon: driverIcon,
  });
  marker.addTo(currentMap);
  marker.bindPopup(
    `<p>${currentOrderModel.orderData.deliveryAddress.addressData}, ${currentOrderModel.orderData.deliveryAddress.addressCity}</p>`,
  );
  mapMarkers.push(marker);
  orderMarkersById[currentOrderModel.orderData.id] = marker;
  //  line to restaurant...
  marker.on('mouseover', () => {
    const restaurantMarker = restaurantMarkersById[currentOrderModel.restaurantId];
    if (restaurantMarker) {
      const points = [{ lat, lon }, restaurantMarker.getLatLng()];
      removeAllLinesFromMap();
      addLines(points);
    }
    highlightOrders$.next([currentOrderModel.orderData.id]);
  });
  marker.on('mouseout', () => {
    removeAllLinesFromMap();
    highlightOrders$.next([]);
  });
};

const addRestaurantMarker = (lat, lon, restaurantMapData: RestaurantMapData) => {
  const driverIcon = window.L.divIcon({
    className: `map-icon map-restaurant-icon`,
    html: `${restaurantMapData.ordersCount}`,
    iconSize: [24, 24],
  });
  const marker = window.L.marker([lat, lon], {
    icon: driverIcon,
  });
  marker.addTo(currentMap);
  mapMarkers.push(marker);
  restaurantMarkersById[restaurantMapData.id] = marker;
  //  line to orders...
  marker.on('mouseover', () => {
    removeAllLinesFromMap();
    restaurantMapData.orders.forEach(orderId => {
      const points = [{ lat, lon }];
      const orderMarker = orderMarkersById[orderId];
      if (orderMarker) {
        points.push(orderMarker.getLatLng());
        addLines(points);
      }
    });
    highlightOrders$.next(restaurantMapData.orders);
  });
  marker.on('mouseout', () => {
    removeAllLinesFromMap();
    highlightOrders$.next([]);
  });
};

//  public API

export const initEventsForMap = mapRef => {
  mapRef.on('click', () => {
    removeAllLinesFromMap();
  });
};

export const displayMapMarkers = (data: MapDataModel, mapRef) => {
  currentMap = mapRef;

  removeAllMarkersFromMap();
  // removeAllLinesFromMap();

  // display markers
  data.drivers.forEach(driverMapData => {
    // @ts-ignore
    if (driverMapData.lon && driverMapData.lat && driverMapData.lat !== 'None') {
      addDriverMarker(driverMapData.lat, driverMapData.lon, driverMapData);
    }
  });
  data.currentOrders.forEach(currentOrderModel => {
    // @ts-ignore
    if (
      currentOrderModel.orderData.deliveryAddress.lon &&
      currentOrderModel.orderData.deliveryAddress.lat &&
      currentOrderModel.orderData.deliveryAddress.lat !== 'None'
    ) {
      addOrderMarker(
        currentOrderModel.orderData.deliveryAddress.lat,
        currentOrderModel.orderData.deliveryAddress.lon,
        currentOrderModel,
      );
    }
  });
  data.restaurants.forEach(restaurantMapData => {
    // @ts-ignore
    if (restaurantMapData.lon && restaurantMapData.lat && restaurantMapData.lat !== 'None') {
      addRestaurantMarker(restaurantMapData.lat, restaurantMapData.lon, restaurantMapData);
    }
  });
};

export const displayLineForOrder = (orderId: number, restaurantIds: number[]) => {
  removeAllLinesFromMap();
  const points: any[] = [];
  const orderMarker = orderMarkersById[orderId];
  if (orderMarker) {
    points.push(orderMarker.getLatLng());
    restaurantIds.forEach(restaurantId => {
      const restaurantMarker = restaurantMarkersById[restaurantId];
      if (restaurantMarker) {
        points.push(restaurantMarker.getLatLng());
      }
    });
    addLines(points);
  }
};

export const displayLinesForDriver = (driverId: number) => {
  removeAllLinesFromMap();
  const driverMarker = driverMarkersById[driverId];
  if (driverMarker) {
    addLines(driverMarker.points);
  }
};

export const displayOnlyDriver = (driverId: number) => {
  //  hide all other driverMarkers
  Object.keys(driverMarkersById)
    .filter(_driverId => `${driverId}` !== _driverId)
    .forEach(_driverId => {
      driverMarkersById[_driverId].setOpacity(0);
    });
};

export const displayAllDrivers = () => {
  //  display all driver markers
  Object.keys(driverMarkersById).forEach(_driverId => driverMarkersById[_driverId].setOpacity(1));
};

// driver tasks dialog

export const displayTasksPointsAndLines = (
  driverMapData: DriverMapData,
  data: DriverTaskWithOrderLetters[],
  mapRef,
) => {
  currentMap = mapRef;
  const points: Array<{ lat: any; lon: any }> = [];

  removeAllMarkersFromMap();
  removeAllLinesFromMap();
  if (driverMapData.lat && driverMapData.lon && driverMapData.lat !== 'None' && driverMapData.lon !== 'None') {
    const driverMarker = getDriverMarker(driverMapData);
    driverMarker.addTo(currentMap);
    points.push({ lat: driverMapData.lat, lon: driverMapData.lon });
  }

  //  display all points and lines
  data.forEach(task => {
    if (task.lon && task.lat) {
      const icon = window.L.divIcon({
        className: `map-icon ${task.taskType === 'customer' && 'map-order-icon--task'} ${task.taskType ===
          'restaurant' && 'map-restaurant-icon map-restaurant-icon--task'}`,
        html: `${task.orderLetter}`,
        iconSize: [24, 24],
      });
      const marker = window.L.marker([task.lat, task.lon], { icon });
      marker.addTo(currentMap);
      mapMarkers.push(marker);
      points.push({ lat: task.lat, lon: task.lon });
    }
  });
  addLines(points);
};
