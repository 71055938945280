import React, { FunctionComponent } from 'react';
import { Radio } from 'antd';

import { FormFieldContainer, FormFieldLabel } from '.';

export interface FormRadioFieldProps {
  touched: any;
  errors: any;
  values: any;
  name: string;
  label?: string;
  hint?: string;
  options: Array<{
    value: string | number;
    label: string;
  }>;
  setFieldValue: Function;
  setFieldTouched: Function;
  onChange?: (value: string | number) => void;
}

const FormRadioField: FunctionComponent<FormRadioFieldProps> = props => {
  return (
    <FormFieldContainer>
      {props.label && <FormFieldLabel hint={props.hint}>{props.label}</FormFieldLabel>}
      <Radio.Group
        value={props.values[props.name]}
        buttonStyle="solid"
        onChange={value => {
          props.setFieldValue(props.name, value.target.value);
          props.setFieldTouched(props.name, true);
          if (props.onChange) {
            props.onChange(value.target.value);
          }
        }}>
        {props.options.map(option => (
          <Radio.Button value={option.value} key={option.value}>
            {option.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </FormFieldContainer>
  );
};

export { FormRadioField };
