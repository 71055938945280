/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Button, ButtonSize, ButtonVariant, Group, Icon, IconFa, IconSize, Spacer, Tooltip } from 'lib/ui';
import React, { FunctionComponent } from 'react';

import { AddressButtons } from './AddressButtons';
import { AddressContainer } from './AddressContainer';

export interface AddressProps {
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  lat?: number;
  lon?: number;
  details: string;
  editable?: boolean;
  compact?: boolean;
  tiny?: boolean;
  deletable?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  onShowOnMap?: () => void;
  onChoose?: () => void;
}

const AddressDefaultProps = {};

export const Address: FunctionComponent<AddressProps> = props => {
  const verified = (props.lon && props.lat) !== null;

  if (props.tiny) {
    return (
      <span>
        {props.lon && props.lat && (
          <Tooltip text={`lat: ${props.lat}, lon: ${props.lon}`}>
            <Icon name="fa-map-marked" fa={IconFa.Fas} size={IconSize.Small} />
            <Spacer size="small" horizontal />
          </Tooltip>
        )}
        <Group>
          <strong>
            {props.street} {props.houseNumber} {props.details}
          </strong>
        </Group>
        {(props.postalCode || props.city) && (
          <span>
            {props.postalCode} {props.city}
          </span>
        )}
      </span>
    );
  }

  return (
    <AddressContainer verified={verified} compact={props.compact}>
      <Group vertical wrap smallSpacing={props.compact}>
        <div className="street">
          <Group>
            <strong>
              {props.street} {props.houseNumber} {props.details}
            </strong>
          </Group>
          {(props.postalCode || props.city) && (
            <span>
              {props.postalCode} {props.city}
            </span>
          )}
        </div>
      </Group>
      {props.editable && (
        <AddressButtons>
          <div className="footer">
            <div className="buttons">
              {verified && props.onChoose && (
                <div className="buttonContainer">
                  <Button
                    variant={ButtonVariant.Action}
                    iconName="hand-point-up"
                    size={ButtonSize.Small}
                    style={{
                      padding: '0.5rem',
                      width: '40px',
                    }}
                    onClick={() => {
                      if (props.onChoose) {
                        props.onChoose();
                      }
                    }}
                  />
                </div>
              )}
              <div className="buttonContainer">
                <Button
                  iconName="edit"
                  size={ButtonSize.Small}
                  style={{
                    padding: '0.5rem',
                    width: '40px',
                  }}
                  onClick={() => {
                    if (props.onEdit) {
                      props.onEdit();
                    }
                  }}
                />
              </div>
              <div className="buttonContainer">
                <Button
                  iconName="trash"
                  size={ButtonSize.Small}
                  style={{
                    padding: '0.5rem',
                    width: '40px',
                  }}
                  onClick={() => {
                    if (props.onDelete) {
                      props.onDelete();
                    }
                  }}
                />
              </div>
            </div>
            <div className="geolocalization">
              <Button
                style={{
                  backgroundColor: !verified ? '#ffab49' : '#F2F2F2',
                  borderColor: !verified ? '#ffab49' : '#d6d6d6',
                  padding: '0.5rem',
                  width: '100%',
                }}
                iconName="map-marked-alt"
                size={ButtonSize.Small}
                label={verified ? 'Pokaż na mapie' : 'Wymaga geolokalizacji'}
                onClick={() => {
                  if (props.onShowOnMap) {
                    props.onShowOnMap();
                  }
                }}
              />
            </div>
          </div>
        </AddressButtons>
      )}
    </AddressContainer>
  );
};

Address.defaultProps = AddressDefaultProps;
