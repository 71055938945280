import { message, Select } from 'antd';
import { ColorGreen } from 'components/UiHelpers';
import { Formik } from 'formik';
import { FieldError, FormFieldContainer, FormFieldLabel, Input } from 'lib/forms';
import { Button, ButtonVariant, Group, Modal, modalConfirm, Spacer } from 'lib/ui';
import { getLabelForStatus } from 'modules/Orders/components/OrderTable/getLabelForStatus';
import { OrderModel } from 'modules/Orders/models/order.model';
import React, { FunctionComponent, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { amountAsText } from 'services/helpers/amountAsText';

export interface OrderFinalizeDialogProps {
  order: OrderModel;
  onClose: (hasChanged?: boolean) => void;
}

export const OrderStatusDialog: FunctionComponent<OrderFinalizeDialogProps> = props => {
  const [deleting, setDeleting] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const dispatch = useDispatch();

  const updateOrderStatus = (
    orderStatus: number,
    payload?: {
      paymentAmount: number;
      paymentType?: number;
    },
  ) => {
    setUpdating(true);
    dispatch({
      type: 'orders/customCollectionAction',
      payload: {
        action: 'status',
        actionMethod: 'patch',
        data: {
          orderId: props.order.id,
          orderStatus,
          ...payload,
        },
      },
    })
      .then(data => {
        message.success('Status został zmieniony');
        dispatch({
          type: 'currentOrders/setItem',
          payload: {
            itemIdFromPayload: props.order.id,
            item: data,
          },
        });
        props.onClose(true);
      })
      .catch(err => {
        setUpdating(false);
        message.error(err.data.detail || 'Wystąpił nieoczekiwany błąd');
      });
  };

  const hidePaymentDetails = props.order.paymentAmount && props.order.gross - props.order.paymentAmount < 0;
  const defaultPaymentAmount = amountAsText(props.order.paymentAmount ? props.order.paymentAmount : 0);
  const defaultPaymentType = props.order.paymentData ? props.order.paymentData.id : undefined;

  const paymentTypes = useSelector(state => state.paymentTypes.data);
  let selectedPayment = paymentTypes.find(payment => payment.id === defaultPaymentType);
  if (selectedPayment) {
    selectedPayment = {
      key: selectedPayment.id,
      label: selectedPayment.namePl,
    };
  }

  const availableStatutes = props.order.withDirectPickUp ? [0, 20, 40] : [0, 10, 20, 30, 40];

  return (
    <Formik
      initialValues={{
        paymentAmount: defaultPaymentAmount,
        paymentType: selectedPayment,
        status: 0,
      }}
      validate={values => {
        const errors: any = {};
        if (!values.paymentAmount && !hidePaymentDetails) {
          errors.paymentAmount = 'Pole jest wymagane';
          // eslint-disable-next-line no-restricted-globals
        } else if (isNaN(Number(values.paymentAmount.replace(',', '.')))) {
          errors.paymentAmount = 'Wpisz poprawną wartość';
        }
        if (!values.paymentType && !props.order.withDirectPickUp && !hidePaymentDetails) {
          errors.paymentType = 'Pole jest wymagane';
        }
        return errors;
      }}
      onSubmit={(values, bag) => {
        const paymentTypeKey = values.paymentType && values.paymentType.key;
        const payloadWithPaymentDetails = {
          paymentAmount: Number(values.paymentAmount.replace(',', '.')) * 100,
          paymentType: paymentTypeKey ? Number(paymentTypeKey) : undefined,
        };
        updateOrderStatus(values.status, hidePaymentDetails ? undefined : payloadWithPaymentDetails);
      }}
      render={formikProps => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <Modal
              visible
              width={'400px'}
              header={<h2>Finalizacja zamówienia</h2>}
              onClose={props.onClose}
              zIndex={1000}
              footer={
                <Group align="space-between">
                  <Button
                    label={'usuń zamówienie'}
                    variant={ButtonVariant.Plain}
                    loading={deleting}
                    disabled={updating}
                    onClick={() => {
                      modalConfirm({
                        title: `Czy na pewnousunąć zamówienie?`,
                        content: ' ',
                        yesCallback: () => {
                          setDeleting(true);
                          dispatch({
                            type: 'currentOrders/deleteItem',
                            payload: {
                              id: props.order.id,
                            },
                          })
                            .then(data => {
                              setDeleting(false);
                              message.success('Zamówienie zostało usunięte');
                              props.onClose(true);
                            })
                            .catch(err => {
                              setDeleting(false);
                              message.error(err.data.detail || 'Wystąpił nieoczekiwany błąd');
                            });
                        },
                      });
                    }}
                  />
                  <Button onClick={props.onClose} disabled={deleting || updating} label={'Anuluj'} />
                </Group>
              }
            >
              {availableStatutes.includes(0) && (
                <FormFieldContainer>
                  <Group align="space-between">
                    <ColorGreen>{getLabelForStatus(0)}</ColorGreen>
                    <Button
                      onClick={() => {
                        updateOrderStatus(0);
                      }}
                      disabled={deleting || updating}
                      variant={ButtonVariant.Plain}
                      label={'Ustaw'}
                    />
                  </Group>
                </FormFieldContainer>
              )}
              {availableStatutes.includes(10) && (
                <FormFieldContainer>
                  <Group align="space-between">
                    {props.order.status < 10 && <div>{getLabelForStatus(10)}</div>}
                    {props.order.status >= 10 && <ColorGreen>{getLabelForStatus(10)}</ColorGreen>}
                    <Button
                      onClick={() => {
                        updateOrderStatus(10);
                      }}
                      disabled={deleting || updating}
                      variant={props.order.status >= 10 ? ButtonVariant.Plain : ButtonVariant.Primary}
                      label={'Ustaw'}
                    />
                  </Group>
                </FormFieldContainer>
              )}
              {availableStatutes.includes(20) && (
                <FormFieldContainer>
                  <Group align="space-between">
                    {props.order.status < 20 && <div>{getLabelForStatus(20)}</div>}
                    {props.order.status >= 20 && <ColorGreen>{getLabelForStatus(20)}</ColorGreen>}
                    <Button
                      onClick={() => {
                        updateOrderStatus(20);
                      }}
                      disabled={deleting || updating}
                      variant={props.order.status >= 20 ? ButtonVariant.Plain : ButtonVariant.Primary}
                      label={'Ustaw'}
                    />
                  </Group>
                </FormFieldContainer>
              )}

              {availableStatutes.includes(30) && (
                <>
                  {hidePaymentDetails && <Spacer size="small" vertical />}
                  <Group align="space-between">
                    <div>
                      <FormFieldContainer>
                        <FormFieldLabel>
                          {props.order.status < 30 && <span>{getLabelForStatus(30)}</span>}
                          {props.order.status >= 30 && <ColorGreen>{getLabelForStatus(30)}</ColorGreen>}
                        </FormFieldLabel>
                        {!hidePaymentDetails && (
                          <Input
                            name="paymentAmount"
                            type="text"
                            onChange={ev => {
                              formikProps.setFieldValue('paymentAmount', ev.currentTarget.value);
                            }}
                            onBlur={_ => {
                              formikProps.setFieldTouched('paymentAmount', true);
                            }}
                            value={formikProps.values.paymentAmount}
                            width={'150px'}
                          />
                        )}
                        {formikProps.errors.paymentAmount && formikProps.touched.paymentAmount && (
                          <FieldError error={formikProps.errors.paymentAmount} />
                        )}
                      </FormFieldContainer>
                      {!hidePaymentDetails && (
                        <FormFieldContainer>
                          <FormFieldLabel>Płatność</FormFieldLabel>

                          <Select
                            value={formikProps.values.paymentType}
                            labelInValue
                            placeholder="-- wybierz --"
                            style={{ width: '200px' }}
                            onChange={value => {
                              formikProps.setFieldValue('paymentType', value);
                            }}
                          >
                            {paymentTypes.map(paymentType => (
                              <Select.Option key={paymentType.id} title={paymentType.namePl}>
                                {paymentType.namePl}
                              </Select.Option>
                            ))}
                          </Select>

                          {formikProps.errors.paymentType &&
                            (formikProps.touched.paymentAmount || formikProps.touched.paymentType) && (
                              <FieldError error={formikProps.errors.paymentType} />
                            )}
                        </FormFieldContainer>
                      )}
                    </div>

                    <Button
                      onClick={() => {
                        formikProps.setFieldValue('status', 30);
                        // setTimeout is needed to properly update field value and instantly submit form
                        setTimeout(() => {
                          formikProps.submitForm();
                        });
                      }}
                      label={'Ustaw'}
                      disabled={deleting || updating}
                      variant={props.order.status >= 30 ? ButtonVariant.Plain : ButtonVariant.Primary}
                    />
                  </Group>
                </>
              )}

              {availableStatutes.includes(40) && (
                <FormFieldContainer>
                  <Group align="space-between">
                    {props.order.status < 40 && <div>{getLabelForStatus(40)}</div>}
                    {props.order.status >= 40 && <ColorGreen>{getLabelForStatus(40)}</ColorGreen>}
                    <Button
                      onClick={() => {
                        formikProps.setFieldValue('status', 40);
                        // setTimeout is needed to properly update field value and instantly submit form
                        setTimeout(() => {
                          formikProps.submitForm();
                        });
                      }}
                      disabled={deleting || updating}
                      variant={props.order.status >= 40 ? ButtonVariant.Plain : ButtonVariant.Primary}
                      label={'Ustaw'}
                    />
                  </Group>
                </FormFieldContainer>
              )}
            </Modal>
          </form>
        );
      }}
    />
  );
};
