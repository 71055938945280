import React, { FunctionComponent, useState } from 'react';

import { Drawer, DrawerProps } from 'lib/ui';
import { DeliveryZoneForm, DeliveryZone } from 'modules/Settings';

export interface DeliveryZoneDrawerProps extends DrawerProps {
  deliveryZone?: DeliveryZone;
}

const DeliveryZoneDrawerDefaultProps = {
  deliveryZone: {
    id: 0,
    distanceFrom: 0,
    distanceTo: 0,
    price: 0,
  },
};

const DeliveryZoneDrawer: FunctionComponent<DeliveryZoneDrawerProps> = ({ onClose, visible, deliveryZone }) => {
  const [closable, setClosable] = useState(true);

  let title;
  if (deliveryZone) {
    // edit mode
    title = `Strefa dostaw ${deliveryZone.distanceFrom} - ${deliveryZone.distanceTo}`;
  } else {
    // create mode
    title = 'Tworzenie strefy dostaw';
  }

  return (
    <Drawer displayCloseButton closeOnEsc={closable} onClose={onClose} visible={visible} title={title} destroyOnClose>
      <DeliveryZoneForm
        deliveryZone={deliveryZone}
        onClose={onClose}
        onModalToggle={visible => setClosable(!visible)}
      />
    </Drawer>
  );
};

DeliveryZoneDrawer.defaultProps = DeliveryZoneDrawerDefaultProps;

export { DeliveryZoneDrawer };
