export const DeliveryZoneMap = {
  apiDataToViewData: apiData => ({
    ...apiData,
    distanceFrom: apiData.distanceFrom || 0,
    distanceTo: apiData.distanceTo || 0,
    price: apiData.price ? apiData.price / 100 : 0,
  }),

  viewDataToApiPayload: viewData => ({
    ...viewData,
    price: viewData.price ? viewData.price * 100 : 0,
  }),
};
