import React, { useEffect, useState } from 'react';
import useToggle from 'react-use/lib/useToggle';
import { connect } from 'react-redux';
import { Empty } from 'antd';

import { PageLayout } from 'components';
import { Button, ButtonVariant, Group, Loading } from 'lib/ui';
import {
  DriverDrawer,
  DriverFormDrawer,
  DriversTable,
  DriversMessagesTable,
  DriversMessageDrawer,
  PAGINATION_LIMIT,
} from 'modules/Drivers';
import { SearchInput } from 'lib/forms';

const enhance = connect(
  ({ drivers, driversMessages, loading }) => ({
    drivers,
    driversMessages,
    loading,
  }),
  ({ drivers, driversMessages }) => ({
    driversStore: drivers,
    driversMessagesStore: driversMessages,
  }),
);

const DriversPage = enhance(props => {
  // currently selected driver (for edit or to see the details)
  const [driver, setDriver] = useState(null);

  // driver form toggle
  const [driverFormVisible, toggleDriverForm] = useToggle(false);

  // driver details toggle
  const [driverDetailsVisible, toggleDriverDetails] = useToggle(false);

  // drivers message details toggle
  const [driversMessage, setDriversMessage] = useState<any>(null);

  // switch / toggle between drivers and messages tables
  const [driversMessagesTableVisible, toggleDriversMessagesTable] = useToggle(false);

  // drivers message form toggle
  const [driversMessageFormVisible, toggleDriversMessageForm] = useToggle(false);

  // driver details toggle
  const [driversMessageDetailsVisible, toggleDriversMessageDetails] = useToggle(false);

  // drivers: componentDidMount ;)
  useEffect(() => {
    props.driversStore.loadCollection();
  }, [props.driversStore]);

  // drivers: search query
  const [driversSearchPhrase, setDriversSearchPhrase] = useState('');

  // drivers: react when search phrase has changed
  useEffect(() => {
    if (!driversMessagesTableVisible) {
      props.driversStore.loadCollection({
        params: {
          search: driversSearchPhrase || undefined,
        },
      });
    }
  }, [driversMessagesTableVisible, driversSearchPhrase, props.driversStore]);

  // messages: handle search query
  const [driversMessagesSearchPhrase, setDriversMessagesSearchPhrase] = useState('');

  // messages: load (also with search phrase) when messages table is shown/visible
  useEffect(() => {
    if (driversMessagesTableVisible) {
      props.driversMessagesStore.loadCollection({
        params: {
          search: driversMessagesSearchPhrase || undefined,
          limit: PAGINATION_LIMIT,
        },
      });
    }
  }, [driversMessagesTableVisible, driversMessagesSearchPhrase, props.driversMessagesStore]);

  const drivers = props.drivers.data;

  const driversMessages = props.driversMessages.data;

  return (
    <PageLayout title={driversMessagesTableVisible ? 'Wiadomości' : 'Kierowcy'}>
      {/* DRIVERS TABLE */}
      {!driversMessagesTableVisible && (
        <>
          <Group align="space-between">
            <SearchInput onChange={setDriversSearchPhrase} value={driversSearchPhrase} />
            <Group align="right">
              <Button
                label="Wiadomości"
                variant={ButtonVariant.Primary}
                onClick={() => toggleDriversMessagesTable(true)}
              />

              <Button
                label="Dodaj kierowcę"
                variant={ButtonVariant.Primary}
                onClick={() => {
                  setDriver(null);
                  toggleDriverForm(true);
                }}
              />
            </Group>
          </Group>

          {!drivers.length && !props.loading.effects.drivers.loadCollection && <Empty description="Brak danych" />}

          {!drivers.length && props.loading.effects.drivers.loadCollection && <Loading text="Ładowanie danych" />}

          {drivers.length > 0 && (
            <DriversTable
              items={drivers}
              onItemClick={item => {
                setDriver(item);
                toggleDriverDetails(true);
              }}
              onDriverReckoning={driverId => {
                props.history.push(`/drivers/${driverId}/reckoning`);
              }}
            />
          )}

          {driverFormVisible && (
            <DriverFormDrawer
              item={driver}
              visible
              onClose={() => {
                toggleDriverForm(false);
                setDriver(null);
              }}
            />
          )}

          {driverDetailsVisible && (
            <DriverDrawer
              item={driver}
              visible
              onClose={() => {
                toggleDriverDetails(false);
              }}
              onEditClick={() => {
                toggleDriverDetails(false);
                toggleDriverForm(true);
              }}
            />
          )}
        </>
      )}

      {/* DRIVERS MESSAGES TABLE */}
      {driversMessagesTableVisible && (
        <>
          <Group align="space-between">
            <SearchInput onChange={setDriversMessagesSearchPhrase} value={driversMessagesSearchPhrase} />
            <Group align="right">
              <Button
                label="Kierowcy"
                variant={ButtonVariant.Primary}
                onClick={() => toggleDriversMessagesTable(false)}
              />

              <Button
                disabled={drivers.length < 1}
                label="Nowa wiadomość"
                variant={ButtonVariant.Primary}
                onClick={() => toggleDriversMessageForm(true)}
              />
            </Group>
          </Group>

          {!driversMessages.length && !props.loading.effects.driversMessages.loadCollection && (
            <Empty description="Brak danych" />
          )}

          {!driversMessages.length && props.loading.effects.driversMessages.loadCollection && (
            <Loading text="Ładowanie danych" />
          )}

          {driversMessages.length > 0 && (
            <DriversMessagesTable
              searchPhrase={driversMessagesSearchPhrase}
              onItemClick={item => {
                setDriversMessage(item);
                toggleDriverDetails(false);
                toggleDriversMessageDetails(true);
              }}
            />
          )}

          {driversMessageFormVisible && (
            <DriversMessageDrawer
              onClose={() => {
                props.driversMessagesStore.clearError();
                setDriversMessage(null);
                toggleDriversMessageForm(false);
              }}
              visible
            />
          )}

          {driversMessageDetailsVisible && (
            <DriversMessageDrawer
              driversMessage={driversMessage}
              onClose={() => {
                setDriversMessage(null);
                toggleDriversMessageDetails(false);
              }}
              visible
            />
          )}
        </>
      )}
    </PageLayout>
  );
});

export { DriversPage };
