import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

export interface EmployeeReckoningDayModel {
  isPaid: null | boolean;
  dayStart: string;
  dayEnd: string;
  paymentCondidions: null | {
    hourRateFri: number;
    hourRateMon: number;
    hourRateSat: number;
    hourRateSun: number;
    hourRateThu: number;
    hourRateTue: number;
    hourRateWed: number;
  };
  paymentDetails: {
    forDay: {
      weekdayName: string;
      workStart: null | string;
      workEnd: null | string;
      amountPerHour: null | number;
      countRbh: number;
      total: number;
    };
  };
}

export type EmployeeReckoning = {
  details: EmployeeReckoningDayModel[];
};

export const EmployeesReckoningModel = SimpleRestModel({
  collectionName: 'employeesReckoning',
  HttpApi,
  getApiPath: (payload, action: string) => {
    switch (action) {
      case 'customCollectionAction':
        return `settlement/employee/${payload.action}/`;
      case 'loadCollection':
      default:
        return `settlement/employee/${payload.employeeId}/date_start/${payload.dateStart}/date_end/${payload.dateEnd}`;
    }
  },
});
