import React, { FunctionComponent, ReactNode } from 'react';

import { Box, Header, Spacer, styled } from 'lib/ui';

export interface PageLayoutProps {
  title?: string;
  children?: ReactNode;
  header?: ReactNode;
}

const PageLayoutContainer = styled.div.attrs({
  className: 'PageLayoutContainer',
})`
  padding: ${p => p.theme.space};
  overflow: auto;
  height: calc(100% - 64px);
`;

const PageHeaderContainer = styled.div.attrs({
  className: 'PageHeaderContainer',
})`
  margin: ${p => p.theme.space} 0;
`;

const PageTitleStyled = styled(Header)`
  margin-left: 0;
`;

const PageLayout: FunctionComponent<PageLayoutProps> = (props: PageLayoutProps) => {
  return (
    <PageLayoutContainer>
      {props.title && (
        <>
          <PageTitleStyled>{props.title}</PageTitleStyled>
          <Spacer size="small" />
        </>
      )}
      {props.header && <PageHeaderContainer>{props.header}</PageHeaderContainer>}
      <Box noShadow noMargin>
        {props.children}
      </Box>
    </PageLayoutContainer>
  );
};

export { PageLayout };
