/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { message, Switch } from 'antd';
import { AddressComponent, CompanyModal } from 'components';

import {
  AddressDialog,
  Button,
  ButtonSize,
  ButtonVariant,
  Divider,
  Drawer,
  DrawerProps,
  formattedShortDateWithWeekDay,
  formattedTime,
  Group,
  Loading,
  modalConfirm,
  SimpleInputDialog,
  Spacer,
} from 'lib/ui';
import { ClientPhone } from 'modules/Clients';
import { ClientBalance } from 'modules/Clients/components/ClientBalance';

import { Company } from 'modules/Companies';
import { OrderClientDetailsTable } from 'modules/Orders/components/OrderClientDetailsTable/OrderClientDetailsTable';
import { OrderDetailsDialog } from 'modules/Orders/components/OrderDetailsDialog/OrderDetailsDialog';
import React, { FunctionComponent, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import { useToggle } from 'react-use';

const enhance = connect(
  ({ clients, clientPhones, clientAddress, loading, orders, companies }) => ({
    clientsStore: clients,
    clientPhonesStore: clientPhones,
    clientAddressStore: clientAddress,
    loading,
    ordersStore: orders,
    companiesStore: companies,
  }),
  ({ clients, clientPhones, clientAddress, orders, companies }) => ({
    clients,
    clientPhones,
    clientAddress,
    orders,
    companies,
  }),
);

export interface ClientDrawerProps extends DrawerProps {
  item: any;
  onEditClick: Function;
  onOrdersHistoryClick: (clientId: number) => void;
}

const ClientDrawer: FunctionComponent<ClientDrawerProps> = enhance(({ item, ...props }) => {
  const [orderDetailsOpened, toggleOrderDetails] = useToggle(false);
  const [order, setOrder] = useState<any>({});
  const [phoneDialogVisible, togglePhoneDialog] = useToggle(false);
  const [selectedPhone, setSelectedPhone] = useState<ClientPhone | null>(null);
  const [companyModalVisible, toggleModalDialog] = useToggle(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [discountDialogVisible, toggleDiscountDialog] = useToggle(false);
  const [userIsActive, setUserIsActive] = useState(item.user.isActive);
  const [userMoneyTransfer, setUserMoneyTransfer] = useState(item.moneyTransfer);
  const [coinsDialogVisible, toggleCoinsDialog] = useToggle(false);
  const [addressDialogVisible, toggleAddressDialog] = useToggle(false);
  const [closable, setClosable] = useState(true);

  //  load sub collection on startup
  useEffect(() => {
    props.clientPhones.loadCollection({
      customerId: item.id,
    });
    props.clientAddress.loadCollection({
      customerId: item.id,
    });
    props.companies.loadCollection({
      params: {
        customer: item.id,
      },
    });
    props.orders.loadCollection({
      params: {
        customer: item.id,
      },
    });
  }, [item, props.clientPhones, props.clientAddress, props.companies, props.orders]);

  const closeDrawer = () => {
    props.onClose();
  };

  return (
    <Drawer
      displayCloseButton
      closeOnEsc={closable}
      onClose={closeDrawer}
      subTitle={
        <div
          style={{
            position: 'absolute',
            right: '9px',
            display: 'flex',
            bottom: 0,
          }}
        >
          <Group style={{ marginRight: '15px' }}>
            <span>Rabat:</span>
            <span>
              <a onClick={() => toggleDiscountDialog(true)}>{item.discount}%</a>
            </span>
          </Group>
          <Group style={{ marginRight: '15px' }}>
            <span>Monety:</span>
            <span>
              <a onClick={() => toggleCoinsDialog(true)}>{item.points}</a>
            </span>
          </Group>
          <Group style={{ marginRight: '15px' }}>
            <span style={{ whiteSpace: 'nowrap' }}>Płatność przelewem:</span>
            <Switch
              checked={userMoneyTransfer}
              size="small"
              onChange={() => {
                props.clients
                  .updateItem({
                    data: {
                      moneyTransfer: !item.moneyTransfer,
                    },
                    id: item.id,
                  })
                  .then(() => {
                    setUserMoneyTransfer(!userMoneyTransfer);
                    message.success(
                      `${userMoneyTransfer ? 'Zablokowano' : 'Odblokowano'} płatność przelewem internetowym.`,
                    );
                  })
                  .catch(() => {
                    message.error('Wystąpił nieoczekiwany bład');
                  });
              }}
            />
          </Group>
          <Group>
            <span>Aktywny:</span>
            <Switch
              checked={userIsActive}
              size="small"
              onChange={() => {
                props.clients
                  .updateItem({
                    id: item.id,
                    data: {
                      userId: item.user.id,
                      isActive: !item.user.isActive,
                    },
                  })
                  .then(() => {
                    setUserIsActive(!userIsActive);
                    message.success(`Klient ${userIsActive ? 'zablokowany' : 'odblokowany'}`);
                  })
                  .catch(() => {
                    message.error('Wystąpił nieoczekiwany bład');
                  });
              }}
            />
          </Group>
        </div>
      }
      visible={props.visible}
      width={'50%'}
      title={` ${item.user.firstName} ${item.user.lastName}`}
      destroyOnClose
    >
      <Group vertical>
        <Group style={{ flexDirection: 'row' }} vertical wrap>
          <Group vertical wrap width={'50%'}>
            <Group>
              <span>
                <strong>Email:</strong>
              </span>
              <span>{item.user.email}</span>
            </Group>
            <Group>
              <span>
                <strong>Login:</strong>
              </span>
              <span>{item.user.username}</span>
            </Group>
            <Group>
              <span>
                <strong>Ostatnie logowanie:</strong>
              </span>
              <span>
                {`${
                  item.user.lastLogin
                    ? `${formattedShortDateWithWeekDay(item.user.lastLogin)}, ${formattedTime(item.user.lastLogin)}`
                    : ''
                }`}
              </span>
            </Group>
            <Group verticalAlign="top">
              <span className="u-flex-no-shrink">
                <strong>Uwagi:</strong>
              </span>
            </Group>
            <Group verticalAlign="top">
              <span className="text-pre-line">{item.notes}</span>
            </Group>
            <Group />
          </Group>
          <Group width={'50%'} wrap>
            <ClientBalance clientId={item.id} />

            <span>
              <strong>Dane do faktury VAT</strong>
            </span>

            {props.loading.effects.companies.loadCollection && <Loading text="Ładowanie danych do faktury VAT" />}
            {!props.loading.effects.companies.loadCollection && (
              <>
                {props.companiesStore.data.length > 0 && (
                  <>
                    <Group style={{ padding: 0, margin: 0, flexDirection: 'column' }}>
                      {props.companiesStore.data.map(company => (
                        <React.Fragment key={company.id}>
                          <Group style={{ marginTop: '5px' }}>
                            <span>&bull; {`${company.vatNumber} - ${company.companyName}`}</span>
                            <Button
                              style={{ marginLeft: '5px' }}
                              iconName="edit"
                              size={ButtonSize.Small}
                              onClick={() => {
                                setSelectedCompany(company);
                                toggleModalDialog(true);
                              }}
                            />
                            <Button
                              iconName="trash"
                              size={ButtonSize.Small}
                              onClick={() => {
                                modalConfirm({
                                  title: `Czy na pewno usunąć wybrane dane firmy?`,
                                  content: '',
                                  yesCallback: () => {
                                    const hide = message.loading('Usuwam dane firmy...', 0);
                                    props.companies
                                      .deleteItem({
                                        id: company.id,
                                      })
                                      .then(() => {
                                        message.success('Dane do faktury zostały usunięte');
                                        props.companies.loadCollection({
                                          params: {
                                            customer: item.id,
                                          },
                                        });
                                      })
                                      .catch(() => {
                                        message.error('Wystąpił nieoczekiwany błąd.');
                                      })
                                      .finally(() => {
                                        hide();
                                      });
                                  },
                                });
                              }}
                            />
                            <Spacer horizontal />
                          </Group>
                        </React.Fragment>
                      ))}
                    </Group>
                  </>
                )}
              </>
            )}

            <Spacer horizontal />

            <Group>
              <div style={{ marginTop: '10px' }}>
                <Button
                  variant={ButtonVariant.Primary}
                  style={{ padding: '0.3rem' }}
                  label="Dodaj nowy"
                  onClick={() => {
                    setSelectedCompany(null);
                    toggleModalDialog(true);
                  }}
                />
              </div>
            </Group>
          </Group>
        </Group>

        <Divider text="Telefon(y)" />

        <Group wrap>
          {props.loading.effects.clientPhones.loadCollection && <Loading text="Ładowanie numerów kontaktowych" />}
          {!props.loading.effects.clientPhones.loadCollection && (
            <>
              {props.clientPhonesStore.data.length > 0 && (
                <>
                  {props.clientPhonesStore.data.map((phoneObject, index) => (
                    <React.Fragment key={phoneObject.id}>
                      {index === 0 ? <strong>{phoneObject.phone}</strong> : <span>{phoneObject.phone}</span>}
                      <Button
                        iconName="edit"
                        size={ButtonSize.Small}
                        onClick={() => {
                          setSelectedPhone(phoneObject);
                          togglePhoneDialog(true);
                        }}
                      />
                      <Button
                        iconName="trash"
                        size={ButtonSize.Small}
                        onClick={() => {
                          modalConfirm({
                            title: `Czy na pewno usunąć numer telefonu?`,
                            content: '',
                            yesCallback: () => {
                              const hide = message.loading('Usuwam numer telefonu...', 0);
                              props.clientPhones
                                .deleteItem({
                                  customerId: item.id,
                                  id: phoneObject.id,
                                })
                                .then(() => {
                                  message.success('Numer telefonu został usunięty');
                                  props.clientPhones.loadCollection({
                                    customerId: item.id,
                                  });
                                })
                                .catch(() => {
                                  message.error('Wystąpił nieoczekiwany błąd.');
                                })
                                .finally(() => {
                                  hide();
                                });
                            },
                          });
                        }}
                      />
                      <Spacer horizontal />
                    </React.Fragment>
                  ))}
                </>
              )}
            </>
          )}

          <Spacer horizontal />

          <Group>
            <div style={{ marginTop: '10px' }}>
              <Button
                variant={ButtonVariant.Primary}
                style={{ padding: '0.3rem' }}
                label="Dodaj telefon"
                onClick={() => {
                  setSelectedPhone(null);
                  togglePhoneDialog(true);
                }}
              />
            </div>
          </Group>
        </Group>

        <Divider text="Adresy" />

        <Group vertical={false} wrap verticalAlign="top">
          {props.loading.effects.clientAddress.loadCollection && <Loading text="Ładowanie adresów dostawy" />}
          {!props.loading.effects.clientAddress.loadCollection && (
            <>
              {props.clientAddressStore.data.length > 0 && (
                <>
                  <AddressComponent data={props.clientAddressStore.data} preview clientId={item.id} />
                </>
              )}
            </>
          )}

          <Spacer horizontal />

          <Group>
            <div style={{ marginTop: '10px' }}>
              <Button
                variant={ButtonVariant.Primary}
                style={{ padding: '0.3rem' }}
                label="Dodaj nowy adres"
                onClick={() => toggleAddressDialog(true)}
              />
            </div>
          </Group>
        </Group>

        <Divider text="Ostatnie zamówienia" />
        <Group vertical={false} wrap verticalAlign="top">
          {props.loading.effects.orders.loadCollection && <Loading text="Ładowanie historii zamówień" />}
          {!props.loading.effects.orders.loadCollection && (
            <>
              {props.ordersStore.data.length > 0 && (
                <>
                  <OrderClientDetailsTable
                    data={props.ordersStore.data}
                    clientId={item.id}
                    buttonTitle={'szczegóły'}
                    onOrderDetailsClick={order => {
                      setOrder(order);
                      toggleOrderDetails();
                    }}
                  />
                </>
              )}
            </>
          )}
        </Group>

        <Divider />
        <Spacer />

        <Group align="space-between">
          <Group>
            <Button
              label="Historia zamówień"
              onClick={() => {
                props.onOrdersHistoryClick(item.id);
              }}
            />
          </Group>
          <Group>
            <Button variant={ButtonVariant.Primary} label="Edytuj dane podstawowe" onClick={props.onEditClick} />
            <Button
              variant={ButtonVariant.Primary}
              label={item.user.isActive ? 'Zablokuj' : 'Odblokuj'}
              onClick={() => {
                modalConfirm({
                  title: `Czy na pewno ${item.user.isActive ? 'zablokować' : 'odblokować'} klienta?`,
                  content: ' ',
                  okText: item.user.isActive ? 'Zablokuj' : 'Odblokuj',
                  yesCallback: () => {
                    const hide = message.loading(`${item.user.isActive ? 'Blokuje' : 'Odblokowuje'} klienta...`, 0);
                    props.clients
                      .updateItem({
                        id: item.id,
                        data: {
                          userId: item.user.id,
                          isActive: !item.user.isActive,
                        },
                      })
                      .then(() => {
                        message.success(`Klient ${item.user.isActive ? 'zablokowany' : 'odblokowany'}`);
                        closeDrawer();
                      })
                      .catch(() => {
                        message.error('Wystąpił nieoczekiwany bład');
                      })
                      .finally(hide);
                  },
                });
              }}
            />
            <Button
              label="Usuń"
              iconName="trash-alt"
              onClick={() => {
                modalConfirm({
                  title: 'Czy na pewno usunąć klienta?',
                  content: ' ',
                  okText: 'Usuń',
                  yesCallback: () => {
                    const hide = message.loading('Usuwam klienta...', 0);
                    props.clients
                      .deleteItem({
                        id: item.id,
                      })
                      .then(() => {
                        message.success('Klient usunięty');
                        closeDrawer();
                      })
                      .catch(() => {
                        message.error('Wystąpił nieoczekiwany bład');
                      })
                      .finally(hide);
                  },
                });
              }}
            />
          </Group>
        </Group>
      </Group>

      {phoneDialogVisible && (
        <SimpleInputDialog
          open
          loading={props.loading.effects.clientPhones.createItem || props.loading.effects.clientPhones.updateItem}
          errors={props.clientPhonesStore.errors}
          inputValue={selectedPhone !== null ? selectedPhone.phone : ''}
          title={selectedPhone ? `Edycja numeru telefonu` : `Dodawanie numer telefonu`}
          inputLabel="Numer telefonu"
          okText={selectedPhone ? 'Zapisz' : 'Dodaj'}
          onSave={data => {
            const phone = data.values.input;
            if (!selectedPhone) {
              props.clientPhones
                .createItem({
                  data: {
                    phone,
                  },
                  customerId: item.id,
                })
                .then(() => {
                  message.success('Numer telefon został dodany');
                  togglePhoneDialog(false);
                });
            } else {
              props.clientPhones
                .updateItem({
                  data: {
                    phone,
                  },
                  id: selectedPhone.id,
                  customerId: item.id,
                })
                .then(() => {
                  message.success('Numer telefon został zaktualizowany');
                  togglePhoneDialog(false);
                });
            }
          }}
          onCancel={() => {
            togglePhoneDialog(false);
          }}
        />
      )}

      {addressDialogVisible && (
        <AddressDialog
          open
          loading={props.loading.effects.clientAddress.createItem || props.loading.effects.clientAddress.updateItem}
          errors={props.clientAddressStore.errors}
          title={`Dodawanie adresu`}
          okText={'Dodaj'}
          withNotes
          onSave={data => {
            props.clientAddress
              .createItem({
                data: {
                  street: data.values.street,
                  postalCode: data.values.postalCode,
                  city: data.values.city,
                  houseNumber: data.values.houseNumber,
                  details: data.values.details,
                },
                customerId: item.id,
              })
              .then(() => {
                message.success('Adres został dodany');
                toggleAddressDialog(false);
              });
          }}
          onCancel={() => {
            toggleAddressDialog(false);
            setClosable(true);
          }}
        />
      )}

      {companyModalVisible && (
        <CompanyModal
          open
          value={selectedCompany}
          okText={selectedCompany ? 'Zapisz' : 'Dodaj'}
          title={selectedCompany ? `Edycja danych do faktury` : `Dodawanie nowych danych do faktury`}
          clientId={item.id}
          loading={props.loading.effects.companies.createItem || props.loading.effects.companies.updateItem}
          onCancel={() => {
            toggleModalDialog(false);
            setClosable(true);
          }}
          onSave={() => {
            toggleModalDialog(false);
          }}
        />
      )}

      {discountDialogVisible && (
        <SimpleInputDialog
          open
          inputValue={item.discount}
          title={'Edycja zniżki klienta'}
          inputLabel="Wartość zniżki"
          okText={'Zapisz'}
          onSave={data => {
            const discount = data.values.input;
            props.clients
              .updateItem({
                data: {
                  discount,
                },
                id: item.id,
              })
              .then(() => {
                message.success('Zniżka klienta została zaktualizowana');
                item.discount = discount;
                toggleDiscountDialog(false);
              });
          }}
          onCancel={() => {
            toggleDiscountDialog(false);
          }}
        />
      )}

      {coinsDialogVisible && (
        <SimpleInputDialog
          open
          inputValue={item.points}
          title={'Edycja monet klienta'}
          inputLabel="Liczba monet do dodania"
          okText={'Zapisz'}
          onSave={data => {
            const points = data.values.input;
            props.clients
              .updateItem({
                data: {
                  points,
                },
                id: item.id,
              })
              .then(() => {
                message.success('Monety klienta zostały zaktualizowane');
                item.points = points;
                toggleCoinsDialog(false);
              });
          }}
          onCancel={() => {
            toggleCoinsDialog(false);
          }}
        />
      )}

      {orderDetailsOpened && (
        <OrderDetailsDialog
          isEditable={false}
          orderId={order.id}
          visible={orderDetailsOpened}
          onClose={toggleOrderDetails}
        />
      )}
    </Drawer>
  );
});

export { ClientDrawer };
