import { createModel } from '@rematch/core';

export const UiModel = createModel({
  state: {
    orderStep2Url: '',
  },
  reducers: {
    setOrder2StepUrl(state, url: string) {
      return {
        ...state,
        orderStep2Url: url,
      };
    },
  },
});
