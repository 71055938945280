/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services';

const mainApiCollection = 'customers';
const subCollection = 'addresses';

export const ClientAddressModel = SimpleRestModel({
  collectionName: 'clientAddress',
  HttpApi,
  getApiPath: (payload, action: string) => {
    switch (action) {
      case 'loadCollection':
      case 'createItem':
      default:
        return `${mainApiCollection}/${payload.customerId}/${subCollection}/`;
      case 'loadItem':
      case 'updateItem':
      case 'deleteItem':
        return `${mainApiCollection}/${payload.customerId}/${subCollection}/${payload.id}/`;
      case 'customCollectionAction':
        return `${mainApiCollection}/${payload.customerId}/${subCollection}/${payload.action}/`;
      case 'customItemAction':
        return `${mainApiCollection}/${payload.customerId}/${subCollection}/${payload.id}/${payload.action}/`;
    }
  },
});

export interface ClientAddress {
  id: number;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  lon: number;
  lat: number;
  details: string;
}
