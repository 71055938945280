import { Affix, message } from 'antd';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { compose } from 'recompose';
import connect from 'react-redux/es/connect/connect';
import useToggle from 'react-use/lib/useToggle';

import {
  DateRangePickerModal,
  RestaurantBasicInputModal,
  RestaurantMenuMealModal,
  RestaurantGroupAvailabilityModal,
  RestaurantMealExtrasModal,
  AffixContent,
} from 'components';
import { MealGroups, MealUtils } from 'modules/Restaurant';
import {
  Button,
  ButtonSize,
  EmptyState,
  Group,
  Loading,
  modalConfirm,
  SimpleSortableDialog,
  Spacer,
  styled,
} from 'lib/ui';
import { Events } from 'lib/services';
import { Meals } from 'modules/Restaurant/components/Meals';
import { SearchInput } from 'lib/forms';

export interface RestaurantMenuFormProps {
  children?: ReactNode;
  restaurant?: any;
  onModalToggle: Function;
}

const RestaurantMenuFormDefaultProps = {};

const enhance = compose(
  connect(
    ({ restaurantsMealGroup, restaurantsMeals, restaurantsExtras, loading }) => ({
      restaurantsMealGroupStore: restaurantsMealGroup,
      restaurantsMealsStore: restaurantsMeals,
      restaurantsExtrasStore: restaurantsExtras,
      loading,
    }),
    ({ restaurantsMealGroup, restaurantsMeals, restaurantsExtras }) => ({
      restaurantsMealGroup,
      restaurantsMeals,
      restaurantsExtras,
    }),
  ),
);

const Container = styled.div.attrs({ className: 'RestaurantFormMenu' })`
  height: 100%;
  position: relative;
  overflow: auto;
`;

const RestaurantFormMenu: FunctionComponent<RestaurantMenuFormProps> = enhance(props => {
  const { restaurant } = props;

  //  sortable modal and items
  const [sortableMealModal, toggleSortableMealModal] = useToggle(false);
  const [sortableGroupModal, toggleSortableGroupModal] = useToggle(false);
  const [sortableItems, setSortableItems] = useState([]);

  const [groupModalVisible, toggleGroupModal] = useToggle(false);
  const [groupAvailabilityModalVisible, toggleGroupAvailabilityModal] = useToggle(false);
  const [dateRangePickerVisible, toggleDateRangePickerModal] = useToggle(false);
  const [MealModalVisible, toggleMealModal] = useToggle(false);
  const [MealExtrasModalVisible, toggleMealExtrasModal] = useToggle(false);

  useEffect(() => {
    props.onModalToggle(
      groupModalVisible ||
        MealModalVisible ||
        dateRangePickerVisible ||
        MealExtrasModalVisible ||
        groupAvailabilityModalVisible,
    );
  }, [
    MealModalVisible,
    groupModalVisible,
    dateRangePickerVisible,
    groupAvailabilityModalVisible,
    MealExtrasModalVisible,
  ]);

  useEffect(() => {
    if (!MealModalVisible) {
      props.restaurantsMeals.clearError();
    }
  }, [MealModalVisible]);

  useEffect(() => {
    if (restaurant) {
      props.restaurantsMealGroup.loadCollection({
        restaurantId: restaurant.id,
      });
      props.restaurantsMeals.loadCollection({
        restaurantId: restaurant.id,
      });
      props.restaurantsExtras.loadCollection({
        restaurantId: restaurant.id,
      });
    }
    return () => {
      props.restaurantsMealGroup.reset();
      props.restaurantsMeals.reset();
    };
  }, [restaurant]);

  useEffect(() => {
    Events.on('Model.restaurantsMeals.createItem.success', () => {
      message.success('Posiłek został dodany');
      toggleMealModal(false);
      props.restaurantsMeals.clearError();

      if (restaurant) {
        props.restaurantsMealGroup.loadCollection({
          restaurantId: restaurant.id,
        });
        props.restaurantsMeals.loadCollection({
          restaurantId: restaurant.id,
        });
      }
    });
    Events.on('Model.restaurantsMeals.updateItem.success', () => {
      message.success('Posiłek został zaktualizowany');
      toggleMealModal(false);
      props.restaurantsMeals.clearError();
    });
    return () => {
      Events.off('Model.restaurantsMeals.createItem.success', true);
      Events.off('Model.restaurantsMeals.updateItem.success', true);
    };
  }, []);

  //  on meal group update
  useEffect(() => {
    Events.on('Model.restaurantsMealGroup.updateItem.success', () => {
      // message.success('Dział został zaktualizowany');
      toggleDateRangePickerModal(false);
    });
    return () => {
      Events.off('Model.restaurantsMealGroup.updateItem.success', true);
    };
  }, []);

  const [editedGroupMeal, setEditedGroupMeal] = useState<any>({});
  const [defaultMealGroup, setDefaultMealGroup] = useState('');
  const [editedMeal, setEditedMeal] = useState<any>({});

  //  reset meal form when closing meal modal
  useEffect(() => {
    if (!MealModalVisible) {
      setEditedMeal({});
      setDefaultMealGroup('');
    }
  }, [MealModalVisible]);

  //  search query
  const [query, setQuery] = useState('');

  //  selected meals
  const [selectedMeals, setSelectedMeals] = useState<any>([]);
  //  additional date range picker
  const [dateRangePickerMultiOpened, toggleDateRangePickerMulti] = useToggle(false);

  const mealGroups = props.restaurantsMealGroupStore.data;
  const meals = props.restaurantsMealsStore.data;

  return (
    <Container>
      <Affix
        target={() => {
          //  todo: move it to service
          //  @ts-ignore
          return window.drawerContentRef;
        }}
      >
        <AffixContent>
          <Group align="space-between">
            <Group>
              <SearchInput value={query} onChange={setQuery} />
            </Group>
            <Group align="right">
              <Button
                label="Dodaj potrawę"
                disabled={!mealGroups.length}
                onClick={() => {
                  toggleMealModal();
                }}
              />
              <Button
                label="Dodaj dział"
                onClick={() => {
                  setEditedGroupMeal({});
                  toggleGroupModal();
                }}
              />
            </Group>
          </Group>
          {selectedMeals.length > 0 && (
            <>
              <Spacer />

              <Group>
                <span>Wybrano posiłków: {selectedMeals.length}</span>
                <Button
                  disabled={selectedMeals.length === 0}
                  label="Nieaktywne dziś"
                  size={ButtonSize.Small}
                  onClick={() => {
                    modalConfirm({
                      title: `Czy na pewno ustawić jako nieaktywne dzisiaj wybrane posłki? (${selectedMeals.length})`,
                      content: (
                        <ul>
                          {selectedMeals.map(item => (
                            <li key={item.id}>{item.namePl}</li>
                          ))}
                        </ul>
                      ),
                      yesCallback: () => {
                        const hide = message.loading('Ustawiam jako nieaktywne wybrane posiłkie...', 0);
                        props.restaurantsMeals
                          .customCollectionAction({
                            restaurantId: restaurant.id,
                            data: {
                              mealIds: selectedMeals.map(r => r.id),
                            },
                            action: 'disable-today',
                          })
                          .then(() => {
                            message.success('Posiłki zostały zaktualizowane');
                            props.restaurantsMeals.loadCollection({
                              restaurantId: restaurant.id,
                            });
                            setSelectedMeals([]);
                          })
                          .catch(() => {
                            message.error('Wystąpił nieoczekiwany błąd.');
                          })
                          .finally(() => {
                            hide();
                          });
                      },
                    });
                  }}
                />
                <Button
                  disabled={selectedMeals.length === 0}
                  size={ButtonSize.Small}
                  label="Zablokuj"
                  onClick={() => {
                    modalConfirm({
                      title: `Czy na pewno zablokować wybrane posiłki? (${selectedMeals.length})`,
                      content: (
                        <ul>
                          {selectedMeals.map(item => (
                            <li key={item.id}>{item.namePl}</li>
                          ))}
                        </ul>
                      ),
                      yesCallback: () => {
                        const hide = message.loading('Blokuję wybrane posiłkie...', 0);
                        props.restaurantsMeals
                          .customCollectionAction({
                            restaurantId: restaurant.id,
                            data: {
                              mealIds: selectedMeals.map(r => r.id),
                            },
                            action: 'block',
                          })
                          .then(() => {
                            message.success('Posiłki zostały zablokowane');
                            props.restaurantsMeals.loadCollection({
                              restaurantId: restaurant.id,
                            });
                            setSelectedMeals([]);
                          })
                          .catch(() => {
                            message.error('Wystąpił nieoczekiwany błąd.');
                          })
                          .finally(() => {
                            hide();
                          });
                      },
                    });
                  }}
                />
                <Button
                  disabled={selectedMeals.length === 0}
                  size={ButtonSize.Small}
                  label="Nieaktywne do..."
                  onClick={() => {
                    toggleDateRangePickerMulti(true);
                  }}
                />
              </Group>
            </>
          )}
        </AffixContent>
      </Affix>

      {(props.loading.effects.restaurantsMeals.loadCollection ||
        props.loading.effects.restaurantsMealGroup.loadCollection) && <Loading text="Ładowanie menu" />}

      <RestaurantBasicInputModal
        visible={groupModalVisible}
        item={editedGroupMeal}
        onClose={() => {
          toggleGroupModal(false);
        }}
        onSubmit={(values, bag) => {
          if (editedGroupMeal && editedGroupMeal.id) {
            props.restaurantsMealGroup
              .updateItem({
                restaurantId: props.restaurant.id,
                id: editedGroupMeal.id,
                data: values,
              })
              .then(() => {
                toggleGroupModal(false);
              })
              .catch(() => {
                toggleGroupModal(false);
              });
          } else {
            props.restaurantsMealGroup
              .createItem({
                restaurantId: props.restaurant.id,
                data: values,
              })
              .then(() => {
                toggleGroupModal(false);
              })
              .catch(() => {
                toggleGroupModal(false);
              });
          }
        }}
      />

      {dateRangePickerVisible && (
        <DateRangePickerModal
          visible
          header={
            <span>
              Okres nieaktywności dla: <strong>{editedGroupMeal.namePl || editedMeal.namePl || ''}</strong>
            </span>
          }
          dateStart={editedGroupMeal.disableFrom || editedMeal.disableFrom || new Date()}
          dateEnd={editedGroupMeal.disableTo || editedMeal.disableTo || new Date()}
          onClose={() => {
            toggleDateRangePickerModal(false);
          }}
          onSubmit={(values, bag) => {
            if (editedGroupMeal && editedGroupMeal.id) {
              props.restaurantsMealGroup
                .updateItem({
                  restaurantId: props.restaurant.id,
                  id: editedGroupMeal.id,
                  data: {
                    disableFrom: values.start,
                    disableTo: values.end,
                  },
                })
                .then(() => {
                  bag.setSubmitting(false);
                  toggleDateRangePickerModal(false);
                });
            }
            if (editedMeal && editedMeal.id) {
              props.restaurantsMeals
                .updateItem({
                  restaurantId: props.restaurant.id,
                  id: editedMeal.id,
                  data: {
                    disableFrom: values.start,
                    disableTo: values.end,
                  },
                })
                .then(() => {
                  bag.setSubmitting(false);
                  toggleDateRangePickerModal(false);
                });
            }
          }}
          onReset={bag => {
            toggleDateRangePickerModal(false);
            if (editedGroupMeal && editedGroupMeal.id) {
              props.restaurantsMealGroup.updateItem({
                restaurantId: props.restaurant.id,
                id: editedGroupMeal.id,
                data: {
                  disableFrom: null,
                  disableTo: null,
                },
              });
            }
            if (editedMeal && editedMeal.id) {
              props.restaurantsMeals.updateItem({
                restaurantId: props.restaurant.id,
                id: editedMeal.id,
                data: {
                  disableFrom: null,
                  disableTo: null,
                },
              });
            }
          }}
        />
      )}

      {dateRangePickerMultiOpened && (
        <DateRangePickerModal
          visible
          header={<span>Okres nieaktywności dla wybranych posiłków</span>}
          onClose={() => {
            toggleDateRangePickerMulti(false);
          }}
          onSubmit={(values, bag) => {
            toggleDateRangePickerMulti(false);
            modalConfirm({
              title: `Czy na pewno ustawić nieaktywne wybrane posiłki (${selectedMeals.length}) na wybrany okres czasu?`,
              content: ``,
              yesCallback: () => {
                const hide = message.loading('Blokuję wybrane posiłkie...', 0);
                props.restaurantsMeals
                  .customCollectionAction({
                    restaurantId: restaurant.id,
                    data: {
                      mealIds: selectedMeals.map(r => r.id),
                      start: values.start,
                      end: values.end,
                    },
                    action: 'disable-dates',
                  })
                  .then(() => {
                    message.success('Posiłki zostały zablokowane');
                    bag.setSubmitting(false);
                    props.restaurantsMeals.loadCollection({
                      restaurantId: restaurant.id,
                    });
                    setSelectedMeals([]);
                  })
                  .catch(() => {
                    message.error('Wystąpił nieoczekiwany błąd.');
                  })
                  .finally(() => {
                    hide();
                  });
              },
              noCallback: () => {
                bag.setSubmitting(false);
              },
            });
          }}
        />
      )}

      <RestaurantGroupAvailabilityModal
        visible={groupAvailabilityModalVisible}
        header={
          <span>
            W jakich godzinach można zamawiać? <strong>{editedGroupMeal.namePl || ''}</strong>
          </span>
        }
        item={editedGroupMeal}
        onClose={() => {
          toggleGroupAvailabilityModal(false);
        }}
        onSubmit={(values, bag) => {
          props.restaurantsMealGroup
            .updateItem({
              restaurantId: props.restaurant.id,
              id: editedGroupMeal.id,
              data: values,
            })
            .then(() => {
              toggleGroupAvailabilityModal(false);
            });
        }}
      />

      {MealModalVisible && (
        <RestaurantMenuMealModal
          item={editedMeal}
          mealGroups={mealGroups}
          defaultGroupMeal={defaultMealGroup}
          errors={props.restaurantsMealsStore.errors}
          onClose={() => {
            toggleMealModal(false);
          }}
          onSubmit={(values, bag) => {
            const formData = new FormData();
            Object.keys(values).forEach(valueKey => {
              if (typeof values[valueKey] !== 'undefined') {
                formData.append(valueKey, values[valueKey]);
              }
            });
            if (editedMeal.id) {
              props.restaurantsMeals
                .updateItem({
                  restaurantId: props.restaurant.id,
                  id: editedMeal.id,
                  data: formData,
                })
                .then(() => {
                  bag.setSubmitting(false);
                })
                .catch(() => {
                  bag.setSubmitting(false);
                });
            } else {
              props.restaurantsMeals
                .createItem({
                  restaurantId: props.restaurant.id,
                  data: formData,
                })
                .then(() => {
                  bag.setSubmitting(false);
                })
                .catch(() => {
                  bag.setSubmitting(false);
                });
            }
          }}
        />
      )}

      {MealExtrasModalVisible && (
        <RestaurantMealExtrasModal
          restaurant={restaurant}
          meal={editedMeal}
          extras={props.restaurantsExtrasStore.data}
          onClose={() => {
            toggleMealExtrasModal(false);
          }}
        />
      )}

      {sortableMealModal && (
        <SimpleSortableDialog
          items={sortableItems}
          title="Zmień kolejność dla posiłków"
          onCancel={() => {
            toggleSortableMealModal(false);
          }}
          onSave={items => {
            props.restaurantsMeals
              .customCollectionAction({
                restaurantId: restaurant.id,
                action: 'update_position',
                data: {
                  mealsList: items.map((item, index) => ({
                    id: item.id,
                    position: index,
                  })),
                },
              })
              .then(() => {
                return props.restaurantsMeals
                  .loadCollection({
                    restaurantId: restaurant.id,
                  })
                  .finally(() => {
                    message.success('Kolejność została zaktualizowana');
                    toggleSortableMealModal(false);
                  });
              })
              .catch(() => {
                message.error('Wystąpił nieoczekiwany błąd.');
                toggleSortableMealModal(false);
              });
          }}
        />
      )}

      {sortableGroupModal && (
        <SimpleSortableDialog
          items={sortableItems}
          title="Zmień kolejność dla grup posiłków"
          onCancel={() => {
            toggleSortableGroupModal(false);
          }}
          onSave={items => {
            props.restaurantsMealGroup
              .customCollectionAction({
                restaurantId: restaurant.id,
                action: 'update_position',
                data: {
                  menuGroupsList: items.map((item, index) => ({
                    id: item.id,
                    position: index,
                  })),
                },
              })
              .then(() => {
                return props.restaurantsMealGroup
                  .loadCollection({
                    restaurantId: restaurant.id,
                  })
                  .finally(() => {
                    message.success('Kolejność została zaktualizowana');
                    toggleSortableGroupModal(false);
                  });
              })
              .catch(() => {
                message.error('Wystąpił nieoczekiwany błąd.');
                toggleSortableGroupModal(false);
              });
          }}
        />
      )}

      {!props.loading.effects.restaurantsMeals.loadCollection &&
        !props.loading.effects.restaurantsMealGroup.loadCollection && (
          <MealGroups
            groups={mealGroups}
            onEdit={item => {
              setEditedGroupMeal(item);
              toggleGroupModal(true);
            }}
            onActionClock={item => {
              setEditedGroupMeal(item);
              toggleGroupAvailabilityModal(true);
            }}
            onActionPowerOff={item => {
              setEditedMeal({});
              setEditedGroupMeal(item);
              toggleDateRangePickerModal(true);
            }}
            onActionSort={() => {
              setSortableItems(
                mealGroups.map(item => ({
                  ...item,
                  name: item.namePl,
                })),
              );
              toggleSortableGroupModal(true);
            }}
            onAddMeal={item => {
              setEditedMeal({});
              setDefaultMealGroup(item.id);
              toggleMealModal(true);
            }}
            onDelete={item => {
              modalConfirm({
                title: `Czy na pewno usunąć grupę posiłków ${item.namePl}?`,
                content: 'Wszystkie potrawy zostaną usunięte.',
                yesCallback: () => {
                  console.warn('usuń', item);
                },
                noCallback: () => {
                  console.warn('usuń nie', item);
                },
              });
            }}
            renderMeals={groupId => {
              return (
                <Meals
                  items={meals.filter(meal => {
                    return meal.group.id === groupId && MealUtils.mealContainsPhrase(meal, query);
                  })}
                  onEdit={item => {
                    setEditedMeal(item);
                    toggleMealModal(true);
                  }}
                  selected={selectedMeals}
                  onToggleSelect={item => {
                    if (selectedMeals.find(meal => meal.id === item.id)) {
                      setSelectedMeals(selectedMeals.filter(meal => meal.id !== item.id));
                    } else {
                      setSelectedMeals([...selectedMeals, item]);
                    }
                  }}
                  onActionSort={() => {
                    setSortableItems(
                      meals
                        .filter(meal => meal.group.id == groupId)
                        .map(meal => ({
                          ...meal,
                          name: meal.namePl,
                        })),
                    );
                    toggleSortableMealModal(true);
                  }}
                  onExtrasClick={item => {
                    setEditedMeal(item);
                    toggleMealExtrasModal(true);
                  }}
                  onStatusChangeClick={item => {
                    const name = item.namePl;
                    const hide = message.loading(`Zmieniam status posiłku: ${name}`, 0);
                    props.restaurantsMeals
                      .updateItem({
                        restaurantId: props.restaurant.id,
                        id: item.id,
                        data: {
                          isActive: !item.isActive,
                        },
                      })
                      .then(() => {
                        hide();
                      });
                  }}
                  //  use in multiactions
                  // onActionPowerOff={item => {
                  //   setEditedMeal(item);
                  //   setEditedGroupMeal({});
                  //   toggleDateRangePickerModal(true);
                  // }}
                  onDelete={item => {
                    modalConfirm({
                      title: `Czy na pewno usunąć posiłek ${item.namePl}?`,
                      content: '',
                      yesCallback: () => {
                        console.warn('usuń', item);
                      },
                      noCallback: () => {
                        console.warn('usuń nie', item);
                      },
                    });
                  }}
                />
              );
            }}
          />
        )}

      {!props.restaurantsMealGroupStore.data.length &&
        !props.loading.effects.restaurantsMeals.loadCollection &&
        !props.loading.effects.restaurantsMealGroup.loadCollection && (
          <EmptyState message={'Brak grup posiłków'} simple>
            Dodaj pierwszą grupę posiłków, by móc dodawać posiłki.
          </EmptyState>
        )}
    </Container>
  );
});

RestaurantFormMenu.defaultProps = RestaurantMenuFormDefaultProps;

export { RestaurantFormMenu };
