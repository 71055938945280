import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { message } from 'antd';

import { BalanceWithDescriptionModal } from 'components/modals/BalanceWithDescriptionModal';
import { DialogService } from 'lib/services/DialogService';
import { Button, ButtonSize, ButtonVariant, Group, Spacer, styled } from 'lib/ui';
import { Balance } from 'modules/Clients';

export interface ClientBalanceProps {
  clientId: number;
}

const ClientBalanceContainer = styled.div.attrs({ className: 'ClientBalanceContainer' })`
  display: block;
  width: 100%;
  padding-bottom: ${p => p.theme.space};
`;

export const ClientBalance: React.FC<ClientBalanceProps> = props => {
  const dispatch = useDispatch();
  const [isLoading, toggleLoading] = useToggle(false);
  const clients = useSelector(state => state.clients.dataById);
  const client = clients[props.clientId];

  return (
    <ClientBalanceContainer>
      <strong>Saldo klienta</strong>
      <Spacer size="small" />
      <Group>
        {client.balance !== 0 && <span>{client.balance < 0 ? 'Niedopłata' : 'Nadpłata'}:</span>}
        <Balance balance={client.balance} />
      </Group>

      {client.balanceNotes && <pre>{client.balanceNotes}</pre>}

      <Group>
        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          loading={isLoading}
          label="Zmień"
          onClick={() => {
            DialogService.open(BalanceWithDescriptionModal, {
              amount: client.balance,
              description: client.balanceNotes,
              onClose: values => {
                if (!values) {
                  return;
                }

                toggleLoading(true);
                //  update order with bonus value and description
                dispatch({
                  type: 'clients/updateItem',
                  payload: {
                    id: client.id,
                    data: {
                      balance: values.bonus,
                      balanceNotes: values.description,
                    },
                  },
                })
                  .then(data => {
                    const messageText = data && data.detail;
                    dispatch({
                      type: 'clients/loadItem',
                      payload: {
                        id: client.id,
                      },
                    }).then(() => {
                      message.success(messageText || 'Saldo klienta zostało zaktualizowane');
                      toggleLoading(false);
                    });
                  })
                  .catch(err => {
                    const messageText = err.data && err.data.detail;
                    message.error(messageText || 'Wystąpił nieoczekiwany błąd');
                  })
                  .finally(() => toggleLoading(false));
              },
            });
          }}
        />
      </Group>
    </ClientBalanceContainer>
  );
};
