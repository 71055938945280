import { Switch } from 'antd';

import { AgGridTable, Button, ButtonSize, Group } from 'lib/ui';
import React, { FunctionComponent } from 'react';
import { ExtraExtra } from 'modules/ExtraExtras/ExtraExtrasModel';
import { ExtraExtraMap } from 'modules/ExtraExtras/ExtraExtraUtils';

export interface ExtraExtrasTableProps {
  extraExtras?: ExtraExtra[];
  onEditClick: Function;
  onStatusChangeClick: Function;
}

const ExtraExtrasTable: FunctionComponent<ExtraExtrasTableProps> = ({
  extraExtras,
  onEditClick,
  onStatusChangeClick,
}) => {
  const columns = [
    {
      headerName: 'Nazwa PL',
      field: 'namePl',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: props => <a onClick={() => onEditClick(props.data)}>{props.data.namePl}</a>,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Nazwa EN',
      field: 'nameEn',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: props => <a onClick={() => onEditClick(props.data)}>{props.data.nameEn}</a>,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Cena',
      field: 'price',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRendererFramework: props => `${props.data.price} zł`,
      suppressSizeToFit: false,
    },
    {
      headerName: 'Status',
      cellRendererFramework: props => (
        <Switch checked={props.data.isActive} size="small" onClick={() => onStatusChangeClick(props.data)} />
      ),
      suppressSizeToFit: false,
    },
    {
      headerName: 'Akcje',
      cellRendererFramework: props => {
        return (
          <Group>
            <Button
              size={ButtonSize.Small}
              label={'edytuj'}
              iconName="edit"
              onClick={event => onEditClick(props.data)}
            />
          </Group>
        );
      },
      suppressSizeToFit: false,
    },
  ];

  const extraExtrasMapped = (extraExtras || []).map(extraExtra => ExtraExtraMap.apiDataToViewData(extraExtra));

  return (
    <AgGridTable
      columnDefs={columns}
      rowData={extraExtrasMapped}
      rowSelection="multiple"
      enableCellChangeFlash={true}
      onViewportChanged={params => params.api.sizeColumnsToFit()}
      onGridSizeChanged={params => params.api.sizeColumnsToFit()}
      onGridReady={params => params.api.sizeColumnsToFit()}
    />
  );
};

ExtraExtrasTable.defaultProps = {};

export { ExtraExtrasTable };
