/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SimpleRestModel } from 'lib/services';
import { HttpApi } from 'services/HttpApi';

export const CurrentOrderModel = SimpleRestModel({
  collectionName: 'currentOrders',
  apiPath: 'orders/currentOrders',
  HttpApi,
  getApiPath: (payload: any, action: string) => {
    const base = 'orders/currentOrders';
    switch (action) {
      case 'loadCollection':
      case 'createItem':
      default:
        return `${base}/`;
      case 'updateItem':
      case 'deleteItem':
      case 'loadItem':
        return `${base}/details/${payload.id}/`;
      case 'customCollectionAction':
        return `${base}/${payload.action}/`;
      case 'customItemAction':
        return `${base}/${payload.id}/${payload.action}/`;
    }
  },
});
