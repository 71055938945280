import { IDatasource } from 'ag-grid';
import { AgGridColumnProps } from 'ag-grid-react';
import { format } from 'date-fns';
import { getOrderTypeName } from 'modules/Orders/utils/getOrderTypeName';
import { getDeliveryAddressFromOrder, getFormattedPriceFromOrder } from 'modules/Orders/utils/order.getters';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AgGridTable, formattedPrice, formattedShortDateWithYear, formattedTime, Icon, IconFa, Loading } from 'lib/ui';
import { getLabelForStatus } from 'modules/Orders/components/OrderTable/getLabelForStatus';
import { OrderModel } from 'modules/Orders/models/order.model';
import { editIconString, loadingIconString, AbsoluteTopContainer } from 'components/UiHelpers';

export interface ArchiveOrdersTableProps {
  // orders: OrderModel[];
  onOrderDetails?: (order: OrderModel) => void;
  query: string;
  endDate: Date;
  startDate: Date;
}

const PAGINATION_LIMIT = 25;

export const ArchiveOrdersTable: React.FC<ArchiveOrdersTableProps> = props => {
  const dispatch = useDispatch();
  const gridParams = useRef<any>();
  const [isLoading, setLoading] = useState(false);

  const dataSource: IDatasource = {
    getRows: params => {
      setLoading(true);
      dispatch({
        type: 'orders/loadCollection',
        payload: {
          params: {
            limit: PAGINATION_LIMIT,
            search: props.query,
            status: [40],
            // eslint-disable-next-line @typescript-eslint/camelcase
            insert_date__lte: format(props.endDate, 'YYYY-MM-DD'),
            // eslint-disable-next-line @typescript-eslint/camelcase
            insert_date__gte: format(props.startDate, 'YYYY-MM-DD'),
            offset: params.startRow,
          },
        },
      }).then(data => {
        const orders = data.results.map(order => ({
          ...order,
          __date: `${formattedShortDateWithYear(order.receiveDate)} ${formattedTime(order.receiveDate)}`,
          __orderType: getOrderTypeName(order.orderType),
          __address: getDeliveryAddressFromOrder(order),
          __status: getLabelForStatus(order.status),
          __amount: getFormattedPriceFromOrder(order),
        }));
        params.successCallback(orders, data.count);
        setLoading(false);
      });
    },
  };

  useEffect(() => {
    if (gridParams.current) {
      gridParams.current.api.setDatasource(dataSource);
    }
  }, [props.query, props.startDate, props.endDate]);

  const Loader = <Icon name="spinner" fa={IconFa.Fal} spin />;

  const columns: AgGridColumnProps[] = [
    {
      headerName: 'Klient',
      field: 'customer.name',
      tooltipValueGetter: params => params.value,
      cellRenderer: params => {
        if (!params.data) {
          return loadingIconString;
        }

        return `${params.modified ? editIconString : ''} ${params.data.customer ? params.data.customer.name : ''}`;
      },
    },
    {
      headerName: 'Telefon',
      field: 'deliveryAddress.phone',
    },
    {
      headerName: 'Data zam.',
      field: '__date',
    },
    {
      headerName: 'Typ',
      field: '__orderType',
    },
    {
      headerName: 'Email',
      field: 'deliveryAddress.email',
      tooltipValueGetter: params => params.value,
    },
    {
      headerName: 'Kwota zam.',
      field: '__amount',
    },
    {
      headerName: 'Płatn.',
      field: 'paymentData.namePl',
    },
    {
      headerName: 'Adres dostawy',
      field: '__address',
      tooltipValueGetter: params => params.value,
    },
    {
      headerName: 'Firma',
      field: 'invoiceData.companyName',
      tooltipValueGetter: params => params.value,
    },
    {
      headerName: 'Status',
      field: '__status',
    },
    {
      headerName: '',
      cellRenderer: params => {
        return params.data ? `<span class="pointer">Szczegóły</span>` : '';
      },
      onCellClicked: event => {
        if (props.onOrderDetails) {
          props.onOrderDetails(event.data);
        }
      },
    },
  ];

  return (
    <>
      {isLoading && (
        <AbsoluteTopContainer>
          <Loading />
        </AbsoluteTopContainer>
      )}
      <AgGridTable
        loadingCellRenderer={loadingIconString}
        columnDefs={columns}
        pagination
        rowModelType="infinite"
        cacheBlockSize={PAGINATION_LIMIT}
        paginationPageSize={PAGINATION_LIMIT}
        onGridReady={params => {
          gridParams.current = params;
          params.api.setDatasource(dataSource);
        }}
      />
    </>
  );
};
