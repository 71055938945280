import { styled } from 'lib/ui';

export const AddressContainer = styled.div.attrs({
  className: 'AddressContainer',
})<{
  compact?: boolean;
  verified?: boolean;
}>`
  width: ${p => (p.compact ? 'auto' : '250px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${p => (p.verified ? 'white' : 'rgba(0, 0, 0, 0.65)')};
  border-radius: 4px;
  max-width: ${p => (p.compact ? 'inherit' : '250px')};
  background: ${p => (p.verified ? '#3790FF' : '#f4f4f4' )};
`;
// border: 1px solid #d6d6d6;
// padding: 10px;
