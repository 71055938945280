export const replaceValueAtIndex = (array, index: number, value) => {
  let newValue;
  if (typeof value === 'function') {
    newValue = value(array[index]);
  } else {
    newValue = value;
  }

  return [...array.slice(0, index), newValue, ...array.slice(index + 1)];
};
