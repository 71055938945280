import React, { FunctionComponent, ReactNode } from 'react';
import { format, addDays, isWeekend } from 'date-fns';

import { styled, AgGridTableContainer, Group, formattedShortDateWithWeekDayName, AgGridTable, Tooltip } from 'lib/ui';
import {
  endsAt17,
  endsAt22,
  getHourEnd,
  getHourStart,
  isCustomSchedule,
  isEveningShift,
  isMorningShift,
  Schedule,
  startsAt17,
  startsAtMorning,
} from 'modules/Schedule';
import { Driver } from 'modules/Drivers';
import { Employee } from 'modules/Employees';

import { Scheduled, Free, Off, DayPaid } from '../UiHelpers';

export interface ScheduleDriversTableProps {
  children?: ReactNode;
  drivers?: Driver[];
  employees?: Employee[];
  schedules: Schedule[];
  startDate: Date;
  onScheduleDialog: (data: {
    userName: string;
    userId: string | number;
    schedule: Schedule;
    day: string | number;
  }) => void;
  userType: 'driver' | 'employee';
}

const ScheduleDrviersTableContainer = styled.div`
  position: relative;
`;

// @todo - clean up this code
// check ag-grid table api for refreshing cell headers

const getDayId = date => format(date, 'YYYY-MM-DD');

export const ScheduleDriversTable: FunctionComponent<ScheduleDriversTableProps> = props => {
  if (!props.drivers && !props.employees) {
    return null;
  }

  const getItemWithInfo = driverOrEmployee => {
    const item = {
      name: `${driverOrEmployee.user.firstName} ${driverOrEmployee.user.lastName}`,
      id: driverOrEmployee.id,
    };

    let day = new Date(props.startDate);
    for (let i = 0; i < 7; i++) {
      const dayId = getDayId(day);
      const schedule = props.schedules.find(_schedule => {
        return (
          // @ts-ignore
          _schedule[props.userType].id === driverOrEmployee.id && getDayId(_schedule.dateStart) === dayId
        );
      });
      if (schedule) {
        item[dayId] = {
          schedule,
          scheduleId: schedule.id,
          hourStart: getHourStart(schedule),
          hourEnd: getHourEnd(schedule),
          isHoliday: schedule.isHoliday,
          isPaid: schedule.isPaid,
        };
      } else {
        item[dayId] = {
          schedule: null,
        };
      }
      day = addDays(day, 1);
    }

    return item;
  };

  let items: any[] = [];
  if (props.drivers) {
    items = props.drivers.map(getItemWithInfo);
  }
  if (props.employees) {
    items = props.employees.map(getItemWithInfo);
  }

  const columns: any[] = [
    {
      headerName: 'Imię i nazwisko',
      field: 'name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
  ];

  let day = new Date(props.startDate);
  for (let i = 0; i < 7; i++) {
    const dayId = getDayId(day);
    const isWeekendDate = isWeekend(day);
    const formattedDay = formattedShortDateWithWeekDayName(day);
    const usersWithSchedules = items.filter(item => item[dayId] && item[dayId].schedule);

    const morningUsers = usersWithSchedules.filter(user => isMorningShift(user[dayId].schedule));
    const eveningUsers = usersWithSchedules.filter(user => isEveningShift(user[dayId].schedule));

    columns.push({
      headerName: `${formattedDay} (${morningUsers.length} - ${eveningUsers.length})`,
      field: getDayId(day),
      cellRendererFramework: cellProps => {
        return (
          <div
            onClick={() => {
              const itemDaySchedule = cellProps.data[dayId];

              if (itemDaySchedule.isPaid) {
                return;
              }
              props.onScheduleDialog({
                userName: cellProps.data.name,
                userId: cellProps.data.id,
                schedule: itemDaySchedule.schedule,
                day: dayId,
              });
            }}
          >
            <Group>
              {cellProps.data[dayId] && cellProps.data[dayId].isPaid && (
                <Tooltip text="Dzień rozliczony">
                  <DayPaid />
                </Tooltip>
              )}
              {cellProps.data[dayId] &&
                cellProps.data[dayId].schedule &&
                !cellProps.data[dayId].isHoliday &&
                !cellProps.data[dayId].isPaid && (
                  <Scheduled
                    marginLeft={
                      startsAtMorning(cellProps.data[dayId].schedule)
                        ? ''
                        : startsAt17(cellProps.data[dayId].schedule)
                        ? '50%'
                        : '20%'
                    }
                    marginRight={
                      endsAt22(cellProps.data[dayId].schedule)
                        ? ''
                        : endsAt17(cellProps.data[dayId].schedule)
                        ? '50%'
                        : '20%'
                    }
                  >
                    {(isCustomSchedule(cellProps.data[dayId].schedule) || props.userType === 'employee') && (
                      <span>
                        {cellProps.data[dayId].hourStart} - {cellProps.data[dayId].hourEnd}
                      </span>
                    )}
                  </Scheduled>
                )}
              {cellProps.data[dayId] && cellProps.data[dayId].schedule && cellProps.data[dayId].isHoliday && <Off />}
              {!cellProps.data[dayId] || (!cellProps.data[dayId].schedule && <Free />)}
            </Group>
          </div>
        );
      },
      cellStyle: {
        padding: 0,
        background: '#baeeff',
        color: isWeekendDate ? '#cf1e2f' : '#fff',
      },
    });
    day = addDays(day, 1);
  }

  return (
    <ScheduleDrviersTableContainer>
      <AgGridTableContainer>
        <AgGridTable columnDefs={columns} rowData={items} />
      </AgGridTableContainer>
    </ScheduleDrviersTableContainer>
  );
};
