import React, { FunctionComponent, useEffect } from 'react';

import { styled } from 'lib/ui';
import connect from 'react-redux/es/connect/connect';

export interface OrdersTableLegendProps {}

const OrdersTableLegendContainer = styled.div.attrs({ className: 'OrdersTableLegendContainer' })`
  & .dl-item {
    display: flex;
    align-items: center;
  }
  & dt {
    width: 50px;
    margin-right: ${p => p.theme.spaceSmall};
    font-size: ${p => p.theme.fontSizeLarge};
  }
  & dd {
    margin: 0;
  }
`;

const enhance = connect(
  ({ paymentTypes }) => ({
    paymentTypesStore: paymentTypes,
  }),
  ({ paymentTypes }) => ({
    paymentTypes,
  }),
);

export const OrdersTableLegend: FunctionComponent<OrdersTableLegendProps> = enhance(props => {
  return (
    <OrdersTableLegendContainer>
      <h4>Legenda</h4>
      <dl>
        {props.paymentTypesStore.data.map(paymentType => (
          <div className="dl-item" key={paymentType.id}>
            <dt>{paymentType.displayedName}</dt>
            <dd>{paymentType.namePl}</dd>
          </div>
        ))}
        <div className="dl-item">
          <dt>U</dt>
          <dd>Notatki</dd>
        </div>
        <div className="dl-item">
          <dt>E</dt>
          <dd>Extra dodatki</dd>
        </div>
        <div className="dl-item">
          <dt>*</dt>
          <dd>Nowy klient</dd>
        </div>
      </dl>
    </OrdersTableLegendContainer>
  );
});
