import { Collapse } from 'antd';
import { OrderNotificationModel } from 'modules/Orders/models/order-notification.model';
import React, { FunctionComponent } from 'react';

import { DateTime, Icon, Spacer, styled } from 'lib/ui';

const NotificationItem = styled.div.attrs({ className: 'NotificationItem' })`
  padding: ${p => p.theme.spaceSmall};
`;

export interface OrderSentMessagesProps {
  sentNotificationsForOrder: OrderNotificationModel[];
}

const OrderSentMessagesContainer = styled.div.attrs({ className: 'OrderSentMessagesContainer' })``;

export const OrderSentMessages: FunctionComponent<OrderSentMessagesProps> = props => {
  return (
    <OrderSentMessagesContainer>
      <div>
        <Spacer />
        <h2>Wysłane wiadomości</h2>
        <Spacer />

        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => (isActive ? <Icon name="caret-up" /> : <Icon name="caret-right" />)}
          expandIconPosition="left"
        >
          {props.sentNotificationsForOrder.map(sentNotification => (
            <Collapse.Panel
              header={
                <span>
                  <Spacer horizontal />
                  <DateTime datetime={sentNotification.sentDate} />
                </span>
              }
              extra={<Icon name="envelope" />}
              key={sentNotification.sentDate}
            >
              <NotificationItem>
                <div data-bp="grid">
                  <div data-bp="6">
                    Wysłana przez:
                    <Spacer horizontal />
                    <span>{sentNotification.sender.name || 'Brak danych'}</span>
                  </div>
                  <div data-bp="6">
                    Wysłana do:
                    <Spacer horizontal />
                    <span>{sentNotification.message.recipients.join(', ')}</span>
                  </div>
                </div>
              </NotificationItem>
              <div dangerouslySetInnerHTML={{ __html: sentNotification.message.emailBody }} />
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </OrderSentMessagesContainer>
  );
};
