import React, { FunctionComponent, ReactNode } from 'react';

import { styled, Price } from 'lib/ui';

export interface BalanceProps {
  balance: number;
}

const BalanceDefaultProps = {};

// TODO: p.theme is undefined here - why?
const BalanceContainer = styled('div').attrs({
  className: 'BalanceContainer',
})<{
  balanceState: 'green' | 'default' | 'red';
}>`
  color: ${p =>
    p.balanceState === 'green'
      ? `#72ac03`
      : p.balanceState === 'red'
      ? `#cf1e2f`
      : 'inherit'};
`;

export const Balance: FunctionComponent<BalanceProps> = props => {
  return (
    <BalanceContainer
      balanceState={
        props.balance > 0 ? 'green' : props.balance < 0 ? 'red' : 'default'
      }
    >
      <Price amount={props.balance} />
    </BalanceContainer>
  );
};

Balance.defaultProps = BalanceDefaultProps;
