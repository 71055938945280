export const getErrorMessageFromErrorResponse = errorResponse => {
  let message = 'Wystąpił nieoczekiwany błąd.';
  if (errorResponse && errorResponse.data) {
    if (errorResponse.data.detail) {
      message = errorResponse.data.detail;
    }
  }

  return message;
};
